import React from 'react';
import SuspenseLoader from '../../../../components/SuspenseLoader'
import { withRouter } from "react-router-dom";
import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleveredOrders from './DeleveredOrders';
import BasketOrders from './BasketOrders';
import PayedAndPending from './PayedAndPending';
import RejectList from'../RejectList';
import AllReportOrders from '../AllReportOrders';




const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);
class SingleClients extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      tabs: [
        {
          value: 'Доставени',
          label: 'Доставени'
        },
        {
          value: 'Во кошничка',
          label: 'Во кошничка'
        },
        {
          value: 'Посакувани извештаи',
          label: 'Посакувани извештаи'
        },
        {
          value: 'За достава',
          label: 'За достава'
        },
        {
          value: 'Одбиени',
          label: 'Одбиени'
        },
      ],
      selectedTab: 'Доставени',
      DeleveredReports: [],
      CurrentBasket: [],
      PayedAndPending: [],
      totalPrice: '',
    }
  }

  componentDidMount(){
    this.getAllDeleveredReports();
    this.getCurrentBasket();
    this.getAllPayedAndPanding();
  }



  getAllDeleveredReports = () => {
    window.makeRequest("GET", `/api/Admin_ReportOrders/allOrders?&getDelevered=true&getBasket=false&getPayedAndPanding=false`, false, true, (callback) => {
      this.setState({DeleveredReports: callback.body, loading:false})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  getCurrentBasket = () => {
    window.makeRequest("GET", `/api/Admin_ReportOrders/allOrders?&getBasket=true&getDelevered=false&getPayedAndPanding=false`, false, true, (callback) => {
      this.setState({CurrentBasket: callback.body.orders, totalPrice: callback.body.totalPrice})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  getAllPayedAndPanding = () => {
    window.makeRequest("GET", `/api/Admin_ReportOrders/allOrders?&getPayedAndPanding=true&getBasket=false&getDelevered=false`, false, true, (callback) => {
      this.setState({PayedAndPending: callback.body})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };


  handleTabsChange = (event, tabsValue) => {
    this.setState({selectedTab: tabsValue});
  };

  returnTab = () => {
    let showTab = this.state.selectedTab;
    if (showTab === 'Доставени') {
      return <DeleveredOrders DeleveredOrders={this.state.DeleveredReports} />;
    }
    if (showTab === 'Во кошничка') {
      return <BasketOrders CurrentBasket={this.state.CurrentBasket} totalPrice={this.state.totalPrice}/>;
    }
    if(showTab === 'За достава'){
      return <PayedAndPending PayedAndPending={this.state.PayedAndPending} getAllPayedAndPending={this.getAllPayedAndPanding}/>
    }
    if(showTab === 'Одбиени'){
      return <RejectList/>;
    }
    if(showTab === 'Посакувани извештаи'){
      return <AllReportOrders/>
    }


  };

  render() {
    if(this.state.loading){
      return <SuspenseLoader />
    } else {
      return <>
        <RootWrapper sx={{p: 3}}>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{xs: 'column', sm: 'row'}}
            justifyContent={{xs: 'center', sm: 'space-between'}}
            pb={3}
          >
            <TabsWrapper
              onChange={this.handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={this.state.selectedTab}
              variant="scrollable"
            >
              {this.state.tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label}/>
              ))}
            </TabsWrapper>
          </Box>
          <Card>
            <Divider/>
            <>
              {this.returnTab()}
            </>
          </Card>

        </RootWrapper>
      </>
    }
  }
}

export default withRouter(SingleClients);
