import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextareaAutosize,
  Button
} from '@mui/material';

class Import extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      companies: '',
    }
  }

  handleImportClose = () => {this.setState({open: false, companies: ''})};
  onChangeCompanies = (e, val) => {this.setState({companies: e.target.value})};
  ImportCompanies = () => {
    this.setState({open: false}, () => this.props.importCompanies(this.state.companies))
  }

  render(){
    return(<>
      <Button variant={'contained'} sx={{ml: 1.4}} onClick={() => this.setState({open: true})}>Импортирај</Button>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.state.open}
        onClose={this.handleImportClose}
      >
        <DialogTitle sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {'Импортирајте компании'}
          </Typography>
          <Typography variant="subtitle2">
            {
              'Внесете ги матичните броеви на компаниите што сакате да ги импортирате.'
            }
          </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <TextareaAutosize
                maxRows={6}
                onChange={this.onChangeCompanies}
                aria-label="maximum height"
                placeholder="Внесете ЕМБС/ЕДБ"
                style={{ width: '100%', minHeight: '300px' }}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button color="secondary" onClick={this.handleImportClose}>
            {'Откажи'}
          </Button>
          <Button
            type="submit"
            disabled={this.state.companies.length === 0}
            variant="contained"
            onClick={this.ImportCompanies}
          >
            {'Импортирај'}
          </Button>
        </DialogActions>
      </Dialog>
    </>)
  }
}


export default Import;
