import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

import Divider from '@mui/material/Divider';
import {
  Button,
  Card,
  Box,
  CardContent,
  CardHeader,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Hidden,
} from '@mui/material';

import TrafficSourcesChart from './TrafficSourcesChart';
import { styled } from '@mui/material/styles';


const TrafficSourcesChartWrapper = styled(TrafficSourcesChart)(
  ({ theme }) => `
        height: 250px;
`
);

function TrafficSources(props) {
  const { t } = useTranslation();

  const periods = [
    {
      value: 1,
      text: 'Приходи'
    },
    {
      value: 2,
      text: 'Расходи'
    },
    {
      value: 3,
      text: 'Просечен број на вработени'
    },
  ];

  const actionRef1 = useRef(null);
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  const [period, setPeriod] = useState('Приходи');





  return (<>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}>
        <Grid item  >
          {props.prosecni === false ?
            <CardHeader
              title={t('Показатели за ефикасност')}
            /> :
            <CardHeader
              title={t('Просечни показатели за ефикасност')}
            />
          }
        </Grid>


        <Grid item ml={2} mr={1} >
          <Button
            size="small"
            variant="outlined"
            ref={actionRef1}
            onClick={() => setOpenMenuPeriod(true)}
            endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
          >
            <Typography variant={'body1'}>{period}</Typography>
          </Button>
          <Menu
            anchorEl={actionRef1.current}
            onClose={() => setOpenMenuPeriod(false)}
            open={openPeriod}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {periods.map((_period) => (
              <MenuItem
                key={_period.value}
                onClick={() => {
                  setPeriod(_period.text);
                  setOpenMenuPeriod(false);
                }}
              >
                {_period.text}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      <Divider />
      <CardContent >
        <Box  >
          {period === 'Приходи' && (
            <Hidden >
              <Box  >
                <TrafficSourcesChartWrapper
                  text={period}
                  data={{current: props.Prihodi}}
                  labels={props.years}
                />
              </Box>
            </Hidden>
          )}
          {period === 'Расходи' && (
            <Hidden >
              <Box  >
                <TrafficSourcesChartWrapper
                  text={period}
                  data={{current: props.Rashodi}}
                  labels={props.years}
                />
              </Box>
            </Hidden>
          )}
          {period === 'Просечен број на вработени' && (
            <Hidden >
              <Box  >
                <TrafficSourcesChartWrapper
                  text={period}
                  data={{current: props.Vraboteni}}
                  labels={props.years}
                />
              </Box>
            </Hidden>
          )}
        </Box>
      </CardContent>
    </>
  );
}

export default TrafficSources;
