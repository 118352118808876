import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SuspenseLoader from '../../../../../../components/SuspenseLoader'
import { DataGrid } from '@mui/x-data-grid';
import {Link as RouterLink} from 'react-router-dom'
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone'


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false});

  }

  DataGridSubscription = () => {
    const columns = [
      {
        field: 'Company_name',
        headerName: 'Име',
        width: 850,
        headerAlign: 'center',
        editable: true,
        renderCell: (params) => {
          return<>
            <Typography noWrap>
              <Tooltip title={params.row.Company_name} arrow>
                <RouterLink  style={{textDecoration: 'none', color: 'black'}} to={`/company/${params.row.ID}/dashboard`}>
                  {params.row.Company_name}
                </RouterLink >
              </Tooltip>
            </Typography>
          </>
        }
      },
      {
        field: 'PIN',
        headerName: 'Матичен број',
        width: 170,
        editable: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'VAT',
        headerName: 'Даночен број',
        width: 230,
        headerAlign: 'center',
        align: 'center',
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'Active' +
        '',
        headerName: 'Активна',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        editable: true,
        disableColumnMenu: true,

      },

    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.props.CompanyList}
          columns={columns}
          pageSize={5}
          getRowId={row => row.ID}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  };

  returnBoxTitle = () => {
    return<Box display="flex" alignItems="center">
      <Box>
        <Typography variant="h3" component="h3" gutterBottom>
          {'Преглед на компании'}
        </Typography>
        <Typography variant="subtitle2">
          {
            'Преглед на сите компании на клиентот'
          }
        </Typography>
      </Box>
    </Box>
  };


  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={0}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            {this.returnBoxTitle()}
          </Box>
          {this.DataGridSubscription()}

        </RootWrapper>

      );
    }

  }


}


export default Companies;

