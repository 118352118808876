import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";
import Text from '../../../../components/Text';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

const Results = ({ users, ...rest }) => {


  const handleActiveDeactive = (id) => {
    window.makeRequest("PUT", `/api/Admin_AllUsers?id=${id}`, false, true, (callback) => {
      rest.renderUsers();
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  const columns = [

    {
      field: 'Firstname',
      headerName: 'Име',
      align: 'center',
      headerAlign: 'center',
      width: 150,
    },
    {
      field: 'Lastname',
      headerName: 'Презиме',
      align: 'center',
      headerAlign: 'center',
      width: 150,
    },
    {
      field: 'Username',
      headerName: 'Кориснчко име',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,

    },
    {
      field: 'Email',
      headerName: 'Е-маил',
      align: 'center',
      headerAlign: 'center',
      width: 350,
      disableColumnMenu: true,

    },
    {
      field: 'Telephone',
      headerName: 'Телефон',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,

    },
    {
      field: 'isActiv',
      headerName: 'Активен',
      renderCell: (params) => {
        return <>
          <Text>{params.row.isActiv? 'Активен': 'Неактивен'}</Text>

        </>
      },
      align: 'center',
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,

    },
    {
      field: 'MainUser',
      headerName: 'Главен корисник',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,

    },
    {
      field: 'UserType',
      headerName: 'Тип на корисник',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <>
          <Text>{params.row.UserType === 'main user'? 'Главен корисник': 'корисник'}</Text>

        </>
      },
      disableColumnMenu: true,
    },
    {
      field: '*',
      headerName: 'Менаџирај',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      type: 'text',
      renderCell: (params) => {
        return <><Tooltip title={'Преглед'} arrow>
          <IconButton
            component={RouterLink}
            to={`/AdminClients/${params.row.ID}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
          {
            params.row.isActiv === false ?
              <Tooltip title={('Активирај')} arrow>
                <IconButton
                  onClick={() => handleActiveDeactive(params.row.ID)}
                  color="primary"
                >
                  <DoneIcon fontSize="small"/>
                </IconButton>
              </Tooltip> :
              <Tooltip title={('Деактивирај')} arrow>
                <IconButton
                  onClick={() => handleActiveDeactive(params.row.ID)}
                  color="primary"
                >
                  <ClearIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
          }

        </>
      },
      disableColumnMenu: true,
    },
  ];



  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
              <BI_DataGrid data={users} columns={columns} search={true} />
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  users: []
};

export default Results;
