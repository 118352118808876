import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Slide,
  Tooltip,
  IconButton,
  Button,
  Typography,
  Dialog,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import BI_DataGrid from "../../../components_bi/BI_DataGrid";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const AllDivisionsResult = ({ divisions, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDivisionID, setDeleteDivisionID] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (ID) => {
    setDeleteDivisionID(ID);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {

    window.makeRequest("DEL", `/api/Division?id=${deleteDivisionID}`, false, true, (callback) => {
      console.log('callback', callback);
      rest.renderDivisions();
      setOpenConfirmDelete(false);
      setDeleteDivisionID(null);


    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });

    enqueueSnackbar('Портфолиото е успешно избришана', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };


  const columns = [

    {
      field: 'Name',
      headerName: 'Портфолио',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'CompanyNumber',
      headerName: 'Број на компании',
      headerAlign: 'center',
      width: 250,
      type:'number',
      align: 'center',
      disableColumnMenu: true,
    },
   {
      field: '#',
      headerName: rest.ManageDivisions ? 'Уреди' : '',
      headerAlign: 'center',
      width: 220,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        if( rest.ManageDivisions){
          return <><Tooltip title={'Уреди портфолио'} arrow>
            <IconButton
              component={RouterLink}
              to={'/edit/division/'+params.row.ID}
              onClick={(event) => {
                event.ignore = true
                handleConfirmDelete(params.row.ID)
              }}
              color="primary"
            >
              <LaunchTwoToneIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
            { params.row.ID !== 1 ?
              <Tooltip title={'Избриши портфолио'} arrow>
                <IconButton 
                  onClick={(event) => {
                    event.ignore = true
                    handleConfirmDelete(params.row.ID)
                  }}
                  color="primary">
                  <DeleteTwoToneIcon fontSize="small"/>
                </IconButton>
              </Tooltip> : ''
            }
            </>
        }


  }},



  ];
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
      <BI_DataGrid search={false} financeID={'id'} columns={columns} data={divisions} 
        onRowClick={(params, event) => {
          if(!event.ignore){
            window.location.href = `/divisions/${params.row.ID}`
          }
      }}/>


      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {'Дали сте сигурни да ја избришете дивизијата'}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {'Откажи'}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {'Избриши'}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

AllDivisionsResult.propTypes = {
  users: PropTypes.array.isRequired
};

AllDivisionsResult.defaultProps = {
  users: [],
  divisions: {},

};

export default AllDivisionsResult;
