import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
  { markerOffset: 15, name: "Скопје", coordinates: [21.43141, 41.99646] },
  { markerOffset: 15, name: "Прилеп", coordinates: [21.55504, 41.34514] },
  { markerOffset: 15, name: "Гостивар", coordinates: [20.90819, 41.79601] },
  { markerOffset: 15, name: "Битола", coordinates: [21.33474, 41.03143] },
  { markerOffset: 15, name: "Тетово", coordinates: [20.97155, 42.00973] },
  { markerOffset: 15, name: "Велес", coordinates: [21.77556, 41.71556] },
  { markerOffset: 15, name: "Штип", coordinates: [22.19583, 41.74583] },
  { markerOffset: 15, name: "Кавадарци", coordinates: [22.01194, 41.43306] },
  { markerOffset: 15, name: "Кочани", coordinates: [22.41278, 41.91639] },
  { markerOffset: 15, name: "Виница", coordinates: [22.50141, 41.14166] },
  { markerOffset: 15, name: "Делчево", coordinates: [22.76944, 41.96722] },
  { markerOffset: 15, name: "Куманово", coordinates: [21.71444, 42.13222] },
  { markerOffset: 15, name: "Охрид", coordinates: [20.80194, 41.11722] },
  { markerOffset: 15, name: "Струмица", coordinates: [22.64333, 41.4375] },
  { markerOffset: 15, name: "Струга", coordinates: [20.67784, 41.17799] },
  { markerOffset: 15, name: "Кичево", coordinates: [20.95886, 41.51267] },
  { markerOffset: 15, name: "Крива паланка",coordinates: [22.33194, 42.20194]},
  { markerOffset: 15, name: "Неготино", coordinates: [22.09056, 41.48456] },
  { markerOffset: 15, name: "Дебар", coordinates: [20.52421, 41.52444] },
  { markerOffset: 15, name: "Ресен", coordinates: [21.01222, 41.08889] }
];
const Map = (props) => {
  const MarkCities = [];

  const mdconfig = {
    rotate: [-22, -40.4, -12],
    scale: 9000
  };
  const lgconfig = {
    rotate: [-21.7, -41.3, -12],
    scale: 17500
  }

  if(props.gradovi){
    Object.keys(props.gradovi).map((row) => {
      markers.find((marker, index) => {
        if(marker.name === row){
          MarkCities.push(marker)

        }
      })
    })
  }

  if(props.city){
    markers.find((marker) =>{
      if(marker.name === props.city){
        MarkCities.push(marker);
      }
    })
  }

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={props.theme && props.theme === true ? lgconfig : mdconfig}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies
            .filter((d) => d.properties.NAME_LONG === "Macedonia")
            .map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
              />
            ))
        }
      </Geographies>
      {MarkCities.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="none"
            stroke="#FF5533"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor="middle"
            y={markerOffset}
            style={props.theme === true ? { fontFamily: "system-ui", fill: "#5D5A6D", fontSize: '35px' } : { fontFamily: "system-ui", fill: "#5D5A6D", fontSize: '20px' }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default Map;
