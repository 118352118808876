import React from 'react';
import SuspenseLoader from '../../../../../components/SuspenseLoader'
import { withRouter, Link as RouterLink } from "react-router-dom";

import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import UserManagement from './UserManagement'
import Permissions from './Permissions'


const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);
class SingleUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      tabs: [
        {
          value: 'Корисник',
          label: 'Корисник'
        },
        {
          value: 'Пермисии',
          label: 'Пермисии'
        },
      ],
      selectedTab: 'Корисник',
      SubscriptionList: [],
      user: '',
    }
  }

  componentDidMount(){
    this.getUser();
  }


  getUser = () => {

    window.makeRequest("GET", `/api/Admin_Users/getSingle?id=${this.props.match.params.id}&getSingle=true`, false, true, (callback) => {
      this.setState({user: callback.body});

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });


   this.setState({loading: false})
  }



  handleTabsChange = (event, tabsValue) => {
    this.setState({selectedTab: tabsValue});
  };

  returnTab = () => {
    let showTab = this.state.selectedTab;
    if (showTab === 'Корисник') {
      return <UserManagement user={this.state.user}/>;
    }
    if(showTab === 'Пермисии'){
      return <Permissions user={this.state.user}/>
    }

  }

  render() {
    if(this.state.loading){
      return <SuspenseLoader />
    } else {
      return <>
        <RootWrapper sx={{p: 3}}>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{xs: 'column', sm: 'row'}}
            justifyContent={{xs: 'center', sm: 'space-between'}}
            pb={3}
          >
            <TabsWrapper
              onChange={this.handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={this.state.selectedTab}
              variant="scrollable"
            >
              {this.state.tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label}/>
              ))}
            </TabsWrapper>
            <Tab component={RouterLink} to={'/AllUsers'} style={{color: 'blue'}} label={'<- Назад'}/>
          </Box>
          <Card>
            <Divider/>
            <>
              {this.returnTab()}
            </>
          </Card>

        </RootWrapper>
      </>
    }
  }
}

export default withRouter(SingleUsers);
