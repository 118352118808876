import React from 'react'
import Results from './Results';
import { Grid } from '@mui/material';

class AllUsers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount(){

   this.renderUsers();

  }

  renderUsers = () => {

    window.makeRequest("GET", `/api/Administrator?users=true`, false, true, (callback) => {
      this.setState({users: callback.body});

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  render(){
    return(

      <Grid container>
        <Grid item sx={{p: 2}} xs={12}><Results users={this.state.users} renderUsers={this.renderUsers}/></Grid>
      </Grid>



    )
  }

}



export default AllUsers;
