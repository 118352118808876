import React, { Component } from 'react';
import { forwardRef } from 'react';
import {withRouter} from 'react-router';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import {
  Avatar,
  Box,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
} from '@mui/material';
import FormattedInput from "../../../components_bi/NumberFormating/FormattedInput";


const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

class SingleReportResultReportForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: true,
      id: '',
      open: false,
      openGenerateError: false,
      aopError: '',
      generateError: '',
    };
  }



  componentDidMount() {

    this.setState({id: this.props.match.params.id})

  }
  thisYearonTextFieldChange = (aop, value) => {
    if (value) {
      this.props.data2[aop] = parseInt(value);
    }
    else {
      this.props.data2[aop] = value;
    }
  };


  handleEditFinance = () => {

    if(this.validate()) {
      window.makeRequest('PUT', `/api/Finance?id=${this.state.id}&isDraft=true`,
        this.props.data2
        , true, (callback) => {
          window.location.href = `/company/${this.props.companyID}/dashboard`
        }, (err) => {
          if (err) {
            console.log('err', err);
            this.setState({ success: false });

          }
        });
    }
    else {
      this.setState({open: true});
    }
  };

  closeCannotGenerate = () => {
    this.setState({openGenerateError: false});
  };

  closeFinanceError = () => {
    this.setState({open: false, aopError: ''})
  };

  validateGenerate = () =>{

    let data = this.props.data2;
    let counter = 0;


    this.props.validatedFields.map((field) => {
      if(data[field]){
        counter++;
      }
    });

    if(counter >= 2){
      return true;
    }
    else{
      this.setState({generateError: 'Не може да се генерира репорт бидејки немате внесено доволно АОП позиции.'})
      return false
    }

  };

  validate = () => {
    let data = this.props.data2;

    let thisYearAOP111 = data.AOP111;
    let thisYearAOP63 = data.AOP63;
    let thisYearAOP255 = data['AOP255'];
    let thisYearAOP256 = data['AOP256'];
    let thisYearAOP77 = data['AOP77'];
    let thisYearAOP78 = data['AOP78'];
    let thisYearAOP258 = data['AOP258'];

    let aopError = '';
    let check255and256 = false;
    let check77and255 = false;
    let check78and256 = false;
    let check258 = false;
    let check63and111 = false;



    for (const [key, value] of Object.entries(data)) {
      if (key.indexOf('AOP') > -1) {

        if (key !== 'AOP65') {
          switch (true) {
            case value < 0:
              aopError = 'Негативен износ не смее да се прифати освен во AOP65';
              break;
          }
        }
      }
    }

    if(thisYearAOP111 || thisYearAOP63){
      if(thisYearAOP111 !== thisYearAOP63){
        check63and111 = true;
      }
    }



    if(thisYearAOP255 || thisYearAOP256){
      if(thisYearAOP255 && thisYearAOP256){
        if(thisYearAOP255 !== 0 || thisYearAOP256 !== 0){
          check255and256 = true
        }
      }
    }



    if(thisYearAOP77 && thisYearAOP255) {
      if (!thisYearAOP77 || thisYearAOP77 === 0) {
        if (thisYearAOP255 && thisYearAOP255 !== 0) {
          check77and255 = true
        }
      }
      if(!thisYearAOP255 || thisYearAOP255 === 0) {
        if(thisYearAOP77 && thisYearAOP77 !== 0){
          check77and255 = true
        }
      }
    }

    if(thisYearAOP78 && thisYearAOP256) {
      if (!thisYearAOP78 || thisYearAOP78 === 0) {
        if (thisYearAOP256 && thisYearAOP256 !== 0) {
          check78and256 = true
        }
      }
      if (!thisYearAOP256 || thisYearAOP256 === 0) {
        if (thisYearAOP78 && thisYearAOP78 !== 0) {
          check78and256 = true
        }
      }
    }


    if(thisYearAOP258){
      if(thisYearAOP258 === 0 || thisYearAOP258 > 12){
        check258 = true;
      }
    }



    switch (true) {
      case check63and111:
        aopError = 'Позициите АОП111 и АОП63 мора да бидат еднакви.';
        break;
      case check258:
        aopError = 'Позицијата АОП258 мора да содржи број од 1 до 12.';
        break;
      case check77and255:
        aopError = 'Позициите АОП77 и АОП255 мора да бидат еднакви.';
        break;
      case check78and256:
        aopError = 'Позициите АОП78 и АОП256 мора да бидат еднакви';
        break;
      case check255and256:
        aopError = 'Во позициите АОП255 и АОП256 неможе да биде содржана вредност истовремено.';
        break;
    }

    if (aopError) {
      this.setState({ aopError });
      return false;
    }

    return true;
  };

  handleEditAndSave = () => {
    if (this.validateGenerate()) {
    if(this.validate()) {
        window.makeRequest("GET", `/api/Finance/single?id=null&Year=${this.props.data2.Godina - 1}&reportTypeID=${this.props.data2.Report_type_ID}&companyID=${this.props.data2.Company_ID}`, false, true, (callback) => {

          window.makeRequest('PUT', `/api/Finance?id=${this.state.id}&isDraft=false`, this.props.data2, true, (put) => {
            window.makeRequest('POST', `/api/Indicator?financeID=${this.state.id}`,
              {thisYear: this.props.data2, lastYear: callback.body}
              , true, (post) => {
                window.location.href = `/company/${this.props.companyID}/dashboard`
              }, (err) => {
                if (err) {
                  console.log('err', err);
                }
              });
          }, (err) => {
            if (err) {
              console.log('err', err);
              this.setState({success: false});
            }
          });

        }, (err) => {
          if (err) {
            console.log(err);


          }

        });

      }
      else {
        this.setState({open: true});
      }
    }
    else {
      this.setState({openGenerateError: true})
    }
  };

  openMessage = () => {
    this.setState({
      aopError: 'Не можете да генерирате извештај бидејки не постои извештај за предходната година',
      open: true
    });
    this.setState({open: true})
  }


  render() {

    return (

      <div>

        <Grid container justifyContent="flex-end">

          <Grid item>
            <Button variant={'outlined'} size={'small'} sx={{ m: 1 }} onClick={this.handleEditAndSave}>Зачувај и генерирај</Button>
          </Grid>
          <Grid item>
            <Button variant={'outlined'} size={'small'} sx={{ m: 1 }} onClick={this.handleEditFinance}>Зачувај</Button>
          </Grid>

        </Grid>


        <TableContainer style={{height: '700px'}}>
          <Table stickyHeader>
            <TableHead >
              <TableRow >
                <TableCell>{'Позиција'}</TableCell>
                <TableCell align={'center'}>{'Назив'}</TableCell>
                <TableCell align={'center'}>{'Тековна година'}</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.fields && this.props.fields.map(aop => {
                  return (
                    <TableRow style={{height: 60}}>
                      <TableCell>
                        <Typography variant="h5">
                          {aop}
                        </Typography>
                      </TableCell>
                      <TableCell align={'center'}>{this.props.titles[aop]}</TableCell>
                      <TableCell align={'center'}>
                            <FormattedInput
                              type='text'
                              size='small'
                              name={aop}
                              className='w-full'
                              defaultValue={this.props.data2[aop]}
                              onChange={(event) => this.thisYearonTextFieldChange(aop, event.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>

        <DialogWrapper
          open={this.state.open}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={this.closeFinanceError}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
          >
            <AvatarError>
              <CloseIcon />
            </AvatarError>

            <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
              {this.state.aopError}
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                sx={{ mx: 1 }}
                onClick={this.closeFinanceError}
              >
                {('Во ред')}
              </Button>
            </Box>
          </Box>
        </DialogWrapper>

        <DialogWrapper
          open={this.state.openGenerateError}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={this.closeCannotGenerate}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
          >
            <AvatarError>
              <CloseIcon />
            </AvatarError>

            <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
              {this.state.generateError}
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                sx={{ mx: 1 }}
                onClick={this.closeCannotGenerate}
              >
                {('Во ред')}
              </Button>
            </Box>
          </Box>
        </DialogWrapper>

      </div>
    );
  }

}

export default withRouter(SingleReportResultReportForm);
