import React from 'react';


import {
  Box,
  Button,
} from '@mui/material';

class BulkActions extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading: true,
    }

  }

 componentDidMount() {

 }

 AddToBasket = () => {
    if(this.props.selectedItems && this.props.selectedItems.length > 0){
      let companies = this.props.selectedItems;
      const addedReports = [];
      const foundReports = [];
      const rejectedReports = [];

      companies.forEach((company, i) => {

        // console.log("Godina", this.props.lastFinancialYear);

        window.makeRequest("POST", `/api/ReportOrders`, {
          VAT: company.VAT,
          PIN: company.PIN,
          Year: this.props.lastFinancialYear,
          Company_name: company.Company_name,
        }, true, (callback) => {
          if(callback.body.Response.ReasonPhrase === 'OK'){
            addedReports.push(company.Company_name);
            window.isTokenValid();
          } else if(callback.body.Response.ReasonPhrase === 'Found'){
            foundReports.push(company.Company_name);
          }else {
            rejectedReports.push(company.Company_name);
          }

          if(companies.length === i+1){
            this.props.close('created',addedReports,foundReports,rejectedReports);
          }
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        });



      })

    } else {
      this.props.close('closed')
    }



 }



 render() {
   return (
     <>
       <Box display="flex" alignItems="center" justifyContent="end" >
         <Button
           sx={{ ml: 1,}}
           variant="contained"
           onClick={this.AddToBasket}
         >
           {'Додади во кошничка.'}
         </Button>
       </Box>
     </>
   );
 }

}


export default BulkActions;
