import React from 'react';

import {
  Grid,
  TextField,
  Card,
  Button,
  Autocomplete,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Typography,
  Input,
  FilledInput,
  OutlinedInput,
  InputLabel,
  FormControl,
  InputAdornment,
  Box
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Label from '../../../../../components/Label'
import Text from '../../../../../components/Text'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AdditionalCustomInput from "./AdditionalCustomInput";
import numeral from "numeral";
import TotalAmountComponent from "./TotalAmountComponent";


class AdditionalPlan extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      additionalUsers: 0,
      additionalDivsions: 0,
    }
  }
  componentDidMount(){

  }


  handleChange = (e) => {
    let value = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    this.setState({[e.target.name]: value}, () => this.props.selectAdditionalPlan(this.state.additionalUsers, this.state.additionalDivsions))
  }

  AddUser = () => {
    this.setState({additionalUsers: this.state.additionalUsers+1}, () => this.props.selectAdditionalPlan(this.state.additionalUsers, this.state.additionalDivsions))
  }
  RemoveUser = () => {
    if(this.state.additionalUsers !== 0){
      this.setState({additionalUsers: this.state.additionalUsers-1}, () => this.props.selectAdditionalPlan(this.state.additionalUsers, this.state.additionalDivsions))
    }


  }
  AddDivision = () => {
    this.setState({additionalDivsions: this.state.additionalDivsions+1}, () => this.props.selectAdditionalPlan(this.state.additionalUsers, this.state.additionalDivsions))
  }
  RemoveDivision = () => {
    if(this.state.additionalDivsions !== 0){
      this.setState({additionalDivsions: this.state.additionalDivsions-1}, () => this.props.selectAdditionalPlan(this.state.additionalUsers, this.state.additionalDivsions))

    }

  }


  render(){

    return <Grid  container xs={12} spacing={1} py={1.5} >
          <Grid item xs={3}  >
            <AdditionalCustomInput
              label={'Дополнителни корисници'}
              name='additionalUsers'
              value={this.state.additionalUsers}
              onChange={this.handleChange}
              ButtonIncrement={this.AddUser}
              ButtonDecrement={this.RemoveUser}
            />
          </Grid>
          <Grid item xs={3} >
            <AdditionalCustomInput
              label={'Дополнителни портфолија'}
              name='additionalDivsions'
              value={this.state.additionalDivsions}
              onChange={this.handleChange}
              ButtonIncrement={this.AddDivision}
              ButtonDecrement={this.RemoveDivision}
            />
          </Grid>
          <Grid item xs={3}  display={'block'} justifyContent={'center'} alignContent={'center'}  >
            {/*/!*<TotalAmountComponent totalAmount={this.returnTotalPrice()} />*!/*/}
          </Grid>
          <Grid item xs={3} display={'flex'} justifyContent={'end'} alignContent={'end'} >
            {this.props.button}
          </Grid>
    </Grid>
  }
}

export default AdditionalPlan;
