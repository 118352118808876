import {  Box, CardHeader,  CardContent,  Grid, CircularProgress } from '@mui/material';
import Label from '../../../../../components/Label'
import Map from '../LocationDivision/Map';
import useMediaQuery from "@material-ui/core/useMediaQuery/index";
import {useTheme} from "@mui/material/index";

function LocationCard(props) {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('lg'));


  return (
    <>
      <Grid
        sx={{p: 0}}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} >
          <Box display={'flex'}>
          <CardHeader
            sx={{p: 0,}}
            title={'Локација'}
            subheaderTypographyProps={{variant: 'body1'}}
            titleTypographyProps={{variant: 'h3', gutterBottom: true}}
          >
          </CardHeader>
          </Box>
        </Grid>

        <Grid item display={'flex'} md={12}
              mt={-1}
              container
              direction="row"
              justifyContent="center"
        >
          <Grid item sm={6} md={6} >
            <CardContent>
              <Map theme={matches} city={props.company.City} />
            </CardContent>
          </Grid>
          <Grid item sm={6}  md={6}>
            <Box  mt={-3} display={'block'}>
              <Box display={'block'}>
                <Label
                  color={'primary'}
                >Дејност</Label>
                <Label style={{maxHeight: 80, fontSize: 11, marginTop: 4, marginBottom: 4}} >{props.dejnost ? props.dejnost : <CircularProgress style={{width: '10px'}}/>}</Label>
              </Box>
              <Box  display={'block'}>
                <Label
                  color={'primary'}
                >Адреса</Label>
                <Label style={{maxHeight: 80, fontSize: 11, marginTop: 4}} >{props.company.Adress}</Label>
              </Box>
            </Box>

          </Grid>
        </Grid>
      </Grid>

  </>
  );
}

export default LocationCard;

