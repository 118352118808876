import React from 'react';
import { Card, Grid } from '@mui/material'
import LocationCard from './LocationCard'
import WarningCard from './WarningCard'
import StatusCard from './StatusCompanyCard'
import LocationDivision from './LocationDivision';
import IndustryCard from './IndustryCard';
import SizeCard from './SizeCard';

class BottomCards extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {

  }

  returnCard = () => {
    const type = this.props.type;
    const cities = this.props.cities;
    const dejnost = this.props.dejnost;
    const dejnosti = this.props.dejnosti;
    const gradovi = this.props.gradovi;
    const starost = this.props.starost;
    const golemina = this.props.golemina;
    switch(type) {
      case 'location':
        return <LocationCard dejnost={dejnost} company={this.props.company} />;
      case 'warning':
        return <WarningCard  data={this.props.data} noData={this.props.noData}/>;
      case 'status':
        return <StatusCard company={this.props.company} />;
      case 'locationDivision':
        return <LocationDivision gradovi={gradovi}  />;
      case 'industry':
        return <IndustryCard dejnosti={dejnosti} />;
      case 'companySize':
        return <SizeCard starost={starost} golemina={golemina} company={this.props.company} />;

    }

  }



  render() {
    return<>
      <Card
        sx={{
          overflow: 'visible',
          position: 'relative',
          p: 2,
          minHeight: 220,
          maxHeight: 220,
        }}
        variant="outlined"
      >
        {this.props.type && this.returnCard()}
      </Card>

      </>

  }
}

export default BottomCards;
