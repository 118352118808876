import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
        <SidebarProvider>
          <BrowserRouter>
              <App />
          </BrowserRouter>
        </SidebarProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
