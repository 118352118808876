import * as React from 'react';
import PropTypes from 'prop-types';

import {
  DataGrid,
  GridToolbar,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,

} from '@mui/x-data-grid';
import {
  Box,
  Tooltip,
  IconButton,
  TextField,
  Typography,
  Button,
} from '@mui/material';


import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';



export default function BI_DataGrid(props) {
  const data = props.data;
  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(data);
  const [height, setHeight] = React.useState(694);
  const [changedHeight, setChangedHeight] = React.useState(false);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  if(props.dynamicHeight && props.dynamicHeight === true && !changedHeight) {
    if(rows.length <= 10){
      let newheight = 218 + (rows.length * 48);
      setHeight(newheight)
      setChangedHeight(true)
    }
    else{
      let newheight = 218 + (480);
      setHeight(newheight)
      setChangedHeight(true)
    }
  }
  return (
    <Box justify={'center'} alignItems={'center'} style={{ height: height, width: '100%', backgroundColor: 'white'}}>
      <DataGrid
        style={{fontSize: 14}}
        rows={rows}
        alignContent={'center'}
        columns={props.columns}
        initialState={props.initialState}
        onRowClick={props.onRowClick}
        sx={{
          "&:hover:not(.Mui-disabled)": {
            cursor: "pointer"
          },
          "& .MuiDataGrid-root": {
            outline: "none !important"
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important"
          }
          ,
          "& .css-95mzio-MuiDataGrid-root": {
            outline: "red"
          }
        }}
        disableColumnMenu
        pageSize={20}
        getRowId={props.id === 'id' ? row=> row.id : props.financeID === 'financeID' ? row => row.financeID : row => row.ID}
        rowsPerPageOptions={[5]}
        components={{ Toolbar: props.search === true ? QuickSearchToolbar : null, NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {props.noRowsMessage || 'Нема податоци'}
            </Stack>
          ), ColumnSortedAscendingIcon: null, ColumnSortedDescendingIcon: null}}
        componentsProps={{
          toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
          },
        }}
        disableSelectionOnClick
      />


    </Box>
  );
}



function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        ml: 1,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Пребарувај…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Избриши"
              aria-label="Избриши"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};


