import React from 'react';
import {
  Typography,
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  CardHeader,
  Menu,
  MenuItem,
  Hidden,

} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import Label from '../../../../components/Label'

class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      years: [],
      open: false,
      anchorReference: 'anchorEl',
      anchorEl: null,
      changed: false,
      year: '',
    }
  }
  componentDidMount() {
    const loadedYears = this.props.years.filter((year) => year !== this.props.year);

    this.setState({years: loadedYears, year: this.props.year})
  }

  setOpenMenu = (event) => {
    this.setState({open: true, anchorEl: event.currentTarget});
  }

  changeYear = (year) => {
      this.setState({year: year, open: false, changed: true});
      this.props.reloadDivision(year);
  }

  render() {

    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography color='primary' variant="h3" component="h3">
              {this.props.name}
            </Typography>
            <Typography variant="h4" mt={1}>
              {'Анализатор на портфолио'}
            </Typography>
          </Grid>

         <Grid item display={"flex"}>
          <Grid item sx={{mr: 1.5}}>
            <Button
              component={RouterLink}
              to={`/edit/division/${this.props.divisionID}`}
              variant="outlined"
            >
              Уреди портфолио
            </Button>

          </Grid>

          <Grid item>
            <Button
              component={RouterLink}
              to={'/AllDivisions'}
              variant="outlined"
              startIcon={'<-'}
            >
              Назад
            </Button>
          </Grid>


          </Grid>

        </Grid>
        {/*<Grid item display={'flex'} justifyContent={'start'} alignItems={'center'} mt={1} >*/}
          {/*<Label style={{fontSize: 16}} color='primary'>Година на приказ</Label>*/}
          {/*<Box ml={1}>*/}
            {/*<Button*/}
              {/*size="small"*/}
              {/*variant="outlined"*/}
              {/*onClick={this.setOpenMenu}*/}
              {/*endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}*/}
            {/*>*/}
              {/*<Typography style={{fontSize: 13}} variant={'body2'}>{this.state.year}</Typography>*/}
            {/*</Button>*/}
            {/*<Menu*/}
              {/*onClose={() => this.setState({open: false})}*/}
              {/*open={this.state.open}*/}
              {/*anchorEl={this.state.anchorEl}*/}
            {/*>*/}
              {/*{this.state.years.length > 0 ? this.state.years.map((year, index) => (*/}
                  {/*<MenuItem*/}
                    {/*key={year+index}*/}
                    {/*onClick={() => this.changeYear(year)}*/}
                  {/*>*/}
                    {/*{year}*/}
                  {/*</MenuItem>*/}
                {/*)) :*/}
                {/*<MenuItem*/}
                {/*>*/}
                  {/*Нема прикажани години!*/}
                {/*</MenuItem>*/}
              {/*}*/}
            {/*</Menu>*/}
          {/*</Box>*/}
        {/*</Grid>*/}
      </>
    );
  }

}

export default PageHeader;
