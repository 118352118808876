import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const ImgWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 5;
    width: 90%;
    padding: 15px;
    height: 400px;
    overflow: hidden;
    border-radius: ${theme.general.borderRadiusLg};
    img {
      width: 95%;
      height: 100%;
      box-shadow: 7px 7px 5px #aaaaaa;
    }
  `
);

const BoxAccent = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadiusLg};
    // background: ${theme.palette.background.default};
    width: 80%;
    height: 100%;
    position: absolute;
    bottom: -30px;
    display: block;
    z-index: 4;
  `
);

const BoxContent = styled(Box)(
  () => `
  width: 150%;
  position: relative;
  `
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.success.main};
    color: ${theme.palette.success.contrastText};
    font-weight: bold;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    font-size: ${theme.typography.pxToRem(11)};
    padding: ${theme.spacing(.5)} ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(2)};
`
);

const ListItemWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
`
);

const MuiAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #e5f7ff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

const JsAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #fef8d8;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

function Hero() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{backgroundColor: 'white', paddingBottom: '15px'}}>
      <Grid spacing={{ xs: 6, md: 10 }} justifyContent="center" alignItems="center" container>
        <Grid item md={6} pr={{ xs: 0, md: 3 }}>
          <TypographyH1 sx={{ mb: 2 }} variant="h2">
            {t('Bonitet.mk – за успешни бизниси!')}
          </TypographyH1>

          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            <b>Bonitet.mk </b>
            {
              t(
              'е интернет платформа за анализа на финансиските перформанси на компаниите. Корисници на оваа платформа се лица кои работат со анализа на компании, претприемачи, финансиски професионалци и сите оние лица кои работат на контрола и следење на конкурентите и соработниците.'
            )}
          </TypographyH2>
          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 2 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            <b>Bonitet.mk </b>
            {t(
              'овозможува брз и едноставен начин на:'
            )}
          </TypographyH2>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'анализирање на финансиските перформанси на компаниите'
                )}
            </TypographyH2>
          </div>
          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'следење и контрола на финансиските индикатори'
                )}
            </TypographyH2>
          </div>
          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'графичко прикажување на работењето на компаниите'
                )}
            </TypographyH2>
          </div>
          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'пресметување на кредитен рејтинг на компаниите'
                )}
            </TypographyH2>
          </div>
          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 4 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'добивање информација за просечните вредности остварени од одредено портфолио на компании'
                )}
            </TypographyH2>
          </div>

          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            Интернет платформата <b>Bonitet.mk </b>
            {
              t(
                'е целосно прилагодена на потребите и барањата на компаниите, овозможувајќи им лесно и едноставно користење и добивање квалитетна финансиска анализа врз основа на внесените податоци.'
              )}
          </TypographyH2>

          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            <b>Bonitet.mk </b>
            {
              t(
                '\n' +
                'ја зголемува продуктивноста, економичноста и ефикасноста на луѓето во деловниот свет.'
              )}
          </TypographyH2>

          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 2 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            Некои од многубројните начини преку кои <b>Bonitet.mk </b>
            {
              t(
                '\n' +
                'го подобрува секојдневното деловно работење на компаниите се:'
              )}
          </TypographyH2>

          {/*-----------*/}
          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Финансиска анализа на бизнис партнерите'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Финансиска анализа и бенчмаркинг на конкуренцијата'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Интерно и екстерно известување'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Лесен начин на вчитување на електронски годишни сметки (PDF и excel формат)'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Автоматизирана обработка на скенирани годишни сметки'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Анализа на сопственото финансиско работење и негова споредба по различни основи'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 4 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Екстракт на податоци за одредени сегменти од работењето (во excel формат)'
                )}
            </TypographyH2>
          </div>
          {/*-----*/}

          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 2 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            Позначајни придобивки за компаниите од користењето на <b>Bonitet.mk се:</b>
            {
              t(
                '\n' +
                ''
              )}
          </TypographyH2>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Намален ризик'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Намалени трошоци'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Намалено време за подготовка и интерпретација на финансиска анализа'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 1 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Лесно, брзо и доверливо користење на апликацијата'
                )}
            </TypographyH2>
          </div>

          <div style={{display: 'flex'}}>
            <ArrowRightIcon style={{fontSize: '30px'}} color={'primary'}/>
            <TypographyH2
              sx={{ lineHeight: 1.5, pb: 4 }}
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {
                t(
                  'Можност за поделба на деловни портфолија на одредени вработени согласно потребите во организациската структура на компаниите'
                )}
            </TypographyH2>
          </div>

          <h2>Креирајте го Вашиот профил!</h2>

          <Button

            component="a"
            href="/register"
            size="large"
            variant="contained"
          >
            {t('Регистрирај се бесплатно.')}
          </Button>
        </Grid>
        <Grid item md={6}>
          <BoxContent>
            <ImgWrapper>
              <img alt="Tokyo" src="/static/images/overview/FP1.png" />
            </ImgWrapper>
            <ImgWrapper>
              <img alt="Tokyo" src="/static/images/overview/FP22.png" />
            </ImgWrapper>
            <ImgWrapper>
              <img alt="Tokyo" src="/static/images/overview/FP3.png" />
            </ImgWrapper>
            <ImgWrapper>
              <img alt="Tokyo" src="/static/images/overview/FP4.png" />
            </ImgWrapper>
            <BoxAccent sx={{ display: { xs: 'none', md: 'block' } }} />
          </BoxContent>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
