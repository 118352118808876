import {
  Grid,
  Typography,
} from '@mui/material';

const PageHeader = () => {

  return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {('Преглед на кошничка')}
          </Typography>
          <Typography variant="subtitle2" style={{color: "red"}}>
            {(
              'Секој купен извештај е официјален годишен извештај.'
            )}
          </Typography>
        </Grid>
      </Grid>

  );
};


export default PageHeader;
