import React from 'react';
import * as XLSX from 'xlsx';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Box,
  Button
} from '@mui/material';
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';
class ImportDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount(){

  }

  handleDownloadTemplate = () => {
    let wb = XLSX.utils.book_new();
    const headerEMBS = 'ЕМБС или ЕДБ';
    let wscols = [{wch:20}];
    const sheet = XLSX.utils.json_to_sheet([{}], {header: [headerEMBS]});
    sheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, sheet);
    XLSX.writeFile(wb, 'Темплејт-импорт-компании.xlsx');
    let a = document.createElement('a');
    a.download = wb;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  render(){
    return(
      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.props.open}
        onClose={this.props.handleImportClose}
      >
        <DialogTitle sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {'Импортирајте компании во портфолиото'}
          </Typography>
          <Typography variant="subtitle2">
            {
              'Прикачете го документот со кој ќе извршите импортирање на компании и притоа следете го образецот даден подолу.'
            }
          </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <TextField type="file" name={'file'} onChange={this.props.onChangeFile}/>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }} >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Button
                onClick={this.handleDownloadTemplate}
                size="small"
                color="secondary"
                variant="outlined"
                startIcon={<CloudDownloadTwoToneIcon />}
              >
                {'Превземи темплејт'}
              </Button>
            </Box>
            <Box>
              <Button color="secondary" onClick={this.props.handleImportClose}>
                {'Откажи'}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={this.props.handleUploadFile}
              >
                {'Импортирај'}
              </Button>
            </Box>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ImportDialog;
