import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SuspenseLoader from '../../../../../../components/SuspenseLoader'
import { DataGrid } from '@mui/x-data-grid';

import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone'


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false});

  }

  DataGridSubscription = () => {
    const columns = [
      {
        field: 'Firstname',
        headerName: 'Име',
        width: 200,
        editable: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Lastname',
        headerName: 'Презиме',
        width: 200,
        editable: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Username',
        headerName: 'Корисничко име',
        width: 200,
        editable: true,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Email',
        headerName: 'Е-пошта',
        width: 300,
        editable: true,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Telephone',
        headerName: 'Телефон',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        editable: true,
        disableColumnMenu: true,
      },
      {
        field: 'isActiv',
        headerName: 'Активен',
        width: 150,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
      },


    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.props.UserList}
          columns={columns}
          pageSize={5}
          getRowId={row => row.ID}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  }

  returnBoxTitle = () => {
    return<Box display="flex" alignItems="center">
      <Box>
        <Typography variant="h3" component="h3" gutterBottom>
          {'Преглед на корисници'}
        </Typography>
        <Typography variant="subtitle2">
          {
            'Преглед на сите корисници на клиентот'
          }
        </Typography>
      </Box>
    </Box>
  }


  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={0}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            {this.returnBoxTitle()}
          </Box>
          {this.DataGridSubscription()}

        </RootWrapper>

      );
    }

  }


}


export default Users;

