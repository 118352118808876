import React from 'react';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import WeatherCard from '../WeatherCard';


import DataGridDivisionDashboard from './DataGridDivisionDashboard/index';
import Chart from '../ChartDisplay'
import BottomCards from '../BottomCards'
import {withRouter} from 'react-router';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';

import Label from '../../../../components/Label'
import MiniBoxes from './MiniBoxes';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BuyDivisionDialogOutdated from "../DIalogs/BuyDivisionDialogOutdated";
import WeatherLegendDialog from '../DIalogs/WeatherLegendDialog';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {styled} from "@mui/material/styles/index";
import {Avatar} from "@mui/material/index";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

class DivisionDashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      active: null,
      data: [],
      company: '',
      name: '',
      displayWeather: '',
      openConfirmDelete: false,
      companies: [],
      companiesInDivision: [],
      DivisionCompanies: [],
      usersDivions: [],
      weatherData: {
        denoviNaNaplata: null,
        denoviNaIsplata: null,
        brzaLikvidnost: null,
        zadolzenost: null,
        Ocena: null,
        periodVrakanjeKrediti: null,
      },
      chartData: [],
      companies_izvestai: [],
      cities:[],
      dejnost: [],
      size: [],
      age: [],
      year: '',
      dejnosti: [],
      golemina: [],
      gradovi: [],
      starost: [],
      tableData: [],
      years: [],
      tableYears: [],
      tableYear: '',
      open: false,
      anchorReference: 'anchorEl',
      anchorEl: null,
      changed: false,
      lastFinancialYear: '',
      allCompanies: '',
      allCompaniesWithLastYearReports: '',
      allCompaniesWithoutLastYearReports: '',
      outDatedCompanies: [],
      openBuyDivisionDialogOutedated: false,
      openWeatherLegendDialog: false,
      add: false,
      found: false,
      rejected: false,
      companiesNames: ''


    }
  }

  componentDidMount() {
    this.getDivisionWeatherAndChart();
    this.getAllCompanies();
    this.getAllUsers();
    this.getDivisionIsActive();

  }

  getDivisionWeatherAndChart = () => {
    window.makeRequest("GET", `/api/DivisionDashboard?divisionID=${this.props.match.params.id}`, false, true, (callback) => {
      if(callback.body && callback.body.hasOwnProperty('weatherData')){
        this.setState({
          weatherData: callback.body.weatherData,
          chartData: callback.body.chartData,
          dejnosti:callback.body.dejnosti,
          golemina:callback.body.golemina,
          gradovi:callback.body.gradovi,
          starost: callback.body.starost,
          tableData: callback.body.tableData,
          years: callback.body.years.reverse(),
          lastFinancialYear: callback.body.lastFinancialYear,
          allCompanies: callback.body.allCompaniesCount,
          allCompaniesWithLastYearReports: callback.body.allCompaniesWithLastYearReports,
          allCompaniesWithoutLastYearReports: callback.body.allCompaniesWithoutLastYearReports,
          outDatedCompanies: callback.body.allCompaniesDataWithoutLastYearReport
        },() => this.getDivision(callback.body.lastFinancialYear));
      } else {
        this.setState({
          loading: false,
          dejnosti:callback.body.dejnosti,
          golemina:callback.body.golemina,
          gradovi:callback.body.gradovi,
          starost: callback.body.starost,
          years: callback.body.years.reverse(),
          lastFinancialYear: callback.body.lastFinancialYear,
          allCompanies: callback.body.allCompaniesCount,
          allCompaniesWithLastYearReports: callback.body.allCompaniesWithLastYearReports,
          allCompaniesWithoutLastYearReports: callback.body.allCompaniesWithoutLastYearReports,
          outDatedCompanies: callback.body.allCompaniesDataWithoutLastYearReport



        },() => this.getDivision(callback.body.lastFinancialYear))
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }
  getDivision = (year) => {
    if(year) {
      this.setState({loading: true, year: year});
      const loadedYears = this.state.years.filter((curyear) => curyear !== year);
      this.setState({tableYears: loadedYears})
    }
    window.makeRequest("GET", `/api/DivisionDashboard?divisionID=${this.props.match.params.id}&Year=${year ? year : this.state.year}`, false, true, (callback) => {
      let companies = callback.body.allCompanies;
      let izvestai = callback.body.izvestai;
        let combinedCompaniesWithIzvestai = companies.map(itm => ({
          ...izvestai.find((item) => (item.CompanyID === itm.id) && item),
          ...itm,
        }));
      this.setState({companies_izvestai: combinedCompaniesWithIzvestai, loading: false });
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }
  getAllCompanies = () => {
    window.makeRequest("GET", `/api/DivisionCompanies?divisionID=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({ companies: callback.body });
        this.setState({companiesInDivision: callback.body})
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };
  getAllUsers = () => {
    window.makeRequest("GET", `/api/UserDivisions?divisionID=${this.props.match.params.id}`, false, true, (callback) => {
        this.setState({usersInDivision: callback.body})
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });

  };
  getDivisionIsActive = () => {
    window.makeRequest("GET", `/api/Division?id=${this.props.match.params.id}`, false, true, (callback) => {
      console.log('evt', callback.body)
      this.setState({active: callback.body.Status, name: callback.body.Name})
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };

  handleCloseNotification = () => {
    window.location.href = '/AllDivisions'
  };



  closeConfirmDelete = () => {
    alert('y')
  }
  deleteFinanceSuccess = () => {
    alert('y')
  }

  addToCheckOut = (addedReports,foundReports,rejectedReports) => {
    let Object = {
        addedReports,
        foundReports,
        rejectedReports
    };
    let CompanyNames = Object && Object;
    this.setState({openBuyDivisionDialogOutedated: true, companiesNames: CompanyNames})

  }

  closeBuyDivisionDialogOutdated = () => {
    this.setState({loading: true, openBuyDivisionDialogOutedated: false, companiesNames: ''}, () => this.getDivisionWeatherAndChart())
  }

  closeWeatherDialog = () => {
    this.setState({openWeatherLegendDialog: false})
  }

  setOpenMenu = (event) => {
    this.setState({open: true, anchorEl: event.currentTarget});
  }

  changeYear = (year) => {
    this.setState({year: year, open: false, changed: true});
    this.getDivision(year);
  }




  render() {
    if(this.state.loading){
      return <SuspenseLoader />
    }else {
      return <>
        {/*<Typography color='primary' variant='h3' ml={4} mb={3}>{this.state.name}</Typography>*/}
        <PageTitleWrapper>
          <PageHeader year={this.state.year} name={this.state.name} years={this.state.years} reloadDivision={(year) => this.getDivision(year)} company={this.state.company} divisionID={this.props.match.params.id}/>
        </PageTitleWrapper>


        <Grid
          sx={{ px: 4 }}
          container
          direction="row"
          alignItems="stretch"
          spacing={3}
        >

          <Grid item>
            <Typography variant="subtitle3" gutterBottom>
              {'Знаците секогаш ги прикажуваат вредностите за последната внесена година согласно избраниот тип на примарен извештај. Графиконите ги прикажуваат износите и пресметките од избраните примарни извештаи\n'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item lg={2} sm={2} xs={2}>
                <WeatherCard
                  description={'Показателот ја покажува ефикасноста на кредитната политика на компаниите во портфолиото или колку пати во просек истите успеваат да ги наплатат своите побарувања.'}
                  title={'Денови на наплата на побарувања'}
                  displayWeather={this.state.weatherData}


                />
              </Grid>
              <Grid item lg={2} sm={2} xs={2}>
                <WeatherCard
                  description={'Показателот покажува колку пати во текот на годината компаниите во портфолиото во просек успеваат да ги исплатат своите обврски.'}
                  title={'Денови на исплата на обврски'}
                  displayWeather={this.state.weatherData}


                />
              </Grid>
              <Grid item lg={2} sm={2} xs={2}>
                <WeatherCard
                  description={'Просечната способност на компаниите во портфолиото преку најликвидните тековни средства да ги покријат тековните обврски. Солидно ниво на показателот се смета дека е над 1.'}
                  title={'Брза ликвидност'}
                  displayWeather={this.state.weatherData}

                />
              </Grid>
              <Grid item lg={2} sm={2} xs={2}>
                <WeatherCard
                  description={'Показателот покажува во колкав степен компаниите од портфолиото во просек користат туѓи извори на средства за финансирање на нивното работење. Прифатливо ниво на задолженост е 60%. '}
                  title={'Задолженост'}
                  displayWeather={this.state.weatherData}

                />
              </Grid>
              <Grid item lg={2} sm={2} xs={2}>
                <WeatherCard
                  description={'Просечен број на бодови кои им се доделени на компаниите во портфолиото врз основа на остварените резултати и успешноста на нивното финансиско работење. Помалиот просечен број на бодови означува дека компаниите во портфолиото се поризични за соработка и обратно.'}
                  title={'Кредитен скор'}
                  displayWeather={this.state.weatherData}

                />
              </Grid>
              <Grid item lg={2} sm={2} xs={2}>
                <WeatherCard
                  description={'Пресметан период за кој компаниите во портфолиото се очекува да ги исплатат нивните кредити со тековното работење.'}
                  title={'Просечен период на враќање на кредити (во години)'}
                  displayWeather={this.state.weatherData}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} xs={4}>
            <MiniBoxes number={this.state.allCompanies} header={'Вкупен број на компании'}/>
          </Grid>
          <Grid item lg={4} xs={4}>
            <MiniBoxes number={this.state.allCompaniesWithLastYearReports} header={'Вкупен број на компании со извештаи за последната година'}/>
          </Grid>
          <Grid item lg={4} xs={4}>
            <MiniBoxes
              addToCheckOut={this.addToCheckOut}
              lastFinancialYear={this.state.lastFinancialYear}
              number={this.state.allCompaniesWithoutLastYearReports}
              header={'Вкупен број на компании без извештаи за последната година'}
              BuyButton={this.state.outDatedCompanies.length > 0} outDatedCompanies={this.state.outDatedCompanies}/>
          </Grid>

          <Grid item xl={4} xs={4}>
            <Chart type={1} data={this.state.chartData.slice(-5)} division={true} prosecni={true}/>
          </Grid  >
          <Grid item  xl={4} xs={4}>
            <Chart type={2} data={this.state.chartData.slice(-5)} division={true} prosecni={true}/>
          </Grid>
          <Grid item xl={4} xs={4}>
            <Chart type={3} data={this.state.chartData.slice(-5)} division={true} prosecni={true}/>
          </Grid>

          <Grid item display={'flex'} mt={1} >
            <Label style={{fontSize: 16}} color='primary'>Година на приказ</Label>
            <Box ml={1}>
              <Button
                size="small"
                variant="outlined"
                onClick={this.setOpenMenu}
                endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
              >
                <Typography style={{fontSize: 13}} variant={'body2'}>{this.state.year}</Typography>
              </Button>
              <Menu
                onClose={() => this.setState({open: false})}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
              >
                {this.state.tableYears.length > 0 ? this.state.tableYears.map((year, index) => (
                    <MenuItem
                      key={year+index}
                      onClick={() => this.changeYear(year)}
                    >
                      {year}
                    </MenuItem>
                  )) :
                  <MenuItem
                  >
                    Нема прикажани години!
                  </MenuItem>
                }
              </Menu>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <DataGridDivisionDashboard data={this.state.companies_izvestai}  />
          </Grid>

          <Grid item xs={12} ml={1}>
            <Typography>Распределба на компаниите по:</Typography>
          </Grid>


          <Grid item lg={4} xs={4}>
            <BottomCards company={this.state.company} gradovi={this.state.gradovi} type={'locationDivision'} />
          </Grid>
          <Grid item lg={4} xs={4}>
            <BottomCards company={this.state.company} dejnosti={this.state.dejnosti} dejnost={this.state.dejnost} type={'industry'} />
          </Grid>
          <Grid item lg={4} xs={4}>
            <BottomCards company={this.state.company}  starost={this.state.starost} golemina={this.state.golemina} type={'companySize'} />
          </Grid>

          <BuyDivisionDialogOutdated
            open={this.state.openBuyDivisionDialogOutedated}
            onClose={this.closeBuyDivisionDialogOutdated}
            companiesNames={this.state.companiesNames}
          />

        </Grid>

        <Footer />

        <WeatherLegendDialog open={this.state.openWeatherLegendDialog} onClose={this.closeWeatherDialog} />

        <DialogWrapper
          open={!this.state.active}
          maxWidth="sm"
          fullWidth
          keepMounted
          onClose={this.handleCloseNotification}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
          >
            <AvatarError>
              <PriorityHighIcon />
            </AvatarError>

            <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
              {('Дивизијата не е активна.' )}
            </Typography>
            <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
              {('Доколку сакате да ја користите мора да ја активирате.' )}
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                sx={{ mx: 1 }}
                onClick={this.handleCloseNotification}
              >
                {('Назад')}
              </Button>
            </Box>
          </Box>
        </DialogWrapper>
      </>
    }
  }
}

export default withRouter(DivisionDashboard);
