import React from 'react'
import Results from './Results';
import { Grid } from '@mui/material';

class PortfolioLimit extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount(){

    this.renderPortfolioLimit();

  }

  renderPortfolioLimit = () => {
    window.makeRequest("GET", `/api/Admin_CompaniesLimit`, false, true, (callback) => {
      this.setState({users: callback.body});

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  render(){
    return(

      <Grid container>
        <Grid item sx={{p: 2}} xs={12}><Results users={this.state.users} renderPortfolio={this.renderPortfolioLimit}/></Grid>
      </Grid>



    )
  }

}



export default PortfolioLimit;
