import React from 'react'
import Results from './Results';
import { Grid } from '@mui/material';

class AllImports extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount(){

    this.renderImports();

  }

  renderImports = () => {
    window.makeRequest("GET", `/api/Administrator?imports=true`, false, true, (callback) => {
      this.setState({users: callback.body});
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  render(){
    return(

      <Grid container>
        <Grid item sx={{p: 2}} xs={12}><Results users={this.state.users} renderImports={this.renderImports}/></Grid>
      </Grid>



    )
  }

}



export default AllImports;
