import React from 'react';
import * as XLSX from 'xlsx';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputAdornment from '@mui/material/InputAdornment';

class Dialog_Insert extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      report: '',
      file: 'Прикачи документ'
    }
  }

  componentDidMount(){

  }

  onChange = (e) => {
    this.props.onChange(e)
    this.setState({file: e.target.files[0].name})
  }



  render(){
    return <Dialog
      fullWidth
      maxWidth="sm"
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <div style={{height: '400px'}}>
      <DialogTitle sx={{ p: 3, m: 5 }}>
      <Typography variant="h4" align='center' style={{color:'blue'}} gutterBottom>
          {'Изберете и прикачете го Вашиот документ'}
        </Typography>

      </DialogTitle>
      <DialogContent sx={{ p: 3, mb: 7 }}>
      <Grid container spacing={3}>
          <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
            <TextField type='submit' value={this.state.file} onClick={() => {
              document.getElementById('files').click()}} sx={{ "& .MuiOutlinedInput-input": {cursor: 'pointer'}}} 
              style={{marginTop: '20px'}} inputProps={{style: { textAlign: 'start' }}}
              InputProps={{startAdornment: ( <InputAdornment position="end"><FileUploadIcon style={{color:'blue'}}/> </InputAdornment> )}}
              helperText={this.props.fileUploadMessage} error={this.props.fileUploadMessage.length > 0}/>
            <TextField id='files' type="file" name='file' style={{display:'none'}} onChange={this.onChange}/>
          </Grid>

        </Grid>
        
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button color="secondary" onClick={this.props.onClose}>
          {'Откажи'}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={this.props.handleUploadFile}
        >
          {'Внесете'}
        </Button>
      </DialogActions>
      </div>
      
    </Dialog>
    
  }
}
export default Dialog_Insert;
