import React from 'react';
import * as XLSX from 'xlsx';

import {
  Button
} from '@mui/material';

class ExportCompanies extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    }
  }
  componentDidMount(){
  }

  handleDownloadCompanies = () => {

    let data = this.props.companies;
    let editedData = [];
    data.filter((row, index) => {
      if(row && row.hasOwnProperty('Company_name')){
        editedData.push([row.Company_name,
          row.PIN,
          row.VAT,])
      }
      if(index +1 === data.length){
        let wb = XLSX.utils.book_new(this.props.companies);
        const CompanyName = 'Име на компанија';
        const EMBS = 'ЕМБС';
        const EMBG = 'ЕДБ';
        let wscols = [{wch:80},{wch:20},{wch:20}];
        let sheet = XLSX.utils.json_to_sheet([{}], {header: [CompanyName,EMBS,EMBG]});
        sheet['!cols'] = wscols;
        XLSX.utils.sheet_add_json(sheet, editedData, { origin: 'A3',skipHeader: true });
        XLSX.utils.book_append_sheet(wb, sheet);
        XLSX.writeFile(wb, `${this.props.divisionName}-компании.xlsx`);
        let a = document.createElement('a');
        a.download = wb;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })



  }


  render(){
    return(<>
      <Button size={'small'} variant={'contained'} sx={{ml: 1.4}} onClick={this.handleDownloadCompanies}>Експортирај</Button>
    </>)
  }
}


export default ExportCompanies;
