import React from 'react'
import Results from './Results';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

class RejectList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount(){

    this.renderOrders();

  }

  renderOrders = () => {
    window.makeRequest("GET", `/api/Admin_RejectedOrders`, false, true, (callback) => {
      this.setState({users: callback.body});

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  render(){
    return(

      <Grid container>
        <Grid item sx={{p: 2}} xs={12}><Results users={this.state.users} renderOrders={this.renderOrders}/></Grid>
      </Grid>







    )
  }

}



export default RejectList;
