import React, {Component} from 'react';
import './tabNotificationWrapper.scss';

export default class TabNotificationWrapper extends Component{
  constructor(props){
    super(props);


  }
  render() {
    return(
      <div className={'tab_notification'}/>
    )
  }
}
