import React from 'react';
import numeral from "numeral";
import { format } from 'date-fns';

import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Container,
  IconButton,
  Checkbox,
  Tooltip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer
} from '@mui/material';
import {styled} from "@mui/material/styles/index";
import CloseIcon from '@mui/icons-material/Close';
import Label from '../../../../components/Label'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StoreIcon from '@mui/icons-material/Store';

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`
);

class CartList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      checked: [],
      allSelected: false,
    }
  }

  componentDidMount(){

  }
  returnStatus = (status) => {
    const statusReturn = [];
    switch(status) {
      case 'available':
        statusReturn.push(<Label color={'success'}>Available</Label>);
        break;
      case 'approved':
        statusReturn.push(<Label color={'warning'}>Pending</Label>);
        break;
      case 'wish':
        statusReturn.push(<Label color={'warning'}>Wish</Label>);
        break;
      default:
        statusReturn.push(<Label color={'success'}>No Status</Label>)
    }
    return statusReturn;
  }


  add = (item) => {
    let array = [...this.state.checked]; // make a separate copy of the array
    let index = array.indexOf(item)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({checked: array});
    } else {
      array.push(item);
      this.setState({checked: array});

    }
  }
  checkAll = () => {
    if(this.state.allSelected) {
      this.setState({checked: [], allSelected: false})
    }else {
      let array = [...this.props.basket];
      this.setState({checked: array, allSelected: true});
    }



  }

  render() {
    return(
      <>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box display={'flex'} justifyContent={'center'}>
            <Button startIcon={<ShoppingBagIcon size={'small'}/>}>Твоја кошничка</Button>
          </Box>
          <Box>
            <Typography variant="h4" gutterBottom>
              <Button disabled={this.state.checked.length === 0} onClick={() => this.props.openCheckOut(this.state.checked)} startIcon={<StoreIcon/>} variant={'contained'}>Продолжи кон наплата</Button>
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 0 }} />
        <TableWrapper>
          <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Checkbox checked={this.state.allSelected} onClick={this.checkAll} /></TableCell>
                <TableCell>{'ЕМБС'}</TableCell>
                <TableCell>{'Компанија'}</TableCell>
                <TableCell>{'Вид на извештај'}</TableCell>
                <TableCell>{'Година'}</TableCell>
                <TableCell>{'Цена'}</TableCell>
                <TableCell>{'Статус'}</TableCell>
                <TableCell>{''}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.basket && this.props.basket.map((item) => {
                const isUserSelected = this.state.checked.includes(item);
                return<TableRow key={item.ID} selected={isUserSelected}>
                  <TableCell>
                    <Checkbox checked={isUserSelected} onClick={() => this.add(item)}/>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{item.PIN}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{item.Company_name}</Typography>
                  </TableCell>
                  <TableCell>Годишна сметка</TableCell>
                  <TableCell>
                    {item.Year}
                  </TableCell>
                  <TableCell>
                    {numeral(this.props.ReportPrice).format(`€0,0.00`) + " МКД"}
                  </TableCell>
                  <TableCell>
                    {this.returnStatus(item.Status)}
                  </TableCell>
                  <TableCell>
                    <Typography >
                      <Tooltip title={'Избриши'} arrow>
                        <IconButton
                          onClick={() => this.props.removeFromList(item.ID)}
                          color="primary"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>

          </Table>
        </TableContainer>
        </TableWrapper>
    </>)}
}

export default CartList;
