import React from 'react';
import Alert from '@mui/material/Alert';

import {
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Autocomplete,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';



class AllDivisionsPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addCompany: [],
      addUsers: [],
      Name: '',
      nameError: '',
    };

  }

  validate = () => {
    let nameError = this.state.nameError;
    let name = this.state.Name;



    if(nameError){
      return false;
    }


    switch (true) {
      case name.length < 1:
        nameError = 'Името не може да биде празно поле.';
        break;
      case name.length < 4:
        nameError = 'Името мора да има 4 или повеќе карактери.';
        break;
      case name.length > 25:
        nameError = 'Името име не смее да има повеќе од 25 карактери.';
        break;

    }

    if(nameError){
      this.setState({
        nameError
      })

      return false;
    }

    return true;
  }

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value, nameError: ''});
  };

  handleCreateDivisionOpen = () => {
    this.setState({ open: true });
  };

  handleCreateDivisionClose = () => {
    this.setState({ open: false, Name: '', nameError: '', addUsers: [], addCompany: []});
  };

  handleAddCompany = (ev, val) =>
  {
    if(val){
      this.setState({addCompany: val});
    }
  };

  handleAddUsers = (ev, val) =>
  {
    if(val){
      this.setState({addUsers: val});
    }
  };

  checkDivisionName = () => {
    let nameError = '';
    window.makeRequest("GET", `/api/Division?name=${this.state.Name}`, false, true, (callback) => {
      if(!callback.body){
        nameError = 'Веќе постои портфолио со истото име.';
        this.setState({nameError});
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }


  handleAddDivisionSuccess = () => {

    this.checkDivisionName();
    let validate = this.validate();

    if(validate){
      window.makeRequest("POST", `/api/Division`, {
        Name: this.state.Name,
        Status: true,
      }, true, (callback) => {
        if(callback.body === -1){
          this.handleCreateDivisionClose();
          this.props.createDivision();
        } else {
          if(this.state.addUsers.length > 0){
            this.state.addUsers.forEach(user =>
              window.makeRequest("POST", `/api/userDivisions`, {
                userID: user.ID,
                divisionID: callback.body,
              }, true, (callback1) => {
              }, (err) => {
                if (err) {
                  console.log('err', err);
                }
              })
            )
          }
          if(this.state.addCompany.length > 0){
            this.state.addCompany.forEach(company =>
              window.makeRequest("POST", `/api/DivisionCompanies`, {
                CompanyID: company.ID,
                DivisionID: callback.body,
              }, true, (callback2) => {

              }, (err) => {
                if (err) {
                  console.log('err', err);
                }
              })
            )
          }
          this.props.renderDivisions();
        }
        this.handleCreateDivisionClose();
        this.setState({addUsers: [], addCompany: [],  Name: '',
          nameError: '',})

      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });
    }
  };

  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {('Уредување на портфолија')}
            </Typography>
            <Typography variant="subtitle2">
              {(
                'Сите работи поврзани со портфолијата може да се уредуваат од оваа страна'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateDivisionOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small"/>}
            >
              {('Додади портфолио')}
            </Button>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCreateDivisionClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {('Додадете ново портфолио')}
            </Typography>
            <Typography variant="subtitle2">
              {(
                'Пополнете ги полињата за да додадете портфолио'
              )}
            </Typography>
          </DialogTitle>


          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label={('Име')}
                      name="Name"
                      value={this.state.Name}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                    {this.state.nameError && <Alert severity="error" sx={{mt: 1}}>{this.state.nameError}</Alert>}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Autocomplete
                      multiple
                      sx={{ m: 0 }}
                      limitTags={2}
                      options={this.props.companies}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.Company_name}
                        </li>
                      )}
                      getOptionLabel={(option) => option.Company_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={('Одберете компании...')}
                        />
                      )}
                      renderTags={(members, getTagProps) =>
                        members.map((ev, index) => (
                          <Chip
                            label={ev.Company_name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={this.handleAddCompany}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Autocomplete
                      multiple
                      sx={{ m: 0 }}
                      limitTags={2}
                      options={this.props.users}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.Name}
                        </li>
                      )}
                      getOptionLabel={(option) => option.Name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={('Одберете корисници...')}
                        />
                      )}
                      renderTags={(members, getTagProps) =>
                        members.map((ev, index) => (
                          <Chip
                            label={ev.Name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={this.handleAddUsers}
                    />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCreateDivisionClose}>
              {('Откажи')}
            </Button>
            <Button
              type="submit"
              onClick={this.handleAddDivisionSuccess}
              variant="contained"
            >
              {('Додади портфолио')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}


export default AllDivisionsPageHeader;

