import React from 'react';

import {
  Box,
  Typography,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableContainer
} from '@mui/material';
import {styled} from "@mui/material/styles/index";

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`
);


class TableResults extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loaded: true
    }
  }


  componentDidMount() {

  }


  render() {
    return<Box p={4} m={2} style={{backgroundColor: 'white'}}>
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                {this.props.TableRow}
              </TableHead>
              <TableBody>
                {this.props.TableRowBody}
              </TableBody>
              {this.props.TableFooter}
            </Table>
          </TableContainer>
        </TableWrapper>
        <Box mt={2} mr={1} display={'flex'} justifyContent={'end'}>
          {this.props.buttons}
        </Box>
      </Box>
  }


}
export default TableResults;
