import { useRef, useState } from 'react';
import {
  Button,
  Card,
  Box,
  Hidden,
  CardContent,
  CardHeader,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import TransactionsStatisticsChart from './TransactionsStatisticsChart';

const TransactionsStatisticsChartWrapper = styled(
  TransactionsStatisticsChart
)(
  ({ theme }) => `
        height: 250px;
`
);

function TransactionsStatistics(props) {
  const { t } = useTranslation();
  const periods = [
    {
      value: 'Долг/Капитал',
      text: t('Долг/Капитал')
    },
    {
      value: 'Период на враќање на кредити',
      text: t('Период на враќање на кредити')
    },
    {
      value: 'Задолженост',
      text: t('Задолженост')
    }
  ];


  const actionRef1 = useRef(null);
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  const [period, setPeriod] = useState('Долг/Капитал');

  return (<>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      xs={12}>
      <Grid item  >
        {
          props.prosecni === false ?
          <CardHeader

            title={t('Показатели на задолженост')}/>
            : <CardHeader

              title={t('Просечни показатели на задолженост')}/>
        }
      </Grid>
      <Grid item ml={2} mr={1} >

        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button
              size="small"
              variant="outlined"
              ref={actionRef1}
              onClick={() => setOpenMenuPeriod(true)}
              endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
            >
              <Typography variant={'body1'}>{period}</Typography>
            </Button>
          </Box>
          <Menu
            anchorEl={actionRef1.current}
            onClose={() => setOpenMenuPeriod(false)}
            open={openPeriod}

            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {periods.map((_period) => (
              <MenuItem
                key={_period.value}
                onClick={() => {
                  setPeriod(_period.text);
                  setOpenMenuPeriod(false);
                }}
              >
                {_period.text}
              </MenuItem>
            ))}
          </Menu>
        </>
      </Grid>

    </Grid>

      <Divider />
      <CardContent>
        <Box  >
          {period === 'Долг/Капитал' && (
            <Hidden >
              <Box  >
                <TransactionsStatisticsChartWrapper
                  data={{income: props.dolg_kapital}}
                  labels={props.years}
                  period={period}
                />
              </Box>
            </Hidden>
          )}
          {period === 'Период на враќање на кредити' && (
            <Hidden >
              <Box  >
                <TransactionsStatisticsChartWrapper
                  data={{income: props.periodVrakanjeKrediti}}
                  labels={props.years}
                  period={period}
                />
              </Box>
            </Hidden>
          )}
          {period === 'Задолженост' && (
            <Hidden >
              <Box  >
                <TransactionsStatisticsChartWrapper
                  data={{income: props.zadolzenost}}
                  percentage={true}
                  labels={props.years}
                  period={period}
                />
              </Box>
            </Hidden>
          )}

        </Box>

      </CardContent>
    </>
  );
}

export default TransactionsStatistics;
