import React from 'react'
import Results from './Results';
import { Grid, Box } from '@mui/material';
import PageHeader from './PageHeader';


class AllUsers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount(){
    this.renderUsers();
  }

  renderUsers = () => {
    window.makeRequest("GET", `/api/Admin_AdminUsers`, false, true, (callback) => {
      this.setState({users: callback.body});

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  render(){
    return(

<>
      <Box sx={{p: 3}}>
      <PageHeader renderUsers={this.renderUsers}/>
      </Box>
      <Grid container>




        <Grid item sx={{p: 2}} xs={12}><Results users={this.state.users} renderUsers={this.renderUsers}/></Grid>
      </Grid>

</>

    )
  }

}



export default AllUsers;
