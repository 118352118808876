import React from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid} from '@mui/x-data-grid';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone'
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";
import DateFormating from '../../../../components_bi/DateFormating'

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openOrderShow: false,
      TotalAmount: 0,
      Status: '',
      DateOrder: '',
      rows: [],
      TransactionLines: [],


    }
  }

  componentDidMount() {
    window.makeRequest("GET", `/api/Transactions`, false, true, (callback) => {
      if (callback.body) {
        this.setState({rows: callback.body, loading: false}, this.getTransaction);
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  DataGridTransactions = () => {
    const columns = [
      {
        field: 'CreatedOn',
        headerName: 'Датум',
        align: 'center',
        headerAlign: 'center',
        width: 250,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {
              DateFormating(params.row.CreatedOn)
            }
          </Typography>
        },
      },
      {
        field: 'ID',
        renderHeader: params => {
          return(
            <Tooltip title={'Системски број на трансакција'} arrow placement='top'>
              <div>Трансакција</div>
            </Tooltip>
          )
        },
        align: 'center',
        headerAlign: 'center',
        width: 150,
        disableColumnMenu: true,

      },
      {
        field: 'orderID',
        headerName: 'Број на нарачка',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {params.row.orderID}
          </Typography>
        },

      },
      {
        field: 'Status',
        headerName: 'Статус',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        disableColumnMenu: true,
        renderCell: params => {
          if(params.row.Status === 'canceled'){
            return 'Откажано'
          }
          if(params.row.Status === 'completed'){
            return 'Завршено'
          }
          if(params.row.Status === 'pending payment'){
            return 'Во тек'
          }
        }

      },


      {
        field: 'TotalAmount',
        headerName: 'Вкупно',
        renderHeader: params => {
          return(
            <Tooltip title={'Износите се без ДДВ'} arrow placement='top'>
              <div>Вкупно</div>
            </Tooltip>
          )
        },
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
              <Typography noWrap align={'center'}>{params.row.TotalAmount.toFixed(0) + ' МКД'}</Typography>
            )
        },

      },



      {
        field: 'amount',
        renderHeader: params => {
          return(
            <Tooltip title={'Прикажаниот износ е износот наплатен од банка'} arrow placement='top'>
              <div>Вкупно од банка</div>
            </Tooltip>
          )
        },
        width: 150,
        align: 'center',
        type: 'number',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Typography noWrap align={'center'}>{params.row.amount !== '' && params.row.amount + ' МКД'}</Typography>
          )
        },
      },

      {
        field: 'UpdatedOn',
        headerName: 'Последна промена',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {
              DateFormating(params.row.UpdatedOn)
            }
          </Typography>
        },

      },

      {
        field: '*',
        headerName: 'Инфо',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        description:
          'Со клик на копчето ви се прикажува трансакцијата со сите детали'
        ,
        type: 'text',
        renderCell: (params) => {
          return <><Typography noWrap align={'center'}>
            <Tooltip title={('Преглед')} arrow>
              <IconButton
                onClick={() => this.openOrderShow(params.row.TransactionLines, params.row.TotalAmount,params.row.Status,params.row.UpdatedOn)}
                color="primary"
              >
                <LaunchTwoToneIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </Typography>
          </>
        },
        disableColumnMenu: true,
      },
    ];


    return (
      <BI_DataGrid data={this.state.rows} columns={columns} search={true} />
    );
  }

  DataGridOrder = () => {
    const columns = [
      {
        field: 'Name',
        headerName: 'Име',
        width: 150,
      },
      {
        field: 'Quantity',
        headerName: 'Количина',
        width: 120,
        disableColumnMenu: true,

      },
      {
        field: 'SinglePrice',
        headerName: 'Цена',
        width: 120,
        disableColumnMenu: true,

      },

    {
      field: 'TotalPrice',
      headerName: 'Вкупно',
      width: 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Typography noWrap align={'center'}>
          {params.row.TotalPrice + ' МКД'}
        </Typography>
      },



    },
      {
        field: '*',
        headerName: 'Статус',
        width: 120,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          if(this.state.TransactionLines.length> 0 ){
            return <Typography>{this.state.Status}</Typography>
          }
        },
      },
      {
        field: '**',
        headerName: 'Вкупно',
        width: 120,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          if(this.state.TransactionLines.length> 0){
            return <Typography>{this.state.TotalAmount + ' МКД'}</Typography>
          }

        },
      },
      {
        field: '***',
        headerName: 'Датум',
        width: 120,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          if(this.state.TransactionLines.length> 0){
            return <Typography>{DateFormating(this.state.DateOrder)}</Typography>
          }

        },
      },

    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.state.TransactionLines}
          columns={columns}
          getRowId={row => row.ID}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick

        />

      </div>
    );
  }
  openOrderShow = (lines,Total,Status,Date) => {
    this.setState({TransactionLines: lines, TotalAmount:Total, Status: Status, DateOrder: Date, openOrderShow: true})

  }
  closeOrderShow = () => {
    this.setState({openOrderShow: false,  })
  }
  render() {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={1}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {'Преглед на трансакции'}
                </Typography>
                <Typography variant="subtitle2">
                  {
                    'Преглед на сите трансакции'
                  }
                </Typography>
              </Box>
            </Box>


          </Box>
          {this.DataGridTransactions()}
          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.openOrderShow}
            onClose={this.closeOrderShow}
          >
            <DialogTitle sx={{ p: 3 }}>
              <Typography variant="h4" gutterBottom>
                {'Преглед на претплата'}
              </Typography>
              <Typography variant="subtitle2">
                {
                  'Преглед на продуктите'
                }
              </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  {this.DataGridOrder()}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

        </RootWrapper>

      );

  }


}


export default Transactions;

