import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import SuspenseLoader from '../../../../components/SuspenseLoader'
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";
import DateFormating from '../../../../components_bi/DateFormating';


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openOrderShow: false,
      rows: [],
      TransactionLines: [],
      reports: [],


    }
  }

  componentDidMount() {
    this.getReports();
  }

  getReports = () => {
    window.makeRequest("GET", `/api/ReportOrders/getBought?getBought=true`, false, true, (callback) => {
      this.setState({reports: callback.body.orders, loading: false})
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }


  DataGridReports = () => {
    const columns = [
      {
        field: 'Company_name',
        headerName: 'Име',
        type: 'number',
        headerAlign: 'center',
        width: 650,
        align: 'start',
        disableColumnMenu: true,
        renderCell: (params) => {
          return<>
            <Typography noWrap>
              <Tooltip title={params.row.Company_name} arrow>
                <RouterLink  style={{textDecoration: 'none', color: 'black'}} to={`/company/${params.row.companyID}/dashboard`}>
                  {params.row.Company_name}
                </RouterLink >
              </Tooltip>
            </Typography>
          </>
        }

      },
      {
        field: 'Year',
        headerName: 'Година',
        headerAlign: 'center',
        type: 'date',
        width: 150,
        align: 'center',
        disableColumnMenu: true,

      },
      {
        field: 'Status',
        headerName: 'Статус',
        headerAlign: 'center',
        type: 'text',
        width: 150,
        align: 'center',
        disableColumnMenu: true,
      },
      {
        field: 'UpdatedAt',
        headerName: 'Датум',
        headerAlign: 'center',
        type: 'text',
        width: 150,
        align: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {
              DateFormating(params.row.UpdatedAt)
            }
          </Typography>
        },
      },

    ];


    return (
        <BI_DataGrid  data={this.state.reports} search={true} columns={columns}  />
    );
  }

  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={1}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {'Преглед на извештаи'}
                </Typography>
                <Typography variant="subtitle2">
                  {
                    'Преглед на сите извештаи'
                  }
                </Typography>
              </Box>
            </Box>


          </Box>
          {this.DataGridReports()}
        </RootWrapper>

      );
    }

  }


}


export default Reports;

