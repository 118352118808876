import { Box, Hidden } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1.5 }}>
      <Hidden smDown>
        <LanguageSwitcher />
      </Hidden>
    </Box>
  );
}

export default HeaderButtons;
