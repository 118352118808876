import React from 'react';
import {
  Dialog,
  Box,
  Typography,
  Button
} from '@mui/material';
import {Avatar} from "@mui/material/index";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { styled } from '@mui/material/styles';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


class LimitCreateDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }


  componentDidMount() {

  }


  render(){
    return(
      <DialogWrapper
        open={this.props.open}
        maxWidth="sm"
        fullWidth
        keepMounted
        onClose={this.props.onClose}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <PriorityHighIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {('Го достигнавте лимитот на компании.' )}
          </Typography>
          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {('Доколку сакате да го зголемите лимитот испратете барање до администраторот.' )}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={this.props.handleRequireLimitChange}
            >
              {('Испрати')}
            </Button>
            <ButtonError
              onClick={this.props.onClose}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {('Откажи')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    )
  }
}


export default LimitCreateDialog;
