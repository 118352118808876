import { useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';

import PropTypes from 'prop-types';
import { IconButton, Box, Tooltip, Badge, Popover, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';

import { styled } from '@mui/material/styles';


const IndicatorWrapper = styled(Box)(
  ({ theme }) => `
 
  .MuiSvgIcon-root {
    width: 100%;
    height: auto;
  }
`
);

const PopoverWrapper = styled(Popover)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(2)} !important;

    .MuiListItem-root {
      padding: 2px 0 !important;

      .MuiIconButton-root {
        width: 100% !important;
        height: auto !important;
        justify-content: flex-start !important;
        font-weight: bold !important;
        background: transparent !important;
        color: ${theme.colors.alpha.black[70]} !important;
        padding: ${theme.spacing(1, 2)} !important;

        

        &.Mui-active,
        &:hover {
          background: ${theme.colors.alpha.black[10]} !important;
          color: ${theme.colors.alpha.black[100]} !important;
        }
      }
    }  
  }
`
);

const SidebarMenuItem = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const { t } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Tooltip title={t(name)} disableInteractive placement="right" arrow>
          <IconButton
            className={clsx({ 'Mui-active': open })}
            onClick={handleClick}
          >
            {Icon && <Icon />}
            {badge && <Badge badgeContent={badge} />}

            <IndicatorWrapper>
              {open ? <KeyboardArrowLeftTwoToneIcon /> : <KeyboardArrowRightTwoToneIcon />}
            </IndicatorWrapper>

          </IconButton>
        </Tooltip>
        <PopoverWrapper
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          open={open}>{children}</PopoverWrapper>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <Tooltip title={t(name)} disableInteractive placement="right" arrow>
        <IconButton
          activeClassName="Mui-active"
          component={RouterLink}
          onClick={toggleSidebar}
          to={link}
        >
          {Icon && <Icon />}
          <span className="name-wrapper">
            {name}
          </span>
          {badge && <Badge badgeContent={badge} />}
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
