import React from 'react';
import {withRouter} from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';

import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import {styled} from "@mui/material/styles/index";
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import Text from 'src/components/Text';

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

class SingleUser extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      id: "",
      username:  "",
      currentUsername: "",
      password:  "",
      isAdmin:  "",
      telephone:  "",
      email:  "",
      currentEmail: "",
      firstname:  "",
      lastname:  "",
      confirm_password: "",
      loading: true,



  }
  }
  componentDidMount(){
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/User?id=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({
        id: callback.body.id,
        username:  callback.body.username,
        currentUsername: callback.body.username,
        password:  callback.body.password,
        isAdmin:  callback.body.isAdmin,
        telephone:  callback.body.telephone,
        email:  callback.body.email,
        currentEmail: callback.body.email,
        firstname:  callback.body.firstname,
        lastname:  callback.body.lastname,
        loading: false,
      })
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }



  getFormatedNumber = (number) => {

    let formatedNumber = "";
    if(number.indexOf("3897") > -1){
      formatedNumber = "+389" + " " +  number[3] + number[4] + " " + number[5] + number[6] + number[7] + "-" + number[8] + number[9] + number[10];

    }
    else if(number.indexOf("3892") > -1){
      formatedNumber = "+389" + " " +  number[3]  + " " + number[4] + number[5] + number[6] + number[7] + "-" + number[8] + number[9] + number[10];
    }
    else {
      formatedNumber = number
    }

    return formatedNumber;

  };



  render(){
    if(this.state.loading) {
      return <SuspenseLoader/>
    }else {
      return(
        <>
          <RootWrapper sx={{p: 5}}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="h3" component="h3" gutterBottom>
                      {('Преглед на корисник')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Button
                  sx={{ mt: { xs: 2, sm: 0 } }}
                  component={RouterLink}
                  startIcon={<ArrowBackTwoToneIcon />}
                  to="/"
                  variant="contained"
                >
                  {('Назад до сите корисници')}
                </Button>
              </Grid>
            </Grid>
          </RootWrapper>

          <RootWrapper sx={{p: 3}}>
            <Card>
              <Box
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {('Детали за корисникот')}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        {('Корисничко име')}:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>{this.state.username}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        {('Име и презиме')}:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>{this.state.firstname + " " +this.state.lastname}</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        {('Е-пошта')}:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>{this.state.email}</b>
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        {('Телефон')}:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>{this.getFormatedNumber(this.state.telephone)}</b>
                        </Text>
                      </Box>
                    </Grid>

                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          </RootWrapper>
        </>
      );
    }

  }


}

export default withRouter(SingleUser);
