import React from 'react';

import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid } from '@mui/material';
import CartBody from './CartBody';
import PageHeader from './PageHeader';
import SuspenseLoader from '../../../components/SuspenseLoader';
import BuyStatus from "./Dialogs/BuyStatus";


class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ReportPrice: '',
      basket: [],
      wishlist: [],
      denied: [],
      paymentStatus: false,

    }
  }
  componentDidMount(){
    this.getCartDetails();
  }

  reload = (type) => {
    if(type){
      this.setState({loading: true, paymentStatus: true, message: type}, () => this.getCartDetails())
    } else {
      this.setState({loading: true}, () => this.getCartDetails())

    }
  }

  closePaymentStatus = () => {
    this.setState({loading: true, message: '', paymentStatus: false}, () => this.getCartDetails())

  }

  getCartDetails = () => {
    window.makeRequest("GET", `/api/ReportOrders?getBasket=true`, false, true, (callback) => {
      this.setState({basket: callback.body.Basket,ReportPrice: callback.body.ReportPrice,wishlist: callback.body.WishList,denied: callback.body.Denied, loading: false})
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }

  render(){
    if(this.state.loading) {
      return <SuspenseLoader />
    } else {
      return (
        <>
          <PageTitleWrapper>
            <PageHeader  />
          </PageTitleWrapper>

          <Grid
            sx={{ px: 4 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <CartBody basket={this.state.basket} reload={this.reload} denied={this.state.denied} wishlist={this.state.wishlist} ReportPrice={this.state.ReportPrice} />
            </Grid>
          </Grid>
          <BuyStatus onClose={this.closePaymentStatus} open={this.state.paymentStatus} message={this.state.message}/>
          <Footer />
        </>
      );
    }

  }
}

export default Cart;
