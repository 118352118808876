import * as React from 'react';

import {
  Box,
  Tooltip,
  IconButton,
  TextField,
  Typography,
  Button,
} from '@mui/material';


import {Link as RouterLink} from 'react-router-dom';

import BI_DataGrid from "../../../../../components_bi/BI_DataGrid";

const columns = [
  // { field: "id",
  //   headerName: 'id',
  //   width: 70,
  //   disableColumnMenu: true,
  //   hidden: true,
  // },
  {
    field: 'Company_name',
    headerName: 'Назив',
    width: 380,
    filter: true,
    renderCell: (params) => {
      console.log('row', params.row);
      return<>

        <Typography noWrap>{params.row.Company_name_by_user ? <Tooltip title={params.row.Company_name_by_user} arrow>
            <p>{params.row.Company_name_by_user}</p>
          </Tooltip> :
          <Tooltip title={params.row.Company_name} arrow>
            <p>{params.row.Company_name}</p>
          </Tooltip>
        }
        </Typography>
        </>
    }
  },
  {
    field: 'City',
    headerName: 'Град',
    width: 100,
    align: 'center',
    disableColumnMenu: true,
    headerAlign: 'center',
  },
  {
    field: 'Dejnost',
    headerName: 'Дејност',
    width: 170,
    disableColumnMenu: true,
    renderCell: (params) => {
      return<>
        <Typography noWrap>
          <Tooltip title={params.row.Dejnost} arrow>
            <p>{params.row.Dejnost}</p>
          </Tooltip>
        </Typography>
      </>
    }

  },
  {
    field: 'Bodovi',
    renderHeader: params => {
      return(
        <Tooltip title={'Просечен број на бодови кои им се доделени на компаниите во портфолиото врз основа на остварените резултати и успешноста на нивното финансиско работење. Помалиот просечен број на бодови означува дека компаниите во портфолиото се поризични за соработка и обратно.'} arrow placement='top'>
          <div>Кредитен скор</div>
        </Tooltip>
      )
    },
    width: 130,
    type:'number',
    align: 'center',
    disableColumnMenu: true,
  },
  {
    field: 'Prihodi',
    renderHeader: params => {
      return(
        <Tooltip title={'Просечна вредност на вкупни приходи кои компаниите во портфолиото ги оствариле по основ на приходи од работење, финансиски приходи и удел во добивката на придружените друштва.'} arrow placement='top'>
          <div>Вкупни приходи</div>
        </Tooltip>
      )
    },
    width: 140,
    type:'number',
    align: 'center',
    disableColumnMenu: true,
  },
  {
    field: 'FinansiskiRezultat',
    renderHeader: params => {
      return(
        <Tooltip title={'Вредност на просечно остварен финансиски резултат на компаниите во портфолиото по одбивање на данокот на добивка.'} arrow placement='top'>
          <div>Финансиски Резултат</div>
        </Tooltip>
      )
    },
    width: 180,
    type:'number',
    align: 'center',
    disableColumnMenu: true,
  },
  {
    field: 'ebitda',
    renderHeader: params => {
      return(
        <Tooltip title={'Просечен износ на добивка која компаниите во портфолиото ја остваруваат од редовно работење зголемена за износот за амортизација.'} arrow placement='top'>
          <div>ЕБИТДА</div>
        </Tooltip>
      )
    },
    width: 120,
    type:'number',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    // disableColumnMenu: true,
    renderCell: params => {
      return params.row.ebitda ?  parseInt(params.row.ebitda).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
    }
  },
  // {
  //   field: 'City',
  //   headerName: 'Град',
  //   width: 100,
  //   type:'text',
  //   align: 'center',
  //   disableColumnMenu: true,
  // },
  {
    field: 'BrutoProfitnaMarza',
    renderHeader: params => {
      return(
        <Tooltip title={'Претставува маржа која компаниите во портфолиото во просек ја остваруваат од продажба на своите производи или услуги.'} arrow placement='top'>
          <div>Бруто профитна маржа</div>
        </Tooltip>
      )
    },
    width: 190,
    type:'number',
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params) => {
      return params.row.BrutoProfitnaMarza ? parseFloat(params.row.BrutoProfitnaMarza * 100).toFixed(0) + "%" : '';
    }
  },
    {
    field: 'NetoProfitnaMarza',
    renderHeader: params => {
      return(
        <Tooltip title={'Показателот покажува колку во просек компаниите во портфолиото успеваат остварените приходи од продажба да ги претворат во нето добивка.'} arrow placement='top'>
          <div>Нето профитна маржа</div>
        </Tooltip>
      )
    },
    width: 185,
    type:'number',
    align: 'center',
    disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.NetoProfitnaMarza ? parseFloat(params.row.NetoProfitnaMarza * 100).toFixed(0) + "%" : '';
      }
  },
  {
    field: 'Vraboteni',
    renderHeader: params => {
      return(
        <Tooltip title={'Просечен број на вработени во компаниите содржани во портфолиото во текот на годината.'} arrow placement='top'>
          <div>Просечен број на вработени</div>
        </Tooltip>
      )
    },
    width: 225,
    type:'number',
    align: 'center',
    disableColumnMenu: true,
  },
  // {
  //   field: '#',
  //   headerName: 'Преглед',
  //   width: 120,
  //   align: 'center',
  //   disableColumnMenu: true,
  //   renderCell: (params) => {
  //     return <Button style={{
  //       minWidth: '120px',
  //       maxWidth: '120px',
  //       minHeight: '52px',
  //       maxHeight: '52px',
  //       lineHeight: '51px',
  //     }}
  //     component={RouterLink}
  //     // onClick={() => alert('go to dashboard '+params.row.id)}
  //     to={`/company/${params.row.id}/dashboard`}
  //     >Преглед</Button>
  //   }
  // },
  //
  //

];



export default function DataGridDivisionDashboard(props) {
 const data = props.data;

  return     <BI_DataGrid search={true} id={'id'} columns={columns} data={props.data} dynamicHeight={true} onRowClick={(params)=> window.location.href = `/company/${params.row.id}/dashboard`}/>
  //
  // return (
  //   <Box justify={'center'} alignItems={'center'} style={{ height: 634, width: '100%', backgroundColor: 'white'}}>
  //     <DataGrid
  //       style={{fontSize: 14}}
  //       rows={rows}
  //       alignContent={'center'}
  //       columns={columns}
  //       pageSize={20}
  //       rowsPerPageOptions={[5]}
  //       components={{ Toolbar: QuickSearchToolbar }}
  //       componentsProps={{
  //         toolbar: {
  //             value: searchText,
  //             onChange: (event) => requestSearch(event.target.value),
  //             clearSearch: () => requestSearch(''),
  //         },
  //       }}
  //       disableSelectionOnClick
  //     />
  //   </Box>
  // );
}



