const indicator_titles = {
  "CFND": "Нето добивка",
  "CFAM": "Амортизација",
  "CFKP": "Краткорочни побарувања",
  "CFKFS": "Краткорочни финансиски средства",
  "CFKO": "Краткорочни обврски",
  "CFZA": "Залихи",
  "CFAVR": "АВР",
  "CFPVR": "ПВР",
  "CFODS": "Одложени даночни средства",
  "CFODO": "Одложени даночни обврски",
  "CFOA": "Приливи / Одливи на готовина од деловни активности",
  "CFRE": "Резерви",
  "CFEA": "Премии на емитувани акции",
  "CFRR": "Ревалоризациона резерва",
  "CFPZ": "Пренесена загуба",
  "CFZG": "Загуба за деловната година",
  "CFAD": "Акумулирана добивка",
  "CFDD": "Добивка за деловната година",
  "CFSA": "Сопствени акции",
  "CFDO": "Долгорочни обврски",
  "CFAK": "Акционерски капитал (основна главнина)",
  "CFND1": "Нето добивка ",
  "CFDR": "Долгорочни резервирања за ризици и трошоци",
  "CFZNK": "Запишан, а неуплатен капитал",
  "CFFA": "Приливи / Одливи на готовина од финансиски активности",
  "CFNS": "Нематеријални средства",
  "CFMS": "Материјални средства",
  "CFVN": "Вложувања во недвижности",
  "CFDFS": "Долгорочни финансиски средства",
  "CFDP": "Долгорочни побарувања",
  "CFAM1": "Амортизација ",
  "CFSPR": "Средства (или групи за оттуѓување наменети за продажба и прекинати работења)",
  "CFOON": "Обврски по основ на нетековни средства",
  "CFIA": "Приливи / Одливи на готовина од инвестициони активности",
  "CFT": "Вкупно приливи/одливи на готовина",
  "CFP": "Парични средства на почетокот на годината",
  "CFK": "Парични средства на крајот на годината",
  "RK": "Работен капитал",
  "TL": "Тековна ликвидност",
  "BL": "Брза ликвидност",
  "PL": "Парична ликвидност",
  "KZ": "Конверзија на залихи во пари",
  "OP": "Обрт на побарувања",
  "OP1": "Денови на обрт на побарувања",
  "OZ": "Обрт на залихи",
  "OZ1": "Денови на обрт на залихи",
  "OS": "Обрт на средства",
  "OS1": "Денови на обрт на средства",
  "OO": "Обрт на обврски",
  "OO1": "Денови на обрт на обврски",
  "OK": "Обрт на капитал и резерви",
  "OF": "Обрт на постојани средства",
  "PS": "Поврат на средства (ROA)",
  "PK": "Поврат на капитал (ROЕ)",
  "NPM": "Нето профитна маржа",
  "BPM": "Бруто профитна маржа",
  "OPM": "Оперативна профитна маржа",
  "PK1": "Поврат на капитал и резерви (бруто)",
  "EM": "ЕБИТДА маргина",
  "PS1": "Поврат на средства (бруто)",
  "PV": "Просечна плата по вработен (месечно)",
  "DVR": "Добивка по вработен",
  "PVR": "Приходи по вработен",
  "BRD": "Бруто добивка",
  "NDF": "Нето добивка од финансирање",
  "EBIT": "ЕБИТ",
  "EBITDA": "ЕБИТДА",
  "FL": "Финансиски ливериџ",
  "ZD": "Задолженост",
  "VO": "Вкупни обврски/ЕБИТДА",
  "DSCR": "Покриеност на долгот (DSCR)",
  "KA": "Покриеност на камати",
  "KK": "Краткорочни кредити/продажба",
  "TO": "Тековни обврски/вкупна продажба",
  "KR": "Период на отплата на кредити (во години)",
};

const pokazateli_fields = ["RK",	"TL",	"BL",	"PL",	"KZ",	"OP",	"OP1",	"OZ",	"OZ1", "OS",	"OS1",	"OO",	"OO1",	"OK",	"OF",	"PS",	"PK",	"NPM",	"BPM",	"OPM",	"PK1",	"EM",	"PS1",	"PV",	"DVR",	"PVR",	"BRD",	"NDF",	"EBIT",	"EBITDA",	"FL",	"ZD",	"VO",	"DSCR",	"KA",	"KK",	"TO",	"KR"];
const gotovinskiTekovi_fields = ["CFND",	"CFAM",	"CFKP",	"CFKFS",	"CFKO",	"CFZA",	"CFAVR",	"CFPVR",	"CFODS",	"CFODO",	"CFOA",	"CFRE",	"CFEA",	"CFRR",	"CFPZ",	"CFZG",	"CFAD",	"CFDD",	"CFSA",	"CFDO",	"CFAK",	"CFND1",	"CFDR",	"CFZNK",	"CFFA",	"CFNS",	"CFMS",	"CFVN",	"CFDFS",	"CFDP",	"CFAM1",	"CFSPR",	"CFOON",	"CFIA",	"CFT",	"CFP",	"CFK"];
const bonitetnaOcena_fields = ["Bodovi", "Limit", "Marza", "Ocena"];

export {indicator_titles, pokazateli_fields, gotovinskiTekovi_fields, bonitetnaOcena_fields};
