import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Box,
  Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputAdornment from '@mui/material/InputAdornment';
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';

class DialogUploadReport extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      report: '', 
      file: 'Прикачи документ'
    }
  }

  componentDidMount(){

  }

  downloadTemplate = () => {
    let wb = XLSX.utils.book_new();
    const headerEMBS = 'EMBS';
    const headerGodina = 'Godina';
    const headerAOP1 = 'AOP1';
    const headerAOP36 = 'AOP36';
    const headerAOP255 = 'AOP255';
    const headerAOP256 = 'AOP256';
    const headerAOP257 = 'AOP257';
    const sheet = XLSX.utils.json_to_sheet([{}], {header: [headerEMBS, headerGodina, headerAOP1 , headerAOP36, headerAOP255, headerAOP256, headerAOP257]});
    XLSX.utils.book_append_sheet(wb, sheet);
    XLSX.writeFile(wb, 'Темплејт.xlsx');
    let a = document.createElement('a');
    a.download = wb;
    document.body.appendChild(a);
    a.click();
    a.remove();

  }

  onChange = (e) => {
    this.props.onChange(e)
    this.setState({file: e.target.files[0].name})
  }

  render(){
    return <Dialog
      fullWidth
      maxWidth="sm"
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <div style={{height: '400px'}}>
        <DialogTitle sx={{ p: 3, m:5 }}>
          <Typography variant="h4" align='center' style={{color:'blue'}} gutterBottom>
            {'Изберете и прикачете го Вашиот ексел документ'}
          </Typography>


        </DialogTitle>
        <DialogContent sx={{ p: 3, mb: 7}}>

          <Grid container spacing={3}>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
              <TextField type='submit' value={this.state.file} onClick={() => {
                document.getElementById('files').click()}} sx={{ "& .MuiOutlinedInput-input": { cursor: 'pointer'}}} 
                InputProps={{ startAdornment: ( <InputAdornment position="end"><FileUploadIcon style={{color:'blue'}}/> </InputAdornment> )}}
                style={{marginTop: '20px'}} inputProps={{style: { textAlign: 'start' }}}
                helperText={this.props.fileUploadMessage} error={this.props.fileUploadMessage.length > 0}/>
              <TextField id='files' type="file" name='file' style={{display:'none'}} onChange={this.onChange}/>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Button onClick={this.downloadTemplate}
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<CloudDownloadTwoToneIcon />}
              >Преземи пример од извештај</Button>
            </Box>
            <Box>
              <Button color="secondary" onClick={this.props.onClose}>
                {'Откажи'}
              </Button>
              <Button
                disabled={this.props.fileUpload === false}
                type="submit"
                variant="contained"
                onClick={this.props.handleUploadFile}
              >
                {'Внеси'}
              </Button>
            </Box>

          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  }
}
export default DialogUploadReport;
