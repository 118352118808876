import React from 'react';
import numeral from "numeral";

import {
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@mui/material';
import {styled} from "@mui/material/styles/index";
import CloseIcon from '@mui/icons-material/Close';
import Label from '../../../../components/Label'
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`
);



class DeniedList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      list: [],
      checked: [],
      allSelected: false,
    }
  }

  componentDidMount(){

  }


  returnStatus = (status) => {
    const statusReturn = [];
    switch(status) {
      case 'available':
        statusReturn.push(<Label color={'success'}>Available</Label>);
        break;
      case 'approved':
        statusReturn.push(<Label color={'warning'}>Pending</Label>);
        break;
      case 'wish':
        statusReturn.push(<Label color={'warning'}>Wish</Label>);
        break;
      case 'denied':
        statusReturn.push(<Label color={'error'}>Denied</Label>);
        break;
      default:
        statusReturn.push(<Label color={'success'}>No Status</Label>)
    }
    return statusReturn;
  }






  render() {
    return(
      <>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box display={'flex'}>
            <Box display={'flex'} justifyContent={'center'}>
              <Button startIcon={<DoDisturbIcon color='warning' size={'small'} />}>Недостапни</Button>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{'ЕМБС'}</TableCell>
                  <TableCell>{'Компанија'}</TableCell>
                  <TableCell>{'Вид на извештај'}</TableCell>
                  <TableCell>{'Година'}</TableCell>
                  <TableCell>{'Цена'}</TableCell>
                  <TableCell>{'Статус'}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.denied && this.props.denied.map((item) => {
                  return<TableRow key={item.ID} >
                    <TableCell>
                      <Typography noWrap>{item.PIN}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{item.Company_name}</Typography>
                    </TableCell>
                    <TableCell>Годишна сметка</TableCell>
                    <TableCell>
                      {item.Year}
                    </TableCell>
                    <TableCell>
                      {numeral(this.props.ReportPrice).format(`0,0.00`) + " МКД"}
                    </TableCell>
                    <TableCell>
                      {this.returnStatus(item.Status)}
                    </TableCell>
                    <TableCell>
                      <Typography >
                        <Tooltip title={'Избриши'} arrow>
                          <IconButton
                            onClick={() => this.props.removeFromList(item.ID)}
                            color="primary"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      </>)}
}

export default DeniedList;
