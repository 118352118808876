import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import EnqueueSnackBarMessage from '../../../components_bi/EnqueueSnackbarMessage'
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  Alert,
  Card,
  CardContent,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
} from '@mui/material';
import {styled} from "@mui/material/styles/index";
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import Text from 'src/components/Text';
const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);
class AdminProfile extends React.Component{


  constructor(props){
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      username: '',
      new_email: '',
      current_password: '',
      new_password: '',
      confirm_new_password: '',
      new_emailError: '',
      current_passwordError: '',
      new_passwordError: '',
      openChangePassword: false,
      openChangeEmail: false,
      subscriptionPlan: [],
      messageLoad: false
    }
  }


  componentDidMount = () => {
    this.getUser();
  };





  getUser = () => {
    window.makeRequest("GET", '/api/authenticate', null, true, (data) => {
      this.setState({
        firstname: data.body.firstname,
        lastname: data.body.lastname,
        email: data.body.email,
        telephone: data.body.telephone,
        username: data.body.username
      });

    }, (err) => {

    });
  };

  validateEmail = () => {
    let new_email = this.state.new_email;
    let new_emailError = '';

    switch (true) {
      case new_email.length < 1:
        new_emailError = 'Е-пошта не смее да биде празно поле';
        break;
      case /\S+@\S+\.\S+/.test(new_email) !== true:
        new_emailError = 'Внесете валидна Е-маил адреса';
        break;
    }

    if(new_emailError){
      this.setState({new_emailError});
      return false;
    }

    else return true;

  };

  validatePassword = () => {
    let current_pasword = this.state.current_password;
    let new_password = this.state.new_password;

    let current_passwordError = '';
    let new_passwordError = '';

    switch (true) {
      case current_pasword.length < 1:
        current_passwordError = 'Сегашната лозинка не смее да биде празно поле.';
        break;
    }

    switch (true) {
      case new_password.length < 1:
        new_passwordError = 'Новата лозинка не смее да биде празно поле.';
        break;
      case new_password.length < 8:
        new_passwordError = 'Новата лозинка не смее да содржи помалку од 8 карактери.';
        break;
      case new_password !== this.state.confirm_new_password:
        new_passwordError = 'Лозинките не се совпаѓаат.';
        break;
    }

    if(current_passwordError || new_passwordError){
      this.setState({current_passwordError, new_passwordError});
      return false;
    }

    return true;
  };


  handleChangePasswordSuccess = () => {
    if(this.validatePassword()) {
      window.makeRequest("PUT", `/api/Authenticate`, {current_password: this.state.current_password, new_password: this.state.new_password}, true, (callback) => {
        this.handleCloseChangePassword();
        this.setState({messageLoad: true})
      }, (err) => {
        if (err) {
          this.setState({current_passwordError: 'Погрешна лозинка.', new_paswordError: ''})
        }
      });
    }
  };

  handleChangeEmailSuccess = () => {
    if(this.validateEmail()) {
      window.makeRequest("GET", `/api/Register/CheckEmail?email=${this.state.new_email}`, null, true, (data) => {
        if(data.body === false){
          this.setState({new_emailError: 'Веке е регистриран корисник со таа Е-пошта.'})
        }
        else{
          window.makeRequest("PUT", `/api/Authenticate/?email=${this.state.new_email}`, false, true, (callback) => {
            this.getUser();
            this.handleCloseChangeEmail();
          }, (err) => {
            if (err) {
              console.log('err', err);

            }
          });
        }
      }, (err) => {

      });

    }
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  };

  handleOpenChangeEmail = () => {
    this.setState({openChangeEmail: true})
  };

  handleCloseChangeEmail = () => {
    this.setState({openChangeEmail: false, new_email: '', new_emailError: ''})
  };

  handleOpenChangePassword = () => {
    this.setState({openChangePassword: true})
  };

  handleCloseChangePassword = () => {
    this.setState({openChangePassword: false, current_password: '', current_passwordError: '', new_password: '', new_passwordError: '', confirm_new_password: ''})
  };

  showSuccessChangePassword = () => {
    if(this.state.messageLoad){
      this.setState({messageLoad: false});
      return<EnqueueSnackBarMessage message={'Успешна промена на лозинката.'} type={'success'}/>
    }
  }

  render() {

    return (
      <>
        {this.showSuccessChangePassword()}
        <RootWrapper sx={{p: 3, backgroundColor: '#fff', mr: 3, ml: 3, borderRadius: '5px', mt: 3}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h3" component="h3" gutterBottom>
                    {('Преглед на профилот')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: { xs: 2, sm: 0 } }}
                component={RouterLink}
                startIcon={<ArrowBackTwoToneIcon />}
                to="/"
                variant="contained"
              >
                {('Откажи')}
              </Button>
            </Grid>
          </Grid>
        </RootWrapper>

        <RootWrapper sx={{p: 3}}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {('Детали за профилот')}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {('Корисничко име')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{this.state.username}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {('Име и презиме')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{this.state.firstname + " " +this.state.lastname}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {('Е-пошта')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                      <Text color="black">
                        <b>{this.state.email}</b>
                      </Text>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                      <Text color="black">
                        <b>{this.state.telephone}</b>
                      </Text>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9} sx={{mt: 6}}>
                    <Button variant={'contained'} size={'small'} onClick={this.handleOpenChangeEmail}>Промени е-пошта</Button>
                    <Button variant={'contained'} size={'small'} sx={{ml: 1}} onClick={this.handleOpenChangePassword}>Промени лозинка</Button>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </RootWrapper>


        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.openChangeEmail}
          onClose={this.handleCloseChangeEmail}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Променете е-пошта'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Пополнете го полето за да ја променете Вашата е-пошта'
              }
            </Typography>
          </DialogTitle>

          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Нова е-пошта'}
                      name="new_email"
                      onChange={this.handleChange}
                      value={this.state.new_email}
                      variant="outlined"
                    />
                    {
                      this.state.new_emailError? <Alert severity="error" sx={{mt: 1}}>{this.state.new_emailError}</Alert> : ""
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCloseChangeEmail}>
              {'Откажи'}
            </Button>
            <Button
              type="submit"
              onClick={this.handleChangeEmailSuccess}
              // disabled={Boolean(errors.submit) || isSubmitting}
              variant="contained"
            >
              {'Промени е-пошта'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.openChangePassword}
          onClose={this.handleCloseChangePassword}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Променете лозинка'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Пополнете ги полињата за да ја промените лозинката'
              }
            </Typography>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Сегашна лозинка'}
                      name="current_password"
                      type={'password'}
                      onChange={this.handleChange}
                      value={this.state.current_password}
                      variant="outlined"
                    />
                    {
                      this.state.current_passwordError? <Alert severity="error" sx={{mt: 1}}>{this.state.current_passwordError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Нова лозинка'}
                      name="new_password"
                      type={'password'}
                      onChange={this.handleChange}
                      value={this.state.new_password}
                      variant="outlined"
                    />
                    {
                      this.state.new_passwordError? <Alert severity="error" sx={{mt: 1}}>{this.state.new_passwordError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Потврди нова лозинка'}
                      name="confirm_new_password"
                      type={'password'}
                      onChange={this.handleChange}
                      value={this.state.confirm_new_password}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCloseChangePassword}>
              {'Откажи'}
            </Button>
            <Button
              type="submit"
              onClick={this.handleChangePasswordSuccess}
              // disabled={Boolean(errors.submit) || isSubmitting}
              variant="contained"
            >
              {'Промени лозинка'}
            </Button>
          </DialogActions>
        </Dialog>
        <Box pl={4} mt={8} sx={{paddingBottom: '30px', position: 'absolute', bottom: 0}}>
          <Box sx={{display: 'flex'}}>
            <Typography variant="subtitle1"> За сите Ваши прашања контактирајте нѐ на: info@bonitet.mk: </Typography>
            <Typography sx={{ paddingLeft: '10px' }} variant="subtitle1"> <b>info@bonitet.mk</b> </Typography>
          </Box>
          <Box alignItems="center">
            <Typography variant="subtitle1"> Ул. Борка Талески бр. 12Б Центар - Скопје, Р. Македонија. </Typography>
          </Box>
        </Box>
      </>
    )
  }
}

export default AdminProfile

