import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Button,  } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function PageHeader(props) {
  const { t } = useTranslation();

  const periods = [
    {
      value: 'this_week',
      text: t('This week')
    },
    {
      value: 'last_month',
      text: t('Last month')
    },
    {
      value: 'last_year',
      text: t('Last year')
    }
  ];

  const actionRef1 = useRef(null);
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  const [period, setPeriod] = useState(periods[0].text);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      {
        props.company &&
        <Grid item style={{width: '70%'}}>
          {/*<Typography variant="h3" component="h3" gutterBottom>*/}
            {/*{t('Приказ на компанија')}*/}
          {/*</Typography>*/}
          <Typography variant="h3" color={'primary'}>
            {props.company.Company_name}
          </Typography>
          <Typography mt={1}>
            <Typography variant="h4" >ЕМБС: {props.company.PIN}</Typography>
            <Typography variant="h4" >ЕДБ: {props.company.VAT}</Typography>
          </Typography>
        </Grid>
      }
      <Grid item>
        <Button
          component={RouterLink}
          to={'/companies'}
          variant="outlined"
          ref={actionRef1}
          onClick={() => setOpenMenuPeriod(true)}
          startIcon={'<-'}
        >
          Назад
        </Button>

      </Grid>
    </Grid>
  );
}

export default PageHeader;
