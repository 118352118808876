import React from 'react';
import Alert from '@mui/material/Alert';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  CircularProgress,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const initialState = {
  isSubmitting: false,
  open:false,
  id: "",
  username:  "",
  password:  "",
  isAdmin:  "",
  telephone:  "",
  email:  "",
  firstname:  "",
  lastname:  "",
  confirm_password: "",
  loading: true,
  isFormValid: false,
  isUsernameValid: null,
  isEmailValid: null,
  emailError : '',
  telephoneError : '',
  usernameError :'',
  firstnameError : '',
  passwordError : '',
  lastnameError : '',
  countries: ['mk']
}

class PageHeader extends React.Component{



constructor(props) {
  super(props);
  this.state = initialState

}

  handleCreateUserOpen = () => {
    this.setState({open:true});
  };

 handleCreateUserClose = () => {
   this.setState(initialState);
 };

  validate = () => {
    let emailError = '';
    let telephoneError = '';
    let usernameError = '';
    let firstnameError = '';
    let passwordError = '';
    let lastnameError = '';

    let email = this.state.email;
    let telephone = this.state.telephone;
    let username = this.state.username;
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;
    let password = this.state.password;
    let confirm_password = this.state.confirm_password;


    switch (true) {
      case username.length < 1:
        usernameError = 'Корисничкото име не може да биде празно поле';
        break;
      case username.length < 4:
        usernameError = 'Корисничкото име мора да има 4 или повеќе карактери';
        break;
      case this.state.isUsernameValid === false:
        usernameError = 'Корисничкото име веќе постои';
        break;
      default:
    }
    switch (true) {

      case firstname.length < 1:
        firstnameError = 'Името не смее да биде празно';
        break;
      case firstnameError.length > 15:
        firstnameError = 'Името не смее да биде повеке од 15 карактери';
        break;
      default:

    }
    switch (true) {
      case email.length < 1:
        emailError = 'Е-пошта не смее да биде празно поле';
        break;
      case /\S+@\S+\.\S+/.test(email) !== true:
        emailError = 'Внесете валидна Е-маил адреса';
        break;
      case this.state.isEmailValid === false:
        emailError = 'Веќе е регистриран кориснички профил со таа Е-пошта';
        break;
      default:


    }
    switch (true) {
      case password.length < 1:
        passwordError = 'Лозинката е задолжителна';
        break;
      case password.length < 8:
        passwordError = 'Лозинката мора да има 8 или повеќе карактери';
        break;
      case password !== confirm_password:
        passwordError = 'Лозинката не се совпаѓа';
        break;
      default:

    }
    switch (true) {
      case telephone.length !== 11:
        telephoneError = 'Невалиден број';
        break;
      case telephone.startsWith('3897') === false && telephone.startsWith('3892') === false && telephone.startsWith('3893') === false && telephone.startsWith('3894') === false:
        telephoneError = 'Невалиден број';
        break;
      default:


    }
    switch (true) {
      case lastname.length < 1:
        lastnameError = 'Презимето не смее да биде празно';
        break;
      case lastname.length > 30:
        lastnameError = 'Името не смее да биде повеке од 30 карактери';
        break;
      default:


    }
    if(usernameError || emailError || lastnameError|| passwordError || telephoneError || firstnameError) {
      this.setState({ usernameError, emailError, lastnameError, passwordError, telephoneError,firstnameError, isFormValid: false });
      return false;
    }

    return true;
  }

  checkUsername = () => {
    window.makeRequest("GET", `/api/User/check?username=${this.state.username}`, false, true, (callback) => {
      this.setState({isUsernameValid: callback.body})
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }

  checkEmail = () => {
    window.makeRequest("GET", `/api/User/check?email=${this.state.email}`, false, true, (callback) => {
      this.setState({isEmailValid: callback.body})
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }

  handleCreateUserSuccess = () => {
    this.checkUsername();
    this.checkEmail();

    if(this.validate()) {
      window.makeRequest("POST", `/api/User`, {
        Username: this.state.username,
        Password: this.state.password,
        isAdmin: this.state.isAdmin,
        Telephone: this.state.telephone,
        Email: this.state.email,
        Firstname: this.state.firstname,
        Lastname: this.state.lastname,
        Division: true,
        AddCompany: true,
        Basket: true,
        CompanyView: true,
        Import: true,
        ManageUsers: false,
        ManageDivisions: true,
        ManagePermissions: false,
        ManageCompanies: true,
        ManagePaymants: false,
        ManageSubscription: false,
        isActiv: true,
        UserType: 'user'
      }, true, (callback) => {
        this.setState(initialState, () => this.props.createUser(callback.body.Response.ReasonPhrase));
      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });
      this.setState({open: false});
    }
  };

 handleChange  = (e) => {this.setState({[e.target.name]: e.target.value})};

  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Уредување на корисници'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Сите функционалности поврзани со корисниците може да се уредуваат преку оваа страна'
              }
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Креирај корисник'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Креирајте нов корисник'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Пополнете ги полињата подолу за да креирате нов корисник'
              }
            </Typography>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Корисничко име'}
                      name="username"
                      onChange={this.handleChange}
                      value={this.state.username}
                      variant="outlined"
                    />
                    {
                      this.state.usernameError? <Alert severity="error" sx={{mt: 1}}>{this.state.usernameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField

                      fullWidth
                      label={'Име'}
                      name="firstname"
                      onChange={this.handleChange}
                      value={this.state.firstname}
                      variant="outlined"
                    />
                    {
                      this.state.firstnameError? <Alert severity="error" sx={{mt: 1}}>{this.state.firstnameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Презиме'}
                      name="lastname"
                      onChange={this.handleChange}
                      value={this.state.lastname}
                      variant="outlined"
                    />
                    {
                      this.state.lastnameError? <Alert severity="error" sx={{mt: 1}}>{this.state.lastnameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Е-пошта'}
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                    />
                    {
                      this.state.emailError? <Alert severity="error" sx={{mt: 1}}>{this.state.emailError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Лозинка'}
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                      value={this.state.password}
                      variant="outlined"
                    />
                    {
                      this.state.passwordError? <Alert severity="error" sx={{mt: 1}}>{this.state.passwordError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Потврди лозинка'}
                      name="confirm_password"
                      onChange={this.handleChange}
                      type="password"
                      value={this.state.confirm_password}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      onlyCountries={this.state.countries}
                      country={'mk'}
                      masks={{mk: '(..) ...-...'}}
                      placeholder={'+389 xxx xxx xx'}
                      value={this.state.telephone}
                      onChange={telephone => this.setState({telephone})}
                    />
                    {
                      this.state.telephoneError? <Alert severity="error" sx={{mt: 1}}>{this.state.telephoneError}</Alert> : ""
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCreateUserClose}>
              {'Откажи'}
            </Button>
            <Button
              onClick={this.handleCreateUserSuccess}
              type="submit"
              startIcon={
                this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              // disabled={Boolean(errors.submit) || isSubmitting}
              variant="contained"
            >
              {'Креирај корисник'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

}

export default PageHeader;
