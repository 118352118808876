import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";


const Results = ({ users, ...rest }) => {
  const { t } = useTranslation();



  const columns = [

    {
      field: 'Firstname',
      headerName: 'Име',
      align: 'center',
      headerAlign: 'center',
      width: 150,

    },
    {
      field: 'Username',
      headerName: 'Кориснчко име',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,

    },
  {
    field: 'Email',
    headerName: 'Е-маил',
    align: 'center',
    headerAlign: 'center',
    width: 250,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'Telephone',
    headerName: 'Телефон',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    disableColumnMenu: true,

  },
  {
    field: 'Company_name',
    headerName: 'Име на компанија',
    align: 'left',
    headerAlign: 'center',
    width: 600,
    disableColumnMenu: true,
    renderCell: (params) => {
      return<>
        <Typography noWrap>
          <Tooltip title={params.row.Company_name} arrow>
            <RouterLink  style={{textDecoration: 'none', color: 'black'}} to={`/company/${params.row.ID}/dashboard`}>
              {params.row.Company_name}
            </RouterLink >
          </Tooltip>
        </Typography>
      </>
    }

  },
  {
    field: 'VAT',
    headerName: 'ЕДБ',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: 'PIN',
    headerName: 'ЕМБС',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    disableColumnMenu: true,

  },

    {
      field: '*',
      headerName: 'Менаџирај',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <><Tooltip title={t('Преглед')} arrow>
          <IconButton
            component={RouterLink}
            to={`/SubscriptionClients/${params.row.ID}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
        </>
      },
      disableColumnMenu: true,
    },
  ];


  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
              <BI_DataGrid data={users} columns={columns} search={true} />

    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  users: []
};

export default Results;
