import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const ImgWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 5;
    width: 120%;
    height: 500px;
    overflow: hidden;
    border-radius: ${theme.general.borderRadiusLg};
    box-shadow: 0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%);

    img {
      display: block;
      width: 100%;
      height: 99%;
    }
  `
);

const BoxAccent = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadiusLg};
    background: ${theme.palette.background.default};
    width: 120%;
    height: 100%;
    position: absolute;
    left: -40px;
    bottom: -40px;
    display: block;
    z-index: 4;
  `
);

const BoxContent = styled(Box)(
  () => `
  width: 120%;
  position: relative;
`
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.success.main};
    color: ${theme.palette.success.contrastText};
    font-weight: bold;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    font-size: ${theme.typography.pxToRem(11)};
    padding: ${theme.spacing(.5)} ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(2)};
`
);

const ListItemWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
`
);

const MuiAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #e5f7ff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

const JsAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #fef8d8;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

function Hero() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" >
      <Grid spacing={{ xs: 6, md: 10 }} justifyContent="center" alignItems="center" container>
        <Grid item md={6} pr={{ xs: 0, md: 3 }}>
          <TypographyH1 sx={{ mb: 2 }} variant="h1">
            {t('За Bonitet.mk')}
          </TypographyH1>
          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4}}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            {t(
              'Bonitet.mk е најсофистицирана business intelligence платформа со автоматизиран систем за пресметка на финансиските резултати и бонитетот на компаниите од Вашето портфолио.\n' +
              'Користењето е целосно бесплатно!'
            )}
          </TypographyH2>


          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            {t(
              'Платформата Bonitet.mk е наменета за секој бизнис кој се стреми да биде успешен. На брз и едноставен начин можете да ги внесете и обработите сите важни финансиски податоци и веднаш да добиете детален финансиски приказ. Постојат два начини на генерирање бонитети за македонски компании. Едниот е со Ваше вчитување на податоците од завршна сметка, а вториот е со купување на податоците преку платформата. За неколку секунди ќе добиете целосна финансиска слика за фирмите од Ваш интерес со што ќе му помогнете на Вашиот бизнис да дејствува во вистинската насока и да носи паметни деловни одлуки.'
            )}
          </TypographyH2>

          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            {t(
              'Преку Bonitet.mk имате можност да создадете Ваше портфолио на компании, а алатката ќе Ви ги пресмета сите важни групни финансиски индикатори на Вашето портфолио.'
            )}
          </TypographyH2>


          <Button

            component="a"
            href="/register"
            size="large"
            variant="contained"
          >
            {t('Регистрирај се веднаш.')}
          </Button>

        </Grid>
        <Grid item md={6}>
          <BoxContent>
            <ImgWrapper>
              <img alt="Tokyo" src="/static/images/overview/zanas.png" />
            </ImgWrapper>
            <BoxAccent sx={{ display: { xs: 'none', md: 'block' } }} />
          </BoxContent>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
