import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);



function Status404() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xl">
          <Box textAlign="center">
            <img alt="404" height={180} src="/static/images/status/404.svg" />
            <Typography variant="h2" sx={{ my: 2 }}>
              {t("Содржината што ја барате не е пронајдена")}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{ mb: 4 }}
            >
              {t(
                "Можеби ја имаме променето содржината на друг линк, кликнете на копчето за да се вратите на почеток!"
              )}
            </Typography>
          </Box>
          <Container maxWidth="sm">


            <Card sx={{ textAlign: 'center',boxShadow: 0 }} style={{backgroundColor: 'transparent'}}>

              <Divider sx={{ my: 4 }}></Divider>
              <Button href="/" variant="outlined">
                {t('Врати се назад')}
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}

export default Status404;
