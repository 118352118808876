import React from 'react';
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button
} from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import SuspenseLoader from '../../../../../../components/SuspenseLoader'
import { styled } from '@mui/material/styles';



const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false});

  }



  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <Box
                  p={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      {'Информации за корисник'}
                    </Typography>
                    <Typography variant="subtitle2">
                      {'Менаџирај ги информациите на корисникот'}
                    </Typography>
                  </Box>
                  <Button variant="text" startIcon={<EditTwoToneIcon />}>
                    {'Промени'}
                  </Button>
                </Box>
                <Divider />
                <CardContent
                  sx={{
                    p: 4
                  }}
                >
                  <Typography variant="subtitle2">
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Име'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.Firstname}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Презиме'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.Lastname}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Корисничко име'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.Username}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Е-маил'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.Email}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Телефон'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.Telephone}</b>
                        </Text>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*<Grid item xs={12}>*/}
              {/*<Card>*/}
                {/*<Box*/}
                  {/*p={3}*/}
                  {/*display="flex"*/}
                  {/*alignItems="center"*/}
                  {/*justifyContent="space-between"*/}
                {/*>*/}
                  {/*<Box>*/}
                    {/*<Typography variant="h4" gutterBottom>*/}
                      {/*{'Лозинка'}*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="subtitle2">*/}
                      {/*{'Промена на лозинка'}*/}
                    {/*</Typography>*/}
                  {/*</Box>*/}
                  {/*<Button variant="text" startIcon={<EditTwoToneIcon />}>*/}
                    {/*{'Промени'}*/}
                  {/*</Button>*/}
                {/*</Box>*/}
                {/*<Divider />*/}
                {/*<CardContent*/}
                  {/*sx={{*/}
                    {/*p: 4*/}
                  {/*}}*/}
                {/*>*/}
                  {/*/!*<Typography variant="subtitle2">*!/*/}
                    {/*/!*<Grid container spacing={0}>*!/*/}
                      {/*/!*<Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>*!/*/}
                        {/*/!*<Box pr={3} pb={2}>*!/*/}
                          {/*/!*{'Лозинка'}:*!/*/}
                        {/*/!*</Box>*!/*/}
                      {/*/!*</Grid>*!/*/}
                      {/*/!*<Grid item xs={12} sm={8} md={9}>*!/*/}
                        {/*/!*<Text color="black">*!/*/}
                          {/*/!*<b>********</b>*!/*/}
                        {/*/!*</Text>*!/*/}
                      {/*/!*</Grid>*!/*/}

                    {/*/!*</Grid>*!/*/}
                  {/*/!*</Typography>*!/*/}
                {/*</CardContent>*/}
              {/*</Card>*/}
            {/*</Grid>*/}
          </Grid>

        </RootWrapper>

      );
    }

  }


}


export default UserManagement;

