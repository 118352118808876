import React from 'react';
import Alert from '@mui/material/Alert';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Autocomplete,
  MenuItem,
  Typography,
  TextField,
  Button
} from '@mui/material';
import Select from '@mui/material/Select';
import {ocupations} from "../Data/all_ocupation";


class AddCompanySecondInputsDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }
  componentDidMount() {

  }

  render(){
    return(
      <Dialog
        fullWidth
        maxWidth="md"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {('Додадете нова компанија')}
          </Typography>
          <Typography variant="subtitle2">
            {(
              'Пополнете ги полињата за да додадете компанија'
            )}
          </Typography>
        </DialogTitle>


        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={('ЕДБ')}
                    name="VAT"
                    value={this.props.VAT}
                    disabled={true}
                    variant="outlined"
                  />
                  {this.props.vatError && <Alert severity="error" sx={{ mt: 1 }}>{this.props.vatError}</Alert>}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={('ЕМБС')}
                    name="PIN"
                    value={this.props.PIN}
                    disabled={true}
                    variant="outlined"
                  />
                  {this.props.pinError && <Alert severity="error" sx={{ mt: 1 }}>{this.state.pinError}</Alert>}
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label={('Име')}
                    name="Company_name"
                    value={this.props.Company_name}
                    disabled={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={('Ваш краток назив за компанијата')}
                    name="Company_name_by_user"
                    value={this.props.Company_name_by_user}
                    onChange={this.props.handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={('Година на основање')}
                    name="Establishment_year"
                    value={this.props.Establishment_year}
                    onChange={this.props.handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>

                    <InputLabel id="demo-simple-select-label">Големина</InputLabel>
                    <Select
                      value={this.props.Size && this.props.Size}
                      label={"Големина"}
                      onChange={this.props.changeGoleminaRole}
                    >
                      >
                      {this.props.goleminaRoles.map((role) => {
                        return <MenuItem value={role.value}>{role.label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    sx={{ m: 0 }}
                    limitTags={2}
                    defaultValue={this.props.Occupations.find(occupation => occupation.id === this.props.Occupation)}
                    options={this.props.Occupations}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option.text}
                      </li>
                    )}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        size={'large'}
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder={('Одберете дејност...')}
                      />
                    )}
                    onChange={this.props.chandeDejnostRole}
                  />

                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={('Адреса')}
                    name={'Adress'}
                    value={this.props.Adress}
                    onChange={this.props.handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={('Град')}
                    name={'City'}
                    onChange={this.props.handleChange}
                    value={this.props.City}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button color="secondary" onClick={this.props.onClose}>
            {('Откажи')}
          </Button>
          <Button
            type="submit"
            onClick={this.props.handleCreateCompanySuccess}
            variant="contained"
          >
            {('Додади компанија')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddCompanySecondInputsDialog
