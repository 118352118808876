import React from 'react';
import request from 'superagent';
import SuspenseLoader from 'src/components/SuspenseLoader';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Avatar,
  Box,
  Divider,
  Slide,
  Button,
  styled
} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ListBuyDivisionDialogOutdated from './ListBuyDivisionDialogOutdated'


const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonSuccess = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);


class BuyDivisionDialogOutdated extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      files: '',
      year: '',
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false})
  }


  returnTypeMessage = (message, companiesNames) => {

      return<><AvatarSuccess>
        <AddIcon color={'success'} />
      </AvatarSuccess>
        <Typography
          align="center"
          sx={{
            pt: 1,
            pb: 2,
            px: 6
          }}
          variant="h3"
        >
          {message}
        </Typography>
        <Divider />
        <ListBuyDivisionDialogOutdated companiesNames={companiesNames} />
      </>

  }


  ReturnMessage = () => {
    if(this.props.open){
      const companiesNames = this.props.companiesNames;
      return  this.returnTypeMessage('Бараните репорти се веќе генерирани или додадени во кошничката!', companiesNames);
    }

  }


  render(){
    if(this.state.loading) {
      return <SuspenseLoader/>
    }else{
      return(
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
            mt={3}
          >

            {this.ReturnMessage()}
            <Box item >
              <ButtonSuccess
                onClick={this.props.onClose}
                size="large"
                sx={{
                  mx: 1,
                  px: 3,
                  mt: 3
                }}
                variant="contained"
              >
                {'Во ред'}
              </ButtonSuccess>
            </Box>
          </Box>
        </Dialog>
      )
    }

  }
}


export default BuyDivisionDialogOutdated;
