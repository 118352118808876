import React from 'react';
import {
  Box,
  Typography,
  Hidden,
  Container,
  Button,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';


const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);


class Unauthorized extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
    }
  }

  render() {
    return (
      <>
        <MainContent>
          <Grid
            container
            sx={{ height: '100%' }}
            alignItems="stretch"
            spacing={0}
          >
            <Grid
              xs={12}
              alignItems="center"
              display="flex"
              justifyContent="center"
              item
            >
              <Container >
                <Box textAlign="center">
                  <img
                    alt="500"
                    height={260}
                    src="/static/images/status/500.svg"
                  />
                  <Typography variant="h2" sx={{ my: 2 }}>
                    {'Недозволен пристап!'}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{ mb: 4 }}
                  >
                    {
                      'Немате право на авторизација за да пристапите.'
                    }
                  </Typography>
                  <Button href="/" variant="contained" sx={{ ml: 1 }}>
                    {'<-'}
                  </Button>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </MainContent>
      </>
    );
  }


}

export default Unauthorized;
