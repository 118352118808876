import React from 'react';
import CustomSnackBar from '../../../../components_bi/CustomSnackBar'

import {
  Box,
  Card,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Autocomplete,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Avatar,
  Dialog,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';

import {
  aop_titles,
  aop_new_obj,
  aop_fields1,
  app_fields2,
  aop_new_obj_before_year,
  aop_new_obj_this_year
} from '../Data/aop_titles';
import {
  ocupations,
} from '../Data/all_ocupation';

import ResultReportForm from './ResultReportForm'
import { forwardRef } from 'react';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const goleminaRoles = [
  { label: 'Голем', value: 'голем' },
  { label: 'Среден', value: 'среден' },
  { label: 'Мал', value: 'мал' },
  { label: 'Микро', value: 'микро' }
];

class ReportResult extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      loading: false,
      tabs: [
        {
          value: 'Биланс на состојба',
          label: 'Биланс на состојба'
        },
        {
          value: 'Биланс на успех',
          label: 'Биланс на успех'
        },
      ],
      selectedTab: 'Биланс на состојба',
      ageOld: '',
      Size: '',
      aop1: [],
      aop2: [],
      CompanyDetails: [],
      aopError: '',
      Occupation: '',
      Godina: '',
      messageLoad: false

    }
  }

  componentDidMount(){
    if(this.props.CompanyDetails){
      let starost = this.props.CompanyDetails.hasOwnProperty('Establishment_year') ? new Date().getFullYear() - this.props.CompanyDetails.Establishment_year   : '';
      let dejnost = this.props.CompanyDetails.hasOwnProperty('Occupation') ? this.props.CompanyDetails.Occupation   : '';
      let golemina = this.props.CompanyDetails.hasOwnProperty('Size') ? this.props.CompanyDetails.Size   : '';
      this.setState({CompanyDetails: this.props.CompanyDetails, ageOld: starost, Size: golemina, Occupation: dejnost,Godina: this.props.Godina && this.props.Godina, loading: true})
    }
  }

  handleTabsChange = (event, tabsValue) => {
    this.setState({selectedTab: tabsValue, });
  };

  handleOccupationChange = (e, v) => {
    this.setState({Occupation: v.id})
  }

  handleSizeChange = (e,v) => {
    this.setState({Size: v.props.value})
  }
  handleAgeChange = (e, v) => {
    if(e){
      if(parseInt(e.target.value)){
        this.setState({ageOld: parseInt(e.target.value)})
      }else {
        this.setState({ageOld: ''})
      }
    }

  }


  handleChangeAOP1 = (e,val) => {
    if(parseInt(val)){
      this.state.aop1[e] = parseInt(val);
    } else if(val === 0 || val === '0') {
      this.state.aop1[e] = 0;
    } else {
      this.state.aop1[e] = null;
    }
  }
  handleChangeAOP2 = (e,val) => {
    if(parseInt(val)) {
      this.state.aop2[e] = parseInt(val);
    } else if(val === 0 || val === '0') {
      this.state.aop2[e] = 0;
    } else {
      this.state.aop2[e] = null;
    }
  }

  validate = () => {
    let data2 = this.state.aop2;
    let data = this.state.aop1;

    let thisYearAOP111 = data.AOP111;
    let thisYearAOP63 = data.AOP63;
    let lastYearAOP111 = data2['AOP111'];
    let lastYearAOP63 = data2['AOP63'];
    let thisYearAOP255 = data['AOP255'];
    let thisYearAOP256 = data['AOP256'];
    let lastYearAOP255 = data2['AOP255'];
    let lastYearAOP256 = data2['AOP256'];
    let thisYearAOP77 = data['AOP77'];
    let thisYearAOP78 = data['AOP78'];
    let lastYearAOP77 = data2['AOP77'];
    let lastYearAOP78 = data2['AOP78'];
    let thisYearAOP258 = data['AOP258'];
    let lastYearAOP258 = data2['AOP258'];
    let Size = this.state.Size;
    let Occupation = this.state.Occupation;
    let ageOld = this.state.ageOld;

    let aopError = '';
    let check255and256 = false;
    let check77and255 = false;
    let check78and256 = false;
    let check258 = false;
    let check63and111 = false;



    for (const [key, value] of Object.entries(data)) {
      if (key.indexOf('AOP') > -1) {

        if (key !== 'AOP65') {
          switch (true) {
            case value < 0:
              aopError = 'Негативен износ не смее да се прифати освен во AOP65';
              break;
          }
        }
      }
    }
    for (const [key, value] of Object.entries(data2)) {
      if (key.indexOf('AOP') > -1) {

        if (key !== 'AOP65') {
          switch (true) {
            case value < 0:
              aopError = 'Негативен износ не смее да се прифати освен во AOP65';
              break;
          }
        }
      }
    }
    //
    if(thisYearAOP111 || thisYearAOP63){
      if(thisYearAOP111 !== thisYearAOP63){
        check63and111 = true;
      }
    }

    if(lastYearAOP111 || lastYearAOP63){
      if(lastYearAOP111 !== lastYearAOP63){
        check63and111 = true;
      }
    }


    if(thisYearAOP255 || thisYearAOP256){
      if(thisYearAOP255 && thisYearAOP256){
        if(thisYearAOP255 !== 0 || thisYearAOP256 !== 0){
          check255and256 = true
        }
      }
    }
    if(lastYearAOP255 || lastYearAOP256){
      if(lastYearAOP255 && lastYearAOP256){
        if(lastYearAOP255 !== 0 || lastYearAOP256 !== 0){
          check255and256 = true
        }
      }
    }


    if(!thisYearAOP77 || thisYearAOP77 === 0) {
      if(thisYearAOP255 && thisYearAOP255 !== 0){
        check77and255 = true
      }
    }
    if(!thisYearAOP78 || thisYearAOP78 === 0){
      if(thisYearAOP256 && thisYearAOP256 !== 0){
        check78and256 = true
      }
    }
    if(!lastYearAOP77 || lastYearAOP77 === 0) {
      if(lastYearAOP255 && lastYearAOP255 !== 0){
        check77and255 = true
      }
    }
    if(!lastYearAOP78 || lastYearAOP78 === 0){
      if(lastYearAOP256 && lastYearAOP256 !== 0){
        check78and256 = true
      }
    }

    if(!thisYearAOP255 || thisYearAOP255 === 0) {
      if(thisYearAOP77 && thisYearAOP77 !== 0){
        check77and255 = true
      }
    }
    if(!thisYearAOP256 || thisYearAOP256 === 0){
      if(thisYearAOP78 && thisYearAOP78 !== 0){
        check78and256 = true
      }
    }
    if(!lastYearAOP255 || lastYearAOP255 === 0) {
      if(lastYearAOP77 && lastYearAOP77 !== 0){
        check77and255 = true
      }
    }
    if(!lastYearAOP256 || lastYearAOP256 === 0){
      if(lastYearAOP78 && lastYearAOP78 !== 0){
        check78and256 = true
      }
    }

    if(thisYearAOP258){
      if(thisYearAOP258 === 0 || thisYearAOP258 > 12){
        check258 = true;
      }
    }
    if(lastYearAOP258){
      if(lastYearAOP258 === 0 || lastYearAOP258 > 12){
        check258 = true;
      }
    }


    switch (true) {
      case check63and111:
        aopError = 'Позициите АОП111 и АОП63 мора да бидат еднакви.';
        break;
      case check258:
        aopError = 'Позицијата АОП258 мора да содржи број од 1 до 12.';
        break;
      case check77and255:
        aopError = 'Позициите АОП77 и АОП255 мора да бидат еднакви.';
        break;
      case check78and256:
        aopError = 'Позициите АОП78 и АОП256 мора да бидат еднакви.';
        break;
      case check255and256:
        aopError = 'Во позициите АОП255 и АОП256 неможе да биде содржана вредност истовремено.';
        break;
      case !Size:
        aopError = 'Мора да има селектирано големина.';
        break;
      case !Occupation:
        aopError = 'Дејноста не смее да биде празно поле.';
        break;
      case !ageOld:
        aopError = 'Староста не смее да биде празно поле.';
        break;
    }

    if (aopError) {
      this.setState({ aopError });
      return false;
    }

    return true;
  };


  handleAddFinance = () => {
    if (this.validate()) {
      const data1 = {
        Godina: parseInt(this.state.Godina) - 1,
        Report_type_ID: this.props.reportType,
        Company_ID: this.props.id,
        isDefault: false,
        Golemina: this.state.Size,
        Dejnost: this.state.Occupation,
        Starost: this.state.ageOld - 1,
        ...this.state.aop1

      }
      const data2 = {
        Godina: parseInt(this.state.Godina),
        Report_type_ID: this.props.reportType,
        Company_ID: this.props.id,
        isDefault: false,
        Golemina: this.state.Size,
        Dejnost: this.state.Occupation,
        Starost: this.state.ageOld,
        ...this.state.aop2

      }



      window.makeRequest('POST', `/api/Finance?isDraft=true`,
        data1
        , true, (callback) => {
          window.makeRequest('POST', `/api/Finance?isDraft=true`,
            data2
            , true, (callback) => {

              this.setState({messageLoad: true})
              setTimeout(() => {
                window.location.href = `/company/${this.props.CompanyID}/dashboard`
              }, 2000)

            }, (err) => {
              if (err) {
                console.log('err', err);
              }
            });
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        });
    }
    else {
      this.setState({ open: true });
    }

  };
  closeCannotGenerate = () => {
    this.setState({ open: false });
  };

  render() {
    if(this.state.loading){
      return(
        <>
          {this.state.messageLoad && <CustomSnackBar message='Успешно зачуван извештај. Истиот се наоѓа во табелата Изработка.'/>}
          <Card container m={2}>
            <Grid
              spacing={2}
              container p={2}>

              <Grid item xs={12} md={2}>
                <TextField
                  size={'small'}
                  fullWidth
                  label={'Старост на компанија'}
                  name="AgeOld"
                  value={this.state.ageOld}
                  onChange={this.handleAgeChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}  md={2} >
                <FormControl fullWidth>
                  <InputLabel>Големина на компанија</InputLabel>
                  <Select
                    size={'small'}
                    label='Големина на компанија'
                    value={this.state.Size}
                    onChange={this.handleSizeChange}
                  >
                    >
                    {goleminaRoles.map((role) => {
                      return <MenuItem value={role.value}>{role.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}  md={8} >
                <Autocomplete
                  sx={{ m: 0 }}
                  limitTags={2}
                  defaultValue={ocupations.find(occupation => occupation.id === this.state.Occupation)}
                  options={ocupations}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.text}
                    </li>
                  )}
                  getOptionLabel={(option) => option.text}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      label='Дејност на компанија'
                      size={'small'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={('Дејност на компанија')}
                    />
                  )}
                  onChange={this.handleOccupationChange}
                />
              </Grid>
            </Grid>
          </Card>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            pb={3}
            mt={2}
          >
            <TabsWrapper
              onChange={this.handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={this.state.selectedTab}
              variant="scrollable"
            >
              {this.state.tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label}/>
              ))}
            </TabsWrapper>
          </Box>
          <Card>
            <Divider/>
            {this.state.selectedTab === 'Биланс на состојба' && <ResultReportForm
              handleAddFinance={this.handleAddFinance}
              CompanyDetails={this.props.CompanyDetails}
              titles={aop_titles}
              fields={aop_fields1}
              handleChangeAOP1={this.handleChangeAOP1}
              handleChangeAOP2={this.handleChangeAOP2}
              Size={this.state.Size}
              ageOld={this.state.ageOld}
              Occupation={this.state.Occupation}
              aop1={this.state.aop1}
              aop2={this.state.aop2}
            />
            }
            {this.state.selectedTab === 'Биланс на успех' &&

            <ResultReportForm
              handleAddFinance={this.handleAddFinance}
              CompanyDetails={this.props.CompanyDetails}
              handleChangeAOP1={this.handleChangeAOP1}
              handleChangeAOP2={this.handleChangeAOP2}
              titles={aop_titles}
              fields={app_fields2}
              Size={this.state.Size}
              ageOld={this.state.ageOld}
              Occupation={this.state.Occupation}
              aop1={this.state.aop1}
              aop2={this.state.aop2}
            />
            }


            {/*{this.returnTab()}*/}
            <DialogWrapper
              open={this.state.open}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
              keepMounted
              onClose={this.closeCannotGenerate}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={5}
              >
                <AvatarError>
                  <CloseIcon/>
                </AvatarError>

                <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
                  {this.state.aopError}
                </Typography>

                <Box>
                  <Button
                    variant="text"
                    size="large"
                    sx={{ mx: 1 }}
                    onClick={this.closeCannotGenerate}
                  >
                    {('Во ред')}
                  </Button>
                </Box>
              </Box>
            </DialogWrapper>
          </Card>
        </>
      )
    } else {
      return false
    }

  }
}




export default ReportResult;
