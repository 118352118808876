import React, {Fragment} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import request from "superagent";


import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';

import Cookies from './content/Cookies/index';
import HomePage from './content/overview/index'
import Offer from './content/Offer/index';
import AboutUs from './content/AboutUs/index'
import Contact from './content/Contact/index';
import Login from './content/pages/Auth/Login/Cover/index'
import Register from './content/pages/Auth/Register/Wizard/index'
import ConfirmResetPassword from './content/pages/Auth/ResetPassword/ConfirmResetPassword'
import ResetPasswordForm from './content/pages/Auth/ResetPassword/ResetPasswordForm'
import Status404 from './content/pages/Status/Status404/index'
import Unauthorized from './content/pages/Status/Status401-Unauthorized'
import {SnackbarProvider} from 'notistack';
import CollapsedSidebarLayout from './layouts/CollapsedSidebarLayout/index'
import ThemeProvider from './theme/ThemeProvider';
import {CssBaseline} from '@mui/material';
import SuspenseLoader from './components/SuspenseLoader';

import Users from './content/pages/Admin/Users/index';
import AllCompanies from './content/pages/Companies/AllCompanies';
import EditDivision from './content/pages/Divisions/EditDivision/index';
import SingleUser from './content/pages/Admin/Users/single/SingleUser';
import Permissions from './content/pages/Admin/Users/single/Permissions';
import SingleReport from './content/pages/Companies/SingleReport';
import ViewReport from './content/pages/Companies/ViewReport';
import CompanyDashboard from './content/pages/Dashboards/CompanyDashboard/index';
import ActivationPage from './content/pages/Auth/ActivationPage';
import AllDivisions from './content/pages/Divisions/AllDivisions';
import DivisionDashboard from './content/pages/Dashboards/DivisionDashboard'
import MyProfile from './content/pages/Profile/';
import AdminProfile from './content/pages/Profile/AdminProfile';
import CartOverview from './content/pages/Cart';
import AllUsers from './content/pages/Administrator/AllUsers/index';
import AllClients from './content/pages/Administrator/AllClients/index';
import AllImports from './content/pages/Administrator/AllImports/index';
import Subscription from './content/pages/Administrator/Subscription'
import AllReportOrders from './content/pages/Administrator/AllReportOrders/index'
import SingleUsers from './content/pages/Administrator/AllUsers/single'
import CheckOut from "./components_bi/CheckOut/index";
import SingleClients from './content/pages/Administrator/AllClients/single'
import RejectList from './content/pages/Administrator/RejectList/index'
import PortfolioLimit from './content/pages/Administrator/PortfolioLimit/index'
import ImportCompanies from "./content/pages/Administrator/AllImports/single";
import Orders from "./content/pages/Administrator/Orders";
import AllAdministrators from "./content/pages/Administrator/AllAdministrators";
import Reports from "./content/pages/Companies/Reports";
import AutoLogout from './components_bi/AutoLogout'

class App extends React.Component {
  constructor(props) {
    super(props);
    window.makeRequest = this.makeRequest;
    window.isTokenValid = this.isTokenValid;

    this.state = {
      // loading: false,
      authenticated: false,
      user: null,
      loadingReq: false,
      acceptedCookies: false,
      divisions: [],
    };

    this.mounted = false;
  }


  componentDidMount() {
    if (window.localStorage.getItem('token')) {
      this.isTokenValid();
    }
    if(localStorage.getItem("CookiesBI") && localStorage.getItem("CookiesBI") === "accepted"){
      this.setState({acceptedCookies: true})
    }
    else {
      this.setState({acceptedCookies: false})
    }
    this.initReactGA();
    this.mounted = true;
  }

  componentDidUpdate(props, state){
    if(state.authenticated !== this.state.authenticated){
      this.interval()
    }
  }

  interval = () => {
    setInterval(()=>{
      if(localStorage.getItem('token') === null && window.location.href !== '/'){
        this.logout()
      }
    }, 1000)
  }


  initReactGA = () => {
    ReactGA.initialize('UA-221315921');
    ReactGA.pageview(window.location.pathname + window.location.search);
  };


  isTokenValid = () => {
    this.makeRequest("GET", '/api/authenticate', null, true, (data) => {
      this.setState({
        user: data.body,
        authenticated: true
      });

    }, (err) => {
      if (err) {
        this.setState({
          authenticated: false,
          user: null,
        })
      }

    });



  };



  logout = () => {
    this.setState({authenticated: false, user: null, loadingReq: true });
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userType');
    window.location.href = '/';
  };

  login = (loginObj) => {

    localStorage.setItem('token', loginObj.AccessToken);

    this.isTokenValid(loginObj);

    if(loginObj.Administrator === false){
      window.location.href = '/companies';

    }
    else{
      window.location.href = '/';

    }

  };




  makeRequest = (type, url, data, includeCredentials, success, error) => {
    let req = null;
    switch (type) {
      case "GET":
        req = request.get(url);
        break;
      case "POST":
        req = request.post(url);
        break;
      case "PUT":
        req = request.put(url);
        break;
      case "DEL":
        req = request.del(url);
        break;
      default:
    }

    if (includeCredentials) {
      req = req.withCredentials();
    }

    if (data) {
      req = req.send(data);
    }

    req = req.set('Access-Control-Allow-Origin', window.location.protocol + '//' + window.location.host);

    if (includeCredentials) {
      req = req.set('authorization', 'Bearer ' + window.localStorage.getItem('token'));

    }
    req.type('application/json').then((data) => {
      this.setState({loadingReq: false})
      success(data);
    }, (err) => {
      if (err.status === 401) {
        () => this.logout();
      }
      if(err){
        this.setState({loadingReq: false})
      }
      error(err);
    });

    this.setState({loadingReq: true})
  };


  ReturnUnAuthorized = (url) => {return <Route exact path={url}><CollapsedSidebarLayout user={this.state.user} logout={this.logout} component={<Unauthorized/>}/></Route>};

  renderAdministrator = () => {
    const routes = [];
    const user = this.state.user;
    routes.push(<Route exact path="/MyProfile/:tab"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AdminProfile user={user} reload={this.isTokenValid}/>}/></Route>)
    if (this.state.user.UserType === 'super admin') {
      routes.push(<Route exact path="/AdminClients/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                                component={<SingleUsers/>}/></Route>);
      routes.push(<Route exact path="/SubscriptionClients/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                                       component={
                                                                                         <SingleClients/>}/></Route>);
      routes.push(<Route exact path="/Subscriptions"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                             component={<Subscription/>}/></Route>);
      routes.push(<Route exact path="/AllUsers"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                        component={<AllUsers/>}/></Route>);
      routes.push(<Route exact path="/Clients"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                       component={<AllClients/>}/></Route>);
      routes.push(<Route exact path="/"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                component={<AllImports/>}/></Route>);
      routes.push(<Route exact path="/Imports/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                           component={<ImportCompanies/>}/></Route>);
      // routes.push(<Route exact path="/ReportOrders"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AllReportOrders />} /></Route>);
      // routes.push(<Route exact path="/RejectList"><CollapsedSidebarLayout user={user} logout={this.logout} component={<RejectList />} /></Route>);
      routes.push(<Route exact path="/PortfolioLimit"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                              component={<PortfolioLimit/>}/></Route>);
      routes.push(<Route exact path="/Orders"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                      component={<Orders/>}/></Route>);
      routes.push(<Route exact path="/AllAdministrators"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                                 component={
                                                                                   < AllAdministrators/>}/></Route>);
    }
    else if (this.state.user.UserType === 'admin') {
      routes.push(<Route exact path="/AdminClients/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                                component={<SingleUsers/>}/></Route>);
      routes.push(<Route exact path="/SubscriptionClients/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                                       component={
                                                                                         <SingleClients/>}/></Route>);
      routes.push(<Route exact path="/AllUsers"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                        component={<AllUsers/>}/></Route>);
      routes.push(<Route exact path="/Clients"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                       component={<AllClients/>}/></Route>);
      routes.push(<Route exact path="/"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                component={<AllImports/>}/></Route>);
      routes.push(<Route exact path="/Imports/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                           component={<ImportCompanies/>}/></Route>);
      routes.push(<Route exact path="/ReportOrders"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                            component={<AllReportOrders/>}/></Route>);
      routes.push(<Route exact path="/RejectList"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                          component={<RejectList/>}/></Route>);
      routes.push(<Route exact path="/PortfolioLimit"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                              component={<PortfolioLimit/>}/></Route>);
      routes.push(<Route exact path="/Orders"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                      component={<Orders/>}/></Route>);
    }
    else if (this.state.user.UserType === 'import admin') {
      // routes.push(<Route exact path="/AdminClients/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<SingleUsers />} /></Route>);
      // routes.push(<Route exact path="/SubscriptionClients/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<SingleClients />} /></Route>);
      // routes.push(<Route exact path="/"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AllUsers />} /></Route>);
      // routes.push(<Route exact path="/Clients"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AllClients />} /></Route>);
      routes.push(<Route exact path="/"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                component={<AllImports/>}/></Route>);
      routes.push(<Route exact path="/Imports/:id"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                           component={<ImportCompanies/>}/></Route>);
      // routes.push(<Route exact path="/ReportOrders"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AllReportOrders />} /></Route>);
      // routes.push(<Route exact path="/RejectList"><CollapsedSidebarLayout user={user} logout={this.logout} component={<RejectList />} /></Route>);
      // routes.push(<Route exact path="/PortfolioLimit"><CollapsedSidebarLayout user={user} logout={this.logout} component={<PortfolioLimit />} /></Route>);
      routes.push(<Route exact path="/Orders"><CollapsedSidebarLayout user={user} logout={this.logout}
                                                                      component={<Orders/>}/></Route>);
    }

    routes.push(<Route exact path="/Cookies"><Cookies /></Route>)

    return (
      <Router>
        <Switch>
          {routes}
        </Switch>
      </Router>
    );
  };
  renderCheckOutPage = () => {
    const routes = [];
    const user = this.state.user;

    routes.push(<Route exact path="*"><CollapsedSidebarLayout user={user} logout={this.logout}component={<CheckOut user={user} reload={this.isTokenValid}/>}/></Route>);
    return (
      <Router>
        <Switch>
          {routes}
        </Switch>
      </Router>
    );
  };

  renderUser = () => {
    const routes = [];
    const user = this.state.user;
    routes.push(<Route exact path="/MyProfile/:tab"><CollapsedSidebarLayout user={user} logout={this.logout} component={<MyProfile user={user} reload={this.isTokenValid}/>}/></Route>)
    if (user.ManagePayments) {
      routes.push(<Route exact path="/transaction/:id/cart"><CollapsedSidebarLayout user={user} dontShowHeader={true} logout={this.logout} component={<CheckOut user={user} reload={this.isTokenValid}/>}/></Route>);
      routes.push(<Route exact path="/transaction/:id/subscriptions"><CollapsedSidebarLayout user={user} dontShowHeader={true} logout={this.logout} component={<CheckOut user={user} reload={this.isTokenValid}/>}/></Route>);
    } else {
      routes.push(this.ReturnUnAuthorized('/transaction/:id/cart'));
      routes.push(this.ReturnUnAuthorized('/transaction/:id/subscriptions'));
    }
    if (user.Basket) {
      routes.push(<Route exact path="/cart"><CollapsedSidebarLayout user={user} logout={this.logout} component={<CartOverview/>}/></Route>)
    }
    else {
      routes.push(this.ReturnUnAuthorized('/cart'))
    }
    if (user.ManageUsers) {
      //korisnici sidebar
      routes.push(<Route exact path="/"><CollapsedSidebarLayout user={user} logout={this.logout} component={<Users ManageSubscription={user.ManageSubscription}/>}/></Route>)
      routes.push(<Route exact path="/user/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<SingleUser/>}/></Route>)
    } else {
      routes.push(this.ReturnUnAuthorized('/'));
      routes.push(this.ReturnUnAuthorized('/user/:id'));
    }
    if(user.CompanyView){
      routes.push(<Route exact path="/companies"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AllCompanies user={user}/>}/></Route>);
    }else {
      routes.push(this.ReturnUnAuthorized('/companies'));
    }
    if (user.ManageCompanies) {
      // kompanija sidebar
      routes.push(<Route exact path="/add/companies"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AllCompanies user={user}/>}/></Route>);
      routes.push(<Route exact path="/company/:id/dashboard"><CollapsedSidebarLayout user={user} logout={this.logout} component={<CompanyDashboard user={user}/>}/></Route>);
    } else {
      routes.push(this.ReturnUnAuthorized('/add/companies'));
      routes.push(this.ReturnUnAuthorized('/company/:id/dashboard'));
    }
    if (user.ManagePermissions) {
      routes.push(<Route exact path="/user/permissions/:id"><CollapsedSidebarLayout user={user} logout={this.logout}component={<Permissions/>}/></Route>);
    } else {
      routes.push(this.ReturnUnAuthorized('/user/permissions/:id'));
    }
    if (user.ManageDivisions) {
      // dodadi divizii sidebar
      routes.push(<Route exact path="/edit/division/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<EditDivision ManageSubscription={user.ManageSubscription}/>}/></Route>);
    } else {
      routes.push(this.ReturnUnAuthorized('/edit/division/:id'));
    }
    if (user.AddCompany) {
      // routes.push(<Route exact path="/add/report/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<AddReport/>}/></Route>);
      routes.push(<Route exact path="/add/report/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<Reports/>}/></Route>);
      routes.push(<Route exact path="/edit/report/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<SingleReport/>}/></Route>);
      routes.push(<Route exact path="/view/report/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<ViewReport/>}/></Route>);
    } else {
      routes.push(this.ReturnUnAuthorized('/add/report/:id'));
      routes.push(this.ReturnUnAuthorized('/edit/report/:id'));
      routes.push(this.ReturnUnAuthorized('/view/report/:id'));
    }
    if (user.Division) {
      // divizii
      routes.push(<Route exact path="/AllDivisions"><CollapsedSidebarLayout user={user} logout={this.logout}component={<AllDivisions ManageSubscription={user.ManageSubscription} ManageDivisions={user.ManageDivisions}/>}/></Route>);
      routes.push(<Route exact path="/divisions/:id"><CollapsedSidebarLayout user={user} logout={this.logout} component={<DivisionDashboard/>}/></Route>);
    } else {
      routes.push(this.ReturnUnAuthorized('/divisions/:id'));
      routes.push(this.ReturnUnAuthorized('/AllDivisions'));

    }
    routes.push(<Route exact path="/Login"/>);
    routes.push(<Route exact path="/Cookies"><Cookies /></Route>)
    routes.push(<Route exact path="*"><Status404/></Route>);

    return (
      <Router>
        <Switch>
          {routes}
        </Switch>
      </Router>
    );
  };
  renderAuthenticated = () => {
    if (this.state.user.Administrator) {
      return this.renderAdministrator();
    } else if (this.state.user.pendingTransactionID) {
      return this.renderCheckOutPage();
    }
    else {
      return this.renderUser();
    }

  };

  renderNotAuth = () => {
    return (
      <Router>
        <Switch>
          <Route exact path="/Login">
            {/*<PlanCard />*/}
            <Login login={this.login}/>
          </Route>
          <Route exact path="/Cookies">
            <Cookies />
          </Route>
          <Route exact path="/">
            <HomePage/>
          </Route>
          <Route exact path="/AboutUs">
            <AboutUs/>
          </Route>
          <Route exact path="/Contact">
            <Contact/>
          </Route>
          <Route exact path="/Offer">
            <Offer/>
          </Route>
          <Route exact path="/register">
            <Register/>
          </Route>
          <Route exact path="/ResetPassword">
            <ConfirmResetPassword/>
          </Route>
          <Route exact path="/ChangePassword/:id">
            <ResetPasswordForm/>
          </Route>
          <Route exact path="/activation/:id">
            <ActivationPage/>
          </Route>
          {/*<Route exact path="*">*/}
          {/*<Status404 />*/}
          {/*</Route>*/}
        </Switch>
      </Router>

    );
  };


  renderApp = () => {
    if (this.state.authenticated) {
      return this.renderAuthenticated();
    } else {
      return this.renderNotAuth();
    }
  };


  render() {
    return (
      <Fragment>
        <AutoLogout logged={this.state.authenticated} logout={this.logout} />
        <ThemeProvider>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <CssBaseline/>
            {this.renderApp()}
          </SnackbarProvider>
        </ThemeProvider>
        <SuspenseLoader value={null} valueBuffer={null} visible={this.state.loadingReq} full={true}/>

        {
          this.state.acceptedCookies === false?
          <CookieConsent
            debug={true}
            buttonText={"Прифати"}
            location={"bottom"}
            style={{background: "#4454CC"}}
            buttonStyle={{background: "#FFFFFF", color: "#4454CC", fontSize: "14px"}}
            onAccept={({acceptedByScrolling}) => {
              if (!acceptedByScrolling) {
                localStorage.setItem("CookiesBI", "accepted");
                this.setState({acceptedCookies: true})
              }
            }}
          >Оваа веб страна користи колачиња. <a href="/Cookies" style={{color: "#FFFFFF"}}>Услови за користење
            колачиња(cookies)</a>


          </CookieConsent> : ''
        }

      </Fragment>
    );
  }

}

export default withRouter(App);



