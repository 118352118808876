import React from 'react';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import SuspenseLoader from 'src/components/SuspenseLoader';

import NotificationDialog from './Dialogs/NotificationDialog';
import Results from './Results';
import request from "superagent";
import CheckBeforeImportDialog from "./Import/CheckBeforeImportDialog";



class AllCompanies extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      companies: [],
      listCompanies: [],
      checkBeforeImportDialog: false,
      arrayList: [],
      deniedList: [],
      invalidList: [],
      duplicateList: [],
      notification: '',
      companyID: '',
      PIN: '',
      loading: false,
      limitMessage: false,


    }
  }

  componentDidMount(){
    this.renderCompanies();

  }




  setLimitMessageFalse = () => {
    this.setState({limitMessage: false})
  };

  returnEstablishmentYear = (VAT) => {
    let arr = VAT.split('');
    const Establishment_year = [];
    if (VAT.indexOf('MK') > -1) {
      if (arr[6] === '0') {
        let Year = '2' + arr[6] + arr[7] + arr[8];
        Establishment_year.push(Year);
      }
      else {
        let Year = '1' + arr[6] + arr[7] + arr[8];
        Establishment_year.push(Year);

      }
    }
    else {
      if (arr[4] === '0') {
        let Year = '2' + arr[4] + arr[5] + arr[6];
        Establishment_year.push(Year);

      }
      else {
        let Year = '1' + arr[4] + arr[5] + arr[6];
        Establishment_year.push(Year);
      }

    }
    return Establishment_year[0];
  }


  renderCompanies = () => {
    window.makeRequest("GET", `/api/Company`, false, true, (callback) => {
      this.setState({companies: callback.body, limitMessage:false})
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };

  deleteCompany = (id) => {
    let items = this.state.companies.filter((company) => company.ID !== id);
    this.setState({ companies: items});

  };



  checkEmbsDetails = (a,b,c)=> {
    this.setState({loading: true})
    let count = 0;
    const removedDuplicates =  a;
    const duplicates = b;
    const invalid = c;
    let arrayList = [];
    let deniedList = [];

    removedDuplicates && removedDuplicates.length > 0 ? removedDuplicates.forEach((list, index) => {
        const PIN = list;
          request
            .get(`/api/Subjekt/GetSubjektiWithGradAndDejnost?embsEdb=${PIN}`)
            .withCredentials()
            .auth('tgmisajlovska', '123456789')
            .then((callback) => {
              if (callback.body && callback.body !== null) {
                  let Pregled = callback.body;
                  let VAT = Pregled.Edb ? Pregled.Edb : '';
                  let PIN = Pregled.Embs ? Pregled.Embs : '';
                  let Company_name = Pregled.CelosenNaziv ? Pregled.CelosenNaziv : '';
                  let Size = Pregled.GoleminaNaSubjekt ? Pregled.GoleminaNaSubjekt.trim() : '';
                  let Adress = Pregled.Sedishte ? Pregled.Sedishte : '';
                  let Occupation = Pregled.OddelSifra ? parseInt(Pregled.OddelSifra.trim()) : '';
                  let Establishment_year = Pregled.DatumOsnovanje ? new Date(Pregled.DatumOsnovanje).getFullYear() : this.returnEstablishmentYear(VAT);
                  let City = Pregled.Grad ? Pregled.Grad : '';
                count++;
                arrayList.push({VAT, PIN, Company_name, Establishment_year, Size, Occupation, Adress, City});
                if(removedDuplicates.length === count) {
                  this.setState({
                    loading: false,
                    arrayList: arrayList,
                    deniedList: deniedList,
                    invalidList: invalid,
                    duplicateList: duplicates,
                    checkBeforeImportDialog: true,
                  });
                }
              } else {
                count++;
                deniedList.push({ PIN});
                if(removedDuplicates.length === count) {
                  this.setState({
                    loading: false,
                    arrayList: arrayList,
                    deniedList: deniedList,
                    invalidList: invalid,
                    duplicateList: duplicates,
                    checkBeforeImportDialog: true });
                }
              }



            })
            .catch((e) => {
              if(e){
                deniedList.push({ PIN});
                count++;
                if(removedDuplicates.length === count) {
                  this.setState({
                    loading: false,
                    arrayList: arrayList,
                    deniedList: deniedList,
                    invalidList: invalid,
                    duplicateList: duplicates,
                    checkBeforeImportDialog: true
                  });
                }
              }
            });
      }) : this.setState({
            loading: false,
            arrayList: arrayList,
            deniedList: deniedList,
            invalidList: invalid,
            duplicateList: duplicates,
            checkBeforeImportDialog: true
    })

  }

  validateImportCompanies = () => {
    let invalidList = [];
    let correctList = [];

    this.state.listCompanies.filter((list) => {
        const PIN = list;
        if(PIN.length === 7 && !parseInt(PIN)){
          invalidList.push(PIN);
        }else if(PIN.length === 13 && !parseInt(PIN)){
          invalidList.push(PIN);
        }else if(PIN.length === 15 && (!PIN.startsWith('MK') || !parseInt(PIN.replace('MK', '')))){
          invalidList.push(PIN);
        }
        else if(PIN.length !== 7 && PIN.length !== 13 && PIN.length !== 15){
          invalidList.push(PIN);
        }
        else {
          correctList.push(PIN)
        }
    })


    window.makeRequest("POST", `/api/Import/CheckEmbs`, {
      Embss: correctList
    }, true, (callback) => {
      let RemovedDuplicates = [];
      let listDuplicates = [];
        if(callback.body && callback.body.duplicates.length > 0) {
          correctList.filter((val, index) => {
            if(!callback.body.duplicates.includes(val)){
              RemovedDuplicates.push(val);
            } else {
              listDuplicates.push(val + ' ' + callback.body.names[index]);
            }
          }
          );
        } else {
          RemovedDuplicates = correctList;
        }
        this.checkEmbsDetails(RemovedDuplicates,listDuplicates, invalidList)

    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });



  }

  separateTextCompanies = () => {
    if(this.state.importCompanies) {
      const companies = this.state.importCompanies;
      let separator = ' ';
      if(companies.indexOf('\n') > 0) {
        separator = ('\n');
      }
      if(companies.indexOf(',') > 0) {
        separator = ','
      }
      if(companies.indexOf(',\n') > 0){
        separator = ',\n'
      }

      let removedList = companies.split(separator);

      if(removedList[removedList.length - 1].length !== 7 && removedList[removedList.length - 1].length !== 13 && removedList[removedList.length - 1].length !== 15){
        removedList[removedList.length - 1] = removedList[removedList.length - 1].substring(0, removedList[removedList.length - 1].length - 1);
      }

      this.setState({ listCompanies: removedList}, this.validateImportCompanies)
    }

  }
  importCompanies = (e,v) => {
    this.setState({loading: true, importCompanies: e}, this.separateTextCompanies)
  }
  createCompanies = () => {
    let companies = this.state.arrayList;
    window.makeRequest("POST", `/api/Import`,{
      companies
    }, true, (callback) => {
      if(callback.body && callback.body.hasOwnProperty('Response') && callback.body.Response.ReasonPhrase === 'Length Required'){
        this.setState({checkBeforeImportDialog: false,limitMessage: true,})
      } else {
        this.setState({checkBeforeImportDialog: false},() => this.renderCompanies())
      }
    }, (err) => {
      if(err) {
        this.setState({checkBeforeImportDialog: false},() => this.renderCompanies())
        console.log(err);
      }
    });
  }
  closeDialog = () => {
    this.setState({checkBeforeImportDialog: false},() => this.renderCompanies())

  }

  removeFromImportCompanies = (id) => {

    this.setState({checkBeforeImportDialog: false})
    const removeArr = [...this.state.arrayList];
    let removed = removeArr.filter((row) => row.PIN !== id);
    this.setState({ arrayList: removed,checkBeforeImportDialog: true})

  }





  render() {
      return (
        <>
          <PageTitleWrapper>
            <PageHeader limitMessage={this.state.limitMessage} importCompanies={this.importCompanies} renderCompanies={this.renderCompanies} setLimitMessageFalse={this.setLimitMessageFalse} companies={this.state.companies}/>
          </PageTitleWrapper>
          <SuspenseLoader visible={this.state.loading} full={true}/>
          <Grid
            sx={{px: 4}}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Results user={this.props.user} companies={this.state.companies} delete={this.deleteCompany} renderCompanies={this.renderCompanies}/>
            </Grid>
          </Grid>
          <CheckBeforeImportDialog
            arrayList={this.state.arrayList}
            deniedList={this.state.deniedList}
            invalidList={this.state.invalidList}
            duplicateList={this.state.duplicateList}
            removeFromImportCompanies={this.removeFromImportCompanies} onClose={this.closeDialog} createCompanies={this.createCompanies}  open={this.state.checkBeforeImportDialog} />




          <Footer/>
        </>
      )
  }

}

export default AllCompanies;
