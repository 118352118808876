import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import {
  Grid,
  Typography,
  Button
} from '@mui/material';

function PageHeader() {
  const { t } = useTranslation();


  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Менаџирање на пермисии')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Сите пермисии може да се менаџираат од оваа страна'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: { xs: 2, sm: 0 } }}
            component={RouterLink}
            to={"/"}
            variant="outlined"
          >
            {t('<- Назад до сите корисници')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
