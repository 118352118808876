import React, { Component } from 'react';
import {withRouter} from 'react-router';
import GaugeChart from 'react-gauge-chart';

import {
  Box,
  Card,
  Typography,
  Tooltip,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import {Link as RouterLink} from "react-router-dom";

class ViewOcenaForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: true,
      id: '',
    };
  }


  componentDidMount() {
    this.setState({id: this.props.match.params.id})

  }


  render(){
    return (

      <>


      <Box sx={{width: '100%'}}>
      <Card sx={{ py: 2, display: 'flex', alignItems: 'center', height: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <GaugeChart
            style={{ width: '50%', margin: '0 auto' }}
            nrOfLevels={16}
            hideText
            colors={[
              this.props.theme.colors.error.main,
              this.props.theme.colors.warning.main,
              this.props.theme.colors.success.main
            ]}
            needleColor={this.props.theme.colors.alpha.black[100]}
            needleBaseColor={this.props.theme.colors.alpha.black[100]}
            arcWidth={0.25}
            arcPadding={0.02}
            cornerRadius={3}
            percent={this.props.data2.Bodovi && this.props.data2.Bodovi !== null ? parseFloat(this.props.data2.Bodovi / 100) : 0}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          {/*<Typography variant={'h3'}>*/}
          {/*{"Оцена: " + this.props.data2.Ocena}*/}
        {/*</Typography>*/}
          <Typography variant={'h3'}>
            <Tooltip
              placement="top"
              arrow
              title={"Кредитниот скор ги прикажува бодови кои и се доделени на компанијата врз основа на остварените резултати и успешноста на нејзиното финансиско работење. Помалиот број на бодови означува дека компанијата е поризична за соработка и обратно."}
            >
            <InfoIcon style={{fontSize: 15}} color={"primary"}/>
            </Tooltip>
            {" Кредитен скор: "}
            {this.props.data2.Bodovi && this.props.data2.Bodovi !== null ? this.props.data2.Bodovi.toFixed(0) : 'Нема резултат'}
          </Typography>
          <Typography variant={'h3'}>
            <Tooltip
              placement="top"
              arrow
              title={"Кредитната маржа го претставува процент од сопствените средства во кој износ компанијата може да го зголеми своето задолжување и при тоа да го задржи своето постоечко работење и функционирање на пазарот."}
            >
              <InfoIcon style={{fontSize: 15}} color={"primary"}/>
            </Tooltip>
             {" Кредитна маржа: "}
            {this.props.data2.Marza && this.props.data2.Marza !== null ? this.props.data2.Marza.toFixed(2) + "%" : 'Нема резултат'}
          </Typography>
          <Typography variant={'h3'}>
            <Box>
            <Tooltip
              placement="top"
              arrow
              title={"Кредитниот лимит претставува препорачан износ на вкупно годишно дополнително задолжување на компанијата без при тоа значително да се наруши нејзината постоечка финансиска состојба."}
            >
              <InfoIcon style={{fontSize: 15}} color={"primary"}/>
            </Tooltip>
             {" Кредитен лимит: "}
            {this.props.data2.Limit && this.props.data2.Limit !== null ? this.props.data2.Limit.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + "МКД" : 'Нема резултат'}
            </Box>
          </Typography>
        </Box>
      </Card>
      </Box>


      </>
    );
  }

}

export default withRouter(ViewOcenaForm);
