import React from 'react';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  CircularProgress,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


class PageHeader extends React.Component{



  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      open:false,
      id: "",
      username:  "",
      password:  "",
      UserType: "",
      // isAdmin:  "",
      // telephone:  "",
      email:  "",
      firstname:  "",
      lastname:  "",
      confirm_password: "",
      loading: true,
      isFormValid: false,
      isUsernameValid: true,
      isEmailValid: null,
      isUsernameValid: true,
      // isEmailValid: null,
      emailError : '',
      telephoneError : '',
      usernameError :'',
      firstnameError : '',
      passwordError : '',
      lastnameError : '',
      // countries: ['mk']
    }
  }

  handleCreateUserOpen = () => {
    this.setState({open:true});
  };

  handleCreateUserClose = () => {
    this.setState({open:false, username: '', password: '', firstname: '', lastname: '', email: '', UserType: '', usernameError: '', firstnameError: '', passwordError: '', lastnameError: '', emailError: '' });
  };

  validate = () => {
    let emailError = '';
    let telephoneError = '';
    let usernameError = '';
    let firstnameError = '';
    let passwordError = '';
    let lastnameError = '';

    let email = this.state.email;
    // let telephone = this.state.telephone;
    let username = this.state.username;
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;
    let password = this.state.password;
    let confirm_password = this.state.confirm_password;


    switch (true) {
      case username.length < 1:
        usernameError = 'Корисничкото име не може да биде празно поле';
        break;
      case username.length < 4:
        usernameError = 'Корисничкото име мора да има 4 или повеќе карактери';
        break;
      case this.state.isUsernameValid === false:
        usernameError = 'Корисничкото име веќе постои';
        break;
      // case usernameError === '' && this.state.checkUsername === true:
      //   usernameError = 'Веќе има регистран корисник со Корисничкото име';
      //   break;
      default:
    }
    switch (true) {

      case firstname.length < 1:
        firstnameError = 'Името не смее да биде празно';
        break;
      case firstnameError.length > 15:
        firstnameError = 'Името не смее да биде повеке од 15 карактери';
        break;
      default:

    }
    switch (true) {
      case email.length < 1:
        emailError = 'Е-пошта не смее да биде празно поле';
        break;
      case /\S+@\S+\.\S+/.test(email) !== true:
        emailError = 'Внесете валидна Е-маил адреса';
        break;
      case this.state.isEmailValid === false:
        emailError = 'Веќе е регистриран кориснички профил со таа Е-пошта';
        // case emailError === '' && this.state.checkEmail === true:
        //   emailError = 'Внесениот е-маил е веќе регистриран';
        break;
      default:


    }
    switch (true) {
      case password.length < 1:
        passwordError = 'Лозинката е задолжителна';
        break;
      case password.length < 7:
        passwordError = 'Лозинката мора да има 7 или повеќе карактери';
        break;
      case password !== confirm_password:
        passwordError = 'Лозинката не се совпаѓа';
        break;
      default:

    }
    // switch (true) {
    //   case telephone.length !== 11:
    //     telephoneError = 'Невалиден број';
    //     break;
    //   case telephone.startsWith('3897') === false && telephone.startsWith('3892') === false && telephone.startsWith('3893') === false && telephone.startsWith('3894') === false:
    //     telephoneError = 'Невалиден број';
    //     break;
    //   default:
    //
    //
    // }
    switch (true) {
      case lastname.length < 1:
        lastnameError = 'Презимето не смее да биде празно';
        break;
      case lastname.length > 30:
        lastnameError = 'Името не смее да биде повеке од 30 карактери';
        break;
      default:


    }
    if(usernameError || emailError || lastnameError|| passwordError || telephoneError || firstnameError) {
      this.setState({ usernameError, emailError, lastnameError, passwordError, telephoneError,firstnameError, isFormValid: false });
      return false;
    }

    return true;
  }

  handleLeaveUsername = () => {
    window.makeRequest("GET", `/api/Admin_AdminUsers/check?username=${this.state.username}`, false, true, (callback) => {
      if(callback.body === false) {
        this.setState({usernameError: 'Корисничкото име веке постои'});
      }

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  }

  handleEmailLeave = () => {
    window.makeRequest("GET", `/api/Admin_AdminUsers/check?email=${this.state.email}`, false, true, (callback) => {
      if(callback.body === false) {
        this.setState({emailError: 'Е-поштата веке постои'});
      }

    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  }


  handleCreateUserSuccess = () => {
    if(this.state.usernameError === '' && this.state.emailError === ''){
      if (this.validate()) {
        window.makeRequest("POST", `/api/Admin_AdminUsers`, {
          Username: this.state.username,
          Password: this.state.password,
          UserType: this.state.UserType,
          FirstName: this.state.firstname,
          LastName: this.state.lastname,
          Email: this.state.email
        }, true, (callback) => {
          this.props.renderUsers();
        }, (err) => {
          if (err) {
            console.log('err', err);

          }
        });
        this.setState({open: false});
      }
    }
  };

  handleChange  = (e) => {

    this.setState({
      [e.target.name]: e.target.value,
    });

    // if(e.target.name === 'username'){
    //
    //   window.makeRequest("GET", `/api/User/check?username=${e.target.value}`, false, true, (callback) => {
    //
    //     this.setState(
    //       {
    //         isUsernameValid: callback.body
    //       }
    //     )
    //
    //   }, (err) => {
    //     if(err) {
    //       console.log('err', err);
    //     }
    //   });
    //
    // }
    // else if(e.target.name === 'email'){
    //
    //   window.makeRequest("GET", `/api/User/check?email=${e.target.value}`, false, true, (callback) => {
    //
    //     this.setState(
    //       {
    //         isEmailValid: callback.body
    //       }
    //     )
    //
    //   }, (err) => {
    //     if(err) {
    //       console.log('err', err);
    //     }
    //   });
    //
    // }



  };


  handleChangeUserType = (e,val) => {
    this.setState({UserType: val.props.value});
  };

  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Менаџирање на администратори'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Сите функционалности поврзани со администраторите може да се менаџираат преку оваа страна'
              }
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Креирај администратор'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Креирајте нов администратор'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Пополнете ги полињата подолу за да креирате нов администратор'
              }
            </Typography>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Корисничко име'}
                      name="username"
                      onChange={this.handleChange}
                      onBlur={this.handleLeaveUsername}
                      value={this.state.username}
                      variant="outlined"
                    />
                    {
                      this.state.usernameError? <Alert severity="error" sx={{mt: 1}}>{this.state.usernameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField

                      fullWidth
                      label={'Име'}
                      name="firstname"
                      onChange={this.handleChange}
                      value={this.state.firstname}
                      variant="outlined"
                    />
                    {
                      this.state.firstnameError? <Alert severity="error" sx={{mt: 1}}>{this.state.firstnameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Презиме'}
                      name="lastname"
                      onChange={this.handleChange}
                      value={this.state.lastname}
                      variant="outlined"
                    />
                    {
                      this.state.lastnameError? <Alert severity="error" sx={{mt: 1}}>{this.state.lastnameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Е-пошта'}
                      name="email"
                      onChange={this.handleChange}
                      onBlur={this.handleEmailLeave}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                    />
                    {
                      this.state.emailError? <Alert severity="error" sx={{mt: 1}}>{this.state.emailError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Лозинка'}
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                      value={this.state.password}
                      variant="outlined"
                    />
                    {
                      this.state.passwordError? <Alert severity="error" sx={{mt: 1}}>{this.state.passwordError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Потврди лозинка'}
                      name="confirm_password"
                      onChange={this.handleChange}
                      type="password"
                      value={this.state.confirm_password}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Тип на администратор</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.UserType}
                        label="Тип на администратор"
                        onChange={this.handleChangeUserType}
                      >
                        <MenuItem value={'super admin'}>Супер администратор</MenuItem>
                        <MenuItem value={'admin'}>Администратор</MenuItem>
                        <MenuItem value={'import admin'}>Импорт администратор</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/*<Grid item xs={12}>*/}
                    {/*<PhoneInput*/}
                      {/*onlyCountries={this.state.countries}*/}
                      {/*country={'mk'}*/}
                      {/*placeholder={'+389 xxx xxx xx'}*/}
                      {/*value={this.state.telephone}*/}
                      {/*onChange={telephone => this.setState({telephone})}*/}
                    {/*/>*/}
                    {/*{*/}
                      {/*this.state.telephoneError? <Alert severity="error" sx={{mt: 1}}>{this.state.telephoneError}</Alert> : ""*/}
                    {/*}*/}
                  {/*</Grid>*/}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCreateUserClose}>
              {'Откажи'}
            </Button>
            <Button
              onClick={this.handleCreateUserSuccess}
              type="submit"
              startIcon={
                this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              // disabled={Boolean(errors.submit) || isSubmitting}
              variant="contained"
            >
              {'Креирај администратор'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

}

export default PageHeader;
