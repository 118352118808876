import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Tooltip,
  IconButton,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";
import Imports from "../../../../components_bi/Import";
import DateFormating from '../../../../components_bi/DateFormating'


const Results = ({ users, ...rest }) => {
  const handleAccept = (id) =>{
    window.makeRequest("PUT", `/api/Administrator/ChangeStatus?id=${id}`, {
      Status: 'Accepted'
    }, true, (callback) => {
      rest.renderImports();
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  };

  const handleReject = (id) =>{
    window.makeRequest("PUT", `/api/Administrator/ChangeStatus?id=${id}`, {
      Status: 'Rejected'
    }, true, (callback) => {
      rest.renderImports();
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  };

  const handleDownload = (id, fileName) => {
      fetch(`/api/Admin_Import?ID=${id}`,{
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer '+ window.localStorage.getItem('token'),
          // 'Content-Type': 'application/x-www-form-urlencoded'
        }),

      })
        .then(response => response.blob())
        .then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        });
  };

  const columns = [

    {
      field: 'FileName',
      headerName: 'Име на документ',
      // align: 'center',
      headerAlign: 'center',
      width: 300,
      renderCell: (params) => {
        return (
        <Tooltip title={params.row.FileName}>
          <Typography style={{textDecoration: 'none', color: 'black'}} component={RouterLink} to={`/imports/` + params.row.ID}>{params.row.FileName}</Typography>
        </Tooltip>
        )}
    },
    {
      field: 'Embs',
      headerName: 'ЕМБС',
      align: 'center',
      headerAlign: 'center',
      width: 170,
    },
    {
      field: 'ImportType',
      headerName: 'Тип на импорт',
      align: 'center',
      headerAlign: 'center',
      width: 170,
      renderCell: (params) => {
        return <Typography style={{textDecoration: 'none', color: 'black'}} component={RouterLink} to={`/imports/` + params.row.ID}>{params.row.ImportType}</Typography>
      }
    },
    {
      field: 'createdAt',
      headerName: 'Креиран',
      align: 'center',
      headerAlign: 'center',
      width: 170,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Typography noWrap align={'center'}>
          { DateFormating(params.row.createdAt)}
        </Typography>
      },
    },

    {
      field: 'createdBy',
      headerName: 'Креиран од',
      align: 'center',
      headerAlign: 'center',
      width: 170,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Изменет на',
      align: 'center',
      headerAlign: 'center',
      width: 170,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Typography noWrap align={'center'}>
          {
            DateFormating(params.row.updatedAt)
          }
        </Typography>
      },

    },
  {
    field: 'updatedBy',
    headerName: 'Изменето од',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: 'Status',
    headerName: 'Статус',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    disableColumnMenu: true,

  },
    {
      field: '*',
      headerName: 'Менаџирај',
      align: 'center',
      headerAlign: 'center',
      width: 170,
      type: 'text',
      renderCell: (params) => {
        return <>
          <Tooltip title={'Одбиј'} arrow>
            <IconButton
              onClick={() => handleReject(params.row.ID)}
              color="primary"
            >
              <ClearIcon fontSize="small"/>
            </IconButton>
          </Tooltip>

          <Tooltip title={'Преземи'} arrow>
            <IconButton
              onClick={() => handleDownload(params.row.ID, params.row.FileName)}
              color="primary"
            >
              <DownloadIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
          <Tooltip title={'Прикачи'} arrow>
            <IconButton
              // onClick={() => alert('Прикачи')}
              color="primary"
            >
              <Imports importID={params.row.ID} embs={params.row.Embs} clientID={params.row.ClientID} handleAccept={handleAccept} />
            </IconButton>
          </Tooltip>
        </>
      },
      disableColumnMenu: true,
    },
  ];
  //component={RouterLink} to={'/imports/' + user.ID}

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
          <BI_DataGrid columns={columns} data={users} search={true} />

    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  // delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  users: []
};

export default Results;
