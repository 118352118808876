import React from 'react';
import numeral from "numeral";

import {
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@mui/material';
import {styled} from "@mui/material/styles/index";
import CloseIcon from '@mui/icons-material/Close';
import Label from '../../../../components/Label'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`
);



class WishList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      list: [],
      checked: [],
      allSelected: false,
    }
  }

  componentDidMount(){

  }


  returnStatus = (status) => {
    const statusReturn = [];
    switch(status) {
      case 'available':
        statusReturn.push(<Label color={'success'}>Available</Label>);
        break;
      case 'approved':
        statusReturn.push(<Label color={'warning'}>Pending</Label>);
        break;
      case 'wish':
        statusReturn.push(<Label color={'warning'}>Wish</Label>);
        break;
      case 'denied':
        statusReturn.push(<Label color={'error'}>Denied</Label>);
        break;
      default:
        statusReturn.push(<Label color={'success'}>No Status</Label>)
    }
    return statusReturn;
  }


  render() {
    return(
      <>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box display={'flex'}>
            <Box display={'flex'} justifyContent={'center'}>
              <Tooltip arrow title="Извештаите кои се во категорија ,,Во чекање,, е потребно да бидат одобрени од администраторите." placement="top-end">
                <Button startIcon={<HourglassTopIcon color='secondary' size={'small'} />}>Во чекање</Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{'ЕМБС'}</TableCell>
                  <TableCell>{'Компанија'}</TableCell>
                  <TableCell>{'Вид на извештај'}</TableCell>
                  <TableCell>{'Година'}</TableCell>
                  <TableCell>{'Цена'}</TableCell>
                  <TableCell>{'Статус'}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.wishlist && this.props.wishlist.map((item) => {
                  return<TableRow key={item.ID} >
                    <TableCell>
                      <Typography noWrap>{item.PIN}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{item.Company_name}</Typography>
                    </TableCell>
                    <TableCell>Годишна сметка</TableCell>
                    <TableCell>
                      {item.Year}
                    </TableCell>
                    <TableCell>
                      {numeral(this.props.ReportPrice).format(`0,0.00`) + " МКД"}
                    </TableCell>
                    <TableCell>
                      {this.returnStatus(item.Status)}
                    </TableCell>
                    <TableCell>
                      <Typography >
                        <Tooltip title={'Избриши'} arrow>
                          <IconButton
                            onClick={() => this.props.removeFromList(item.ID)}
                            color="primary"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
              {/*<TableFooter>*/}
                {/*<TableRow>*/}
                  {/*<TableCell colSpan={7} align="right">*/}
                    {/*<Typography*/}
                      {/*gutterBottom*/}
                      {/*variant="caption"*/}
                      {/*color="text.secondary"*/}
                      {/*fontWeight="bold"*/}
                    {/*>*/}
                      {/*{'Вкупно'}:*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="h3" fontWeight="bold">*/}
                      {/*{numeral(65).format(`0,0.00`)+'€'}*/}
                    {/*</Typography>*/}
                  {/*</TableCell>*/}
                {/*</TableRow>*/}
              {/*</TableFooter>*/}
            </Table>
          </TableContainer>
        </TableWrapper>
    </>)}
}

export default WishList;
