import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


class Dialog_Import_Insert_type extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      report: ''
    }
  }

  componentDidMount(){

  }



  render(){
    return <Dialog
      fullWidth
      maxWidth="sm"
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <Box height='400px'>
      <DialogTitle sx={{ p: 3, m: 5 }}>
        <Typography variant="h4" gutterBottom align='center' style={{color:'blue'}}>
          {'Внесување'}
        </Typography>
        <Typography variant="h4" align='center' style={{color:'blue'}}>
          {
            'Изберете начин на внесување на документот.'
          }
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>

          <Grid item xs={12} sx={{mt:5, mb:2}}>
            <Box display={'flex'} justifyContent={'center'}>
            <Button
              type="submit"
              variant="contained"
              onClick={this.props.StandardExcelDocument}
              style={{width: '170px', height: '45px'}}
            >
              {'Ексел формат'}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={this.props.RestDocument}
              style={{marginLeft: '10px', width: '170px', height: '45px'}}
            >
              {'Друг формат'}
            </Button>
            </Box>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions sx={{mt: 2, p: 2, display: 'flex', justifyContent: 'left' }}>

        <Button color="primary" onClick={this.props.onClose}>
          <ArrowBackIcon />
        </Button>

      </DialogActions>
      </Box>
    </Dialog>
  }
}
export default Dialog_Import_Insert_type;
