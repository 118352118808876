import { Box, CardHeader,  Grid} from '@mui/material';
import Label from '../../../../../components/Label'


function WarningCard(props) {
  let Text = [];

  let prva = props.data && props.data.prva && props.data.prva;
  let vtora = props.data && props.data.vtora && props.data.vtora;
  let treta = props.data && props.data.treta && props.data.treta;
  if(prva){
    Text.push(<Label style={{fontSize: 12, maxHeight: 34,marginBottom: 4}} color={'primary'}>Компанијата има незадоволителен работен капитал</Label>)
  }
  if(vtora){
    Text.push(<Label style={{fontSize: 12, maxHeight: 34,marginBottom: 4}} color={'primary'}>Компанијата има ниска ефикасност во користење на вкупните средства</Label>)
  }
  if(treta){
    Text.push(<Label style={{fontSize: 12, maxHeight: 34}} color={'primary'}>Компанијата има низок обрт на средства</Label>)
  }

  const ReturnText = () => {
    if(props.noData){
      return <Label style={{fontSize: 12, maxHeight: 120, }} color={'primary'}>За компанијата не се внесени финансиски податоци.</Label>
    }
    else if(Object.entries(Text).length === 0){
      return <Label style={{fontSize: 12, maxHeight: 120, }} color={'primary'}>За компанијата во   (последната внесена година)    не се евидентирани незадоволителни финансиски резултати од аспект на работен капитал како и од аспект на ефикасност и обрт во користење на вкупните средства.</Label>
    } else {
      return <>
        {Text}
      </>
    }

  }

  return (
    <>
    <Grid
      sx={{p: 0}}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} p={2} >
        <Box display={'flex'}>
          <CardHeader
            sx={{p: 0,}}
            title={'Предупредување'}
            subheaderTypographyProps={{variant: 'body1'}}
            titleTypographyProps={{variant: 'h3', gutterBottom: true}}
          >
          </CardHeader>
        </Box>
      </Grid>
      <Grid  container
             direction="column"
             justifyContent="space-evenly"
             alignItems="start"
             mt={1}
             p={2}
      >
        {ReturnText()}
      </Grid>
    </Grid>
    </>
  );
}

export default WarningCard;
