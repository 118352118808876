import { Box, List, lighten, alpha, darken } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from './items';
import { styled } from '@mui/material/styles';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(1)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 0;
        justify-content: center;

        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 54px;
          height: 54px;
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 8px;
            top: 12px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }

          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiSvgIcon-root {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path
}) => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}) => {
  const key = item.name;

  const exactMatch = item.link ? !!matchPath({
    path: item.link,
    end: true
  }, path) : false;

  if (item.items) {
    const partialMatch = item.link ? !!matchPath({
      path: item.link,
      end: false
    }, path) : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
}

function SidebarMenu(props) {
  if(props.user && props.user.Administrator){
      menuItems[0].items.map(item => {if (item.name === 'Корисници') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Корисници"), 1)}});
      menuItems[0].items.map(item => {if (item.name === 'Портфолија') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Портфолија"), 1)}});
      menuItems[0].items.map(item => {if (item.name === 'Компании') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Компании"), 1) }});

      if(props.user.UserType === 'admin' && props.user.UserType !== 'super admin'){
        menuItems[0].items.map(item => {if (item.name === 'Претплата') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Претплата"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Сите администратори') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите администратори"), 1)}});
      }

     if(props.user.UserType === 'import admin' && props.user.UserType !== 'super admin'){
        menuItems[0].items.map(item => {if (item.name === 'Претплата') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Претплата"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Сите администратори') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите администратори"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Нарачки') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Нарачки"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Одбиени нарачки') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Одбиени нарачки"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Менаџирање на портфолио лимит') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Менаџирање на портфолио лимит"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Сите корисници') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите корисници"), 1)}});
        menuItems[0].items.map(item => {if (item.name === 'Сите клиенти') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите клиенти"), 1)}});
      }


  }
  else if(props.user && props.user.Administrator === false) {
    menuItems[0].items.map(item => {if (item.name === 'Сите корисници') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите корисници"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Сите клиенти') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите клиенти"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Сите импорти') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите импорти"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Претплата') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Претплата"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Нарачки') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Нарачки"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Одбиени нарачки') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Одбиени нарачки"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Менаџирање на портфолио лимит') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Менаџирање на портфолио лимит"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Нарачки') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Нарачки"), 1)}});
    menuItems[0].items.map(item => {if (item.name === 'Сите администратори') {menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Сите администратори"), 1)}});




    if (props.user && props.user.ManageUsers === false) {
      menuItems[0].items.map(item => {
        if (item.name === 'Корисници') {
          menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Корисници"), 1);
        }
      })
    }
    if (props.user && props.user.ManageDivisions === false) {
      menuItems[0].items.map(item => {
        if (item.name === 'Портфолија') {
          menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Портфолија"), 1);
        }
      })
    }
    if (props.user && props.user.ManageCompanies === false) {
      menuItems[0].items.map(item => {
        if (item.name === 'Компании') {
          menuItems[0].items.splice(menuItems[0].items.findIndex(v => v.name === "Компании"), 1);
        }
      })
    }


  }
  const location = useLocation();

  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper key={section.heading}>
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
