import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(40)};
    color: #4454CC;
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const ImgWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 5;
    width: 100%;
    overflow: hidden;
    border-radius: ${theme.general.borderRadiusLg};
    box-shadow: 0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%);

    img {
      display: block;
      width: 100%;
    }
  `
);

const BoxAccent = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadiusLg};
    background: ${theme.palette.background.default};
    width: 100%;
    height: 100%;
    position: absolute;
    left: -40px;
    bottom: -40px;
    display: block;
    z-index: 4;
  `
);

const BoxContent = styled(Box)(
  () => `
  width: 150%;
  position: relative;
`
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.success.main};
    color: ${theme.palette.success.contrastText};
    font-weight: bold;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    font-size: ${theme.typography.pxToRem(11)};
    padding: ${theme.spacing(.5)} ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(2)};
`
);

const ListItemWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
`
);

const MuiAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #e5f7ff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

const JsAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #fef8d8;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

function Hero() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Grid spacing={{ xs: 6, md: 10 }} justifyContent="center" alignItems="center" container>
        <Grid item md={12} pr={{ xs: 0, md: 3 }}>


          <TypographyH1
            sx={{ lineHeight: 1.5, pb: 1 }}
            variant="h2"
            color="text.secondary"
            fontWeight="normal"
          >
            {t(
              'Контакт: '
            )}
          </TypographyH1>


          <Typography
            sx={{ lineHeight: 1.5, pb: 1 }}
            variant="h2"
            color="text.secondary"
            fontWeight="normal"
          >
            {t(
              'Сопственик на платформата Bonitet.mk е компанијата ДАТАБАНК ДООЕЛ Скопје'
            )}
          </Typography>


          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 1 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            <p><b>Седиште:  </b> <br/> Ул. Борка Талески бр. 12Б Центар - Скопје, Р. Македонија.</p>
          </TypographyH2>


          {/*<TypographyH2*/}
            {/*sx={{ lineHeight: 1.5, pb: 1 }}*/}
            {/*variant="h4"*/}
            {/*color="text.secondary"*/}
            {/*fontWeight="normal"*/}
          {/*>*/}
            {/*<p><b>Телефон: </b> <br/> 02/3117-100</p>*/}
          {/*</TypographyH2>*/}



          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 1, mb: '300px' }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >

              <p><b>Е-пошта: </b> <br/> info@bonitet.mk</p>


          </TypographyH2>


        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
