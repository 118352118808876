import React from 'react';

import {
  TextField,
  IconButton,
  Divider,
  InputAdornment,
  Box
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function AdditionalCustomInput (props) {




  return <>
    <TextField
      defaultValue={0}
      onChange={props.onChange}
      name={props.name}
      value={props.value}
      InputProps={{
        endAdornment:
          <InputAdornment position="end" >
            <IconButton
              onClick={props.ButtonDecrement}
              fullWidth
            >
              {<RemoveIcon    fontSize={'small'} />}
            </IconButton>
            <Box ml={-0.5} mr={-0.5}>
              <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
            </Box>
            <IconButton
              onClick={props.ButtonIncrement}
              fullWidth
            >
              {<AddIcon fontSize={'small'} />}
            </IconButton>
          </InputAdornment>
      }}

      label={props.label}
    />
  </>


}

export default AdditionalCustomInput;
