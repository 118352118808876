import React from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
  styled
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { Link as RouterLink } from 'react-router-dom';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonSuccess = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.success.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);
const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);




class Notifications extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    }
  }



  componentDidMount() {

  }


  ReturnMessage = () => {

    let Avatar = '';
    if(this.props.success) {
      Avatar = <AvatarSuccess><AddIcon /></AvatarSuccess>
    }
    if(this.props.error) {
      Avatar = <AvatarError><CloseIcon /></AvatarError>
    }

    return<><Typography
        align="center"
        sx={{
          pt: 1,
          px: 6,
          pb: 2,
        }}
        variant="h3"
      >
        {this.props.text}

      </Typography>
      <Box mt={2} mb={2}>
        {Avatar}
      </Box>
      <Box display='flex' mt={4}>
        <Box item >
          <Button
            onClick={this.props.onCancel}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="outlined"
          >
            {'Откажи'}
          </Button>
        </Box>
          <Box item >
            <ButtonSuccess
              onClick={this.props.onClose}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {'Во ред'}
            </ButtonSuccess>
          </Box>
        </Box>
      </>
  }

  render(){
    return<DialogWrapper
      open={this.props.open}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={this.props.onClose}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        {this.ReturnMessage()}
      </Box>
    </DialogWrapper>
  }
}

export default Notifications;
