const aop_titles = {
  'AOP1': 'АКТИВА:                     А.НЕТЕКОВНИ СРЕДСТВА (002+009+020+021+031)',
  'AOP2': 'I.НЕМАТЕРИЈАЛНИ СРЕДСТВА (003+004+005+006+007+008)',
  'AOP3': 'Издатоци за развој',
  'AOP4': 'Kонцесии, патенти, лиценци, заштитни знаци и слични права',
  'AOP5': 'Гудвил',
  'AOP6': 'Аванси за набавка на  нематеријални средства',
  'AOP7': 'Нематеријални средства во подготовка',
  'AOP8': 'Останати нематеријални средства',
  'AOP9': 'II. МАТЕРИЈАЛНИ СРЕДСТВА (010+013+014+015+016+017+018+019)',
  'AOP10': 'Недвижности (011+012)',
  'AOP11': 'Земјиште',
  'AOP12': 'Градежни објекти',
  'AOP13': 'Постројки и опрема',
  'AOP14': 'Транспортни средства',
  'AOP15': 'Алат, погонски и канцелариски инвентар и мебел',
  'AOP16': 'Биолошки средства',
  'AOP17': 'Аванси за набавка на материјални средства',
  'AOP18': 'Материјални средства во подготовка',
  'AOP19': 'Останати материјални средства',
  'AOP20': 'III. ВЛОЖУВАЊА ВО НЕДВИЖНОСТИ',
  'AOP21': 'IV. ДОЛГОРОЧНИ ФИНАНСИСКИ СРЕДСТВА (022+023+024+025+026+030)',
  'AOP22': 'Вложувања во подружници',
  'AOP23': 'Вложувања во придружени друштва и учества во заеднички вложувања',
  'AOP24': 'Побарувања по дадени долгорочни заеми на поврзани друштва',
  'AOP25': 'Побарувања по дадени долгорочни заеми',
  'AOP26': 'Вложувања во долгорочни хартии од вредност (027+028+029)',
  'AOP27': 'Вложувања во хартии од вредност кои се чуваат до доспевање',
  'AOP28': 'Вложувања во хартии од вредност расположливи за продажба',
  'AOP29': 'Вложувања во хартии од вредност според објективната вредност преку добивката или загубата',
  'AOP30': 'Останати долгорочни финансиски средства',
  'AOP31': 'V. ДОЛГОРОЧНИ ПОБАРУВАЊА (032+033+034)',
  'AOP32': 'Побарувања од поврзани друштва',
  'AOP33': 'Побарувања од купувачи',
  'AOP34': 'Останати долгорочни побарувања',
  'AOP35': 'VI. ОДЛОЖЕНИ ДАНОЧНИ СРЕДСТАВА',
  'AOP36': 'Б. ТЕКОВНИ СРЕДСТВА (037+045+052+059)',
  'AOP37': 'I. ЗАЛИХИ   (038+039+040+041+042+043)',
  'AOP38': 'Залихи на суровини и материјали',
  'AOP39': 'Залихи на резервни делови, ситен инвентар, амбалажа и автогуми',
  'AOP40': 'Залихи на недовршени производи и полупроизводи',
  'AOP41': 'Залихи на  готови производи',
  'AOP42': 'Залихи на трговски стоки',
  'AOP43': 'Залихи на биолошки средства',
  'AOP44': 'II.СРЕДСТВА (ИЛИ ГРУПИ ЗА ОТУЃУВАЊЕ НАМЕНЕТИ ЗА ПРОДАЖБА И ПРЕКИНАТИ РАБОТЕЊА)',
  'AOP45': 'III. КРАТКОРОЧНИ ПОБАРУВАЊА (046+047+048+049+050+051)',
  'AOP46': 'Побарувања од поврзани друштва',
  'AOP47': 'Побарувања од купувачи',
  'AOP48': 'Побарувања за дадени аванси на добавувачи',
  'AOP49': 'Побарувања од државата по основ на даноци, придонеси, царина, акцизи и за останати давачки кон државата (претплати)',
  'AOP50': 'Побарувања од вработените',
  'AOP51': 'Останати краткорочни побарувања',
  'AOP52': 'IV. КРАТКОРОЧНИ ФИНАНСИСКИ СРЕДСТВА (053+056+057+058)',
  'AOP53': 'Вложувања во хартии од вредност (054+055)',
  'AOP54': 'Вложувања кои се чуваат до доспевање',
  'AOP55': 'Вложувања според објективната вредност преку добивката и загубата',
  'AOP56': ' Побарувања по дадени заеми од поврзани друштва',
  'AOP57': ' Побарувања по дадени заеми',
  'AOP58': 'Останати краткорочни финансиски средства',
  'AOP59': 'V. ПАРИЧНИ СРЕДСТВА И  ПАРИЧНИ ЕКВИВАЛЕНТИ (060+061)',
  'AOP60': 'Парични средства',
  'AOP61': 'Парични еквиваленти',
  'AOP62': 'VI. ПЛАТЕНИ ТРОШОЦИ ЗА ИДНИТЕ ПЕРИОДИ И ПРЕСМЕТАНИ ПРИХОДИ (АВР)',
  'AOP63': 'ВКУПНА АКТИВА:                            СРЕДСТВА (001+035+036+044+062)',
  'AOP64': 'В. ВОНБИЛАНСНА ЕВИДЕНЦИЈА - АКТИВА',
  'AOP65': 'ПАСИВА :                       А. ГЛАВНИНА И РЕЗЕРВИ (066+067-068-069+070+071+075-076+077-078)',
  'AOP66': 'I. ОСНОВНА ГЛАВНИНА',
  'AOP67': 'II. ПРЕМИИ НА ЕМИТИРАНИ АКЦИИ',
  'AOP68': 'III. СОПСТВЕНИ АКЦИИ (-)',
  'AOP69': 'IV. ЗАПИШАН, НЕУПЛАТЕН КАПИТАЛ (-) ',
  'AOP70': 'V. РЕВАЛОРИЗАЦИСКА РЕЗЕРВА И РАЗЛИКИ ОД ВРЕДНУВАЊЕ НА КОМПОНЕНТИ НА ОСТАНАТА СЕОПФАТНА ДОБИВКА',
  'AOP71': 'VI. РЕЗЕРВИ (072+073+074)',
  'AOP72': 'Законски резерви',
  'AOP73': 'Статутарни резерви',
  'AOP74': 'Останати резерви',
  'AOP75': 'VII. АКУМУЛИРАНА ДОБИВКА',
  'AOP76': 'VIII. ПРЕНЕСЕНА ЗАГУБА (-)',
  'AOP77': 'IX. ДОБИВКА ЗА ДЕЛОВНАТА ГОДИНА',
  'AOP78': 'X. ЗАГУБА ЗА ДЕЛОВНАТА ГОДИНА',
  'AOP79': 'XI. ГЛАВНИНА НА СОПСТВЕНИЦИТЕ НА МАТИЧНОТО ДРУШТВО',
  'AOP80': 'XII. НЕКОНТРОЛИРАНО УЧЕСТВО',
  'AOP81': 'Б. ОБВРСКИ   (082+085+095)',
  'AOP82': 'I. ДОЛГОРОЧНИ РЕЗЕРВИРАЊА ЗА РИЗИЦИ И ТРОШОЦИ     (083+084)',
  'AOP83': 'Резервирања за пензии, отпремнини и слични обврски кон вработените',
  'AOP84': 'Останати долгорочни резервирања за ризици и трошоци',
  'AOP85': 'II. ДОЛГОРОЧНИ ОБВРСКИ    (од 086 до 093)',
  'AOP86': 'Обврски спрема поврзани друштва',
  'AOP87': 'Обврски спрема добавувачи',
  'AOP88': 'Обврски за аванси, депозити и кауции',
  'AOP89': 'Обврски по заеми и кредити спрема поврзани друштва',
  'AOP90': 'Обврски по заеми и кредити',
  'AOP91': 'Обврски по хартии од вредност',
  'AOP92': 'Останати финансиски обврски',
  'AOP93': 'Останати долгорочни обврски',
  'AOP94': 'III. ОДЛОЖЕНИ ДАНОЧНИ ОБВРСКИ',
  'AOP95': 'IV. КРАТКОРОЧНИ ОБВРСКИ     (од 096 до 108)',
  'AOP96': 'Обврски спрема поврзани друштва',
  'AOP97': 'Обврски спрема добавувачи',
  'AOP98': 'Обврски за аванси, депозити и кауции',
  'AOP99': 'Обврски за даноци и придонеси на плата и на надомести на плати',
  'AOP100': 'Обврски кон вработените',
  'AOP101': 'Тековни даночни обврски',
  'AOP102': 'Краткорочни резервирања за ризици и трошоци',
  'AOP103': 'Обврски по заеми и кредити спрема поврзани друштва',
  'AOP104': 'Обврски по заеми и кредити',
  'AOP105': 'Обврски по хартии од вредност',
  'AOP106': 'Обврски по основ на учество во резултатот ',
  'AOP107': 'Останати финансиски обврски',
  'AOP108': 'Останати краткорочни обврски',
  'AOP109': 'V. ОДЛОЖЕНО ПЛАЌАЊЕ НА ТРОШОЦИ И ПРИХОДИ ВО ИДНИТЕ  ПЕРИОДИ (ПВР)',
  'AOP110': 'VI. ОБВРСКИ ПО ОСНОВ НА НЕТЕКОВНИ СРЕДСТВА (ИЛИ ГРУПИ ЗА ОТУЃУВАЊЕ) КОИ СЕ ЧУВААТ ЗА ПРОДАЖБА И ПРЕКИНАТИ РАБОТЕЊА',
  'AOP111': 'ВКУПНО ПАСИВА :             ГЛАВНИНА, РЕЗЕРВИ И ОБВРСКИ  (065+081+094+109+110)',
  'AOP112': 'В. ВОНБИЛАНСНА ЕВИДЕНЦИЈА-ПАСИВА',
  'AOP201': 'I. ПРИХОДИ ОД РАБОТЕЊЕТО (202+203+206)',
  'AOP202': 'Приходи од продажба',
  'AOP203': 'Останати приходи',
  'AOP204': 'Залихи на  готови производи и недовршено производство на почетокот на годината',
  'AOP205': 'Залихи на  готови производи и недовршено производство на крајот на годината',
  'AOP206': 'Капитализирано сопствени производство и услуги',
  'AOP207': 'II. РАСХОДИ ОД РАБОТЕЊЕТО (208+209+210+211+212+213+218+219+220+221+222)',
  'AOP208': 'Трошоци за суровини и други материјали',
  'AOP209': 'Набавна вредност на продадените стоки',
  'AOP210': 'Набавна вредност на продадените материјали, резервни делови, ситен инвентар, амбалажа и автогуми',
  'AOP211': 'Услуги со карактер на материјални трошоци',
  'AOP212': 'Останати трошоци од работењето',
  'AOP213': 'Трошоци за вработени (214+215+216+217)',
  'AOP214': 'Плати и надоместоци на плата (нето)',
  'AOP215': 'Трошоци за даноци на плати и надоместоци на плата',
  'AOP216': 'Придонеси од задолжително социјално осигурување',
  'AOP217': 'Останати трошоци за вработените',
  'AOP218': 'Амортизација на материјалните и нематеријалните средства',
  'AOP219': 'Вредносно усогласување (обезвреднување) на нетековни средства',
  'AOP220': 'Вредносно усогласување (обезвреднување)  на тековните средства ',
  'AOP221': 'Резервирања за трошоци и ризици',
  'AOP222': 'Останати расходи од работењето',
  'AOP223': 'III. ФИНАНСИСКИ ПРИХОДИ (224+229+230+231+232+233)',
  'AOP224': 'Финансиски приходи од односи со поврзани друштва (225+226+227+228)',
  'AOP225': 'Приходи од вложувања во поврзани друштва',
  'AOP226': 'Приходи по основ на камати од работење со поврзани  друштва',
  'AOP227': 'Приходи по основ на курсни разлики од работење со поврзани  друштва',
  'AOP228': 'Останати финансиски приходи од работење со поврзани  друштва',
  'AOP229': 'Приходи од вложувања во неповрзани друштва',
  'AOP230': 'Приходи по основ на камати од работење со неповрзани  друштва',
  'AOP231': 'Приходи по основ на курсни разлики од работење со неповрзани  друштва',
  'AOP232': 'Нереализирани добивки (приходи) од финансиски средства',
  'AOP233': 'Останати финансиски приходи',
  'AOP234': 'IV. ФИНАНСИСКИ РАСХОДИ (235+239+240+241+242+243)',
  'AOP235': 'Финансиски расходи од односи со поврзани друштва (236+237+238)',
  'AOP236': 'Расходи по основ на камати од работење со поврзани  друштва',
  'AOP237': 'Расходи по основ на курсни разлики од работење со поврзани  друштва',
  'AOP238': 'Останати финансиски расходи од поврзани  друштва',
  'AOP239': 'Расходи по основ на камати од работење со неповрзани  друштва',
  'AOP240': 'Расходи по основ на курсни разлики од работење со неповрзани  друштва',
  'AOP241': 'Нереализирани загуба (расходи) од финансиски средства',
  'AOP242': 'Вредносно усогласување на финансиски средства и вложувања',
  'AOP243': 'Останати финансиски расходи',
  'AOP244': 'Удел во добивката на придружените друштва',
  'AOP245': 'Удел во загуба на придружените друштва',
  'AOP246': 'Добивка од редовното работење             (201+223+244)-(204-205+207+234+245)',
  'AOP247': 'Загуба од редовното работење                             (204-205+207+234+245)-(201+223+244)',
  'AOP248': 'Нето добивка од прекинати работења',
  'AOP249': 'Нето загуба од прекинати работења',
  'AOP250': ' Добивка пред оданочување  (246+248) или (246-249)',
  'AOP251': ' Загуба пред оданочување  (247+249) или (247-248)',
  'AOP252': ' Данок на добивка',
  'AOP253': 'Одложени даночни приходи',
  'AOP254': 'Одложени даночни расходи',
  'AOP255': 'НЕТО ДОБИВКА ЗА ДЕЛОВНАТА ГОДИНА           (250-252+253-254)',
  'AOP256': 'НЕТО ЗАГУБА ЗА ДЕЛОВНАТА ГОДИНА           (251+252-253+254)',
  'AOP257': ' Просечен број на вработени врз основа на часови на работа во пресметковниот период (во апсолутен износ)',
  'AOP258': ' Број на месеци на работење (во апсолутен износ)',
  'AOP259': 'ДОБИВКА/ЗАГУБА  ЗА ПЕРИОД',
  'AOP260': 'Добивка која им припаѓа на имателите на акции на матичното друштво',
  'AOP261': 'Добивка која им припаѓа на неконтролирано учество',
  'AOP262': 'Загуба која се однесува на имателите на акции на матичното друштво',
  'AOP263': 'Загуба која се однесува на неконтролирано учество',
  'AOP264': 'ЗАРАБОТУВАЧКА ПО АКЦИЈА',
  'AOP265': 'Вкупна основна заработувачка по акција',
  'AOP266': 'Вкупна разводнета заработувачка по акција',
  'AOP267': 'Основна заработувачка по акција од прекинато работење',
  'AOP268': 'Разводнета заработувачка по акција од прекинато работење',
  'AOP269': ' Добивка за годината',
  'AOP270': 'Загуба за годината',
  'AOP271': 'Останата сеопфатна добивка (273+275+277+279+280+282)-(274+276+278+281+283)',
  'AOP272': 'Останата сеопфатна загуба    (274+276+278+281+283)-(273+275+277+279+280+282)',
  'AOP273': 'Добивки кои произлегуваат од преведување од странско работење',
  'AOP274': 'Загуби кои произлегуваат од преведување од странско работење',
  'AOP275': 'Добивки од повторно мерење на финансиски средства расположливи за продажба',
  'AOP276': 'Загуби од повторно мерење на финансиски средства расположливи за продажба',
  'AOP277': 'Ефективен дел од добивки од хеџинг инструменти за хеџирање на парични текови',
  'AOP278': 'Ефективен дел од загуби од хеџинг инструменти за хеџирање на парични текови',
  'AOP279': 'Промени на ревалоризациските резерви за нетековни средства (+)',
  'AOP280': 'Промени на ревалоризациските резерви за нетековни средства (-)',
  'AOP281': 'Актуарски добивки на дефинирани планови за користи на вработените',
  'AOP282': 'Актуарски загуби на дефинирани планови за користи на вработените',
  'AOP283': 'Удел во останата сеопфатна добивка на придружени друштва (само за потреби на консолидација)',
  'AOP284': 'Удел во останата сеопфатна загуби на придружени друштва (само за потреби на консолидација)',
  'AOP285': 'Данок на добивка на компоненти на останата сеопфатна добивка',
  'AOP286': 'Нето останата сеопфатна добивка               (271-285) ',
  'AOP287': 'Нето останата сеопфатна загуби                (285-271) или (272+285) ',
  'AOP288': 'Вкупна сеопфатна добивка за годината (269+286) или  (286-270)',
  'AOP289': 'Сеопфатна добивка која им припаѓа на имателите на акции на матичното друштво',
  'AOP290': 'Сеопфатна добивка која припаѓа на неконтролираното учество',
  'AOP291': 'Вкупна сеопфатна загуба за годината (270+287) или (270-286) или (287-269)',
  'AOP292': 'Сеопфатна загуба која им припаѓа на имателите на акции на матичното друштво',
  'AOP293': 'Сеопфатна загуба која припаѓа на неконтролираното учество',
  'TG501': 'Вкупни Приходи',
  'TG502': 'Вкупни Расходи',
};

const aop_new_obj_this_year = {

};

const aop_new_obj_before_year = {

};



const aop_fields1 = ["AOP1","AOP2","AOP3","AOP4","AOP5","AOP6","AOP7","AOP8","AOP9","AOP10","AOP11","AOP12","AOP13","AOP14","AOP15","AOP16","AOP17","AOP18","AOP19","AOP20","AOP21","AOP22","AOP23","AOP24","AOP25","AOP26","AOP27","AOP28","AOP29","AOP30","AOP31","AOP32","AOP33","AOP34","AOP35","AOP36","AOP37","AOP38","AOP39","AOP40","AOP41","AOP42","AOP43","AOP44","AOP45","AOP46","AOP47","AOP48","AOP49","AOP50","AOP51","AOP52","AOP53","AOP54","AOP55","AOP56","AOP57","AOP58","AOP59","AOP60","AOP61","AOP62","AOP63","AOP64","AOP65","AOP66","AOP67","AOP68","AOP69","AOP70","AOP71","AOP72","AOP73","AOP74","AOP75","AOP76","AOP77","AOP78","AOP79","AOP80","AOP81","AOP82","AOP83","AOP84","AOP85","AOP86","AOP87","AOP88","AOP89","AOP90","AOP91","AOP92","AOP93","AOP94","AOP95","AOP96","AOP97","AOP98","AOP99","AOP100","AOP101","AOP102","AOP103","AOP104","AOP105","AOP106","AOP107","AOP108","AOP109","AOP110","AOP111","AOP112"];
const app_fields2 = ["AOP201","AOP202","AOP203","AOP204","AOP205","AOP206","AOP207","AOP208","AOP209","AOP210","AOP211","AOP212","AOP213","AOP214","AOP215","AOP216","AOP217","AOP218","AOP219","AOP220","AOP221","AOP222","AOP223","AOP224","AOP225","AOP226","AOP227","AOP228","AOP229","AOP230","AOP231","AOP232","AOP233","AOP234","AOP235","AOP236","AOP237","AOP238","AOP239","AOP240","AOP241","AOP242","AOP243","AOP244","AOP245","AOP246","AOP247","AOP248","AOP249","AOP250","AOP251","AOP252","AOP253","AOP254","AOP255","AOP256","AOP257","AOP258","AOP259","AOP260","AOP261","AOP262","AOP263","AOP264","AOP265","AOP266","AOP267","AOP268","AOP269","AOP270","AOP271","AOP272","AOP273","AOP274","AOP275","AOP276","AOP277","AOP278","AOP279","AOP280","AOP281","AOP282","AOP283","AOP284","AOP285","AOP286","AOP287","AOP288","AOP289","AOP290","AOP291","AOP292","AOP293", "TG501", "TG502"];
const validatedFields = ["AOP36",	"AOP95",	"AOP37",	"AOP59",	"AOP47",	"AOP202",	"AOP208",	"AOP209",	"AOP210",	"AOP211",	"AOP63",	"AOP207",	"AOP204",	"AOP205",	"AOP218",	"AOP97",	"AOP255",	"AOP256",	"AOP65",	"AOP85",	"AOP82",	"AOP234",	"AOP103",	"AOP104",	"AOP223",	"AOP250",	"AOP251",	"AOP244",	"AOP245",	"AOP1",	"AOP201",	"AOP257",	"AOP214",	"AOP258",	"AOP90",	"AOP219",	"AOP220",	"AOP45",	"AOP52",	"AOP62",	"AOP109",	"AOP35",	"AOP94",	"AOP71",	"AOP67",	"AOP70",	"AOP76",	"AOP78",	"AOP75",	"AOP77",	"AOP68",	"AOP66",	"AOP2",	"AOP9",	"AOP20",	"AOP21",	"AOP31",	"AOP69",	"AOP44",	"AOP110"];
export {aop_titles, aop_new_obj_this_year,aop_new_obj_before_year ,aop_fields1, app_fields2, validatedFields};
