import React from 'react';
import IconButton from '@mui/material/IconButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import {
  Grid,
  Dialog,
  Alert,
  TextField,DialogTitle,
  DialogActions,
  DialogContent,
  Autocomplete,
  Typography,  Button
} from '@mui/material';


class Bell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }


  handleCloseNotification = () => {
    this.setState({open: false})
  }

  render() {
    return (
      <>
        <IconButton onClick={() => {this.setState({open: true})}}>
          <NotificationsActiveIcon/>
        </IconButton>


        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCloseNotification}
        >
          <DialogTitle sx={{p: 3}}>
            <Typography variant="h4" gutterBottom>
              {'Нотификации'}
            </Typography>
          </DialogTitle>
          <DialogContent dividers sx={{p: 3}}>
           <Typography variant={"h3"}>
             <a href="/PortfolioLimit">Имате {this.props.limitChangeCount} барања за зголемување на лимит.</a>
           </Typography>
            <Typography variant={"h3"}>
              <a href="/Orders">Имате {this.props.syncCount} извештаи кои чекаат за синхронизирање.</a>
            </Typography>
            <Typography variant={"h3"}>
              <a href="/">Имате {this.props.pendingImports} извештаи кои чекаат за импортирање.</a>
            </Typography>
            pendingImports
          </DialogContent>
          <DialogActions sx={{p: 3}}>
            <Button color="secondary" onClick={this.handleCloseNotification}>
              {'Откажи'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}


export default Bell;
