import { useState,  useEffect } from 'react';
import PropTypes from 'prop-types';


import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  aop_titles,
  aop_new_obj,
  aop_fields1,
  app_fields2,
  aop_new_obj_before_year,
  aop_new_obj_this_year
} from './Data/aop_titles';
import {
  indicator_titles,
  pokazateli_fields,
  gotovinskiTekovi_fields,
  bonitetnaOcena_fields
} from './Data/indicator_titles';
import { useSnackbar } from 'notistack';
import ViewResultReportForm from './ViewResultReportForm';
import ViewOcenaForm from "./ViewOcenaForm";
import { useTheme } from '@mui/material/index';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);


const applyFilters = (users, query, filters) => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['email', 'name', 'username'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (filters.role && user.role !== filters.role) {
        matches = false;
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (users, page, limit) => {
  return users.slice(page * limit, page * limit + limit);
};

const Results = ({ users, ...rest }) => {

  const [thisYearObj, setThisYearObj] = useState({Year:rest.Year, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});
  const [lastYearObj, setLastYearObj] = useState({Year:rest.Year- 1, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if(!isMounted) {
      setIsMounted(true);
      deleteData();
    }
  });


  function deleteData() {
    setThisYearObj({Year:rest.Year, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});
    setLastYearObj({Year:rest.Year - 1, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});
  };


  const [selectedItems, setSelectedUsers] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const tabs = [
    {
      value: 'Биланс на состојба',
      label: t('Биланс на состојба')
    },
    {
      value: 'Биланс на успех',
      label: t('Биланс на успех')
    },
    {
      value: 'Показатели',
      label: t('Показатели')
    },
    {
      value: 'Готовински текови',
      label: t('Готовински текови')
    },
    {
      value: 'Бонитетна оцена',
      label: t('Бонитет')
    }
  ];



  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');

  const [filters, setFilters] = useState({
    role: 'Биланс на состојба'
  });

  const handleTabsChange = (event, tabsValue) => {

    setFilters((prevFilters) => ({
      ...prevFilters,
      role: tabsValue,

    }));

  };


  const filteredUsers = applyFilters(users, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeUsers =
    selectedItems.length > 0 && selectedItems.length < users.length;
  const selectedAllUsers = selectedItems.length === users.length;

  const [toggleView, setToggleView] = useState('table_view');


  // rest.indicators.TL = (Math.round(rest.indicators.TL * 100) / 100).toFixed(2);
  // rest.indicators.BL = (Math.round(rest.indicators.BL * 100) / 100).toFixed(2);
  // rest.indicators.PL = (Math.round(rest.indicators.PL * 100) / 100).toFixed(2);
  // rest.indicators.KZ = Math.round(rest.indicators.KZ);
  // rest.indicators.OP = (Math.round(rest.indicators.OP * 100) / 100).toFixed(2);
  // rest.indicators.OP1 = Math.round(rest.indicators.OP1);


  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={filters.role || 'all'}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label}/>
          ))}
        </TabsWrapper>
      </Box>
      <Card>
        <Divider/>
        <>
          {
            filters.role === "Биланс на состојба"?
              <ViewResultReportForm data={rest.financeLastYear}  data2={rest.finance} fields={aop_fields1} titles={aop_titles} companyID={rest.companyID} showLastYear={true} showPozicija={true} showRecenica={true} pokazateli={false} key={Date.now()}></ViewResultReportForm>
              :filters.role === "Биланс на успех"
              ?<ViewResultReportForm data={rest.financeLastYear}  data2={rest.finance} fields={app_fields2} titles={aop_titles} companyID={rest.companyID} showLastYear={true} showPozicija={true} showRecenica={true} pokazateli={false} key={Date.now()}></ViewResultReportForm>:
              filters.role === "Показатели"
            ?<ViewResultReportForm data={rest.financeLastYear}  data2={rest.indicators} fields={pokazateli_fields} titles={indicator_titles} companyID={rest.companyID} showLastYear={false} showPozicija={false} showRecenica={false} pokazateli={true} key={Date.now()}></ViewResultReportForm>:
            filters.role === "Готовински текови"
            ?<ViewResultReportForm data={rest.financeLastYear}  data2={rest.indicators} fields={gotovinskiTekovi_fields} titles={indicator_titles} companyID={rest.companyID} showLastYear={false} showPozicija={false}  showRecenica={false} pokazateli={true} key={Date.now()}></ViewResultReportForm>:
              filters.role === "Бонитетна оцена"
                ?<ViewOcenaForm data={rest.financeLastYear}  data2={rest.indicators} theme={theme} fields={bonitetnaOcena_fields} titles={indicator_titles} companyID={rest.companyID} key={Date.now()}></ViewOcenaForm>:''
          }
        </>
      </Card>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired
};

Results.defaultProps = {
  users: []
};

export default Results;
