import { Typography, Button, Grid, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';



function PageHeader(props) {
  const { t } = useTranslation();
  return (
    <>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4" component="h4" gutterBottom>
          {t('ЕМБС: ' + props.pin)}
        </Typography>
        <Typography variant="h4" component="h4" gutterBottom>
          {t('ГОДИНА: ' + props.Year)}
        </Typography>
        <Typography variant="h4" component="h4" gutterBottom>
          {t('ТИП НА ИЗВЕШТАЈ: ' + props.reportName)}
        </Typography>
        <Typography variant="subtitle2">
          {t(props.company_name)}
        </Typography>
      </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={`/company/${props.id}/dashboard`}
            color={"primary"}
            variant={"contained"}

          >
            {"Назад до компанијата"}
          </Button>
        </Grid>
    </Grid>

      </>
  );
}

export default PageHeader;
