import {useEffect} from 'react';
import {Box, CircularProgress} from '@mui/material';

function SuspenseLoader(props) {

  if (props.visible === false) {
    return null;
  }

  if (props.full) {
    return (
      <div style={{position: 'fixed', width: '100%', height: '100%', backgroundColor: 'white', top: 0, left: 0, zIndex: 20}}>
        <Box
          sx={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100%'}}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress variant='indeterminate'  size={64} disableShrink thickness={3}/>
        </Box>
      </div>
    );
  }

  return (
    <Box
      sx={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100%'}}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress variant='indeterminate' size={64} disableShrink thickness={3}/>
    </Box>
  );
}

export default SuspenseLoader;
