import React from 'react';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Button
} from '@mui/material';

import {Link as RouterLink} from 'react-router-dom';
import DivisionCreateLimit from '../../Dialogs/DivisionCreateLimit';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      Name: this.props.Name,
      nameError: '',
      status: this.props.division.Status,
      openLimitDialog: false,
      validateName: true,
    };

  }

  componentDidMount(){
  }

  validate = () => {

    let validateName = this.state.validateName;
    let nameError = this.state.nameError;
    let name = this.state.Name;
    switch (true) {
      case name.length < 1:
        nameError = 'Името не може да биде празно поле.';
        break;
      case name.length < 4:
        nameError = 'Името мора да има 4 или повеќе карактери.';
        break;
      case name.length > 25:
        nameError = 'Името име не смее да има повеќе од 25 карактери.';
        break;
      case validateName === false:
        nameError = 'Веке постои портфолио со тоа име.';
        break;

    }


    if(nameError && validateName === false){
      this.setState({
        nameError
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  handleEditDivisionOpen = () => {
    this.setState({ open: true });
  };

  handleEditDivisionClose = () => {
    this.setState({ open: false}, this.props.getDivision());
  };


  handleAddDivision = () => {
    window.makeRequest("GET", `/api/Division?name=${this.state.Name}`, false, true, (callback) => {
      if(callback){
        if(this.props.Name === this.state.Name){
            this.setState({validateName: true}, () => this.handleAddDivisionSuccess());
        } else {
          this.setState({validateName: callback.body}, () => this.handleAddDivisionSuccess());
        }
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });

  }

  handleAddDivisionSuccess = () => {
    let validate = this.validate();
    if(validate){
      this.setState({open: false})
      window.makeRequest("PUT", `/api/Division?id=${this.props.division.ID}`, {
        Name: this.state.Name,
        Status: this.state.status,
      }, true, (callback) => {
        if(callback.body.Response.ReasonPhrase !== 'OK'){
          this.setState({openLimitDialog: true})
        } else {
          this.props.getDivision();
          this.handleEditDivisionClose();
        }


      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });
    }
  };
  handleChangeStatus = (e) => {
    this.setState({status: e.target.value})
  }

  returnLimitDialog = () => {

    return<DivisionCreateLimit ManageSubscription={this.props.ManageSubscription} onClose={this.closeLimitDialog} open={this.state.openLimitDialog}/>
  }
  closeLimitDialog = () => {
      this.setState({openLimitDialog: false})
  }

  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center" sx={{p: 2}}>
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {this.props.division.Name}
            </Typography>
            <Typography variant="subtitle2">
              {(
                'Сите работи поврзани со ова портфолио може да се уредуваат од овде.'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleEditDivisionOpen}
              variant="contained"
            >
              {('Промени')}
            </Button>
            <Button
              sx={{ mt: { xs: 2, sm: 0 }, ml: 1 }}
              component={RouterLink}
              to={'/divisions/' + this.props.division.ID}
              variant="contained"
            >
              {('Анализатор на портфолио')}
            </Button>
            <Button
              sx={{ mt: { xs: 2, sm: 0 }, ml: 1 }}
              component={RouterLink}
              to={'/AllDivisions'}
              variant="contained"
            >
              {('Назад до сите портфолија')}
            </Button>
          </Grid>
          {this.returnLimitDialog()}
        </Grid>

        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleEditDivisionClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {('Променете го името на портфолијата')}
            </Typography>
            <Typography variant="subtitle2">
              {(
                'Пополнете ги полињата за да го промените името.'
              )}
            </Typography>
          </DialogTitle>


          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={('Име')}
                      name="Name"
                      value={this.state.Name}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                    {
                      this.state.nameError? <Alert severity="error" sx={{mt: 1}}>{this.state.nameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Статус</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.status}
                        label="Статус"
                        onChange={this.handleChangeStatus}
                      >
                        <MenuItem value={true}>Активна</MenuItem>
                        <MenuItem value={false}>Неактивна</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleEditDivisionClose}>
              {('Откажи')}
            </Button>
            <Button
              type="submit"
              onClick={this.handleAddDivision}
              variant="contained"
            >
              {('Промени')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}


export default Header;

