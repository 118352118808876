import React from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
} from '@mui/material';

import { withRouter, Link as RouterLink } from 'react-router-dom';


import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

class ActivationPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    let ID = this.props.match.params.id;
    window.makeRequest("PUT", `/api/Register?activationID=${ID}`, null, true, (callback) => {
      this.setState({activated: true});
    }, (err) => {
      if (err) {
        this.setState({activated: false});
        console.log('err', err);
      }
    })
  }


  render() {
    return <>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center" mb={3}>
            {this.state.activated && <Container maxWidth="xs">
              <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                {'Активација'}
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                fontWeight="normal"
                sx={{ mb: 4 }}
              >
                {
                  "Успешно активирање на корисникот"
                }
              </Typography>
            </Container>}
            {this.state.activated  === false  && <Container maxWidth="xs">
              <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                {'Активација'}
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                fontWeight="normal"
                sx={{ mb: 4 }}
              >
                {
                  "Активирањето не е успешно"
                }
              </Typography>
            </Container>}
            {this.state.activated && <CheckIcon style={{fontSize: 55}} color={'success'} /> }
            {this.state.activated === false && <CloseIcon style={{fontSize: 55}} color={'error'} />}
          </Box>

          <Box textAlign="center">

            <Button component={RouterLink} to={"/Login"} variant={"contained"}>
              Продолжи понатаму
            </Button>

          </Box>
        </Container>
      </MainContent>
    </>;
  }
}

export default  withRouter(ActivationPage);
