import React from 'react';
import SuspenseLoader from '../../../../../components/SuspenseLoader'
import { withRouter } from 'react-router-dom';
import { TextField, Card, Box, Grid,Divider,Button } from '@mui/material';
import Text from "../../../../../components/Text";
import request from "superagent";
import ImportingDialog from "./ImportingDialog";
import moment from 'moment';
import SuccessDialog from "./SuccessDialog";

class ImportCompanies extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      list: [],
      checkedList: [],
      showStopButton: false,
      count: 0,
      loadingScreen: false,
      deniedList: [],
      successDialog: false,
    }
  }


  componentDidMount() {
    this.getDataFromFile();

  }

  getDataFromFile = () => {

      fetch(`/api/ClientImports?ID=${this.props.match.params.id}`)
        .then(response => response.blob())
        .then(blob => {
          let list = blob.text().then(text => {
            let separator = ''; 
            if(text.indexOf('\n') > 0) {
              separator = '\r\n'+separator;
            }
            if(text.indexOf(',') > 0) {
              separator = ','+separator
            }
            if(text.indexOf(';') > 0){
              separator = ';'+ separator
            }
            let removedList = text.split(separator);
            if(removedList[removedList.length - 1].length > 7){
              removedList[removedList.length - 1] = removedList[removedList.length - 1].substring(0, removedList[removedList.length - 1].length - 1);
            }
            let collator = new Intl.Collator([], {numeric: true});

            this.setState({loading: false, list: removedList.sort((a, b) => collator.compare(a, b))})
          });


        });
  }

  handleChange = (e, row) => {
    if(e.target.value.length === 7) {
      const clone = [...this.state.list];
      clone.filter((rows, filter) => {
        if(filter === row) {
          console.log('here', filter,row);
          clone[filter] = e.target.value;
        }
      })
      this.setState({list: clone})
    }
  }

  checkAvability = () => {
    this.setState({checkedList: [], loadingScreen: true, count: 0, showStopButton: false})
    let arrayList = [];
    let deniedList = [];
    let count = 0;
    this.state.list.forEach((list, index) => {
      request
        .get(`/api/TekovenIzvestaj/GetTekovenIzvestajObject?embsEdb=${list}`)
        .withCredentials()
        .auth('tgmisajlovska', '123456789')
        .then((callback) => {
          if (callback.body && callback.body.hasOwnProperty('PregledNaSubjekt')) {
            let Pregled = callback.body.PregledNaSubjekt;
            let PIN = list;
            let VAT = Pregled.Edb && Pregled.Edb;
            let Company_name = Pregled.CelosenNaziv && Pregled.CelosenNaziv;
            let Establishment_year = Pregled.Establishment_year && Pregled.Establishment_year.substring(0, 3);
            let Size = Pregled.GoleminaNaKompanija && Pregled.GoleminaNaKompanija;
            let Occupation = Pregled.PrioritetnaDejnost && Pregled.PrioritetnaDejnost.substring(0, 4);
            let Adress = Pregled.Sedishte && Pregled.Sedishte;
            //   ,[City]
            arrayList.push({VAT, PIN, Company_name, Establishment_year, Size, Occupation, Adress});
            this.setState({count: this.state.count + 1});
            count++;

          } else {
            let PIN = list;
            count++;
            let Company_name = 'Не е пронајдена';
            arrayList.push({PIN, Company_name});
            deniedList.push({PIN});
            this.setState({count: this.state.count+1, deniedList: deniedList})
          }

          if(this.state.list.length === count) {
            let collator = new Intl.Collator([], {numeric: true});
            const clone = [...this.state.list];
            this.setState({list: []});
            clone.sort((a, b) => collator.compare(a, b));
            this.setState({checkedList: arrayList.sort((a, b) => collator.compare(a.PIN, b.PIN)), list:clone,showStopButton: true });
          }

        })
        .catch((e) => {
          if(e){
            let PIN = list;
            count++;
            let Company_name = 'Не е пронајдена';
            arrayList.push({PIN, Company_name});
            deniedList.push({PIN});
            this.setState({count: this.state.count+1, deniedList: deniedList})
            if(this.state.list.length === count) {
              let collator = new Intl.Collator([], {numeric: true});
              const clone = [...this.state.list];
              this.setState({list: []});
              clone.sort((a, b) => collator.compare(a, b));
              this.setState({checkedList: arrayList.sort((a, b) => collator.compare(a.PIN, b.PIN)), list:clone,showStopButton: true });
            }
          }
        });

    })


  }

  closeImportingDialog = () => {
    this.setState({
      count: 0,
      loadingScreen: false,
    })
  }

  importInDatabase = () => {
    this.setState({successDialog: true})
    // window.makeRequest("POST", `/api/Admin_imports?imports=true`, {
    //
    // }, true, (callback) => {
    //
    //
    //   // this.setState({users: callback.body});
    //
    // }, (err) => {
    //   if(err) {
    //     console.log(err);
    //   }
    // });
  }

  downloadDeniedList = () => {
    let embs = this.state.deniedList.map((row) => row.PIN);
    const element = document.createElement("a");
    const file = new Blob([embs], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    const date = moment(new Date()).format("DD/MM/YYYY");
    element.download = date+"-Одбиени-компании.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  closeSuccessDialog = () => {
    this.setState({successDialog: false, deniedList: [], count: 0, checkedList: []})
  }




  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    } else {
      return <>
        <Grid container  >
          <Grid item sx={{p: 2}} xs={12} >
            <Card >
              <Grid p={3}>
                    <Grid display={'flex'}>
                      <Grid item xs={3} >
                        <Text pb={2} style={{fontSize: '20px'}} color={'primary'}>Компании</Text>
                      </Grid>
                      <Grid item xs={3} >
                        <Text pb={2} style={{fontSize: '20px'}} color={'primary'}>Импорти</Text>
                      </Grid>
                    </Grid>
                          <Box
                            display={'flex'}
                            alignContent={'center'}
                          >
                            <Grid item xs={3} >
                            {this.state.list ? this.state.list.map((row, index) => {
                              return<Box  item py={0.2}  >
                                <TextField   size='small'  name={row}  onChange={(e) => this.handleChange(e,index)} variant="outlined" defaultValue={row}/>
                              </Box>
                            }) : 'no inputs'}
                            </Grid>
                            <Grid item xs={9}>
                              {this.state.checkedList ? this.state.checkedList.map((row, index) => {
                                return<Box  item py={0.2}  ><TextField fullWidth error={row.Company_name === 'Не е пронајдена'}  size='small' value={row.PIN + ' - ' + row.Company_name} /> </Box>
                              }) : 'no inputs'}
                            </Grid>
                      </Box>
                <Grid display={'flex'} mt={2} xs={12} >
                  <Grid item xs={3} >
                    <Box mr={1} >
                      <Button variant={'contained'} onClick={this.checkAvability}>Провери достапност</Button>
                    </Box>
                  </Grid>
                  <Box mr={1}>
                    <Button variant={'outlined'} disabled={!this.state.showStopButton} onClick={this.importInDatabase}>Внеси во база</Button>
                  </Box>
                  <Box mx={1}>
                    <Button disabled={this.state.deniedList.length === 0} variant={'contained'} onClick={this.downloadDeniedList}>Превземи непронајдени</Button>
                  </Box>

                </Grid>

              </Grid>

              <ImportingDialog open={this.state.loadingScreen} onClose={this.state.count && this.closeImportingDialog} total={this.state.list.length} count={this.state.count} button={this.state.showStopButton}/>
              <SuccessDialog open={this.state.successDialog} onClose={this.closeSuccessDialog} />

            </Card>
          </Grid>
        </Grid>
      </>;
    }
  }
}

export default withRouter(ImportCompanies);
