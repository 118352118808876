import { useState } from 'react';

import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useTheme
} from '@mui/material';


function ButtonGroupToggle(props) {
  const theme = useTheme();

  const [tabs, setTab] = useState(props.SubscriptionPeriod);

  const handleViewOrientation = (_event, newValue) => {
    setTab(newValue);
    props.onChange(newValue);
  };

  return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {props.header && <Box>
          {props.title &&
          <Typography gutterBottom variant="h4">
            {props.title}
          </Typography>}
          {props.subtitle && <Typography variant="subtitle2">
            {props.subtitle}
          </Typography>}
        </Box>}
        <ToggleButtonGroup
          size="small"
          value={tabs}
          exclusive
          onChange={handleViewOrientation}
        >
          <ToggleButton
            sx={{
              px: 2,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`
            }}
            disableRipple
            value="monthly"
          >
            {'Месечно'}
          </ToggleButton>
          <ToggleButton
            sx={{
              px: 2,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`
            }}
            disableRipple
            value="yearly"
          >
            {'Годишно'}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
  );
}

export default ButtonGroupToggle;
