import React from 'react';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import TopCard from '../TopCard/index';
import DataGridDashboard from './DataGridDashboard/index';
import Chart from '../ChartDisplay/index'
import BottomCards from '../BottomCards/index'
import {withRouter} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';



import {
  Button,
  Grid,
  Box,
  Typography,
  Tooltip,
  Dialog,
  IconButton,
} from '@mui/material';

import Import from '../../../../components_bi/Import';
import DateFormating from '../../../../components_bi/DateFormating';

import WeatherLegendDialog from '../DIalogs/WeatherLegendDialog';

import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import WeatherCard from '../WeatherCard'
import SuspenseLoader from 'src/components/SuspenseLoader';
import {Avatar} from "@mui/material/index";
import {styled} from "@mui/material/styles/index";
import request from "superagent";
import BuyDialog from "../DIalogs/BuyDialog";
import BuyStatusDialog from "../DIalogs/BuyStatusDialog";
import AllWeatherCards from "./AllWeatherCards";
import GetAppIcon from '@mui/icons-material/GetApp';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

class CompanyDashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      showDrafts: false,
      successImport: false,
      openConfirmDelete: false,
      openWeatherLegendDialog: false,
      deleteID: null,
      dejnost: '',
      data: [],
      dataReverse: [],
      data2: [],
      dataImages: [],
      noData: false,
      columns: [

        { field: 'Year',
          renderHeader: params => {
            return(
              <div>Година</div>
            )
          },
          width: 80,
          disableColumnMenu: true,
          align: 'center'
        },
        {
          field: 'Report_type',
          headerName: 'Тип на извештај',
          width: 160,
          type:'text',
          align: 'center',
          disableColumnMenu: true,

        },
        {
          field: 'tekovnaLikvidnost',
          renderHeader: params => {
            return(
              <Tooltip title={'Показателот покажува колку компанијата успева остварените приходи од продажба да ги претвори во нето добивка.'} arrow placement='top'>
                  <div>Тековна ликвидност</div>
              </Tooltip>
            )
          },
          width: 170,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return params.row.tekovnaLikvidnost ? parseFloat(params.row.tekovnaLikvidnost).toFixed(2) : ''}
        },
        {
          field: 'brzaLikvidnost',
          renderHeader: params => {
            return(
              <Tooltip title={'Способност на компанијата преку најликвидните тековни средства да ги покрие краткорочните обврски.'} arrow placement='top'>
                  <div>Брза ликвидност</div>
              </Tooltip>
            )
          },
          width: 150,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return params.row.brzaLikvidnost ? parseFloat(params.row.brzaLikvidnost).toFixed(2) : '';
          }
        },
        {
          field: 'dolg_kapital',
          renderHeader: params => {
            return(
              <Tooltip title={'Показателот го покажува односот помеѓу изворите на компанијата стекнати по пат на задолжување и по пат на сопственост.'} arrow placement='top'>
                  <div>Долг/капитал</div>
              </Tooltip>
            )
          },
          width: 120,
          editable: true,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return params.row.dolg_kapital ? parseFloat(params.row.dolg_kapital).toFixed(2) : '';
          }

        },
        {
          field: 'zadolzenost',
          renderHeader: params => {
            return(
              <Tooltip title={'Показателот означува во колкав степен компанијата ги користи туѓите извори на средства за финансирање на своето работење.'} arrow placement='top'>
                  <div>Задолженост</div>
              </Tooltip>
            )
          },
          width: 120,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return params.row.zadolzenost ? parseFloat(params.row.zadolzenost * 100).toFixed(0) + "%" : '';
          }
        },
        {
          field: 'Prihodi',
          renderHeader: params => {
            return(
              <Tooltip title={'Вкупна вредност на приходи кои компанијата ги остварила по основ на приходи од работење, финансиски приходи и удел во добивката на придружените друштва.'} arrow placement='top'>
                  <div>Вкупни приходи</div>
              </Tooltip>
            )
          },
          width: 140,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
        },
        {
          field: 'FinansiskiRezultat',
          renderHeader: params => {
            return(
              <Tooltip title={'Вредност на остварен финансиски резултат на компанијата по одбивање на данокот на добивка.'} arrow placement='top'>
                  <div>Финансиски резултат</div>
              </Tooltip>
            )
          },
          width: 180,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
        },
        {
          field: 'NetoProfitnaMarza',
          renderHeader: params => {
            return(
              <Tooltip title={'Показателот покажува колку компанијата успева остварените приходи од продажба да ги претвори во нето добивка.'} arrow placement='top'>
                  <div>Нето профитна маржа</div>
              </Tooltip>
            )
          },
          width: 180,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return params.row.NetoProfitnaMarza ? parseFloat(params.row.NetoProfitnaMarza * 100).toFixed(0) + "%" : '';
          }
        },
        {
          field: 'PovratNaSredstva',
          renderHeader: params => {
            return(
              <Tooltip title={'Показателот ја прикажува способноста и ефикасноста на менаџментот на користењето на вкупните средства на компанијата за генерирање профит.'} arrow placement='top'>
                  <div>Поврат на средства (ROA)</div>
              </Tooltip>
            )
          },
          width: 210,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return params.row.PovratNaSredstva ? parseFloat(params.row.PovratNaSredstva * 100).toFixed(0) + "%" : '';
          }

        },
        {
          field: 'Vraboteni',
          renderHeader: params => {
            return(
              <Tooltip title={'Просечен број на вработени во текот на годината.'} arrow placement='top'>
                  <div>Вработени</div>
              </Tooltip>
            )
          },
          width: 100,
          type:'number',
          align: 'center',
          disableColumnMenu: true,
        },
        {
          field: '#',
          renderHeader: params => {
            return(
              <Tooltip title={'Со клик на знакот за примарен извештај го означувате посакуваниот извештај за примарен. Истиот се прикажува во девизиите во кои се прикажува компанијата.'} arrow placement='top'>
                  <div>Менаџираj</div>
              </Tooltip>
            )
          },
          width: 200,
          headerAlign: 'center',
          type: 'text',
          renderCell: (params) => {
            return <Typography noWrap align={'center'}>
              <Tooltip title={('Експортирај')} arrow>
                <IconButton
                  onClick={(event) => {
                    event.ignore = true;
                    this.handleDownload(params.row.id)
                  }}
                  color="primary"
                >
                  <GetAppIcon fontSize="medium"/>
                </IconButton>
              </Tooltip>
              <Tooltip title={('Избриши')} arrow>
                
                <IconButton
                  onClick={(event) => {
                    event.ignore = true;
                    this.openConfirmDeleteForm(params.row.id)
                  }}                  
                  color="primary"
                >
                  <DeleteIcon fontSize="medium"/>
                </IconButton>
              </Tooltip>
              {
                params.row.isDefault === false ?
                  <Tooltip title={('Примарен')} arrow>
                    <IconButton
                      onClick={(e) => {
                        e.ignore = true;
                        this.handleSelectAsDefault(params.row.id, params.row.Year)
                      }}
                      color="primary"
                    >
                      <DoneIcon fontSize="medium"/>
                    </IconButton>
                  </Tooltip> : ''
              }

              {
                  <Tooltip title={('Синхронизирај')} arrow>
                    <IconButton
                      onClick={(e) => {
                        e.ignore = true;
                        this.syncReport(params.row.id)
                      }}
                      color="primary"
                    >
                      <SyncIcon fontSize="medium"/>
                    </IconButton>
                  </Tooltip>

              }
            </Typography>
          },
          align: 'center',
          disableColumnMenu: true,
        }



      ],
      columns2: [
        {
          field: 'Year',
          renderHeader: params => {
            return(
              <div>Година</div>
            )
          },
          width: 100,
          type:'text',
          align: 'center',
          disableColumnMenu: false,
        },
        {
          field: 'report_type',
          headerName: 'Тип на извештај',
          width: 150,
          type:'text',
          align: 'center',
          disableColumnMenu: true,
        },
        {
          field: 'updatedAt',
          headerName: 'Последна промена',
          width: 180,
          type:'text',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => {
            return DateFormating(params.row.updatedAt)
          },
        },
        {
          field: '#',
          headerName: 'Менаџирај',
          width: 120,
          type: 'text',
          align: 'center',
          renderCell: (params) => {
            
            return <Typography noWrap align={'center'}>
              <Tooltip title={('Избриши')} arrow>
                <IconButton
                  onClick={(event) => {
                    event.ignore = true;
                    this.openConfirmDeleteForm(params.row.id)
                  }}
                  color="primary"
                >
                  <DeleteIcon fontSize="medium"/>
                </IconButton>
              </Tooltip>

              {
                params.row.synced === false ?
                  <Tooltip title={('Синхронизирај')} arrow>
                    <IconButton
                      color="primary"
                    >
                      <SyncIcon fontSize="medium"/>
                    </IconButton>
                  </Tooltip>
                  : ''
              }

            </Typography>
          },
          align: 'center',
          disableColumnMenu: true,
        }
      ],
      displayWeather: {
        denoviNaNaplata: null,
        denoviNaIsplata: null,
        brzaLikvidnost: null,
        zadolzenost: null,
        Ocena: null,
        periodVrakanjeKrediti: null,
      },
      company: '',
      notificationDraft: false,
      openBuyDialog: false,
      PIN: '',
      VAT: '',
      Company_name: '',
      BuyStatusDialog: false,
      BuyStatusMessage: '',
      SyncNotFoundDialog: false,

  }

    this.scroll = this.scroll.bind(this);
    this.box = React.createRef();
  }

  componentDidMount(){
    this.loadDashboard();
    this.renderData();
  }

  successImport = () => {
    this.setState({successImport: true})
  }

  scroll() {
    console.log(this.box.current)
    window.scrollBy(0, 100);
    this.box.current.scrollBy({top:'100px', behavior: "smooth"});
  }

  syncReport = (id) => {
    if(id){
      window.makeRequest("PUT", `/api/Finance/Sync?id=${id}`, null, true, (data) => {
        if(data.body && data.body.hasOwnProperty('Response')){
          if(data.body.Response.ReasonPhrase === 'OK'){
            this.loadDashboard();
            this.renderData();
          }
          if(data.body.Response.ReasonPhrase === 'Not Found'){
            this.setState({SyncNotFoundDialog: true})
          }
        }

      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      });
    }
  }


  reverse = (array) => {
    let output = [];
    for (let i = array.length - 1; i> -1; i--){
      output.push(array[i]);
    }

    return output;
  }

  loadDashboard = () => {
    window.makeRequest("GET", `/api/Company?ID=${this.props.match.params.id}`, null, true, (callback) => {
      if(callback.body && callback.body.hasOwnProperty('ID') > 0) {
        this.setState({
          PIN: callback.body.PIN,
          VAT: callback.body.VAT,
          Company_name: callback.body.Company_name,
          company: callback.body,
        });
        let company = callback.body;
        let embs = company.PIN;


        this.getOcupation(embs);
        window.makeRequest("GET", `/api/ChartData?companyID=${this.props.match.params.id}`, null, true, (callback1) => {
          let dataCallback = callback1.body;
          if(callback1.body && callback1.body.length > 0) {
            let calbackData = callback1.body;
            let reverseCallback = this.reverse(calbackData);
            let displayWeather = callback1.body[callback1.body.length - 1];
            let callbackData = callback1.body;
            this.setState({company: company,  dataReverse: reverseCallback})

          }
          else{
            this.setState({data: [], loading: false})
          }
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        });
        window.makeRequest("GET", `/api/Finance?companyID=${this.props.match.params.id}&getCompanyReports=true`, null, true, (callback2) => {
          if(callback2.body && callback2.body.length > 0) {
            this.setState({data2: callback2.body, loading: false, notificationDraft: true})
          }
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        });
        window.makeRequest("GET", `/api/ChartData/getImagesData?companyID=${this.props.match.params.id}&getImageData=true`, null, true, (callback3) => {
          if(callback3.body) {


            console.log('displayWeather',  callback3.body.dataImages)
            this.setState({dataImages: callback3.body.dataImages, displayWeather: callback3.body.dataImages, data: callback3.body.chartData, loading: false})
          }
          else{
            this.setState({displayWeather: [], dataImages: {}, data: [], loading: false})
          }
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        });

      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }


  handleDownload = (id) => {
    fetch(`/api/ExportFinance?id=${id}`,{
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer '+ window.localStorage.getItem('token'),
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }),

    })
      .then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = "Izvestaj.xls";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
  };


  // excluded React component syntax...

  scrollToSection = () => {
    console.log("da tuka sum");
    window.scrollBy({top: 100, behavior: 'smooth'})
  };

  getOcupation = (embs) => {
    request
      .get(`/api/Subjekt/GetSubjektiWithGradAndDejnost?embsEdb=${embs}`)
      .withCredentials()
      .auth('tgmisajlovska', '123456789')
      .then((callback) => {
        this.setState({
          dejnost: callback.body.GlavnaPrihodnaSifra + " " + callback.body.PrioritetnaDejnost,
        })
      })
      .catch((e) => console.log(e));
  }

  closeWeatherDialog = () => {
    this.setState({openWeatherLegendDialog: false})
  };

 handleSelectAsDefault = (financeID, Year) => {
   window.makeRequest("PUT", `/api/Finance/Default?id=${financeID}&Year=${Year}&companyID=${this.props.match.params.id}&set_unset=true`, {
   }, true, (callback) => {
     this.loadDashboard();
     this.renderData();
   }, (err) => {
     if(err) {
       console.log('err', err);

     }
   });
 };

  // handleUnsetDefault = (financeID, Year) => {
  //   window.makeRequest("PUT", `/api/Finance/Default?id=${financeID}&Year=${Year}&companyID=${this.props.match.params.id}&set_unset=false`, {
  //   }, true, (callback) => {
  //     this.renderData();
  //   }, (err) => {
  //     if(err) {
  //       console.log('err', err);
  //     }
  //   });
  // };


  closeConfirmDelete = () => {
    this.setState({deleteID: null, openConfirmDelete: false})
  };

  openConfirmDeleteForm = (id) => {
    this.setState({deleteID: id, openConfirmDelete: true})
  };

  deleteFinanceSuccess = (id) => {
    this.setState({data: [], data2: [],dataReverse: []})
    window.makeRequest("DEL", `/api/Finance?id=${id}`, false, true, (callback) => {
      this.setState({
        deleteID: null,
        openConfirmDelete: false,
      }, this.loadDashboard)
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  };



  renderData = () => {
    // window.makeRequest("GET", `/api/ChartData?companyID=${this.props.match.params.id}`, null, true, (callback) => {
    //
    //     this.setState({ data: callback.body})
    //
    // }, (err) => {
    //   if (err) {
    //     console.log('err', err);
    //   }
    // });

    // window.makeRequest("GET", `/api/Finance?companyID=${this.props.match.params.id}&getCompanyReports=true`, null, true, (data) => {
    //     this.setState({data2: data.body})
    //   if(data.body.length > 0){
    //       this.setState({notificationDraft: true})
    //   }
    // }, (err) => {
    //   if (err) {
    //     console.log('err', err);
    //   }
    // });
    //
    // window.makeRequest("GET", `/api/ChartData/getImagesData?companyID=${this.props.match.params.id}&getImageData=true`, null, true, (callback3) => {
    //   if(callback3.body.noData === false) {
    //     this.setState({dataImages: callback3.body, noData: false, loading: false})
    //   }
    //   else{
    //     this.setState({dataImages: {}, noData: true, loading: false})
    //   }
    // }, (err) => {
    //   if (err) {
    //     console.log('err', err);
    //   }
    // });
  };

  handleChangeTable = () => {

    this.setState({showDrafts: !this.state.showDrafts})
    this.scroll();

     //this.scrollToSection();
  };

  openBuyDialog = () => {
    this.setState({openBuyDialog: true})
  }
  closeBuyDialog = (status) => {
    if(status === 'OK') {
      this.setState({openBuyDialog: false, BuyStatusDialog: true, BuyStatusMessage: 'accepted'})
    }else if(status === 'close'){
      this.setState({openBuyDialog: false, })
    }
    else if(status === 'Found'){
      this.setState({openBuyDialog: false, BuyStatusDialog: true, BuyStatusMessage: 'found'})
    }else {
      this.setState({openBuyDialog: false, BuyStatusDialog: true, BuyStatusMessage: 'rejected'})
    }
  }

  closeBuyStatusDialog = () => {
    this.setState({BuyStatusDialog: false, BuyStatusMessage: ''})
  }

  showLoading = () => {
    this.setState({loading: true}, () => this.loadDashboard())
  }

  stopLoading = () => {
    this.setState({loading: false})
  }


  openWeatherLegend = () => {
    this.setState({openWeatherLegendDialog: true})
  }

  returnChart = () => {
    if(this.state.loading === false)  {
      return(
        <div ref={this.box}>
          <PageTitleWrapper>
            <PageHeader company={this.state.company} open={this.openWeatherLegend}/>
          </PageTitleWrapper>
          <Grid
            sx={{ px: 4 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
              <Grid item xs={12} display={'flex'}>
                <Button onClick={this.openBuyDialog} variant={'contained'} size={'small'} style={{marginRight: '12px'}}>
                  Купи
                </Button>
                <Import loading={this.showLoading} embs={this.state.PIN} stoploading={this.stopLoading} id={this.props.match.params.id} successImport={this.successImport}/>
                <Button component={RouterLink}
                        to={'/add/report/' + this.props.match.params.id}
                        color={'primary'}
                        variant={'contained'}
                        size={'small'}
                        sx={{ml: 1.4}}
                >

                  Додади извештај
                </Button>
              </Grid>

              <Grid item xs={12} display={'flex'}>
                <Typography variant="subtitle3" gutterBottom>
                  {'Знаците секогаш ги прикажуваат вредностите за последната внесена година согласно избраниот тип на примарен извештај. Графиконите ги прикажуваат износите и пресметките од избраните примарни извештаи\n'}
                </Typography>
              </Grid>

            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
              >
                <AllWeatherCards displayWeather={this.state.displayWeather}/>
              </Grid>
            </Grid>

            <Grid item xl={4} xs={4}>
              <Chart type={1} data={this.state.data ? this.state.data.slice(-5) : []} prosecni={false}/>
            </Grid  >
            <Grid item  xl={4} xs={4}>
              <Chart type={2} data={this.state.data ? this.state.data.slice(-5) : []} prosecni={false}/>
            </Grid>
            <Grid item xl={4} xs={4}>
              <Chart type={3} data={this.state.data ? this.state.data.slice(-5) : []} prosecni={false}/>
            </Grid>


            <Grid item xs={12}>
            {/*{*/}
              {/*!this.state.showDrafts && this.state.notificationDraft?*/}

                {/*!this.state.showDrafts && !this.state.notificationDraft?*/}
                  {/*<Button*/}
                    {/*variant={'contained'}*/}
                    {/*size={'small'}*/}
                    {/*sx={{ml: 1.4}}*/}
                    {/*onClick={this.handleChangeTable}*/}
                  {/*>*/}
                    {/*Во изработка*/}
                  {/*</Button>*/}
                  {/*:*/}
                  {/*< Button*/}
                    {/*variant={'contained'}*/}
                    {/*size={'small'}*/}
                    {/*sx={{ml: 1.4}}*/}
                    {/*onClick={this.handleChangeTable}*/}
                  {/*>*/}
                    {/*Изработени*/}
                  {/*</Button>*/}
                {/*:*/}
                {/*<Button*/}
                  {/*variant={'contained'}*/}
                  {/*size={'small'}*/}
                  {/*sx={{ml: 1.4}}*/}
                  {/*onClick={this.handleChangeTable}*/}
                {/*>*/}
                  {/*Во изработка ({this.state.data2.length})*/}
                {/*</Button>*/}
            {/*}*/}
              {
                this.state.showDrafts ?
                  <Button
                    variant={'contained'}
                    size={'small'}
                    sx={{ml: 1.4}}
                    onClick={this.handleChangeTable}
                  >
                    Во изработка ({this.state.data2.length})
                  </Button>
                  :
                  < Button
                    variant={'contained'}
                    size={'small'}
                    sx={{ml: 1.4}}
                    onClick={this.handleChangeTable}
                  >
                    Изработени
                  </Button>
              }
            </Grid>

            {
              this.state.showDrafts === false ?
            <Grid item xs={12}>
              <div className={'scroll'}>
              <DataGridDashboard data={this.state.dataReverse} columns={this.state.columns} noRowsMessage='Не се внесени извештаи'
              onRowClick={(params, event) => {
                if(!event.ignore){
                  window.location.href = `/view/report/` + params.row.id
                }
              }}/>
              </div>
            </Grid>
                :
            <Grid item xs={12}>
              <div className={'scroll'}>
              <DataGridDashboard data={this.state.data2} columns={this.state.columns2} noRowsMessage='Не се внесени извештаи'
                onRowClick={(params, event) => {
                  if(!event.ignore){
                    window.location.href = '/edit/report/' + params.row.id
                  }
                }} />
              </div>
            </Grid>
            }
            <Grid item lg={4} xs={4}>
              <BottomCards company={this.state.company} dejnost={this.state.dejnost} data={this.state.dataImages} type={'location'} />
            </Grid>
            <Grid item lg={4} xs={4}>
              <BottomCards company={this.state.company} data={this.state.dataImages} noData={this.state.noData} type={'warning'} />
            </Grid>
            <Grid item lg={4} xs={4}>
              <BottomCards company={this.state.company} data={this.state.dataImages} type={'status'} />
            </Grid>
          </Grid>

          <Footer />


          <DialogWrapper
            open={this.state.openConfirmDelete}
            maxWidth="sm"
            fullWidth
            keepMounted
            onClose={this.closeConfirmDelete}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p={5}
            >
              <AvatarError>
                <CloseIcon />
              </AvatarError>

              <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
                {('Дали сте сигурни дека сакате да го избришете извештајот')}
                ?
              </Typography>

              <Box>
                <Button
                  variant="text"
                  size="large"
                  sx={{ mx: 1 }}
                  onClick={this.closeConfirmDelete}
                >
                  {('Откажи')}
                </Button>
                <ButtonError
                  onClick={() => this.deleteFinanceSuccess(this.state.deleteID)}
                  size="large"
                  sx={{ mx: 1, px: 3 }}
                  variant="contained"
                >
                  {('Избриши')}
                </ButtonError>
              </Box>
            </Box>
          </DialogWrapper>
          <BuyDialog Company_name={this.state.Company_name} VAT={this.state.VAT} PIN={this.state.PIN} openBuyDialog={this.state.openBuyDialog} Establishment_year={this.state.company.Establishment_year} onClose={this.closeBuyDialog}/>
          <BuyStatusDialog  open={this.state.BuyStatusDialog} message={this.state.BuyStatusMessage} onClose={this.closeBuyStatusDialog} />
          <BuyStatusDialog  open={this.state.SyncNotFoundDialog} type={'error'} message={'sync'} onClose={() => this.setState({SyncNotFoundDialog: false})} />
          <WeatherLegendDialog open={this.state.openWeatherLegendDialog} onClose={this.closeWeatherDialog} />

          {
            this.state.successImport && <DialogWrapper
              open={this.state.successImport}
              keepMounted
              onClose={this.props.onClose}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                height={'400px'}
                width={'600px'}
                borderRadius={1}
                // p={2}
                // style={{
                //   backgroundColor: '#DADADA',
                // }}
              >
                <Box width='90%' height='70%' style={{textAlign: 'center', lineHeight: '50px'}}>
                  <Typography align="center" style={{ paddingBottom: '70px', color: 'blue'}} variant="h3">
                    {('Успешно импортиран документ' )}
                  </Typography>
                  <Typography align="center" style={{ paddingBottom: '80px', color: 'blue'}} variant="h4">
                    {('Документот ќе биде превземен за обработка од нашите администратори. За проверка на статусот на извештајот можете да пристапите до Мој Профил - Импорти' )}
                  </Typography>
                  <Typography align="right">
                    <Button
                      sx={{ mt: { xs: 2, sm: 0 } }}
                      onClick={()=> this.setState({successImport: false})}
                      variant="contained"
                    >
                      {('Во ред')}
                    </Button>
                  </Typography>
                </Box>

              </Box>
            </DialogWrapper>
          }


        </div>
      )
    } else {
      return <SuspenseLoader/>;
    }
  }


  render(){
    return this.returnChart();
  }

}

export default withRouter(CompanyDashboard);
