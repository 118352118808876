import React from 'react';
import request from 'superagent';
import SuspenseLoader from 'src/components/SuspenseLoader';

import {
  Grid,
  Dialog,
  Alert,
  Box,
  TextField,DialogTitle,
  DialogActions,
  DialogContent,
  Autocomplete,
  Typography,  Button
} from '@mui/material';

class BuyDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      files: '',
      year: '',
      loading: false,
      error: '',
    }

    if(this.props.companyID){
      window.makeRequest("GET", `/api/Company?id=${this.props.companyID}`, false, true, (callback) => {
       this.setState({PIN: callback.body.PIN, VAT: callback.body.VAT, Company_name: callback.body.Company_name, Establishment_year: callback.body.Establishment_year})
      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      });
    }

  }


  componentDidMount(){

  }


  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.companyID != this.props.companyID){
      window.makeRequest("GET", `/api/Company?id=${nextProps.companyID}`, false, true, (callback) => {
        this.setState({PIN: callback.body.PIN, VAT: callback.body.VAT, Company_name: callback.body.Company_name, Establishment_year: callback.body.Establishment_year})
      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      });

    }
    return true;
  }


  handleBuyReport = () => {

    let checkForEstablishment = false;
    if(this.props.companyID){
      if(this.state.year < this.state.Establishment_year){
        checkForEstablishment = true;
      }
    }
    else {
      if(this.state.year < this.props.Establishment_year){
        checkForEstablishment = true;
      }
    }


    if(this.state.year !== '') {
      if(!checkForEstablishment) {
        if (this.state.year < parseInt(new Date().getFullYear())) {
          if (this.state.year >= 2016) {
            this.setState({loading: true});
            window.makeRequest("POST", `/api/ReportOrders`, {
              VAT: this.props.companyID ? this.state.VAT : this.props.VAT,
              PIN: this.props.companyID ? this.state.PIN : this.props.PIN,
              Year: this.state.year,
              Company_name: this.props.companyID ? this.state.Company_name : this.props.Company_name,
            }, true, (callback) => {
              if (callback.body.Response.ReasonPhrase === 'OK') {
                this.setState({loading: false, year: ''}, () => this.props.onClose('OK'))
                window.isTokenValid();
              } else if (callback.body.Response.ReasonPhrase === 'Found') {
                this.setState({loading: false, year: ''}, () => this.props.onClose('Found'))
              } else {
                this.setState({loading: false, year: ''}, () => this.props.onClose('Denied'))
              }
            }, (err) => {
              if (err) {
                console.log('err', err);
              }
            });
            this.setState({error: '', loading: false})
          }
          else {
            this.setState({error: 'Не е достапен извештај за бараната година'});
          }
        } else {
          this.setState({error: 'Годината не смее да биде поголема од ' + (new Date().getFullYear() - 1).toString()})
        }

      }
      else {
        this.setState({error: 'Годината не смее да биде помала од годината на основање'});
      }
    }

    else
      {
      this.setState({error: 'Годината не смее да биде празно поле.'})
    }
  };

  handleReportYear = (e, v) => {
    if(parseInt(e.target.value)){
      this.setState({year: parseInt(e.target.value)});
    }else if(e.target.value === ''){
      this.setState({year: ''});
    }
  };


  render(){
    console.log('ID from buy',this.props.companyID);
    if(this.state.loading) {
      return <SuspenseLoader/>
    }else{
      return(
        <Dialog
          fullWidth
          open={this.props.openBuyDialog}
          onClose={() => this.setState({year: '', error: ''}, this.props.onClose('close'))}
        >
          <Box >
            <DialogTitle sx={{ p: 3, m: 5 }}>
              <Typography variant="h4" gutterBottom align='center' style={{color:'blue'}}>
                {'Купи извештај'}
              </Typography>

              <Typography variant="h4" gutterBottom align='center' style={{color:'blue', marginTop: '20px'}}>
                {'Внесете година на извештај што сакате да го купите.'}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ p: 3, mb: 4 }}>
              <Grid
                item
                xs={12}
              >
                <TextField
                  onChange={this.handleReportYear}
                  variant="outlined"
                  value={this.state.year}
                  fullWidth
                  placeholder={('Внесете година')}
                />
              </Grid>
              {this.state.error && <Alert severity="error" sx={{ mt: 1 }}>{this.state.error}</Alert>}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button color="secondary" onClick={() => this.setState({year: '', error: ''}, this.props.onClose('close'))}>
                {'Откажи'}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={this.handleBuyReport}
              >
                {'Купи извештај'}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )
    }

  }
}


export default BuyDialog;
