import React from 'react';
import SuspenseLoader from '../../../../../components/SuspenseLoader'
import { withRouter } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Subscription from './Subscription';
import Transactions from './Transactions';
import Users from './Users';
import Companies from './Companies';
import ReportOrders from './ReportOrders';
import CurrentBasket from './CurrentBasket';


const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);
class SingleClients extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      tabs: [
        {
          value: 'Претплата',
          label: 'Претплата'
        },
        {
          value: 'Трансакции',
          label: 'Трансакции'
        },
        {
          value: 'Извештаи',
          label: 'Извештаи'
        },
        {
          value: 'Моментална кошничка',
          label: 'Моментална кошничка'
        },
        {
          value: 'Корисници',
          label: 'Корисници'
        },
        {
          value: 'Компании',
          label: 'Компании'
        },

      ],
      selectedTab: 'Претплата',
      SubscriptionList: [],
      UserList: [],
      CompanyList: [],
      DeleveredReports: [],
      CurrentBasket: [],
      totalPriceBasket: '',
    }
  }

  componentDidMount(){
    this.getUserSubscription();
    this.getAllClientUsers();
    this.getAllCompanies();
    this.getAllDeleveredReports();
    this.getCurrentBasket();
  }


  getUserSubscription = () => {
    window.makeRequest("GET", `/api/Admin_SubscriptionClients?ID=${this.props.match.params.id}&Transactions=false`, false, true, (callback) => {
      let SubscriptionList = callback.body;
      window.makeRequest("GET", `/api/Admin_SubscriptionClients?ID=${this.props.match.params.id}&Transactions=true`, false, true, (callback) => {
        this.setState({SubscriptionList: SubscriptionList, TransactionList: callback.body, loading: false});

      }, (err) => {
        if(err) {
          console.log(err);
        }
      });
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  getAllClientUsers = () => {
    window.makeRequest("GET", `/api/Admin_Users?clientID=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({UserList: callback.body})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  getAllCompanies = () => {
    window.makeRequest("GET", `/api/Admin_Companies?clientID=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({CompanyList: callback.body})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  getAllDeleveredReports = () => {
    window.makeRequest("GET", `/api/Admin_ReportOrders?clientID=${this.props.match.params.id}&getBought=true`, false, true, (callback) => {
      this.setState({DeleveredReports: callback.body})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  getCurrentBasket = () => {
    window.makeRequest("GET", `/api/Admin_ReportOrders?clientID=${this.props.match.params.id}&getBasket=true`, false, true, (callback) => {
      this.setState({CurrentBasket: callback.body.orders, totalPriceBasket: callback.body.totalPrice})
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };


  handleTabsChange = (event, tabsValue) => {
    this.setState({selectedTab: tabsValue});
  };

  returnTab = () => {
    let showTab = this.state.selectedTab;
    if (showTab === 'Претплата') {
      return <Subscription SubscriptionList={this.state.SubscriptionList} />;
    }
    if (showTab === 'Трансакции') {
      return <Transactions TransactionList={this.state.TransactionList} />;
    }
    if(showTab === 'Корисници'){
      return <Users UserList={this.state.UserList} />
    }
    if(showTab === 'Компании'){
      return <Companies CompanyList={this.state.CompanyList} />
    }
    if(showTab === 'Извештаи'){
      return <ReportOrders  DeleveredOrders={this.state.DeleveredReports}/>
    }
    if(showTab === 'Моментална кошничка'){
      return <CurrentBasket CurrentBasket={this.state.CurrentBasket} totalPrice={this.state.totalPriceBasket}/>
    }


  };

  render() {
    if(this.state.loading){
      return <SuspenseLoader />
    } else {
      return <>
        <RootWrapper sx={{p: 3}}>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{xs: 'column', sm: 'row'}}
            justifyContent={{xs: 'center', sm: 'space-between'}}
            pb={3}
          >
            <TabsWrapper
              onChange={this.handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={this.state.selectedTab}
              variant="scrollable"
            >
              {this.state.tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label}/>
              ))}
            </TabsWrapper>
          </Box>
          <Card>
            <Divider/>
            <>
              {this.returnTab()}
            </>
          </Card>

        </RootWrapper>
      </>
    }
  }
}

export default withRouter(SingleClients);
