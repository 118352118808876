import React from 'react';
import ViewResultReport from './ViewResultReport';
import {withRouter} from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material/index';
import { styled } from '@mui/material/styles/index';
import Footer from 'src/components/Footer';

import {
  Grid,
  Typography,
  Button,
} from '@mui/material';


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

class ViewReport extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      finance: {},
      financeLastYear: null,
      company: {},
      report: {},
      indicators: {},

    }

  }


  componentDidMount()
  {


    window.makeRequest("GET", `/api/Finance/single?id=${this.props.match.params.id}&company=null`, false, true, (callback) => {
      this.setState({ finance: callback.body });
      window.makeRequest("GET", `/api/Company?id=${callback.body.Company_ID}`, false, true, (callback1) => {
        this.setState({ company: callback1.body });
        window.makeRequest("GET", `/api/Indicator?id=${this.props.match.params.id}`, false, true, (callback2) => {
          this.setState({ indicators: callback2.body })

        }, (err) => {
          if (err) {
            console.log('err', err);

          }
        });
      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });
      window.makeRequest("GET", `/api/Finance/single?id=null&Year=${callback.body.Godina - 1}&reportTypeID=${callback.body.Report_type_ID}&companyID=${callback.body.Company_ID}`, false, true, (callback2) => {
        if(callback2){
          this.setState({ financeLastYear: callback2.body })

        }

      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });



    }, (err) => {
      if (err) {
        console.log('err', err);

      }
    });



  }

  returnReportType = (type) => {

    if(type === 2){
      return 'Годишен извештај';
    }
    else if(type === 3){
      return 'Годишен извештај консолидиран';
    }
    else if(type === 4){
      return 'Ревизорски извештај';
    }
    else if(type === 5){
      return 'Ревизорски извештај консолидиран';
    }

    return '';


  }

  render(){
    return(
      <div>

        <RootWrapper sx={{p: 5}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h3" component="h3" gutterBottom>
                    {"ЕМБС: " + this.state.company.PIN}
                  </Typography>
                  <Typography variant="h3">
                    {"ГОДИНА: " + this.state.finance.Godina}
                  </Typography>
                  <Typography variant="h3">
                    {"ТИП НА ИЗВЕШТАЈ: " + this.returnReportType(this.state.finance.Report_type_ID)}
                    {/*{this.state.finance.Report_type_ID === 2 && 'Годишен извештај'}*/}
                    {/*{"ТИП НА ИЗВЕШТАЈ: " + this.state.finance.Report_type_ID === 2?'Годишен извештај':this.state.finance.Report_type_ID === 3?'Годишен извештај консолидиран':this.state.finance.Report_type_ID === 4?'Ревизорски извештај':this.state.finance.Report_type_ID === 5?'Ревизорски извештај консолидиран':'blabla'}*/}
                  </Typography>
                  <Typography variant="subtitle" gutterBottom>
                    {this.state.company.Company_name}
                  </Typography>

                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: { xs: 2, sm: 0 }, mr: 1 }}
                component={RouterLink}
                size='small'
                to={`/company/${this.state.company.ID}/dashboard`}
                onClick={this.handleAddUserOpen}
                variant="contained"
              >
                {('Откажи')}
              </Button>
            </Grid>
          </Grid>
        </RootWrapper>

        <Grid
          sx={{px: 4}}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <ViewResultReport finance={this.state.finance} financeLastYear={this.state.financeLastYear} companyID={this.state.company.ID} indicators={this.state.indicators}/>
          </Grid>
        </Grid>
        <Footer/>


      </div>
    )
  }

}

export default withRouter(ViewReport);
