import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import PlanCard from '../pages/Subscription/PlanCard';
import HomeHeader from '../../components_bi/HomeHeader/index'
import HomeFooter from '../../components_bi/HomeFooter/index';

import {
  Grid,
} from '@mui/material';
import Wizard from '../pages/Auth/Register/Wizard';
import {styled} from "@mui/material/styles/index";
import {Box} from "@mui/material/index";

const StyledBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.common.white};
    height: 100%;
`
);

class Offer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      SubscriptionPeriod: 'monthly',
      subscriptionPlan: [],
      goToRegister: false
    }
  }


  componentDidMount() {
    this.getSubscriptionPlan();
  }



  getSubscriptionPlan = () => {
    window.makeRequest("GET", `/api/Subscription`, false, true, (callback) => {
      if(callback.body){
        this.setState({subscriptionPlan: callback.body})
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }

  selectPlan = (e, plan) => {
    this.setState({isPlanSelected: true,selectedPlanID: plan.ID, goToRegister: true})
  }
  selectAdditionalPlan = (Users, Divisions) => {
    if(Users){
      this.setState({additionalUsers: Users})
    }
    if(Users){
      this.setState({additionalDevision: Divisions})
    }

  }
  changeSubscriptionPeriod = (e) => {
    this.setState({SubscriptionPeriod: e})
  }

  render(){
    return(
      <>
        <StyledBox>
          <HomeHeader />

        <Grid container
              sx={{width: '80%', margin: 'auto'}}
        >
          {
            this.state.goToRegister ?
            <Wizard firstStep/>
            : <PlanCard SubscriptionPeriod={this.state.SubscriptionPeriod} changeSubscriptionPeriod={this.changeSubscriptionPeriod}  subscriptionPlan={this.state.subscriptionPlan} onClick={this.selectPlan}/>
          }
        </Grid>

          <HomeFooter />
        </StyledBox>
    </>
    )
  }
}

export default Offer;
