import { Link, Box, Card, Grid, styled, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Text from 'src/components/Text';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import Label from '../../../../../components/Label'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import SecurityIcon from '@mui/icons-material/Security';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import PublicIcon from '@mui/icons-material/Public';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import GridViewIcon from '@mui/icons-material/GridView';
import MonitorIcon from '@mui/icons-material/Monitor';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import SetMealIcon from '@mui/icons-material/SetMeal';
import IronIcon from '@mui/icons-material/Iron';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Zemjodelstvo from '../../../../../components_bi/Images/Zemjodelstvo.png';
import AdministrativnaIndustrija from '../../../../../components_bi/Images/Administrativna industrija.png';
import Drugo from '../../../../../components_bi/Images/Drugo.png';
import Gradeznistvo from '../../../../../components_bi/Images/Gradeznistvo.png';
import InformaciiIKomunikacii from '../../../../../components_bi/Images/Informacii i komunikacii.png';
import PrerabotuvackaIndustrija from '../../../../../components_bi/Images/Prerabotuvacka Industrija.png';
import Proizvodstvo from '../../../../../components_bi/Images/Proizvodstvo.png';
import Ribarstvo from '../../../../../components_bi/Images/Ribarstvo.png';
import Rudarstvo from '../../../../../components_bi/Images/Rudarstvo.png';
import Trgovija from '../../../../../components_bi/Images/Trgovija.png';
import ZabavaIRekreacija from '../../../../../components_bi/Images/Zabava i rekreacija.png';
import Zdravstvo from '../../../../../components_bi/Images/Zdrastvena i socijalna zastita.png';


const DividerVertialPrimary = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    background: ${theme.colors.primary.main};
  `
);

const DividerVertialSuccess = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    background: ${theme.colors.success.main};
  `
);

const DividerVertialWarning = styled(Box)(
  ({ theme }) => `
    height: 80%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    background: ${theme.colors.warning.main};
  `
);

const LabelPrimary = styled(Box)(
  ({ theme }) => `
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      background: ${theme.colors.primary.main};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(8)};
      padding: ${theme.spacing(0.5, 1.5)};
      color: ${theme.palette.primary.contrastText};
  `
);

const LabelSuccess = styled(Box)(
  ({ theme }) => `
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      background: ${theme.colors.success.main};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      padding: ${theme.spacing(0.5, 1.5)};
      color: ${theme.palette.success.contrastText};
  `
);

const LabelWarning = styled(Box)(
  ({ theme }) => `
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      background: ${theme.colors.warning.main};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      padding: ${theme.spacing(0.5, 1.5)};
      color: ${theme.palette.warning.contrastText};
  `
);

function IndystryCard(props) {
  const { t } = useTranslation();

  const dejnosti = props.dejnosti;
  const returnKey = (key) => {
    const icon = [];
    const style = `ml: 5,mr: 0.5`
    switch(key){
      case 'Производство':
        icon.push(<img style={{marginLeft: "5px"}} src={Proizvodstvo} alt=""/>);
        break;
      case 'Трговија':
        icon.push(<img style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Trgovija} alt=""/>);
        break;
      case 'Информации и комуникации':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={InformaciiIKomunikacii} alt=""/>);
        break;
      case 'Друго':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Drugo} alt=""/>);
        break;
      case 'Здравство':
        icon.push(<HealthAndSafetyIcon sx={{ml: 0.5,mr: 0.5}} fontSize={'large'}/>);
        break;
      case 'Туристичка агенција':
        icon.push(<PublicIcon sx={{ml: 0.5,mr: 0.5}} fontSize={'large'}/>);
        break;
      case 'Транспорт':
        icon.push(<AirportShuttleIcon sx={{ml: 0.5,mr: 0.5}} fontSize={'large'}/>);
        break;
      case 'Обезбедување':
        icon.push(<SecurityIcon sx={{ml: 0.5,mr: 0.5}} fontSize={'large'}/>);
        break;
      case 'Угостителство':
        icon.push(<LocalDiningIcon sx={{ml: 0.5,mr: 0.5}} fontSize={'large'}/>);
        break;
      case 'Индустрија':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={PrerabotuvackaIndustrija} alt=""/>);
        break;
      case 'Земјоделство':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Zemjodelstvo} alt=""/>);
        break;
      case 'Рибарство':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Ribarstvo} alt=""/>);
        break;
      case 'Руда':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Rudarstvo} alt=""/>);
        break;
      case 'Градежништво':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Gradeznistvo} alt=""/>);
        break;
      case 'Административна дејност':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={AdministrativnaIndustrija} alt=""/>);
        break;
      case 'Здравствена и социјална заштита':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={Zdravstvo} alt=""/>);
        break;
      case 'Забава и рекреација':
        icon.push(<img  style={{marginLeft: "5px", height: "28px", width: "28px"}} src={ZabavaIRekreacija} alt=""/>);
        break;
    }

    return(<>{icon}
    </>);
  }
  const renderDejnosti = () => {
    let boxes = [];
    boxes = Object.keys(props.dejnosti).map((dejnost) => {
      return<Grid item xs={6} md={6}><Box mt={1} display={'flex'} alignItems="center"  >
        <LabelPrimary style={{width: '45%'}}>{dejnost}</LabelPrimary>
        {/*{returnKey(dejnost)}*/}
        <Typography style={{fontSize: 15, fontWeight: "bold"}} sx={{ml:1}}>
          {props.dejnosti[dejnost]}
        </Typography>
      </Box></Grid>
    })


    return boxes;

  }

  return (<>
      <DividerVertialPrimary />
      <Link
        color="text.primary"
        component="a"
        variant="h4"
        fontWeight="normal"
      >
        Индустрија
      </Link>
      <Grid container xs={12}>
        {renderDejnosti()}
      </Grid></>
  );
}

export default IndystryCard;
