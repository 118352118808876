export function CheckPayUrl(type ) {
  let subscriptionType = 'subscriptions';
  let cartType = 'cart';
  let returnType = '';


  if (!type ) {
    returnType = false;
  }

  if(type === 'subscriptions') {
    returnType = '/MyProfile/Subscription'
  }
  if(type === 'cart') {
    returnType = '/cart'
  }

  if(type === subscriptionType || type === cartType){
    return returnType
  }else {
    return false
  }

}

export function isSubscription(subscriptions ) {
  let subscriptionType = 'subscriptions';

  if (!subscriptions ) {
    return false;
  } else {
    return subscriptions === subscriptionType
  }


}
export function isReport(report ) {
  let reportType = 'cart';

  if (!report ) {
    return false;
  } else {
    return report === reportType
  }


}
