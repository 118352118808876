
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Typography, useTheme, Box, alpha, Card } from '@mui/material';
import NotificationImportantTwoToneIcon from '@mui/icons-material/NotificationImportantTwoTone';
import PageHeader from './PageHeader';

function Notification(props) {
  const theme = useTheme();

  return (
    <>
      <Grid item lg={6} md={10} sm={11} xs={12}>
        <Card
          variant="outlined"
          sx={{
            background: alpha(theme.colors.info.main, 0.08),
            display: 'flex',
            alignItems: 'flex-start',
            p: 2
          }}
        >
          <NotificationImportantTwoToneIcon
            sx={{
              mr: 1,
              color: theme.colors.info.main,
              fontSize: theme.typography.pxToRem(22)
            }}
          />
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: theme.typography.pxToRem(13)
              }}
            >
              {props.text}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </>
  )
}

export default Notification;
