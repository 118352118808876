import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(40)};
    color: #4454CC;
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const ImgWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 5;
    width: 100%;
    overflow: hidden;
    border-radius: ${theme.general.borderRadiusLg};
    box-shadow: 0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%);

    img {
      display: block;
      width: 100%;
    }
  `
);

const BoxAccent = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadiusLg};
    background: ${theme.palette.background.default};
    width: 100%;
    height: 100%;
    position: absolute;
    left: -40px;
    bottom: -40px;
    display: block;
    z-index: 4;
  `
);

const BoxContent = styled(Box)(
  () => `
  width: 150%;
  position: relative;
`
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.success.main};
    color: ${theme.palette.success.contrastText};
    font-weight: bold;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    font-size: ${theme.typography.pxToRem(11)};
    padding: ${theme.spacing(.5)} ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(2)};
`
);

const ListItemWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
`
);

const MuiAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #e5f7ff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

const JsAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #fef8d8;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`
);

function Hero() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <h2>Политика за користење Колачиња (Cookies)</h2>

      <p><h4>Што се колачињата (cookies)?</h4>
        Колачињата (cookies) се мали текстуални датотеки кои се сместуваат во Вашиот компјутер или мобилен телефон од страна на веб страните кои ги посетувате. Овие текстуални датотеки можат да бидат прочитани од страна на веб страните кои ги посетувате и да помогнат во Вашата идентификација кога повторно ќе ја отворите веб страната. Овие текстуални датотеки можат да бидат „постојани“ или „привремени“. Постојаните колачиња (cookies) остануваат на Вашиот компјутер и кога ќе го исклучите пребарувачот (ќе преминете во офлајн состојба), додека привремените се бришат штом го затворите интернет пребарувачот. Колачињата (cookies) се користат за повеќе цели, како на пример за да се запомнат Вашите активности и преференции (вклучувања, јазични преференции, големина на фонт или како да Ви се прикажува веб страната), за да се обезбеди персонализирана/приспособена содржина на веб страната и да се прикаже навигациската историја на истата.</p>

      <p><h4>Зошто користиме колачиња (cookies)?</h4>
        На оваа веб страна (платформа) користиме колачиња (cookies) од следниве причини:</p>

      <p>• За техничко функционирање на платформата;<br />
        • За собирање на збирни и анонимни статистички податоци;<br />
        • За да ги запомниме Вашите преференции како да се прикажува страната (преференции за параметрите на екранот), како на пример контрастот на бојата или големината на фонтот;<br />
        • За да запомниме/да се потсетиме, дали се согласувате (или не) со нашето користење на колачиња (cookies) на оваа веб страна.<br /><br/>
        Кога ја посетувате нашата платформа, можеме да собереме некои податоци од Вашето пребарување, како на пример IP адресата, која содржина од платформата www.bonitet.mk сте ја посетиле, кога сте ја посетиле и од која веб страна сте биле пренасочени.<br />
        Кога ја посетувате нашата платформа користиме услуга на трета страна (Google Analytics) да собереме стандардни информации за пристапот и детали за однесувањето на посетителите, но на начин без да може некој да биде идентификуван, ниту е овозможено Google да ги открие идентитетот на оние кои ја посетиле нашата платформа.<br />
        Каков вид на колачиња (cookies) користиме?<br />
        Користиме постојани колачиња (cookies), кои го памтат пребарувањето/сесијата кога корисникот ќе го исклучи пребарувачот, со цел да се задржи состојбата на корисникот.<br />
        Можете ли да ги избришете или контролирате колачињата (cookies)?<br />
        Информациите поврзани со колачињата (cookies) не се користат за Ваше лично идентификување. Прифаќањето на овие колачиња (cookies) не е неопходно за работа на платформата, но истите би Ви овозможиле подобро искуство во пребарувањето. Доколку сакате, овие колачиња (cookies) можете да ги избришете или блокирате, но ако го сторите тоа некои карактеристики на оваа платформа можеби нема да работат правилно.<br />
        Можете да ги избришете сите колачиња (cookies) кои веќе се наоѓаат на Вашиот компјутер и можете да ги подесите поголемиот дел од пребарувачите за нивно спречување. Доколку го сторите ова можеби ќе има потреба рачно да ги прилагодувате преференциите секој пат кога ја посетувате платформата, а исто така можно е да не работат и некои услуги и функционалности.<br />
      </p><br /><br /><br />
    </Container>
  );
}

export default Hero;
