import React from 'react';
import numeral from "numeral";
import TableResults from './TableResults'

import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Container,
  IconButton,
  Tooltip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer
} from '@mui/material';


class Results extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      payment: false,
    }
  }

  componentDidMount(){
  }


  TableFooter = (type) => {
    let TotalAmount = 0;
    if(this.props.TotalAmount) {
      TotalAmount = this.props.TotalAmount;
    }

    return<TableFooter>
      <TableRow>
        <TableCell colSpan={7} align="right">
          <Typography
            gutterBottom
            variant="caption"
            color="text.secondary"
            fontWeight="bold"
          >
            {'Вкупно'}:
          </Typography>
          <Typography variant="h3" fontWeight="bold">
            {numeral((TotalAmount * 1.18)).format(`0,0.00`)+' МКД'}
          </Typography>
        </TableCell>
      </TableRow>
    </TableFooter>;

  }

  cancelPayment = () => {
    window.makeRequest("PUT", `/api/Transactions?transactionID=${this.props.transactionID}`, {
      status: 'canceled'
    }, true, (data) => {
      window.location.href = `/MyProfile/Subscription`
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }

  CheckOutButtons = () => {
    // ѐ
    return<>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography style={{color: 'red'}}>Цените се со вклучен ДДВ</Typography>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Button color="secondary" onClick={this.cancelPayment}>
            {'Откажи'}
          </Button>
          <form id="paymentForm" method="post" action="https://epay.halkbank.mk/fim/est3Dgate">
            <input type="hidden" name="clientid" value={this.props.payment.clientId}/>
            <input type="hidden" name="amount" value={this.props.payment.amount}/>
            <input type="hidden" name="oid" value={this.props.payment.oid}/>
            <input type="hidden" name="okUrl" value={this.props.payment.okUrl}/>
            <input type="hidden" name="failUrl" value={this.props.payment.failUrl}/>
            <input type="hidden" name="islemtipi" value={this.props.payment.transactionType}/>
            <input type="hidden" name="taksit" value={this.props.payment.instalmentCount}/>
            <input type="hidden" name="rnd" value={this.props.payment.rnd}/>
            <input type="hidden" name="hash" value={this.props.payment.hash}/>
            <input type="hidden" name="currency" value={this.props.payment.currency}/>
            <input type="hidden" name="storetype" value="3d_pay_hosting"/>
            <input type="hidden" name="refreshtime" value="10"/>
            <input type="hidden" name="encoding" value="UTF-8" />

            <Button
              type="submit"
              // onClick={() => this.setState({payment: true})}
              variant="contained"
              value="Pay"
            >
              {'Плати'}
            </Button>
          </form>
        </div>
      </div>
    </>
  }

  TableRowBody = (list) => {
    return list && list.map((item) => {
      return<TableRow key={item.ID}>
        <TableCell>
          <Typography >{item.Name.replace("New Subscription ", '').replace("Divisions", "Portfolio")}</Typography>
        </TableCell>
        <TableCell>
          <Typography >{item.Quantity}</Typography>
        </TableCell>
        <TableCell>
          {numeral((item.SinglePrice * 1.18)).format(`0,0.00`)+' МКД'}
        </TableCell>
        <TableCell>
          {numeral((item.TotalPrice * 1.18)).format(`0,0.00`)+' МКД'}
        </TableCell>

      </TableRow>});

  }



  returnTable = () => {

    console.log("props", this.props.payment);

    const TableRowHeader = <TableRow>
      <TableCell>{'Производ'}</TableCell>
      <TableCell>{'Количина'}</TableCell>
      <TableCell>{'Цена'}</TableCell>
      <TableCell>{'Вкупно'}</TableCell>
    </TableRow>;

    return <TableResults TableRow={TableRowHeader} TableRowBody={this.TableRowBody(this.props.transactionLines)} TableFooter={this.TableFooter()} buttons={this.CheckOutButtons()}/>



    if(this.state.payment && this.props.payment) {
        return<form id="paymentForm" method="post" action="https://epay.halkbank.mk/fim/est3Dgate">
          <input type="hidden" name="clientid" value={this.props.payment.clientId}/>
          <input type="hidden" name="amount" value={this.props.payment.amount}/>
          <input type="hidden" name="oid" value={this.props.payment.oid}/>
          <input type="hidden" name="okUrl" value={this.props.payment.okUrl}/>
          <input type="hidden" name="failUrl" value={this.props.payment.failUrl}/>
          <input type="hidden" name="islemtipi" value={this.props.payment.transactionType}/>
          <input type="hidden" name="taksit" value={this.props.payment.instalmentCount}/>
          <input type="hidden" name="rnd" value={this.props.payment.rnd}/>
          <input type="hidden" name="hash" value={this.props.payment.hash}/>
          <input type="hidden" name="currency" value={this.props.payment.currency}/>
          <input type="hidden" name="storetype" value="3d_pay_hosting"/>
          <input type="hidden" name="refreshtime" value="10"/>
          <input type="submit" value="Pay" />
          <input type="hidden" name="encoding" value="UTF-8" />
        </form>
    }










  }


  render() {
    return this.returnTable();
  }
}

export default Results;
