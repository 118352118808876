import {useState} from 'react';
import {
  Box,
  Card,
  Typography,
  Avatar,
  Divider,
  alpha,
  Button,
  styled,
  useTheme
} from '@mui/material';


import Text from 'src/components/Text';

const CardWrapper = styled(Card)(
  ({theme}) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
    transform: scale(1);
    position: relative;
    z-index: 5;
    &:hover {
        border: ${theme.colors.primary.main} solid 1px;
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.01);
    }
  `
);

const getCrossedPrize = (Name) => {
  switch (Name){
    case "Micro":
      return '';
    case "SME":
      return '10680';
    case "Large":
      return '15480';
    case "Enterprise":
      return '22680';
  }
};

const getName = (Name) => {
  switch (Name){
    case "Micro":
      return 'Micro - Standard ' +  <span style={{color: 'red'}}>free</span>;
    case "SME":
      return 'SME - Professional';
    case "Large":
      return 'Large - Professional';
    case "Enterprise":
      return 'Enterprise - Professional';
  }
}

const getColor = (Name) => {

  switch (Name){
    case "Micro":
      return '#a7bed3';
    case "SME":
      return '#c6e2e9';
    case "Large":
      return '#ffcaaf';
    case "Enterprise":
      return '#dab894';
  }
}

const getDescription = (Name) => {
  switch (Name){
    case "Micro":
      return 'Micro го добивате без надомест и тој ги вклучува следните функционалности:.';
    case "SME":
      return 'Овој пакет ги вклучува следните функционалности:';
    case "Large":
      return 'Овој пакет ги вклучува следните функционалности:';
    case "Enterprise":
      return 'Овој пакет ги вклучува следните функционалности:';
  }
};


function SingleCard(props) {
  const theme = useTheme();
  const active = `${theme.colors.primary.main} solid 1px`;
  const ID = props.plan.ID;
  const Name = props.plan.Name;
  const Price = props.SubscriptionPeriod === 'monthly' ? props.plan.Price : props.plan.PriceAnnual;
  const crossedPrize = props.SubscriptionPeriod !== 'monthly' ? getCrossedPrize(Name) : '';
  const Plan = props.plan;
  const SelectedPlan = (e) => {
    props.setSelected(ID, Plan);
  }

  return (<CardWrapper
    style={{border: props.selected === props.plan.ID ? active : false, borderColor: getColor(props.plan.Name)}}
    onClick={SelectedPlan}
    sx={{
      p: 2
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography

        sx={{
          fontSize: `${theme.typography.pxToRem(18)}`,
          color: getColor(props.plan.Name)
        }}
        variant="h3"
      >
        {props.plan.Name === 'Micro' ? <span>Micro - Standard <span style={{color: '#a70000'}}>free</span></span>:getName(props.plan.Name)}
      </Typography>

    </Box>
    <Box
      display="center"
      justifyContent={'center'}
    >
      <Box
        display="block"
        mt={3}
        mb={2}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: `${theme.typography.pxToRem(20)}`
          }}
        >
          <Typography variant={"body"}>{<Text style={{fontSize: '15px'}}><strike>{ crossedPrize? crossedPrize.toLocaleString() + "денари +ддв" : ''} </strike></Text>}</Typography>
          {crossedPrize? <br/> : props.SubscriptionPeriod !== 'monthly' ? <br/> : ''}
          <Text color="">{Price !== 0 ? Price.toLocaleString() + " денари +ддв" : Price.toLocaleString() + " денари"}</Text>
        </Typography>
        <Typography variant="body">
          <Text>{props.SubscriptionPeriod === 'monthly' ? "МЕСЕЧНО" : "ГОДИШНО"}</Text>
        </Typography>
      </Box>

    </Box>
    <Box
      display="center"
      justifyContent={'center'}
      mb={2}
      px={2}
    >
      <Typography variant="body">

        <Text style={{fontSize: '12px'}}>{props.plan.Name !== 'Micro' ? getDescription(Name) : getDescription(Name)}</Text>
      </Typography>
    </Box>
    <Divider/>

    <Box my={2} display="flex" alignItems="center">
      <Avatar
        variant="rounded"
        sx={{
          mr: 1,
          width: 18,
          height: 18,
          p: 0,
          background: getColor(props.plan.Name),
          color: `${theme.palette.getContrastText(
            theme.colors.error.dark
          )}`
        }}
      >
        {`->`}
      </Avatar>
      <Typography variant="h5">
        <Text>Број на корисници {props.plan.Num_Users}</Text>
      </Typography>
    </Box>
    <Box my={2} display="flex" alignItems="center">
      <Avatar
        variant="rounded"
        sx={{
          mr: 1,
          width: 18,
          height: 18,
          p: 0,
          background: getColor(props.plan.Name),
          color: `${theme.palette.getContrastText(
            theme.colors.error.dark
          )}`
        }}
      >
        {`->`}
      </Avatar>
      <Typography variant="h5">
        <Text>Број на портфолија {props.plan.Num_Devisions} </Text>
      </Typography>
    </Box>
    <Box my={2} display="flex" alignItems="center">
      <Avatar
        variant="rounded"
        sx={{
          mr: 1,
          width: 18,
          height: 18,
          p: 0,
          background: getColor(props.plan.Name),
          color: `${theme.palette.getContrastText(
            theme.colors.error.dark
          )}`
        }}
      >
        {`->`}
      </Avatar>
      <Typography variant="h5">
        <Text>Број на внесени компании НЕОГРАНИЧЕНО </Text>
      </Typography>
    </Box>
    <Box my={2} display="flex" alignItems="center">
      <Avatar
        variant="rounded"
        sx={{
          mr: 1,
          width: 18,
          height: 18,
          p: 0,
          background: getColor(props.plan.Name),
          color: `${theme.palette.getContrastText(
            theme.colors.error.dark
          )}`
        }}
      >
        {`->`}
      </Avatar>
      <Typography variant="h5" style={{fontSize: "13px"}}>
        {Price === 0 ?
          <Text>Цена за извештај <br/>{props.plan.Price_Report} денари +ддв</Text>
          : <><Text>Цена за извештај</Text>
            <Text style={{fontSize: "11px"}}>
            {/*<strike> 650 денари +ддв</strike>*/}
            </Text>
            <br/>
              <Text>
          {props.plan.Price_Report + " денари +ддв"}
              </Text>
          </>

        }
      </Typography>
    </Box>
    {/*{props.readOnly === false ?*/}
      {/*<Divider/>*/}
      {/*: ''*/}
    {/*}*/}
    {/*{props.readOnly === false ?*/}
      {/*<Box mt={1} container display={'flex'} justifyContent={'center'}>*/}
        {/*<Button variant={'contained'} style={{width: '100%', background: getColor(props.plan.Name)}}>Избери план</Button>*/}
      {/*</Box> : ''*/}
    {/*}*/}


  </CardWrapper>);

}

export default SingleCard;
