import AddIcon from '@mui/icons-material/Add';
import BallotIcon from '@mui/icons-material/Ballot';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';

const menuItems = [

  {
    heading: '',
    items: [

      {
        name: 'Дома',
        icon: <HomeIcon style={{fontSize: '35px'}}/>,
        link: '/divisions/1'
      },
      {
        name: 'Портфолија',
        icon: <BallotIcon style={{fontSize: '35px'}}/>,
        link: '/AllDivisions'
      },
      {
        name: 'Компании',
        icon: <BusinessIcon style={{fontSize: '35px'}}/>,
        link: '/companies'
      },
      {
        name: 'Корисници',
        icon: <GroupIcon style={{fontSize: '35px'}} />,
        link: '/',
      },
      {
        name: 'Сите корисници',
        icon: <GroupIcon style={{fontSize: '35px'}}/>,
        link: '/AllUsers'
      },
      {
        name: 'Сите клиенти',
        icon: <BusinessIcon style={{fontSize: '35px'}}/>,
        link: '/Clients'
      },
      {
        name: "Сите импорти",
        icon: <BallotIcon style={{fontSize: '35px'}}/>,
        link: '/'
      },
      {
        name: "Претплата",
        icon: <LoyaltyIcon style={{fontSize: '35px'}}/>,
        link: '/Subscriptions'
      },
      {
        name: "Менаџирање на портфолио лимит",
        icon: <ProductionQuantityLimitsIcon style={{fontSize: '35px'}}/>,
        link: '/PortfolioLimit'
      },
      {
        name: "Нарачки",
        icon: <AccountBalanceWalletIcon style={{fontSize: '35px'}}/>,
        link: '/Orders'
      },
      {
        name: "Сите администратори",
        icon: <GroupIcon style={{fontSize: '35px'}}/>,
        link: '/AllAdministrators'
      }

    ]
  },
];

export default menuItems;
