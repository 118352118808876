import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Container,
  Button,
  Card,
  CircularProgress,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Alert,
  Divider,
  Avatar,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {styled} from '@mui/material/styles';
import Logo from 'src/components/LogoSign';
import request from "superagent";
import Steps from './Steps';
import TermsAndConditions from "./TermsAndConditions";
import PlanCard from "../../../Subscription/PlanCard";
import AdditionalPlan from "../../../Subscription/PlanCard/AdditionalPlan";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const MainContent = styled(Box)(
  () => `
    height: 100%;
    overflow: auto;
    flex: 1;
`
);


const AvatarSuccess = styled(Avatar)(
  ({theme}) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      margin-left: auto;
      margin-right: auto;

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SubscriptionCards: true,
      FormRegisterOneForm: false,
      FormRegisterTwoForm: false,
      FormRegisterLastForm: false,
      step: 0,
      Firstname: '',
      Lastname: '',
      Username: '',
      Email: '',
      Password: '',
      Password_confirm: '',
      Telephone: '',
      FirstnameError: '',
      LastnameError: '',
      UsernameError: '',
      EmailError: '',
      PasswordError: '',
      Password_confirmError: '',
      TelephoneError: '',
      isSubmitting: false,
      acceptConditions: false,
      isPlanSelected: false,
      embs: '',
      embsError: '',
      subscriptionPlan: [],
      selectedPlanID: '',
      SubscriptionPeriod: 'monthly',
      planPrice: 0,
      Price_Extra_User: 5,
      Price_Extra_Devision: 5,
      additionalUsers: 0,
      additionalDevision: 0,
      checkboxConditions: false
    }
  }

  componentDidMount() {
    this.getSubscriptionPlan();
    if(this.props.firstStep){
      this.setFirstStep()
    }
  }



    getSubscriptionPlan = () => {
      window.makeRequest("GET", `/api/Subscription`, false, true, (callback) => {
        if(callback.body){
          this.setState({subscriptionPlan: callback.body})
        }
      }, (err) => {
        if(err) {
          console.log('err', err);
        }
      });
    }


  getEstablishmentYear = (VAT) => {
    let arr = VAT.split('');
    let Establishment_year = '';
    if (VAT.indexOf('MK') > -1) {
      if (arr[6] === '0') {
        Establishment_year = '2' + arr[6] + arr[7] + arr[8];
      }
      else {
        Establishment_year = '1' + arr[6] + arr[7] + arr[8];
      }
    }
    else {

      if (arr[4] === '0') {

        Establishment_year = '2' + arr[4] + arr[5] + arr[6];

      }
      else {
        Establishment_year = '1' + arr[4] + arr[5] + arr[6];
      }

    }

    return Establishment_year;

  };

  checkEmail = () => {
    this.state.Email !== '' && window.makeRequest("GET", `/api/Register/CheckEmail?email=${this.state.Email}`, false, true, (callback) => {
      if(callback.body === false) {
        this.setState({EmailError: 'Е-маил адресата е веќе зафатена', errEmail: true,})
      }else {
        this.checkUsername();
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }
  checkUsername = () => {
    this.state.Username !== '' && window.makeRequest("GET", `/api/Register/CheckEmail?username=${this.state.Username}`, false, true, (callback) => {
      if(callback.body === false) {
        this.setState({UsernameError: 'Корисничкото име е веќе зафатено', errUser: true,})
      } else {
        if(this.state.UsernameError === '' && this.state.EmailError === ''){
          this.setState({acceptConditions: true})
        }
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }
  validateUser = () => {
    let Firstname = '';
    let Lastname = '';
    let Email = '';
    let Telephone = '';
    let Password = '';
    let Username = '';
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;


    switch (true) {
      case this.state.Firstname.length < 3:
        Firstname = 'Не смее помалку од три карактери';
        break;
    }
    switch (true) {
      case this.state.Username.length < 3:
        Username = 'Не смее помалку од три карактери';
        break;
    }
    switch (true) {
      case this.state.Lastname.length < 3:
        Lastname = 'Не смее помалку од три карактери';
        break;
    }
    switch (true) {
      case this.state.Email.length < 3:
        Email = 'Недоволен број на карактери';
        break;
      case !regEmail.test(this.state.Email):
        Email = 'Внесете валиден е-маил';
        break;
    }
    switch (true) {
      case this.state.Telephone.length !== 11:
        Telephone = 'Невалиден број';
        break;
      case this.state.Telephone.startsWith('3897') === false && this.state.Telephone.startsWith('3892') === false && this.state.Telephone.startsWith('3893') === false && this.state.Telephone.startsWith('3894') === false:
        Telephone = 'Невалиден број';
        break;
      default:
    }
    switch (true) {
      case this.state.Password.length < 8:
        Password = 'Лозинката мора да содржи 8 или повеке карактери';
        break;
      case this.state.Password !== this.state.Password_confirm:
        Password = 'Не се совпаѓаат лозинките';
        break;
    }


    if(Username || Firstname || Password || Telephone || Email || Lastname){
      this.setState({UsernameError: Username,EmailError: Email, FirstnameError: Firstname, LastnameError: Lastname, TelephoneError: Telephone, PasswordError: Password});
      return false;
    }


    return true;

  };
  createUser = () => {
    const isValid = this.validateUser();
    if(isValid) {
      this.checkEmail();
    }
  };
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value, [e.target.name+'Error']: ''});
  };
  validateCompany = () => {
    let embsError = this.state.embsError;
    switch(true) {
      case this.state.embs === '':
        embsError = 'Ебмс/Едб не може да биде празно полно';
            break;
      case this.state.embs.length !== 7 && this.state.embs.length !== 13 && this.state.embs.length !== 15:
        embsError = 'Невалидно внесување на ембс/едб број';
        break;
    }

    if(embsError !== ''){
      this.setState({embsError: embsError});
      return false;
    }
    return true;

  };
  checkEmbsRequest = () => {
    window.makeRequest("GET", `/api/Register/CheckEmail?edbEmbs=${this.state.embs}`, false, true, (callback) => {
      if(callback.body === false) {
        this.setState({embsError: 'Компанијата е веќе додадена',})
      } else {
        this.setState({isSubmitting: true});
        request
          .get(`/api/Subjekt/GetSubjektiWithGradAndDejnost?embsEdb=${this.state.embs}`)
          .withCredentials()
          .auth('tgmisajlovska', '123456789')
          .then((callback) => {
            if(callback.body === null) {
              this.setState({embsError: 'Компанијата не е пронајдена', isSubmitting: false})
            } else {
              let VAT = callback.body.Edb;
              let PIN = callback.body.Embs;
              let CompanyName = callback.body.CelosenNaziv;
              let Adress = callback.body.Sedishte;
              let City = callback.body.Grad;
              let Occupation = callback.body.OddelSifra;
              let Size = callback.body.GoleminaNaSubjekt.trim();
              let Establishment_year = callback.body.DatumOsnovanje ? new Date(callback.body.DatumOsnovanje).getFullYear() : this.getEstablishmentYear(VAT);

              window.makeRequest("POST", `/api/Register`, {
                VAT: VAT,
                PIN: PIN,
                Company_name: CompanyName,
                Status: "активен",
                Establishment_year: Establishment_year,
                Size: Size,
                Occupation: Occupation,
                Adress: Adress,
                City: City,
                Firstname: this.state.Firstname,
                Lastname: this.state.Lastname,
                Email: this.state.Email,
                Telephone: this.state.Telephone,
                Username: this.state.Username,
                Password: this.state.Password,
                SubscriptionID: this.state.selectedPlanID,
                ExtraUsers: this.state.additionalUsers,
                ExtraDivisions: this.state.additionalDevision,
                SubscriptionPeriod: this.state.SubscriptionPeriod,
              }, true, (callback) => {
                this.setState({FormRegisterLastForm: true, FormRegisterTwoForm: false, step: 3, isSubmitting: false})
              }, (err) => {
                if(err) {
                  console.log('err', err);
                }
              });
            }
          })
          .catch((e) => console.log(e));
      }
    }, (err) => {
      if(err) {
        this.setState({isSubmitting: false});
        console.log('err', err);
      }
    });


  };
  checkEmbs = () => {
    const isValid = this.validateCompany();
    if(isValid) {
      this.checkEmbsRequest();
    }
  };
  changeEmbs = (e) => {
    this.setState({embs: e.target.value, embsError: ''})
  };
  backStep = () => {
    this.setState({FormRegisterOneForm: true, step: 0, FormRegisterTwoForm: false, checkboxConditions: false})
  };
  closeAcceptConditions = () => {
    this.setState({acceptConditions: false,checkboxConditions: false})
  }
  acceptTermsAndConditions = () => {
    this.setState({FormRegisterTwoForm: true, FormRegisterOneForm: false, step: 2, acceptConditions: false})
  }

  selectPlan = (e, plan) => {
    this.setState({isPlanSelected: true,selectedPlanID: plan.ID,})
    this.setState({FormRegisterOneForm: true, SubscriptionCards: false, step: 1})
  }

  setFirstStep = () => {
    this.setState({FormRegisterOneForm: true, SubscriptionCards: false, step: 1})
  }

  selectAdditionalPlan = (Users, Divisions) => {
    if(Users){
      this.setState({additionalUsers: Users})
    }
    if(Users){
      this.setState({additionalDevision: Divisions})
    }

  }
  changeSubscriptionPeriod = (e) => {
    this.setState({SubscriptionPeriod: e})
  }

  goToRegister = () => {
    this.setState({FormRegisterOneForm: true, SubscriptionCards: false, step: 1})
  }

  SubscriptionPlan = () => {
    return<>
      <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={4} mt={3} mb={2}
      >
      <PlanCard SubscriptionPeriod={this.state.SubscriptionPeriod} changeSubscriptionPeriod={this.changeSubscriptionPeriod}  subscriptionPlan={this.state.subscriptionPlan} onClick={this.selectPlan} />
      </Grid>
        {/* <Box px={6}>
          <Divider  />
          <AdditionalPlan selectAdditionalPlan={this.selectAdditionalPlan} planPrice={this.state.planPrice} addUser={this.state.Price_Extra_User} addDivision={this.state.Price_Extra_Devision}  />
          <Divider  />
        </Box> */}
        {/* <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              px={6} mt={3} mb={2}
        >

          <Button component={RouterLink} to={'/Login'}>Веќе сте регистрирани? Најави се</Button>
          <Button disabled={!this.state.isPlanSelected} onClick={() => this.setState({FormRegisterOneForm: true, SubscriptionCards: false, step: 1})}>Следно</Button>
        </Grid> */}
    </>
  };

  checkCondtions = () => {
    this.setState({checkboxConditions: !this.state.checkboxConditions})
  }


  render() {
    return (
      <>
      <MainContent>
        <Container sx={{my: 4}} maxWidth="lg">
          <Logo/>
          <Card sx={{mt: 3, pt: 4}}>

          <Steps step={this.state.step} />
            {this.state.SubscriptionCards &&  this.SubscriptionPlan()}
            {this.state.FormRegisterOneForm &&<>
              <Box px={4}>
                <Typography variant="h3" sx={{mb: 1}}>
                  {'Креирај корисник'}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                >
                  {'Потполнете ги полињата за да регистрирате корисник.'}
                </Typography>
              </Box>
              <Box p={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="Firstname"
                      onChange={this.onChange}
                      value={this.state.Firstname}
                      label={'Име'}
                    />

                    {
                      this.state.FirstnameError? <Alert severity="error" sx={{mt: 1}}>{this.state.FirstnameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="Lastname"
                      onChange={this.onChange}
                      value={this.state.Lastname}
                      label={'Презиме'}
                    />
                    {
                      this.state.LastnameError? <Alert severity="error" sx={{mt: 1}}>{this.state.LastnameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="Username"
                      onChange={this.onChange}
                      value={this.state.Username}
                      label={'Корисничко име'}
                    />

                    {
                      this.state.UsernameError? <Alert severity="error" sx={{mt: 1}}>{this.state.UsernameError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="Email"
                      type={"text"}
                      onChange={this.onChange}
                      value={this.state.Email}
                      label={'Е-маил'}
                    />

                    {
                      this.state.EmailError? <Alert severity="error" sx={{mt: 1}}>{this.state.EmailError}</Alert> : ""
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="Password"
                      type={'password'}
                      onChange={this.onChange}
                      value={this.state.Password}
                      label={'Лозинка'}
                    />

                    {
                      this.state.PasswordError? <Alert severity="error" sx={{mt: 1}}>{this.state.PasswordError}</Alert> : ""
                    }

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="Password_confirm"
                      type={'password'}
                      onChange={this.onChange}
                      value={this.state.Password_confirm}
                      label={'Потврда лозинка'}
                    />
                    {
                      this.state.PasswordError? <Alert severity="error" sx={{mt: 1}}>{this.state.PasswordError}</Alert> : ""
                    }

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneInput
                      onlyCountries={this.state.countries}
                      country={'mk'}
                      masks={{mk: '(..) ...-...'}}
                      placeholder={'+389 xxx xxx xx'}
                      value={this.state.Telephone}
                      onChange={Telephone => this.setState({Telephone})}
                    />

                    {
                      this.state.TelephoneError? <Alert severity="error" sx={{mt: 1}}>{this.state.TelephoneError}</Alert> : ""
                    }

                  </Grid>


                  <Grid item xs={12} container
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                  >
                    <Button variant="contained" onClick={() => this.createUser() }>Следно</Button>

                  </Grid>
                </Grid>
              </Box>
              </>}
            {this.state.FormRegisterTwoForm && <>
              <Box px={4}>
                <Typography variant="h3" sx={{mb: 1}}>
                  {'Регистрирај компанија'}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                >
                  {'Потполнете ги полињата за да регистрирате компанија.'}
                </Typography>
              </Box>
              <Box p={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      name="embs"
                      error={this.state.embsError}
                      helperText={this.state.embsError}
                      onChange={this.changeEmbs}
                      label={'Даночен или матичен број'}
                    />
                    <div onClick={() => window.open('http://biznismreza.mk/')} style={{color: 'blue', cursor: 'pointer'}}>За проверка на ЕМБС/ЕДБ кликнете тука</div>
                  </Grid>

                  <Grid item xs={12}    container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                  >
                    <Button variant="contained" onClick={this.backStep}>Претходно</Button>
                    <Button
                      type="submit"
                      onClick={this.checkEmbs}
                      variant="contained"
                      disabled={this.state.isSubmitting}
                      startIcon={
                        this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                    >
                      {'Следно'}
                    </Button>


                  </Grid>
                </Grid>

              </Box>


            </>}
            {this.state.FormRegisterLastForm && <>
              <Box px={4} py={8}>
                <AvatarSuccess>
                  <CheckTwoToneIcon/>
                </AvatarSuccess>
                <Alert
                  sx={{mt: 5}}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                    >
                      <CloseIcon fontSize="inherit"/>
                    </IconButton>
                  }
                  severity="info"
                >
                  {
                    'Испратена е e-mail порака на Вашата адреса!'
                  }
                </Alert>

                <Typography
                  align="center"
                  sx={{pt: 5, pb: 4, lineHeight: 1.5, px: 10}}
                  variant="h2"
                >
                  {
                    'Пристапете до линкот на Вашата адреса за да можете да го користете Вашиот кориснички профил.'
                  }
                </Typography>

                <Button fullWidth
                        component={RouterLink} to="/Login"
                        variant="contained" >
                  Продолжете со Најава
                </Button>
              </Box>
            </>}
            {this.state.acceptConditions && <TermsAndConditions checkCondtions={this.checkCondtions} checkboxConditions={this.state.checkboxConditions} nextStepAcceptConditions={this.acceptTermsAndConditions} onClose={this.closeAcceptConditions} acceptConditions={this.state.acceptConditions} />}
          </Card>
        </Container>
  </MainContent>
  </>
  );
  }

}

export default Wizard;
