import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Button
} from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Label from '../../../../../components/Label';


class ImportResultDialog extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      report: ''
    }
  }

  componentDidMount(){

  }

  returnSuccessMessages = () => {
    let ReturnValue = [];
    const Success = this.props.success;
    if(Success.length > 0){
      ReturnValue = Success.map((row) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxIcon size='small' style={{marginRight: '10px'}}  /><Label>Успешно импортиранa компанија со ембс/ембг {row}</Label>
        </Grid>
      })
    }
    return ReturnValue;

  }

  returnExistsMessages = () => {
    let ReturnValue = [];
    const Exists = this.props.duplicates;
    if(Exists.length > 0){
      ReturnValue = Exists.map((row) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label >Компанијата е веќе внесена во дивизијата {row}.</Label>
        </Grid>
      })
    }
    return ReturnValue;

  }


  returnNotValidMessages = () => {
    let ReturnValue = [];
    const InvalidCompany = this.props.notAddedCompany;
    if(InvalidCompany.length > 0){
      ReturnValue = InvalidCompany.map((row, i) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label>Компанијата е недостапна во вашето портфолио.</Label>
        </Grid>
      })
    }
    return ReturnValue;
  }

  returnNotValidEmbs = () => {
    let ReturnValue = [];
    const InvalidEMBS = this.props.error;
    if(InvalidEMBS.length > 0){
      ReturnValue = InvalidEMBS.map((row) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label>Невалиден ембс/ембг {row}</Label>
        </Grid>
      })
    }
    return ReturnValue;

  }

  returnResults = () => {
    return<>
      {this.returnSuccessMessages()}
      <Divider style={{marginBottom: '10px'}}/>
      {this.returnExistsMessages()}
      {this.returnNotValidMessages()}
      {this.returnNotValidEmbs()}
    </>
  }



  render(){
    return <Dialog
      fullWidth
      maxWidth="md"
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {'Извештај за импортирање компании во портфолио'}
        </Typography>
        <Typography variant="subtitle2">
          {
            'Во прилог е статусот на импортираните компании.'
          }
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}  display={'block'}>
            {this.props.open && this.returnResults()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          type="submit"
          variant="contained"
          onClick={() => this.props.onClose()}
        >
          {'Во ред'}
        </Button>
      </DialogActions>
    </Dialog>
  }
}
export default ImportResultDialog;
