import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Button,
  Typography,
} from '@mui/material';
import FormattedInput from "../../../../components_bi/NumberFormating/FormattedInput";



class ResultReportForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: true,
      open: false,
      aopError: '',
      id: ''
    };
  }
  componentDidMount() {
  }


  render() {
    return (
      <>
        <div>
          <Box>

              <Grid item sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{color: 'red', paddingLeft: '10px'}}>Износите треба да се прикажани како цел број</div>
                <Button variant={'outlined'} size={'small'} sx={{ m: 1 }} onClick={this.props.handleAddFinance}>Зачувај
                  податоци</Button>
              </Grid>
          </Box>

          <TableContainer style={{height: '650px'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{'Позиција'}</TableCell>
                  <TableCell align={'center'}>{'Назив'}</TableCell>
                  <TableCell align={'center'}>{'Тековна година'}</TableCell>
                  <TableCell align={'center'}>{'Предходна година'}</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.props.fields && this.props.fields.map(aop => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5">
                            {aop}
                          </Typography>
                        </TableCell>
                        <TableCell align={'center'}>{this.props.titles[aop]}</TableCell>

                        <TableCell align={'center'}>
                            <FormattedInput
                              type='text'
                              size='small'
                              name={aop}
                              className='w-full'
                              defaultValue={this.props.aop2[aop]}
                              onChange={(event) => this.props.handleChangeAOP2(aop, event.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />

                        </TableCell>

                        <TableCell align={'center'} sx={{ ml: 2 }}>
                          <FormattedInput
                            type='text'
                            size='small'
                            name={aop}
                            className='w-full'
                            defaultValue={this.props.aop1[aop]}
                            onChange={(event) => this.props.handleChangeAOP1(aop, event.target.value)}
                            InputLabelProps={{ shrink: true }}

                          />
                        </TableCell>

                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }

}

export default withRouter(ResultReportForm);
