import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SuspenseLoader from '../../../../../components/SuspenseLoader'
import { DataGrid } from '@mui/x-data-grid';
import {Link as RouterLink} from 'react-router-dom';

import SyncIcon from '@mui/icons-material/Sync';
import EnqueueSnackBarMessage from '../../../../../components_bi/EnqueueSnackbarMessage'


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class PayedAndPending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showMessageSuccess: false,
      showMessageError: false,
    }
  }

  componentDidMount() {
    this.setState({loading: false});

  }

  handleSync = (clientID, orderID, PIN, Year) => {
    window.makeRequest("POST", `/api/Admin_ReportOrders?clientID=${clientID}&orderID=${orderID}`, {
      PIN: PIN,
      Year: Year
    }, true, (callback1) => {
      if(callback1.body === true){
        this.setState({showMessageSuccess: true})
        this.props.getAllPayedAndPending();

      }
      else {
        this.setState({showMessageError: true})
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  DataGridSubscription = () => {
    const columns = [
      {
        field: 'PIN',
        headerName: 'ЕМБС',
        width: 150,
        editable: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'VAT',
        headerName: 'ЕДБ',
        width: 150,
        editable: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Company_name',
        headerName: 'Име на компанијата',
        width: 700,
        editable: true,
        headerAlign: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return<>
            <Typography noWrap>
              <Tooltip title={params.row.Company_name} arrow>
                <RouterLink  style={{textDecoration: 'none', color: 'black'}} to={`/company/${params.row.ID}/dashboard`}>
                  {params.row.Company_name}
                </RouterLink >
              </Tooltip>
            </Typography>
          </>
        }

      },
      {
        field: 'Year',
        headerName: 'Година',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'username',
        headerName: 'Корисничко име',
        width: 200,
        editable: true,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Status',
        headerName: 'Статус',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: '#',
        headerName: 'Менаџирај',
        width: 108,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            <Tooltip title={('Синхронизирај')} arrow>
              <IconButton
                onClick={() => this.handleSync(params.row.ClientID, params.row.ID,params.row.PIN, params.row.Year)}
                color="primary"
              >
                <SyncIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </Typography>
        },
        align: 'center',
        disableColumnMenu: true,
        headerAlign: 'center',
        sortable: false,
      }


    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.props.PayedAndPending}
          columns={columns}
          pageSize={5}
          getRowId={row => row.ID}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  }

  returnBoxTitle = () => {
    return<Box display="flex" alignItems="center">
      <Box>
        <Typography variant="h3" component="h3" gutterBottom>
          {'Преглед на извештаи'}
        </Typography>
        <Typography variant="subtitle2">
          {
            'Преглед на сите платени извештаи кои чекаат достава'
          }
        </Typography>
      </Box>
    </Box>
  }

  showMessageSuccess = () => {

    if(this.state.showMessageSuccess) {
      this.setState({showMessageSuccess: false});
      return<EnqueueSnackBarMessage message={'Успешно синхронизирање на извештај.'} type={'success'}/>
    }

  }

  showMessageError = () => {
    if(this.state.showMessageError){
      this.setState({showMessageError: false});
      return<EnqueueSnackBarMessage message={'Неуспешно синхронизирање на извештај.'} type={'success'}/>
    }
  }


  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={0}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            {this.returnBoxTitle()}
          </Box>
          {this.DataGridSubscription()}
          {this.showMessageSuccess()}
          {this.showMessageError()}


        </RootWrapper>

      );
    }

  }



}


export default PayedAndPending;

