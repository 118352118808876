import React from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  TextField,
  Typography,
  Container,
  Alert,
  Slide,
  Dialog,
  Collapse,
  Button,
  Avatar,
  IconButton
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


class ConfirmResetPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      openDialog: false,
      openAlert: true,
      email: '',
      emailError: '',
    }
  }

  validate = () => {

    let email = this.state.email;
    let emailError = '';

    switch (true) {
      case email.length < 1:
        emailError = 'Е-пошта не смее да биде празно поле';
        break;
      case /\S+@\S+\.\S+/.test(email) !== true:
        emailError = 'Внесете валидна Е-маил адреса';
        break;
      default:
    }

    if(emailError){
      this.setState({emailError})
      return false
    }

    return true

  };

  handleOpenDialog = () => {
    this.setState({openDialog: true})
  };

  handleCloseDialog = () => {
    this.setState({openDialog: false})
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  };

  confirmResetPassword = () => {
    if(this.validate()) {

      window.makeRequest("GET", `/api/Register/CheckEmail?email=${this.state.email}`, false, true, (callback) => {
        if(callback.body === false){
          window.makeRequest("PUT", `/api/ResetPassword`, {
            Email: this.state.email
          }, true, (callback) => {
            this.handleOpenDialog();
          }, (err) => {
            if (err) {
              console.log('err', err);

            }
          });
        }
        else{
          this.setState({emailError: 'Нема регистрирано корисник со внесената Е-пошта.'})
        }
      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });

    }
  };



  render(){
    return(
      <>
        <Helmet>
          <title>Recover Password</title>
        </Helmet>
        <MainContent>
          <Container maxWidth="sm">
            <Card sx={{ mt: 3, p: 4 }}>
              <Box>
                <Typography variant="h2" sx={{ mb: 1 }}>
                  {('Ресетирај лозинка')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 3 }}
                >
                  {(
                    'Внесете е-маил адреса за да ја ресетирате лозинката'
                  )}
                </Typography>
              </Box>


                    <TextField
                      fullWidth
                      label={('Е-маил')}
                      margin="normal"
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                    />
                    {
                      this.state.emailError? <Alert severity="error" sx={{mt: 1}}>{this.state.emailError}</Alert> : ""
                    }

                    <Button
                      sx={{ mt: 3 }}
                      color="primary"
                      onClick={this.confirmResetPassword}
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      {('Ресетирај')}
                    </Button>
            </Card>
            <Box mt={3} textAlign="right">
              <Typography
                component="span"
                variant="subtitle2"
                color="text.primary"
                fontWeight="bold"
              >
                {('Обидете се да се најавите ?')}
              </Typography>{' '}
              <Link component={RouterLink} underline="hover" to="/">
                <b>Најава</b>
              </Link>
            </Box>
          </Container>
        </MainContent>

        <DialogWrapper
          open={this.state.openDialog}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseDialog}
        >
          <Box sx={{ px: 4, pb: 4, pt: 10 }}>
            <AvatarSuccess>
              <CheckTwoToneIcon />
            </AvatarSuccess>

            <Collapse in={this.state.openAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({openAlert: false});
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity="info"
              >
                {(
                  'Испратена е порака на вашата е-маил адреса!'
                )}
              </Alert>
            </Collapse>

            <Typography align="center" sx={{ py: 4, px: 10 }} variant="h4">
              {('Проверете ја вашата е-маил адреса за понатамошни инструкции.')}
            </Typography>

            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={this.handleCloseDialog}
              href="/"
            >
              {('Продолжете со најава')}
            </Button>
          </Box>
        </DialogWrapper>
      </>
    )
  }
}


export default ConfirmResetPassword;
