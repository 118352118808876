import { Box, Stack, Card, Typography, Divider, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Text from 'src/components/Text';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import SubscriptionsTwoToneIcon from '@mui/icons-material/SubscriptionsTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';

function TopCards(props) {
  const { t } = useTranslation();

  return (
    <Card  sx={{
      boxShadow: 0
    }}>

      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={0}
      >
        <Box
          p={2}
          sx={{
            textAlign: 'center'
          }}
        >
          <Text color="success">
            <PersonTwoToneIcon fontSize="small" />

          </Text>
          <Typography variant="h6">{props.totalUsers}</Typography>
          <Typography variant="subtitle2">Број на корисници</Typography>
        </Box>
        <Box
          p={2}
          sx={{
            textAlign: 'center'
          }}
        >
          <Text color="info">
            <SubscriptionsTwoToneIcon fontSize="small" />
          </Text>
          <Typography variant="h6">{props.totalDivisions}</Typography>
          <Typography variant="subtitle2">Број на портфолија</Typography>
        </Box>
        <Box
          p={2}
          sx={{
            textAlign: 'center'
          }}
        >
          <Text color="warning">
            <MonetizationOnTwoToneIcon fontSize="small" />
          </Text>
          <Typography variant="h6">{props.ReportPrice} МКД + 18% ДДВ</Typography>
          <Typography variant="subtitle2">Цена за извештај</Typography>
        </Box>
        {props.status !== 1 ?
          <Box
            p={3}
            sx={{
              textAlign: 'center'
            }}
          >
            { props.status === 2 ?
              <Button variant={'contained'} size={'small'} onClick={props.handleSubscription}>Откажи претплата</Button>
              : <Button variant={'contained'} size={'small'} onClick={props.handleSubscription}>Продолжи претплата</Button>
            }
          </Box> : ''
        }
      </Stack>
    </Card>
  );
}

export default TopCards;
