import { Box, Container, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Hero from './Hero';
import HomeHeader from '../../components_bi/HomeHeader/index';
import { useHistory } from "react-router-dom";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(14)};
`
);

const StyledBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.common.white};
    height: 100%;
`
);

function Cookies() {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <StyledBox>
      <HeaderWrapper>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <h1>Bonitet.mk</h1>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Box />
              <Box>

                <Button
                  onClick={() => history.goBack()}
                  variant="contained"
                  sx={{ ml: 2 }}
                >
                  {t('Назад')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </HeaderWrapper>
      <Hero />
      {/*<Footer />*/}
    </StyledBox>
  );
}

export default Cookies;
