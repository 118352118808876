import React, { Component } from 'react';
import {withRouter} from 'react-router';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

class ViewResultReportForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: true,
      id: '',
    };
  }




  componentDidMount() {

    this.setState({id: this.props.match.params.id})

  }

  returnCalculateFromTwoYears = (a,b) => {

    // {this.props.data && this.props.data2 ? this.returnCalculateFromTwoYears(this.props.data[aop] && this.props.data[aop], this.props.data2[aop] && this.props.data2[aop]) + "%" : ''}


    if(a && parseInt(a)  && b && parseInt(b)){
      return (b - a).toLocaleString();
    }
    else if(a && parseInt(a)){
      return (0 - a).toLocaleString();
    }
    else if(b && parseInt(b)){
      return (b - 0).toLocaleString();
    }
    else {
      return 0;
    }

  }

  getFormated = (name, value) => {
    if(name === "PS" || name === "PK" || name === "NPM" || name === "BPM" || name === "OPM" || name === "PK1" || name === "EM" || name === "PS1" || name === "ZD" || name === "KK" || name === "TO"){
     return value != null ? parseFloat(value * 100).toFixed(0) + '%' : 'N/R';
    }
    else if(name === "TL" || name === "BL" || name === "PL" || name === "OP" || name === "OZ" || name === "OS" || name === "OO" || name === "OK" || name === "OF" || name === "FL" || name === "VO" || name === "DSCR" || name === "KA"){
      return value != null ? parseFloat(value).toFixed(2) : 'N/R';
    }
    else{
      return value != null ? parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/R';
    }
  }

  getPrihodi = (aop201, aop223, aop244) => {
     let prihodi = null;

     if(aop201){
       prihodi = aop201
     }

     if(aop223){
       if(prihodi != null){
         prihodi += aop223
       }
       else {
         prihodi = aop223
       }
     }

    if(aop244){
      if(prihodi != null){
        prihodi += aop244
      }
      else {
        prihodi = aop244
      }
    }

    return prihodi == null ? 0 : prihodi;

  }

  getRashodi = (aop207, aop204, aop205, aop234, aop245) => {

    let rashodi = null;

    if(aop207){
      rashodi = aop207
    }

    if(aop204){
      if(rashodi != null){
        rashodi += aop204
      }
      else {
        rashodi = aop204
      }
    }

    if(aop205){
      if(rashodi != null){
        rashodi -= aop205
      }
      else {
        rashodi = aop205
      }
    }

    if(aop234){
      if(rashodi != null){
        rashodi += aop234
      }
      else {
        rashodi = aop234
      }
    }

    if(aop245){
      if(rashodi != null){
        rashodi += aop245
      }
      else {
        rashodi = aop245
      }
    }

    return rashodi == null ? 0 : rashodi;

  }


  render() {

    return (

      <div>

        {this.props.showRecenica?
          <Typography variant={"h5"} sx={{p: 2, color: "gray"}}>
            Вредностите се прикажани во МКД
          </Typography>
          : ''
        }

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  this.props.showPozicija?
                  <TableCell>{'Позиција'}</TableCell>
                    : ''
                }
                <TableCell align={'center'}>{'Назив'}</TableCell>
                <TableCell align={'center'}>{'Тековна година'}</TableCell>
                {
                 this.props.showLastYear? <TableCell align={'center'}>{'Предходна година'}</TableCell> : ''
                }
                {
                 this.props.showLastYear? <TableCell align={'center'}>{'Разлика'}</TableCell> : ''
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.fields.map(aop => {
                  return (
                    <TableRow>
                      {this.props.showPozicija?
                        <TableCell width='150px'>
                          <Typography variant="h5">
                            {aop}
                          </Typography>
                        </TableCell>
                        : ''
                      }
                      <TableCell align={'center'}>{this.props.titles[aop]}</TableCell>
                      <TableCell align={'center'}  width='150px'>
                        <Typography>
                          {aop != 'TG501' && aop != 'TG502' ? this.props.data2[aop] != null ? this.getFormated(aop, this.props.data2[aop]) : this.props.pokazateli ? 'N/R' : 0 : aop === 'TG501' ? this.getPrihodi(this.props.data2.AOP201, this.props.data2.AOP223, this.props.data2.AOP244).toLocaleString() : this.getRashodi(this.props.data2['AOP207'], this.props.data2['AOP204'], this.props.data2['AOP205'], this.props.data2['AOP234'], this.props.data2['AOP245']).toLocaleString()}
                        </Typography>
                      </TableCell>
                      {
                        this.props.showLastYear?
                        <TableCell align={'center'} width='150px'>
                          <Typography>
                            {
                              aop !== 'TG501' && aop !== 'TG502' ? this.props.data && this.props.data[aop] != null ? this.props.data[aop].toLocaleString() : 0 : aop === 'TG501' ? this.props.data ? this.getPrihodi(this.props.data['AOP201'], this.props.data['AOP223'], this.props.data['AOP244']).toLocaleString() : 0 :  this.props.data ? this.getRashodi(this.props.data['AOP207'], this.props.data['AOP204'], this.props.data['AOP205'], this.props.data['AOP234'], this.props.data['AOP245']).toLocaleString() : 0
                            }



                          </Typography>
                        </TableCell>
                          : ''
                      }
                      {
                        this.props.showLastYear?
                        <TableCell align={'center'} width='150px'>
                          {aop !== 'TG501' && aop !== 'TG502' ? this.props.data && this.props.data2 ? this.returnCalculateFromTwoYears(this.props.data[aop], this.props.data2[aop]) : 0 : aop === 'TG501'  ? this.props.data && this.props.data2 ? this.returnCalculateFromTwoYears(this.getPrihodi(this.props.data['AOP201'], this.props.data['AOP223'], this.props.data['AOP244']), this.getPrihodi(this.props.data2['AOP201'], this.props.data2['AOP223'], this.props.data2['AOP244'])) : 0 : this.props.data && this.props.data2 ? this.returnCalculateFromTwoYears(this.getRashodi(this.props.data['AOP207'], this.props.data['AOP204'], this.props.data['AOP205'], this.props.data['AOP234'], this.props.data['AOP245']), this.getRashodi(this.props.data2['AOP207'], this.props.data2['AOP204'], this.props.data2['AOP205'], this.props.data2['AOP234'], this.props.data2['AOP245'])): 0}
                          {/*<Typography>*/}
                          {/*{*/}
                          {/*this.props.data2 && this.props.data && this.props.data[aop] && this.props.data2[aop]  ? (this.props.data2[aop] - this.prop.data[aop]) / this.props.data[aop]  : ''*/}
                          {/*}*/}
                          {/*</Typography>*/}
                        </TableCell>:''
                      }
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

}

export default withRouter(ViewResultReportForm);
