import { useTranslation } from 'react-i18next';

import { Link, Box, Card, Grid, styled, Divider, Typography } from '@mui/material';
import Text from 'src/components/Text';

const DividerVertialPrimary = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    background: ${theme.colors.primary.main};
  `
);


const LabelPrimary = styled(Box)(
  ({ theme }) => `
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      background: ${theme.colors.primary.main};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(8)};
      padding: ${theme.spacing(0.5, 1.5)};
      color: ${theme.palette.primary.contrastText};
  `
);

function SizeCard(props) {
  const { t } = useTranslation();
  const starost = props.starost;
  const golemina = props.golemina;

  const returnIconSize = (val) => {
    let valueTrim = val.trim();
    let icon = [];

    switch(true){
      case valueTrim === 'мал':
        break;
      case valueTrim === 'микро':
        break;
      case valueTrim === 'среден':
        break;
      case valueTrim === 'голем':
        break;
    }
    return icon;
  }

  const returnIconAge = (icon) => {
    const displayIcon = [];
    switch(icon) {
      case 'toFive':
        displayIcon.push(<><LabelPrimary style={{width: '45%'}}>До 5 год</LabelPrimary>  </>);
        break;
      case 'fromFiveToFiveteen':
        displayIcon.push(<><LabelPrimary style={{width: '45%'}}>Од 5 до 15 год</LabelPrimary> </>);
        break;
      case 'fromSixteenToThirty':
        displayIcon.push(<><LabelPrimary style={{width: '45%'}}>Од 16 до 30 год</LabelPrimary> </>);
        break;
      case 'overThirty':
        displayIcon.push(<><LabelPrimary style={{width: '45%'}}>Над 30 год</LabelPrimary></>);
        break;
    }
    return<>{displayIcon}</>
  }

  const returnAge = () => {
    let boxes = [];
    boxes = Object.keys(starost).map((key) => {
      return<Grid item >
        <Box mt={1} display={'flex'} alignItems="center"  >
            {returnIconAge(key)}
        <Typography style={{fontSize: 15, fontWeight: "bold"}} sx={{ml:1}}>
          {starost[key]}
        </Typography>
      </Box>
      </Grid>
    })

    return boxes;
  }

  const returnSize = () => {
    let boxes = [];
    boxes = Object.keys(golemina).map((key) => {
      return<Grid item ><Box mt={1} display={'flex'} alignItems="center"  >
        <LabelPrimary style={{width: '45%'}}>{key}</LabelPrimary>
        {returnIconSize(key)}
        <Typography style={{fontSize: 15, fontWeight: "bold"}} sx={{ml:1}}>
          {golemina[key]}
        </Typography>
      </Box></Grid>
    })


    return boxes;

  }

  return (
    <>
      <DividerVertialPrimary />
      <Grid container xs={12}>
        <Grid item xs={6}>
          <Link
            color="text.primary"
            component="a"
            variant="h4"
            fontWeight="normal"
          >
            Големина
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            color="text.primary"
            component="a"
            variant="h4"
            fontWeight="normal"
          >
            Старост
          </Link>
        </Grid>
      </Grid>
      <Grid container xs={12} mt={1}>
        <Grid item xs={6}>
          {returnSize()}
        </Grid>
        <Grid item xs={6}>
          {returnAge()}
        </Grid>
      </Grid>

    </>
  );
}

export default SizeCard;
