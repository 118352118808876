import React from 'react';
import {withRouter} from 'react-router';
import PageHeader from './PageHeader';
import {
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Divider,
  Card,
  Grid,
  ListItemIcon,
  Checkbox,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuspenseLoader from 'src/components/SuspenseLoader';

const AccordionSummaryWrapper = styled(AccordionSummary)(
  () => `
      &.Mui-expanded {
        min-height: 48px;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
      }
  `
);

const ListItemWrapper = styled(ListItem)(
  () => `
  
      &.MuiButtonBase-root {
        border-radius: 0;
      }
  `
);

class Permissions extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      user: {},
      permissions: [
        {
          id: 1,
          text: ('Менаџирање на корисници')
        },
        {
          id: 2,
          text: ('Менаџирање на дивизии')
        },
        {
          id: 3,
          text: ('Менаџирање на компании')
        },
        {
          id: 4,
          text: ('Внесување компании и извештаи')
        },
        {
          id: 5,
          text: ('Менаџирање на плаќање')
        },
        {
          id: 6,
          text: ('Менаџирање на претплата')
        },
        {
          id: 7,
          text: ('Менаџирање на пермисии')
        },
        {
          id: 8,
          text: ('Преглед на компании')
        },
        {
          id: 9,
          text: ('Преглед на дивизии')
        },
        {
          id: 10,
          text: ('Кошничка')
        },
        {
          id: 11,
          text: ('Импортирање')
        },
      ],
      checked1: null,
      checked2: null,
      checked3: null,
      checked4: null,
      checked5: null,
      checked6: null,
      checked7: null,
      checked8: null,
      checked9: null,
      checked10: null,
      checked11: null,


    }
  }

  componentDidMount(){
    window.makeRequest("GET", `/api/User?id=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({checked1: callback.body.ManageUsers,
        checked2: callback.body.ManageDivisions,
        checked3: callback.body.ManageCompanies,
        checked4: callback.body.AddCompany,
        checked5: callback.body.ManagePayments,
        checked6: callback.body.ManageSubscription,
        checked7: callback.body.ManagePerimissions,
        checked8: callback.body.CompanyView,
        checked9: callback.body.Division,
        checked10: callback.body.Basket,
        checked11: callback.body.Import,
        loading: false,

      });
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }

  handleToggle = (value) => {
    if(value === 1){
      this.setState({checked1: !this.state.checked1})
    }
    else if(value === 2){
      this.setState({checked2: !this.state.checked2})
    }
    else if(value === 3){
      this.setState({checked3: !this.state.checked3})
    }
    else if(value === 4){
      this.setState({checked4: !this.state.checked4})
    }
    else if(value === 5){
      this.setState({checked5: !this.state.checked5})
    }
    else if(value === 6){
      this.setState({checked6: !this.state.checked6})
    }
    else if(value === 7){
      this.setState({checked7: !this.state.checked7})
    }
    else if(value === 8){
      this.setState({checked8: !this.state.checked8})
    }
    else if(value === 9){
      this.setState({checked9: !this.state.checked9})
    }
    else if(value === 10){
      this.setState({checked10: !this.state.checked10})
    }
    else if(value === 11){
      this.setState({checked11: !this.state.checked11})
    }
  };
  handleSavePermissions = () => {
    window.makeRequest("PUT", `/api/User/?id=${this.props.match.params.id}&permissionChange=true`, {
      ManageUsers: this.state.checked1,
      ManageDivisions: this.state.checked2,
      ManageCompanies: this.state.checked3,
      AddCompany: this.state.checked4,
      ManagePayments: this.state.checked5,
      ManageSubscription: this.state.checked6,
      ManagePermissions: this.state.checked7,
      CompanyView: this.state.checked8,
      Division: this.state.checked9,
      Basket: this.state.checked10,
      Import: this.state.checked11
    }, true, (callback) => {
      window.location.href = '/';
    }, (err) => {
      if (err) {
        console.log('err', err);

      }
    });
  };

  render(){
    if(this.state.loading){
      return <SuspenseLoader/>
    }else {
      return(
        <div style={{padding: '30px'}}>
          <PageHeader/>
          <div style={{marginTop: '30px'}}>
            <Card>
              <Accordion defaultExpanded>
                <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">{('Пермисии')}</Typography>
                </AccordionSummaryWrapper>
                <AccordionDetails sx={{ p: 0 }}>
                  <List component="div">
                    <Grid container>
                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[0].id}

                          onClick={() => this.handleToggle(this.state.permissions[0].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked1}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[0].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[1].id}

                          onClick={() => this.handleToggle(this.state.permissions[1].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked2}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[1].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[2].id}

                          onClick={() => this.handleToggle(this.state.permissions[2].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked3}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[2].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[3].id}

                          onClick={() => this.handleToggle(this.state.permissions[3].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked4}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[3].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[4].id}

                          onClick={() => this.handleToggle(this.state.permissions[4].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked5}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[4].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[6].id}

                          onClick={() => this.handleToggle(this.state.permissions[6].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked7}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[6].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[7].id}

                          onClick={() => this.handleToggle(this.state.permissions[7].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked8}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[7].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[8].id}

                          onClick={() => this.handleToggle(this.state.permissions[8].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked9}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[8].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[9].id}

                          onClick={() => this.handleToggle(this.state.permissions[9].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked10}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[9].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[10].id}

                          onClick={() => this.handleToggle(this.state.permissions[10].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked11}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[10].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4}>
                        <ListItemWrapper
                          sx={{ py: 0, px: 2 }}
                          key={this.state.permissions[5].id}

                          onClick={() => this.handleToggle(this.state.permissions[5].id)}
                        >
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked6}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={this.state.permissions[5].text}
                            primaryTypographyProps={{ variant: 'body1' }}
                          />
                        </ListItemWrapper>
                      </Grid>

                      <Grid item sm={4} md={4} sx={{ml: 1.7, mt: 1.5}}>
                        <Button
                          color={'primary'}
                          variant={'contained'}
                          size={'small'}
                          onClick={this.handleSavePermissions}
                        >
                          Save
                        </Button>

                      </Grid>
                    </Grid>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Divider />
            </Card>
          </div>
        </div>
      )
    }

  }

}

export default withRouter(Permissions);
