import React from 'react';
import {
  Dialog,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import './NotificationDialog.scss';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Avatar} from "@mui/material/index";
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';

import AddReportDialog from './AddReportDialog';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


class NotificationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }


  componentDidMount() {

  }

  handleShow = () => {
    window.makeRequest("PUT", `/api/Clients`, false, true, (callback) => {

    }, (err) => {
      if (err) {
        console.log('err', err);

      }
    });
  }

  handleChange = (ev, val) => {
    window.location.href = ev.target.value;
  }

  openMainDivision = () => {
    window.location.href = '/divisions/1'
  };

  openAddReport = () => {
    this.setState({open: true})
    this.props.onClose();
  }

  onClose = () => {
    this.setState({open: false});
  }

  onPrev = () => {
    this.setState({open: false})
    this.props.onOpenNotification()
  }

  addCompany = () => {
    this.props.addCompany();
    this.props.onClose();
  }

  render() {
    return (
      <>
        <DialogWrapper
          open={this.props.open}

          keepMounted
          onClose={this.props.onClose}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height={'200px'}
            width={'600px'}
            borderRadius={1}
            p={5}
            // style={{
            //   backgroundColor: '#DADADA',
            // }}
          >
            <Box>
              <Typography align="center" style={{py: 2, px: 6, color: 'blue', fontSize: '25px'}} variant="h2">
                {('Внеси податоци за:')}
              </Typography>
            </Box>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', marginTop: '20px'}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: this.props.reportCounts > 0 ? 'space-between' : 'center', gap: this.props.reportCounts > 0 ? '0': '20px', width: '100%', height: '100%'}}>
                <div><Button variant={'contained'} onClick={this.openAddReport}>
                  <div>Мојата компанија</div>
                </Button></div>
                <div><Button variant={'contained'} onClick={this.addCompany}>
                  <div>Нова компанија</div>
                </Button></div>
                {
                  this.props.reportCounts > 0 ?
                    <div>
                      {this.props.Divisions.length === 1 ?
                        <Button variant={'contained'} onClick={this.openMainDivision}>
                          <div>Анализирај портфолио</div>
                        </Button>
                        : <div>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Анализирај <br/> портфолио</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              style={{height: '50px', marginBottom: '20px'}}
                              onChange={this.handleChange}
                              label="Age"
                            >
                              {
                                this.props.Divisions.map((division) => {
                                  return (
                                    <MenuItem value={`/divisions/${division.ID}`}>{division.Name}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div>
                      }
                    </div> : ''
                }


              </div>
            </div>
            {this.props.reportCounts > 0 ?
              <Grid item xs={12} align='center' sx={{alignItems: 'center'}}><Checkbox
                onChange={this.handleShow}/> Исклучи нотификација</Grid>
              : ''
            }
          </Box>
        </DialogWrapper>


        <AddReportDialog open={this.state.open} onPrev={this.onPrev} VAT={this.props.VAT}
                         Company_name={this.props.Company_name} onClose={this.onClose} PIN={this.props.PIN}
                         companyID={this.props.companyID} openAddReport={this.openAddReport}
                         showMessageLoad={this.props.showMessageLoad}/>

      </>
    )
  }
}


export default NotificationDialog;
