import { useState, forwardRef } from 'react';
import {
  Avatar,
  Box,
  Slide,
  Button,
  Typography,
  Dialog,
  styled
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';

import { Link as RouterLink } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonSuccess = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});




function ImportingDialog (props) {
  const ReturnButtonOrText = () => {
      return<Typography
        align="center"
        sx={{
          py: 4,
          px: 6
        }}
        variant="h6"
      >
        {props.count} Од {props.total}
      </Typography>
  }


  return<>
    <DialogWrapper
      open={props.open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarSuccess>
          <Add />
        </AvatarSuccess>

        <Typography
          align="center"
          sx={{
            pt: 1,
            px: 6
          }}
          variant="h3"
        >
          {'Обработување на компании!'}

        </Typography>

        {ReturnButtonOrText()}
        <Box item >
          <ButtonSuccess
            disabled={!props.button}
            onClick={props.onClose}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
          >
            {'Во ред'}
          </ButtonSuccess>

        </Box>
      </Box>
      <Box style={{width: '100%'}}><LinearProgress  variant={props.count === props.total ? 'buffer' : 'indeterminate'}  /></Box>
    </DialogWrapper>

  </>
}

export default ImportingDialog;
