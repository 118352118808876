import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Grid,
  Alert,
  TextField,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Tabs,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { useSnackbar } from 'notistack';

import LockIcon from '@mui/icons-material/Lock';
import TuneIcon from '@mui/icons-material/Tune';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";


const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background-color: ${theme.colors.success.lighter};
    color: ${theme.colors.success.main};
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
`
);



const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const Results = ({ users,createUser, ...rest }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteID, setDeleteID] = useState(null);
  const [deactivateID, setDeactivateID] = useState(null);
  const [activateID, setActivateID] = useState(null);
  const [changePasswordID, setChangePasswordID] = useState(null);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirm_password] = useState('');
  const [error, setError] = useState(null);




  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openDeactivateUser, setOpenDeactivateUser] = useState(false);
  const [openActivateUser, setOpenActivateUser] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleConfirmDelete = (id) => {
    setDeleteID(id);
    setOpenConfirmDelete(true);
  };

  const handleConfirmDeactivateUser = (id) => {
    setDeactivateID(id);
    setOpenDeactivateUser(true);
  };

  const handleConfirmActivateUser = (id) => {
    setActivateID(id);
    setOpenActivateUser(true);
  };

  const handleConfirmChangePassword = (id) => {
    setChangePasswordID(id);
    setOpenChangePassword(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const closeDeactivateUser = () => {
    setOpenDeactivateUser(false);
  };

  const closeActivateUser = () => {
    setOpenActivateUser(false);
  };

  const closeChangePassword = () => {
    setOpenChangePassword(false);
    setPassword('');
    setConfirm_password('');
    setError('');
  };

  const handleEditUserOpen = (e) => {
    setEditUser(e);
    if(editUser) {
      setEditUserOpen(true);
    }
  };

  const handleEditUserClose = () => {
    setEditUser(null);
    setEditUserOpen(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirm_password(e.target.value);
  };

  const validate = () =>{
    let error = '';
    switch (true){
      case password !== confirm_password:
        error = "Лозинката не се совпаѓа.";
        break;
      case password.length < 1:
        error = "Лозинката не смее да биде празно поле.";
        break;
      case password.length < 7:
        error = "Лозинката мора да има 7 карактери.";
        break;
    }

    if(error){
      setError(error);
      return false;
    }
    else{
      return true;
    }

  };

  const handleDeactivateCompleted = () => {
    window.makeRequest("PUT", `/api/User/activate_deactivate?id=${deactivateID}`, false, true, (callback) => {
      setOpenDeactivateUser(false);
      rest.renderUsers();
      setDeactivateID(null);
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });

    enqueueSnackbar(t('Корисникот е успешно деактивиран'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const handleActivateCompleted = () => {
    window.makeRequest("PUT", `/api/User/activate_deactivate?id=${activateID}`, false, true, (callback) => {
      if(callback.body.Response.ReasonPhrase === 'OK'){
        rest.renderUsers();
        enqueueSnackbar(t('Корисникот е успешно активиран'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      } else {
        createUser();
      }
      setActivateID(null);
      setOpenActivateUser(false);


    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });


  };


  const getFormatedNumber = (number) => {

    let formatedNumber = "";
    if(number.indexOf("3897") > -1){
      formatedNumber = "+389" + " " +  number[3] + number[4] + " " + number[5] + number[6] + number[7] + "-" + number[8] + number[9] + number[10];

    }
    else if(number.indexOf("3892") > -1){
      formatedNumber = "+389" + " " +  number[3]  + " " + number[4] + number[5] + number[6] + number[7] + "-" + number[8] + number[9] + number[10];
    }
    else {
      formatedNumber = number
    }

    return formatedNumber;

  };


  const handleChnagePasswordCompleted = () => {

    if(validate()) {
      window.makeRequest("PUT", `/api/User/?id=${changePasswordID}&passwordChange=true`, { Password: password }, true, (callback) => {
        setOpenChangePassword(false);
        setChangePasswordID(null);
        setPassword('');
        setConfirm_password('');

      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });

      enqueueSnackbar(t('Лозинката е успешно променета'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  };

  const handleDeleteCompleted = () => {
    window.makeRequest("DEL", `/api/User?id=${deleteID}`, false, true, (callback) => {
      setOpenConfirmDelete(false);
      rest.delete(deleteID);
      setDeleteID(null);
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });

    enqueueSnackbar(t('Корисникот е успешно избришан'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const columns = [
    {
      field: 'username',
      headerName: 'Корисничко име',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,

    },
    // + lastname
    {
      field: 'firstname',
      headerName: 'Цело име',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,

    },
    {
      field: 'email',
      headerName: 'E-пошта',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,

    },
    {
      field: 'telephone',
      headerName: 'Телефон',
      width: 250,
      type:'number',
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>
          {params.row.telephone && getFormatedNumber(params.row.telephone.toString())}
        </>
      }

    },
    {
      field: '#',
      headerName: 'Уреди',
      headerAlign: 'center',
      width: 220,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>
          {params.row.UserType !== 'main user'?
            <TableCell align="center">
              {params.row.isActiv ?
                <Typography noWrap>
                  {/* <Tooltip title={t('Преглед')} arrow>
                    <IconButton
                      component={RouterLink}
                      to={`/user/${params.row.id}`}
                      onClick={() => handleEditUserOpen(params.row)}

                      color="primary"
                    >
                      <LaunchTwoToneIcon fontSize="medium"/>
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title={t('Менаџирај пермисии')} arrow>
                    <IconButton
                      component={RouterLink}
                      to={`/user/permissions/${params.row.id}`}
                      onClick={(event) => {
                        event.ignore = true
                        handleConfirmDelete(params.row.id)
                      }}
                      color="primary"
                    >
                      <TuneIcon fontSize="small"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Промени лозинка')} arrow>
                    <IconButton
                      onClick={(event) => {
                        event.ignore = true
                        handleConfirmChangePassword(params.row.id)
                      }}
                      color="primary"
                    >
                      <LockIcon fontSize="small"/>
                    </IconButton>

                  </Tooltip>
                  <Tooltip title={t('Деактивирај корисник')} arrow>
                    <IconButton
                      onClick={(event) => {
                        event.ignore = true
                        handleConfirmDeactivateUser(params.row.id)
                      }}
                      color="primary"
                    >
                      <WarningIcon fontSize="small"/>
                    </IconButton>
                  </Tooltip>
                  {/*<Tooltip title={t('Избриши корисник')} arrow>*/}
                  {/*<IconButton*/}
                  {/*onClick={() => handleConfirmDelete(user.id)}*/}
                  {/*color="primary"*/}
                  {/*>*/}
                  {/*<DeleteTwoToneIcon fontSize="small" />*/}
                  {/*</IconButton>*/}
                  {/*</Tooltip>*/}
                </Typography> :
                <Typography>
                  <Tooltip title={t('Активирај корисник')} arrow>
                    <IconButton
                      onClick={(event) => {
                        event.ignore = true
                        handleConfirmActivateUser(params.row.id)
                      }}
                      color="primary"
                    >
                      <CheckTwoToneIcon color='error'/>
                      {/*<CheckCircleIcon fontSize="small"/>*/}
                    </IconButton>
                  </Tooltip>

                </Typography>
              }
            </TableCell>:<TableCell align={'center'}>
              <Typography noWrap>
                <Tooltip title={"Главен корисник"} arrow>
                  <IconButton
                  onClick={(event) => {
                    event.ignore = true
                  }}>
                    <AvatarSuccess>
                      <CheckTwoToneIcon />
                      {/*{"Главен извештај"}*/}
                    </AvatarSuccess>
                  </IconButton>
                </Tooltip>
              </Typography>
            </TableCell>
          }

        </>

      }},
      ]

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
          <BI_DataGrid search={true} id={'id'} columns={columns} data={users}  
              onRowClick={(params, event) => {
                    if(!event.ignore){
                      window.location.href = `/user/${params.row.id}`
                    }}}/>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сте сигурни дека сакате да го избришете корисникот')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Откажи')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Избриши')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <DialogWrapper
        open={openDeactivateUser}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDeactivateUser}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <WarningIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сте сигурни дека сакате да го деактивирате корисникот')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeDeactivateUser}
            >
              {t('Откажи')}
            </Button>
            <ButtonError
              size="large"
              onClick={handleDeactivateCompleted}
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Деактивирај')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <DialogWrapper
        open={openActivateUser}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeActivateUser}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CheckCircleIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сте сигурни дека сакате да го активирате корисникот')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeActivateUser}
            >
              {t('Откажи')}
            </Button>
            <ButtonError
              size="large"
              onClick={handleActivateCompleted}
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Активирај')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openChangePassword}
        onClose={closeChangePassword}
        >
        <DialogTitle sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {('Променете лозинка')}
          </Typography>
          <Typography variant="subtitle2">
            {(
              'Пополнете ги полињата за да промените лозинка'
            )}
          </Typography>
        </DialogTitle>


        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type={'password'}
                    label={('Нова лозинка')}
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                  />

                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type={'password'}
                    label={('Потврди лозинка')}
                    name="confirm_password"
                    value={confirm_password}
                    onChange={handleConfirmPasswordChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {error && <Alert severity="error">{error}</Alert>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button color="secondary" onClick={closeChangePassword}>
            {('Откажи')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleChnagePasswordCompleted}
          >
            {('Промени лозинка')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  delete: PropTypes.func.isRequired,
  renderUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired

};

Results.defaultProps = {
  users: [],
};

export default Results;
