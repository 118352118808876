import React from 'react';
import {
  Box,
  Card,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone'
import DateFormating from '../../../../../../components_bi/DateFormating'


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openOrderShow: false,
      rows: [],
      TransactionLines: [],


    }
  }

  componentDidMount() {
    window.makeRequest("GET", `/api/Transactions`, false, true, (callback) => {
      if (callback.body) {
        this.setState({rows: callback.body, loading: false}, this.getTransaction);
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  DataGridTransactions = () => {
    const columns = [
      {
        field: 'CreatedOn',
        headerName: 'Датум',
        width: 180,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        renderCell: (params) => {
          return DateFormating(params.row.CreatedOn)
        }
      },
      {
        field: 'ID',
        headerName: 'Трансакција',
        type: 'number',
        width: 170,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'Status',
        headerName: 'Статус',
        width: 180,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'Status',
        headerName: 'Статус',
        width: 150,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,

      },
      {
        field: 'orderID',
        headerName: 'Број на нарачка',
        width: 150,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'transactionID',
        headerName: 'ID на трансакција',
        width: 230,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,

      },
      {
        field: 'amount',
        headerName: 'Вкупно од банка',
        width: 180,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'HostRefNumber',
        headerName: 'Наше ID',
        width: 150,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,

      },



      {
        field: 'TotalAmount',
        headerName: 'Вкупно',
        width: 150,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {'$'+params.row.TotalAmount}
          </Typography>
        },

      },
      {
        field: '*',
        headerName: 'Менаџирај',
        headerAlign: 'center',

        width: 150,
        description:
          'Со клик на копчето ви се прикажува трансакцијата со сите детали'
        ,
        renderCell: (params) => {
          return <><Typography noWrap align={'center'}>
            <Tooltip title={('Преглед')} arrow>
              <IconButton
                onClick={() => this.openOrderShow(params.row.TransactionLines)}
                color="primary"
              >
                <LaunchTwoToneIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </Typography>
          </>
        },
        align: 'center',
        disableColumnMenu: true,
      },
    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.props.TransactionList}
          columns={columns}
          pageSize={5}
          getRowId={row => row.ID}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  }

  DataGridOrder = () => {
    const columns = [
      {
        field: 'Name',
        headerName: 'Име',
        width: 150,
        editable: true,
      },
      {
        field: 'Quantity',
        headerName: 'Количина',
        type: 'number',
        width: 150,
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'SinglePrice',
        headerName: 'Цена',
        type: 'number',
        width: 150,
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'TotalPrice',
        headerName: 'Вкупно',
        type: 'number',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {'$' + params.row.TotalPrice}
          </Typography>
        },

      },

    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.state.TransactionLines}
          columns={columns}
          getRowId={row => row.ID}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  }
  openOrderShow = (lines) => {
    this.setState({openOrderShow: true, TransactionLines: lines})

  }
  closeOrderShow = () => {
    this.setState({openOrderShow: false, TransactionLines: []})
  }
  render() {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {'Преглед на трансакции'}
                </Typography>
                <Typography variant="subtitle2">
                  {
                    'Преглед на сите трансакции за корисник'
                  }
                </Typography>
              </Box>
            </Box>


          </Box>
          {this.DataGridTransactions()}
          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.openOrderShow}
            onClose={this.closeOrderShow}
          >
            <DialogTitle >
              <Typography variant="h4" gutterBottom>
                {'Преглед на претплата'}
              </Typography>
              <Typography variant="subtitle2">
                {
                  'Преглед на продуктите'
                }
              </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  {this.DataGridOrder()}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

        </RootWrapper>

      );

  }


}


export default Transactions;

