import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Button,
  Typography,
  Dialog,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
import DateFormating from '../../../../components_bi/DateFormating'

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const applyFilters = (users, query, filters) => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['VAT', 'PIN', 'Company_name'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property] && user[property].toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (filters.role && user.role !== filters.role) {
        matches = false;
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (users, page, limit) => {
  return users.slice(page * limit, page * limit + limit);
};

const Results = ({ users, ...rest }) => {
  const [selectedItems, setSelectedUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();


  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [deleteID, setDeleteID] = useState(null);
  const [filters, setFilters] = useState({
    role: null
  });
  const handleTabsChange = (event, tabsValue) => {
    let value = null;

    if (tabsValue !== 'all') {
      value = tabsValue;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      role: value
    }));

    setSelectedUsers([]);
  };



  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredUsers = applyFilters(users, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (id) => {
    setDeleteID(id);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };


  const handleAccept = (id) => {
    window.makeRequest("PUT", `/api/Admin_ReportOrders?orderID=${id}`, {
      Status: 'approved'
    }, true, (callback) => {
      rest.renderOrders();
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  }

  const handleReject = (id) => {

    window.makeRequest("PUT", `/api/Admin_ReportOrders?orderID=${id}`, {
      Status: 'denied'
    }, true, (callback) => {
      rest.renderOrders();
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  }

  const handleDeleteCompleted = () => {
    window.makeRequest("DEL", `/api/Company?id=${deleteID}`, false, true, (callback) => {
      setOpenConfirmDelete(false);
      rest.delete(deleteID);
      setDeleteID(null);
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });

    setOpenConfirmDelete(false);

    enqueueSnackbar('Компанијата е успешно избришана', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const columns = [
    {
      field: 'PIN',
      headerName: 'ЕМБС',
      width: 150,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'VAT',
      headerName: 'ЕДБ',
      width: 150,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Company_name',
      headerName: 'Име на компанијата',
      width: 600,
      editable: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Tooltip title={params.row.Company_name}>
            <div>{params.row.Company_name}</div>
          </Tooltip>
        )
      }
    },
    {
      field: 'Year',
      headerName: 'Година',
      width: 150,
      editable: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'username',
      headerName: 'Корисничко име',
      width: 150,
      editable: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Status',
      headerName: 'Статус',
      width: 150,
      editable: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'CreatedAt',
      headerName: 'Креирано',
      width: 150,
      editable: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return DateFormating(params.row.CreatedAt);
      },
    },
    {
      field: 'UpdatedAt',
      headerName: 'Креирано',
      width: 150,
      editable: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return DateFormating(params.row.UpdatedAt);
      },
    },
    {
      field: '#',
      headerName: 'Менаџирај',
      width: 108,
      renderCell: (params) => {
        return <Typography noWrap align={'center'}>
          <Tooltip title={'Прифати'} arrow>
            <IconButton
              onClick={() => handleAccept(params.row.ID)}
              color="primary"
            >
              <DoneIcon fontSize="small"/>
            </IconButton>
          </Tooltip>

          <Tooltip title={'Одбиј'} arrow>
            <IconButton
              onClick={() => handleReject(params.row.ID)}
              color="primary"
            >
              <CloseIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </Typography>
      },
      align: 'center',
      disableColumnMenu: true,
    }
  ]


  return (
    <>

      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={5}
          getRowId={row => row.ID}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>

        {/*<Card>*/}

          {/*<Divider />*/}

          {/*{paginatedUsers.length === 0 ? (*/}
            {/*<>*/}
              {/*<Typography*/}
                {/*sx={{ py: 10 }}*/}
                {/*variant="h3"*/}
                {/*fontWeight="normal"*/}
                {/*color="text.secondary"*/}
                {/*align="center"*/}
              {/*>*/}
                {/*{"Не се пронајдени нарачки."}*/}
              {/*</Typography>*/}
            {/*</>*/}
          {/*) : (*/}
            {/*<>*/}
              {/*<TableContainer>*/}
                {/*<Table>*/}
                  {/*<TableHead>*/}
                    {/*<TableRow>*/}
                      {/*<TableCell>{'Име на компанија'}</TableCell>*/}
                      {/*<TableCell>{'Матичен број'}</TableCell>*/}
                      {/*<TableCell>{'Даночен број'}</TableCell>*/}
                      {/*<TableCell>{'Година'}</TableCell>*/}
                      {/*<TableCell>{'Корисничко име'}</TableCell>*/}
                      {/*<TableCell>{'Статус'}</TableCell>*/}
                      {/*<TableCell>{'Креирано на'}</TableCell>*/}
                      {/*<TableCell>{'Изменето на'}</TableCell>*/}
                      {/*<TableCell>{'Менаџирај'}</TableCell>*/}

                    {/*</TableRow>*/}
                  {/*</TableHead>*/}
                  {/*<TableBody>*/}
                    {/*{paginatedUsers.map((user) => {*/}
                      {/*const isUserSelected = selectedItems.includes(user.id);*/}
                      {/*return (*/}
                        {/*<TableRow hover key={user.id} selected={isUserSelected}>*/}
                          {/*<TableCell >*/}
                            {/*<Typography variant="h5">*/}
                              {/*{user.Company_name}*/}
                            {/*</Typography>*/}
                          {/*</TableCell>*/}
                          {/*<TableCell  style={{textDecoration: "none"}}>*/}
                            {/*{user.PIN}*/}
                          {/*</TableCell>*/}
                          {/*<TableCell>*/}
                                  {/*{user.VAT}*/}
                          {/*</TableCell>*/}
                          {/*<TableCell>*/}
                          {/*{user.Year}*/}
                        {/*</TableCell>*/}
                          {/*<TableCell>*/}
                            {/*{user.username}*/}
                          {/*</TableCell>*/}
                          {/*<TableCell>*/}
                            {/*{user.Status}*/}
                          {/*</TableCell>*/}
                          {/*<TableCell>*/}


                            {/*{*/}
                              {/*DateFormating(user.CreatedAt)*/}
                            {/*}*/}


                          {/*</TableCell>*/}
                          {/*<TableCell>*/}
                            {/*{*/}
                              {/*DateFormating(user.UpdatedAt)*/}
                            {/*}*/}
                          {/*</TableCell>*/}
                          {/*<TableCell align="center">*/}
                            {/*<Tooltip title={'Прифати'} arrow>*/}
                              {/*<IconButton*/}
                                {/*onClick={() => handleAccept(user.ID)}*/}
                                {/*color="primary"*/}
                              {/*>*/}
                                {/*<DoneIcon fontSize="small"/>*/}
                              {/*</IconButton>*/}
                            {/*</Tooltip>*/}

                            {/*<Tooltip title={'Одбиј'} arrow>*/}
                              {/*<IconButton*/}
                                {/*onClick={() => handleReject(user.ID)}*/}
                                {/*color="primary"*/}
                              {/*>*/}
                                {/*<CloseIcon fontSize="small"/>*/}
                              {/*</IconButton>*/}
                            {/*</Tooltip>*/}

                          {/*</TableCell>*/}
                        {/*</TableRow>*/}
                      {/*);*/}
                    {/*})}*/}
                  {/*</TableBody>*/}
                {/*</Table>*/}
              {/*</TableContainer>*/}
              {/*<Box p={2}>*/}
                {/*<TablePagination*/}
                  {/*component="div"*/}
                  {/*count={filteredUsers.length}*/}
                  {/*onPageChange={handlePageChange}*/}
                  {/*onRowsPerPageChange={handleLimitChange}*/}
                  {/*page={page}*/}
                  {/*rowsPerPage={limit}*/}
                  {/*rowsPerPageOptions={[5, 10, 15]}*/}
                {/*/>*/}
              {/*</Box>*/}
            {/*</>*/}
          {/*)}*/}
        {/*</Card>*/}


      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {'Are you sure you want to permanently delete this user account'}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {'Cancel'}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {'Delete'}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  users: []
};

export default Results;
