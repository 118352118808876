import React from 'react';

import {
  Dialog,
  Typography,
  Avatar,
  Box,
  Button,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Label from "../../../../components/Label";
import CheckIcon from '@mui/icons-material/Check';


const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonSuccess = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);


class BuyStatus extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      files: '',
      year: '',
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false})
  }

  returnTypeMessage = (type,message) => {
    if(type === 'error'){
      return<><AvatarError>
        <CloseIcon color={'error'} />
      </AvatarError>

        <Typography
          align="center"
          sx={{
            pt: 1,
            px: 6
          }}
          variant="h3"
        >
          {message}
        </Typography></>
    }
    if(type === 'success') {
      return<><AvatarSuccess>
        <CheckIcon color={'success'} />
      </AvatarSuccess>

        <Typography
          align="center"
          sx={{
            pt: 1,
            px: 6,
            pb: 2,
          }}
          variant="h3"
        >
          {message}
        </Typography>
        <Label style={{fontSize: '12px'}}>Доколку нарачаните извештаи не ги добиете веднаш, истите ќе бидат обработени и ќе Ви бидат прикажани во максимален рок од 2 часа.</Label>

      </>
    }

  }


  ReturnMessage = () => {
    let typeMessage = this.props.message;
    if(typeMessage === 'completed'){
      return this.returnTypeMessage('success', 'Успешно плаќање!');
    }
    if(typeMessage === 'canceled'){
      return this.returnTypeMessage('error', 'Неуспешно плаќање!');
    }

  }


  render(){
      return(
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
            mt={3}
          >

            {this.ReturnMessage()}
            <Box item >
              <ButtonSuccess
                onClick={this.props.onClose}
                size="large"
                sx={{
                  mx: 1,
                  px: 3,
                  mt: 3
                }}
                variant="contained"
              >
                {'Во ред'}
              </ButtonSuccess>
            </Box>
          </Box>
        </Dialog>
      )
  }
}


export default BuyStatus;
