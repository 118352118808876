import React from 'react';

import {
  Button,
  TextField,
  CircularProgress
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
class LoginJWT extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      username: this.getCookie('username'),
      password: "",
      isSubmitting: false,
      remember: false,
    }
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  Login = () => {
    if(this.state.username === '' || this.state.password === '') {
      this.setState({usernameError: 'Внесете корисничко име и лозинка',username: '', password: ''})
    }
    else {
      window.makeRequest("POST", `/api/Authenticate`, {
        Username: this.state.username,
        Password: this.state.password
      }, true, (callback) => {
        if(callback.body.Message === 'OK') {
          if(this.state.remember){
            const d = new Date();
            d.setTime(d.getTime() + (26*60*60*1000)); //2*24*60*60*1000
            let expires = "expires="+ d.toUTCString();
            document.cookie = 'username' + "=" + this.state.username + ";" + expires + ";path=/";
          }
          this.setState({username: '', password: ''}, this.props.login(callback.body))
        }
        else if(callback.body.Message === 'Not active'){
          this.setState({usernameError: 'Ве молиме направете валидација на Вашиот кориснички пристап',username: '', password: ''})
        }
        else {
          this.setState({usernameError: 'Невалидна лозинка или корисничко име',username: '', password: ''})
        }


      }, (err) => {
        if(err) {
          console.log("err", err);
        }
        console.log('err', err);
      });
    }

  };

  handleKeyPress = (e) => {

   if(e.charCode === 13){
     this.Login();
   }
  };

  getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  render(){

    return (
      <>
        <TextField
          error={this.state.usernameError && this.state.usernameError}
          fullWidth
          margin="normal"
          autoFocus
          helperText={this.state.usernameError && this.state.usernameError}
          label={'Корисничко име'}
          name="username"
          onChange={this.handleChange}
          type="text"
          value={this.state.username}
          variant="outlined"
        />
        <TextField
          error={this.state.usernameError && this.state.usernameError}
          fullWidth
          margin="normal"
          label={'Лозинка'}
          name="password"
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          type="password"
          value={this.state.password}
          variant="outlined"
        />

        <FormGroup>
          <FormControlLabel control={<Checkbox checked={this.state.remember} onChange={(e) => {this.setState({remember: !this.state.remember})}}/>} label="Запомни ме" />
        </FormGroup>

        <Button
          sx={{ mt: 3 }}
          color="primary"
          onClick={this.Login}
          startIcon={this.state.isSubmitting ? <CircularProgress size="1rem" /> : null}
          disabled={this.state.isSubmitting}
          type="submit"
          fullWidth
          size="large"
          variant="contained"
        >
          {'Најава'}
        </Button>
      </>
    );
  }

}

export default LoginJWT;
