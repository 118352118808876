import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function CartPreview(props) {
  return (
    <IconButton aria-label="cart" onClick={()=> {
      window.location.href = "/Cart"
    }}>
      {props.number ?
        <StyledBadge badgeContent={props.number} color='error'>
          <ShoppingCartIcon/>
        </StyledBadge> : <ShoppingCartIcon/>
      }
    </IconButton>
  );
}
