import React from 'react';

const steps = [
  'БИ Пакет',
  'Регистрација на корисник',
  'Регистрација на компанија',
  'Потврда на е-маил',
];

import {
  Box,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';

class Steps extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){

  }


  render(){
    return(
      <Box style={{backgroundColor: 'white'}} pb={1}>
        <Stepper style={{backgroundColor: 'white'}} activeStep={this.props.step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }
}

export default Steps;
