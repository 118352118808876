import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
  Button
} from '@mui/material';

import {styled} from "@mui/material/styles/index";


const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Import from '../../../../components_bi/Import';
import BuyDialog from '../../Dashboards/DIalogs/BuyDialog';
import BuyStatusDialog from "../../Dashboards/DIalogs/BuyStatusDialog";

class AddReportDialog extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      report: '',
      openBuyDialog: false,
      BuyStatusDialog: false,
      BuyStatusMessage: '',
      successImport: false
    }
  }

  componentDidMount(){

  }


  successImport = () => {
    this.setState({successImport: true})
  }


  AddReport = () => {
    window.location.href = `/add/report/${this.props.companyID}`;
    this.props.onClose();
  }

  loading = () => {
    this.props.onClose();
  }

  stopLoading = () => {


  }

  openBuyDialog = () => {
    this.setState({openBuyDialog: true})
  }

  closeBuyDialog = (status) => {
    if(status === 'OK') {
      this.setState({openBuyDialog: false, BuyStatusDialog: true, BuyStatusMessage: 'accepted'})
    }else if(status === 'close'){
      this.setState({openBuyDialog: false, })
    }
    else if(status === 'Found'){
      this.setState({openBuyDialog: false, BuyStatusDialog: true, BuyStatusMessage: 'found'})
    }else {
      this.setState({openBuyDialog: false, BuyStatusDialog: true, BuyStatusMessage: 'rejected'})
    }
  }

  closeBuyStatusDialog = () => {
    this.setState({BuyStatusDialog: false, BuyStatusMessage: ''})
  }


  render(){
    return (
      <>
      <Dialog
      fullWidth
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <Box sx={{height: '400px'}}>

      <DialogTitle sx={{ p: 4, color: 'blue'}}>
        <Typography style={{fontSize: 18.
          , fontWeight: 600}} gutterBottom align='center'>
          {`Одберете начин за внесување на финансиските податоци за одбраната компанија:`}<br /><br />
        </Typography>
        <Typography variant='subtitle3'>
        <b>Вчитај</b> (внесување на извештај со помош на дигитална копија),<br />
        <b>Купи</b> (можност за купување на посакуваниот извештај, кој ќе биде автоматски прикажан)</Typography>
        
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'space-around'} mx={4}>
              {/*<Button*/}
                {/*type="submit"*/}
                {/*size='small'*/}
                {/*variant="contained"*/}
                {/*style={{width: '150px', height: '45px'}}*/}
                {/*onClick={this.AddReport}*/}
              {/*>*/}
                {/*{'Внеси'}*/}
              {/*</Button>*/}
              <Import loading={this.loading} title='Вчитај' stopLoading={this.stopLoading} embs={this.props.PIN} id={this.props.companyID} openAddReport={this.props.openAddReport} btnWidth={170} successImport={this.successImport} showMessageLoad={this.props.showMessageLoad}/>
              <Button
                type="submit"
                variant="contained"
                style={{width: '170px', height: '50px'}}
                onClick={this.openBuyDialog}
              >
                {'Купи'}
              </Button>
            </Box>
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions sx={{padding: '10px 15px', display: 'flex', justifyContent: 'left' }}>
        <Button color="primary" onClick={()=>{
          this.props.onPrev  ? this.props.onPrev() : this.props.onClose()
        }}>
          <ArrowBackIcon />
        </Button>
      </DialogActions>
      </Box>

    </Dialog>
        {this.props.companyID ?
          <>
            <BuyDialog openBuyDialog={this.state.openBuyDialog} companyID={this.props.companyID}  onClose={this.closeBuyDialog}/>
            <BuyStatusDialog  open={this.state.BuyStatusDialog} message={this.state.BuyStatusMessage} onClose={this.closeBuyStatusDialog} />
          </> : ''
        }

        {
          this.state.successImport && <DialogWrapper
            open={this.state.successImport}
            keepMounted
            onClose={this.props.onClose}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={'400px'}
              width={'600px'}
              borderRadius={1}
              // p={2}
              // style={{
              //   backgroundColor: '#DADADA',
              // }}
            >
              <Box width='90%' height='70%' style={{textAlign: 'center', lineHeight: '50px'}}>
                <Typography align="center" style={{ paddingBottom: '70px', color: 'blue'}} variant="h3">
                  {('Успешно импортиран документ' )}
                </Typography>
                <Typography align="center" style={{ paddingBottom: '80px', color: 'blue'}} variant="h4">
                  {('Документот ќе биде превземен за обработка од нашите администратори. За проверка на статусот на извештајот можете да пристапите до Мој Профил - Импорти' )}
                </Typography>
                <Typography align="right">
                  <Button
                    sx={{ mt: { xs: 2, sm: 0 } }}
                    onClick={()=> this.setState({successImport: false})}
                    variant="contained"
                  >
                    {('Во ред')}
                  </Button>
                </Typography>
              </Box>

            </Box>
          </DialogWrapper>
        }
    </>
    )
  }
}
export default AddReportDialog;
