import React from 'react';

import {
  Box,
  Card,
  Divider,
  alpha,
  styled,
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from '@mui/material';

import SingleCard from './SingleCard'
import AdditionalPlan from './AdditionalPlan'
import ButtonGroupToggle from '../../../../components_bi/Buttons/ButtonGroupToggle'
class PlanCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selecedID: ''
    }
  }t


  componentDidMount() {
    if(this.props.displayCurrentPlan && this.props.displayCurrentPlan.hasOwnProperty('SubscriptionID')){
      this.setState({selectedID: this.props.displayCurrentPlan.SubscriptionID})
    }
  }


  setSelected = (id,  plan) => {
    this.setState({selectedID: id}, this.props.onClick(id, plan));
  }

  setChangeSubscriptionPeriod = (e) => {
    this.props.changeSubscriptionPeriod(e)
  }


  render() {
    return<>
    <Grid container spacing={2} px={4} mt={2} mb={1} >
      <Grid item xs={12}>
        <ButtonGroupToggle SubscriptionPeriod={this.props.SubscriptionPeriod} onChange={this.setChangeSubscriptionPeriod}/>
      </Grid>
        {this.props.subscriptionPlan.length > 0 && this.props.subscriptionPlan.map((plan) => {
          return<Grid item xs={12} md={3}  >
            <SingleCard SubscriptionPeriod={this.props.SubscriptionPeriod} plan={plan} setSelected={this.setSelected} selected={this.state.selectedID} onClick={this.props.onClick} readOnly={this.props.readOnly ? this.props.readOnly : false}/>
          </Grid>
        })}
        {/* {this.props.SubscriptionPeriod == 'monthly' ?
          <Grid item xs={12}>
            Цената за дополнителен корисник и дополнително портфолио за секој од прикажаните пакети, изнесува 300 денари +ддв
          </Grid> :
          <Grid item xs={12}>
            Цената за дополнителен корисник и дополнително портфолио за секој од прикажаните пакети, изнесува 2880 денари +ддв
          </Grid>

        } */}
        {/* <Grid item xs={12} style={{color: '#a70000'}}>
          *<a href="https://www.bonitet.mk" style={{color: '#a70000', textDecoration: 'none'}}>Бонитет.мк</a> го задржува правото за одобрување на дополнителен лимит на компании
        </Grid> */}

    </Grid>

    </>;
  }
}


export default PlanCard;
