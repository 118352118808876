import {
  Box,
  Card,
  Typography,
  Grid,
  Button,
  Avatar,
  styled,
  Divider,
  Tooltip,
  useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Sun from '../weather/sun.png';
import Mist from '../weather/mist.png';
import Storm from '../weather/brainstorming.png';
import Clound from '../weather/cloud.png';
import Cloudy from '../weather/cloudy.png';
import Snowy from '../weather/snowy.png';
import {Link as RouterLink } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/Info';

const AvatarDisplayWeather = styled(Avatar)(
  ({ theme }) => `
        background-color: white;
        color: white;
        width: ${theme.spacing(9)};
        height: ${theme.spacing(9)};
        box-shadow: white;
        top: -${theme.spacing(4.5)};
        position: absolute;
        left: 50%;
        margin-left: -${theme.spacing(4.5)};
  `
);


function WeatherCard(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const description = props.description;
  const title = props.title;


  const returnIcon = () => {
    let title = props.title;
    let denoviNaNaplata = props.displayWeather && props.displayWeather.denoviNaNaplata != null? props.displayWeather.denoviNaNaplata : null;
    let denoviNaIsplata = props.displayWeather && props.displayWeather.denoviNaIsplata != null ? props.displayWeather.denoviNaIsplata : null;
    let brzaLikvidnost = props.displayWeather && props.displayWeather.brzaLikvidnost != null? props.displayWeather.brzaLikvidnost : null;
    let zadolzenost = props.displayWeather && props.displayWeather.zadolzenost != null ? parseFloat(props.displayWeather.zadolzenost * 100).toFixed(0) : null;
    let Ocena = props.displayWeather && props.displayWeather.Ocena != null ? parseFloat(props.displayWeather.Ocena).toFixed(0) : null;
    let periodVrakanjeKrediti = props.displayWeather && props.displayWeather.periodVrakanjeKrediti != null ? parseFloat(props.displayWeather.periodVrakanjeKrediti).toFixed(0) : null;
    let showIcon = '';
    switch(title){
      case title = "Денови на наплата на побарувања":
        switch(true) {
          case denoviNaNaplata === null:
            showIcon = Mist;
            break;
          case denoviNaNaplata <= 60:
            showIcon = Sun;
            break;
          case denoviNaNaplata >= 61 && denoviNaNaplata <= 130:
            showIcon = Cloudy;
            break;
          case denoviNaNaplata >= 131 && denoviNaNaplata <= 180:
            showIcon = Clound;
            break;
          case denoviNaNaplata >= 181 && denoviNaNaplata <= 300:
            showIcon = Mist;
            break;
          case denoviNaNaplata >= 301 && denoviNaNaplata <= 380:
            showIcon = Snowy;
            break;
          case denoviNaNaplata >= 381:
            showIcon = Storm;
            break;

        }
        break;
      case title = "Денови на исплата на обврски":
        switch(true) {
          case denoviNaIsplata === null:
            showIcon = Mist;
            break;
          case denoviNaIsplata <= 75:
            showIcon = Sun;
            break;
          case denoviNaIsplata >= 76 && denoviNaIsplata <= 150:
            showIcon = Cloudy;
            break;
          case denoviNaIsplata >= 151 && denoviNaIsplata <= 250:
            showIcon = Clound;
            break;
          case denoviNaIsplata >= 251 && denoviNaIsplata <= 340:
            showIcon = Mist;
            break;
          case denoviNaIsplata >= 341 && denoviNaIsplata <= 440:
            showIcon = Snowy;
            break;
          case denoviNaIsplata >= 441:
            showIcon = Storm;
            break;

        }
        break;
      case title = "Брза ликвидност":
        switch(true) {
          case brzaLikvidnost === null:
            showIcon = Mist;
            break;
          case brzaLikvidnost >= 1.8:
            showIcon = Sun;
            break;
          case brzaLikvidnost >= 1.2 && brzaLikvidnost <= 1.79:
            showIcon = Cloudy;
            break;
          case brzaLikvidnost >= 0.9 && brzaLikvidnost <= 1.19:
            showIcon = Clound;
            break;
          case brzaLikvidnost >= 0.6 && brzaLikvidnost <= 0.89:
            showIcon = Mist;
            break;
          case brzaLikvidnost >= 0.4 && brzaLikvidnost <= 0.59:
            showIcon = Snowy;
            break;
          case brzaLikvidnost <= 0.39:
            showIcon = Storm;
            break;

        }
        break;
      case title = "Задолженост":
        switch(true) {
          case zadolzenost === null:
            showIcon = Mist;
            break;
          case zadolzenost <= 20:
            showIcon = Sun;
            break;
          case zadolzenost >= 21 && zadolzenost <= 40:
            showIcon = Cloudy;
            break;
          case zadolzenost >= 41 && zadolzenost <= 60:
            showIcon = Clound;
            break;
          case zadolzenost >= 61 && zadolzenost <= 75:
            showIcon = Mist;
            break;
          case zadolzenost >= 76 && zadolzenost <= 85:
            showIcon = Snowy;
            break;
          case zadolzenost >= 85:
            showIcon = Storm;
            break;

        }
        break;
      case title = "Кредитен скор":
        switch(true) {
          case Ocena === null:
            return showIcon = Mist;
          case Ocena >= 91:
            return showIcon = Sun;
          case Ocena >= 76 && Ocena <= 90:
            return showIcon = Cloudy;
          case Ocena >= 61 && Ocena <= 75:
            return showIcon = Clound;
          case Ocena >= 45 && Ocena <= 60:
            return showIcon = Mist;
          case Ocena >= 37 && Ocena <= 44:
            return showIcon = Snowy;
          case Ocena <= 36:
            return showIcon = Storm;

        }
        break;
      case title = "Просечен период на враќање на кредити (во години)":
        switch(true) {
          case periodVrakanjeKrediti === null:
            showIcon = Mist;
            break;
          case periodVrakanjeKrediti <= 0.5:
            showIcon = Sun;
            break;
          case periodVrakanjeKrediti >= 0.51 && periodVrakanjeKrediti <= 2:
            showIcon = Cloudy;
            break;
          case periodVrakanjeKrediti >= 2.01 && periodVrakanjeKrediti <= 3.5:
            showIcon = Clound;
            break;
          case periodVrakanjeKrediti >= 3.51 && periodVrakanjeKrediti <= 4.5:
            showIcon = Mist;
            break;
          case periodVrakanjeKrediti >= 4.51 && periodVrakanjeKrediti <= 7:
            showIcon = Snowy;
            break;
          case periodVrakanjeKrediti >= 7.01:
            showIcon = Storm;
            break;

        }
        break;
    }
    return showIcon;
  }
  const returnValue = () => {
    let title = props.title;
    let value = '';
    switch(title) {
      case title = "Денови на наплата на побарувања":
        value = props.displayWeather && props.displayWeather.denoviNaNaplata !== null ? parseFloat(props.displayWeather.denoviNaNaplata).toFixed(0) : 'N/R';
        break;
      case title = "Денови на исплата на обврски":
        value = props.displayWeather && props.displayWeather.denoviNaIsplata !== null ? parseFloat(props.displayWeather.denoviNaIsplata.toFixed(0)) : 'N/R';
        break;
      case title = "Брза ликвидност":
        value = props.displayWeather && props.displayWeather.brzaLikvidnost !== null ? parseFloat(props.displayWeather.brzaLikvidnost).toFixed(2) : 'N/R';
        break;
      case title = "Задолженост":
        value = props.displayWeather && props.displayWeather.zadolzenost !== null ? `${parseFloat(props.displayWeather.zadolzenost * 100).toFixed(0)}%` : 'N/R';
        break;
      case title = "Кредитен скор":
        value = props.displayWeather && props.displayWeather.Ocena !== null ? parseFloat(props.displayWeather.Ocena).toFixed(0) : 'N/R';
        break;
      case title = "Просечен период на враќање на кредити (во години)":
        value = props.displayWeather && props.displayWeather.periodVrakanjeKrediti !== null ? parseFloat(props.displayWeather.periodVrakanjeKrediti).toFixed(0) : 'N/R';
        break;
    }
    return value;
  }

  const returnTitle = icon => {
    let value = '';
    switch(icon) {
      case Mist:
        value = 'Остварени резултати под просечните вредности или не може да се прикаже вредност за сооветниот параметар';
        break;
      case Sun:
        value = 'Остварени задоволителни резултати за прикажаниот параметар';
        break;
      case Cloudy:
        value = 'Остварени резултати над просечните вредности за прикажаниот параметар';
        break;
      case Clound:
        value = 'Остварени просечни резултати за прикажаниот параметар';
        break;
      case Snowy:
        value = 'Остварени ниски резултати за прикажаниот параметар';
        break;
      case Storm:
        value = 'Остварени незадоволителни резултати за прикажаниот параметар';
        break;
    }
    return value;
  }

  return (
    <Card
          sx={{
            overflow: 'visible',
            position: 'relative',
            pt: 3.5,
            pb: 2,
            mt: 4.5,
            maxHeight: '200px',
            minHeight: '200px',
          }}
        >
        <Box style={{width: '15px', position: 'absolute', right: 10, top: 10}}>
          <Typography align='right'>
            <Tooltip title={returnTitle(returnIcon())} placement='top' arrow>
              <InfoIcon style={{fontSize: 15}} color={"primary"}/>
            </Tooltip>
          </Typography>
        </Box>
      <Tooltip
        placement="top"
        arrow
        title={returnValue() !== 'N/R' ? description : ''/*<Button component={RouterLink} to={'/cart'} color={'warning'} variant={'outlined'} startIcon={<p>Купи</p>}>За компанијата немате внесено доволно финансиски податоци. Ве молиме дополнете го извештајот или купете ги податоците преку копчето</Button>*/}
      >
        <AvatarDisplayWeather >
          <img style={{width: '50px'}} src={returnIcon()} alt="image" />
        </AvatarDisplayWeather>
      </Tooltip>



          <Box px={3.5} pt={3.5}>

            <Typography
              textAlign="center"
              variant="h1"
              sx={{
                mb: 1,
                fontSize: `${theme.typography.pxToRem(26)}`
              }}
            >
              {returnValue()}
            </Typography>
            <Divider
              sx={{
                mb: 2
              }}
            />
            <Typography
              textAlign="center"
              variant="subtitle2"
              sx={{
                mb: 3,
                fontSize: `${theme.typography.pxToRem(10.5)}`
              }}
            >
              {t(props.title)}
            </Typography>
          </Box>

      </Card>

  );
}

export default WeatherCard;
