import {  Box, CardHeader,Typography,  Grid } from '@mui/material';
import Label from '../../../../../components/Label'
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


function StatusCard(props) {

  return (
    <>
        <Grid   p={0}>
          <CardHeader
            sx={{ p: 0,  }}
            title={'Статус на компанија'}
            subheaderTypographyProps={{ variant: 'body1' }}
            titleTypographyProps={{ variant: 'h3', gutterBottom: true }}
          >
          </CardHeader>
          <Grid
            container
            display={'flex'}
            justifyContent="center"
            alignContent={'center'}
            xs={12}

          >
              <Grid item xs={6} pb={1} pt={4} pr={1}  container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center" >

                  <ApartmentIcon alignItems={'center'} fontSize={'large'} mr={-1}/>
                  <Label   alignItems={'center'} color={'secondary'}>Големина на компанијата</Label>
                  <Typography  variant={'h5'}>{props.company.Size}</Typography>


              </Grid>

              <Grid item xs={6}  pb={1} pt={4} pl={1}
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"  >

                  <AccessTimeIcon fontSize={'large'} mr={-1} />
                  <Label  color={'secondary'} >Старост на компанијата</Label>
                   <Typography variant={'h5'}  >{new Date().getFullYear() - props.company.Establishment_year} години</Typography>

              </Grid>

          </Grid>

        </Grid>
    </>
  );
}

export default StatusCard;
