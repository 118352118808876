import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import {Link as RouterLink, withRouter} from "react-router-dom";




const roles = [
    {label: "Годишен извештај", value: "Годишен извештај"},
    {label: "Годишен извештај консолидиран", value: "Годишен извештај консолидиран"},
    {label: "Ревизорски извештај", value: "Ревизорски извештај"},
    {label: "Ревизорски извештај консолидиран", value: "Ревизорски извештај консолидиран"},
    ];

class AddReportDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      reportID: 0,
      Year: ''
    }
  }

  componentDidMount(){

  }

  validate = () => {
    let yearError = '';
    let reportTypeError = '';
    let Year = this.state.Year;
    let currentReportName = this.state.currentReportName;
    let Establishment_year = this.state.Establishment_year;




    switch (true) {
      case Year.length < 1:
        yearError = 'Годината не може да биде празно поле.';
        break;
      case /^s*-?[0-9]{1,10}s*$/g.test(Year) !== true:
        yearError = 'Внесете валидна година';
        break;
      case Year - Establishment_year < 0:
        yearError = 'Годината не смее да биде помала од годината на основање.';
        break;
      case parseInt(Year) > parseInt(new Date().getFullYear()) - 1:
        yearError = 'Годината не смее да биде поголема од ' + (new Date().getFullYear() - 1).toString();
        break;
    }
    switch (true) {
      case !currentReportName:
        reportTypeError = 'Селектирајте тип на репорт';
        break;
    }


    if(yearError || reportTypeError){
      this.setState({
        yearError,
        reportTypeError
      });

      return false;
    }


    this.setState({yearError, reportTypeError});
    return true;
  };
  handleChange = (e) => {
    if(e.target.name === "Year"){
      this.setState({[e.target.name]: e.target.value, AgeOld: e.target.value - this.state.Establishment_year})
      this.forceUpdate();
    }
    else {
      this.setState({[e.target.name]: e.target.value});
      this.forceUpdate();
    }


  };
  changeRole = (ev, val) => {
    if(val) {
      this.setState({ currentReportName: val.value });
      if(val.value === 'Годишен извештај')
        this.setState({reportID: 2});
      else if(val.value === 'Годишен извештај консолидиран')
        this.setState({reportID: 3});
      else if(val.value === 'Ревизорски извештај')
        this.setState({reportID: 4});
      else if(val.value === 'Ревизорски извештај консолидиран')
        this.setState({reportID: 5});

      if(this.state.Year){

      }

    }
    else{
      this.setState({currentReportName: ''});
    }

  };
  handleConfirm = () => {
    let valid = this.validate();
    if(valid){
      window.makeRequest("GET", `/api/Finance?Company_ID=${this.props.match.params.id}&Report_type_ID=${this.state.reportID}&Year=${this.state.Year}`, false, true, (callback) => {
        if(callback.body) {

          this.setState({ reportTypeError: '',reportName: this.state.currentReportName }, () => this.props.showResults(this.state.currentReportName, this.state.reportID, this.state.Year));
        }
        else{
          this.setState({reportTypeError: 'Веќе постои таков извештај'});
        }

      }, (err) => {
        if(err) {
          console.log('err', err);
        }
      });
    }

  };


  render(){
    return<Dialog
      fullWidth
      open={this.props.open}
      onClose={this.handleCreateUserClose}
    >
      <Box >
      <DialogTitle sx={{ p: 3 }}>
        <Typography style={{fontSize: 15, fontWeight: 600, color: 'blue'}} gutterBottom align='center'>
          {`Креирајте нов извештај`}<br />
        </Typography>
        <Typography style={{fontSize: 15,  color: 'blue', fontWeight: 600}} gutterBottom align='center'>
          {`Пополнете ги полињата подолу за да креирате нов извештај`}<br /><br />
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{mt: 2}}>
                <TextField
                  fullWidth
                  label={'Година'}
                  name="Year"
                  onChange={this.handleChange}
                  value={this.state.Year}
                  variant="outlined"
                />
                {
                  this.state.yearError?<Alert severity="error" sx={{mt: 1}}>{this.state.yearError}</Alert>:''
                }

              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  options={roles}
                  onChange={this.changeRole}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label={'Тип на извештај'}
                    />
                  )}
                />
                {
                  this.state.reportTypeError?<Alert severity="error" sx={{mt: 1}}>{this.state.reportTypeError}</Alert>:''
                }
                {
                  this.state.financeError?<Alert severity="error" sx={{mt: 1}}>{this.state.financeError}</Alert>:''
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={this.handleConfirm}
        >
          {'Потврди'}
        </Button>
        <Button
          color="secondary"
          component={RouterLink}
          to={`/company/${this.props.match.params.id}/dashboard`}
        >
          {'Откажи'}
        </Button>
      </DialogActions>
      </Box>
    </Dialog>
  }
}

export default withRouter(AddReportDialog);
