import { Link, Box,  Grid, styled, Typography, useTheme } from '@mui/material';
import Map from './Map'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const DividerVertialPrimary = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    background: ${theme.colors.primary.main};
  `
);


const LabelPrimary = styled(Box)(
  ({ theme }) => `
      font-weight: bold;
      border-radius: ${theme.general.borderRadiusSm};
      background: ${theme.colors.primary.main};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(8)};
      padding: ${theme.spacing(0.5, 1.5)};
      color: ${theme.palette.primary.contrastText};
  `
);

function LocationDivision(props) {
  const gradovi = props.gradovi;
  const renderCities = () => {
    let boxes = [];
    boxes = Object.keys(gradovi).map((dejnost) => {
      return<Grid item xs={6} md={6}>
        <Box mt={1} display={'flex'} alignItems="center"  >
        <LabelPrimary style={{width: '150%'}}>{dejnost}</LabelPrimary>
        <Typography style={{fontSize: 15, fontWeight: "bold", }} sx={{ml:1}}>
          {gradovi[dejnost]}
        </Typography>
      </Box></Grid>
    })


    return boxes;

  }
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('lg'));




  return (
    <>
      <DividerVertialPrimary />
      <Link
        color="text.primary"
        component="a"
        variant="h4"
        fontWeight="normal"
      >
        Локација
      </Link>
      <Grid container xs={12}  display={"flex"} justifiContent={"space-between"}>

        <Grid item xs={8} >
          <Map item theme={matches} gradovi={props.gradovi}/>
        </Grid>

        <Grid item xs={4} >
          {renderCities()}
        </Grid>
      </Grid>
    </>
  );
}

export default LocationDivision;

