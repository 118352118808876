import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
  styled
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonSuccess = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});




function UserCreateLimit (props) {
  const ManageSubscription = props.ManageSubscription;

const ReturnButtonOrText = () => {
  if(ManageSubscription){
    return <div style={{margin: 'auto', textAlign: 'center'}}>
        <Typography
          align="center"
          sx={{
            py: 4,
            px: 6
          }}
          variant="h5"
        >
          {'Зголемете го бројот на корисници со клик на'}


        </Typography>
        <Button
          component={RouterLink}
          to={'/MyProfile/Subscription'}
        >Претплата</Button>
      </div>
  } else {
    return<Typography
      align="center"
      sx={{
        py: 4,
        px: 6
      }}
      variant="h6"
    >
      {'Контактирајте го вашиот адимистратор'}
    </Typography>
  }
}


  return<>
    <DialogWrapper
      open={props.open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarError>
          <CloseIcon />
        </AvatarError>

        <Typography
          align="center"
          sx={{
            pt: 1,
            px: 6
          }}
          variant="h3"
        >
          {'Лимитот за креирање на нов корисник е искористен!'}

        </Typography>
        {ReturnButtonOrText()}
        <Box item >
          <ButtonSuccess
            onClick={props.onClose}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
          >
            {'Во ред'}
          </ButtonSuccess>
        </Box>
      </Box>
    </DialogWrapper>

  </>
}

export default UserCreateLimit;
