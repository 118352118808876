import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

class CustomSnackBar extends React.Component{

  render(){
    return(
      <>
        <div style={{position: 'absolute', top: this.props.top || '20px', zIndex: 100000, height:'50px', boxShadow: '3px 3px 3px darkgrey', left: 0, right: 0,  marginRight: 'auto', width: 'max-content', marginLeft: 'auto', textAlign: 'center', borderRadius: '5px', margin: 'auto', backgroundColor: '#43A047', display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center'}}>
          <CheckCircleIcon style={{color: 'white', fontSize: '20px'}} />
          <p style={{color: 'white', fontWeight: '500', marginLeft: '5px'}}>{this.props.message}</p>
        </div>
      </>
    )
  }

}

export default CustomSnackBar
