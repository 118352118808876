import React from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

import {
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Box,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Button
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LaunchTwoTone from '@mui/icons-material/LaunchTwoTone';
import Label from '../../../../components/Label'

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      // row: [],
      Subscriptions: [],
      Name: '',
      Price: '',
      Price_annual: '',
      Num_Users: '',
      Num_Devisions: '',
      Price_Extra_User: '',
      Price_Extra_Devision: '',
      Price_Report: '',
      rowID: '',
      Active: true,
    }
  }

  componentDidMount(){
    this.getAllSubscriptions();
  }

  getAllSubscriptions = () => {
    window.makeRequest("GET", `/api/AdminSubscription`, false, true, (callback) => {
          this.setState({Subscriptions: callback.body, loading: false})
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        })
  }

  DataGridSubscriptions = () => {
    const columns = [
      {
        field: 'Name',
        headerName: 'Име на претплата',
        width: 150,
        editable: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'Price',
        headerName: 'Цена',
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <>${params.row.Price}</>
        },

      },
      {
        field: 'Price_Annual',
        headerName: 'Цена годишно',
        type: 'number',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <>${params.row.Price_Annual}</>
        },

      },
      {
        field: 'Num_Users',
        headerName: 'Број на корисници',
        type: 'date',
        headerAlign: 'center',
        align: 'center',
        width: 170,
        editable: true,
        disableColumnMenu: true,

      },
      {
        field: 'Num_Devisions',
        headerName: 'Број на дивизии',
        type: 'text',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        editable: true,
        disableColumnMenu: true,
      },
      {
        field: 'Price_Extra_User',
        headerName: 'Цена за дополнителен корисник',
        type: 'date',
        width: 300,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return <>${params.row.Price_Extra_User}</>
        },

      },
      {
        field: 'Price_Extra_Devision',
        headerName: 'Цена за дополнителенa дивизија',
        type: 'text',
        width: 300,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return <>${params.row.Price_Extra_Devision}</>
        },
      },
      {
        field: 'Price_Report',
        headerName: 'Цена за репорт',
        headerAlign: 'center',
        align: 'center',
        type: 'text',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <>${params.row.Price_Report}</>
        },
      },


      {
        field: 'Active',
        headerName: 'Статус',
        type: 'text',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          if(params.row.Active) {
            return<Label color={'success'}>Активна</Label>
          }else{
            return<Label color={'error'}>Неактивна</Label>
          }
        },
      },
      {
        field: '*',
        headerName: 'Менаџирај',
        type: 'text',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <><Tooltip title={'Промени'} arrow>
            <IconButton
              onClick={() => this.setState({loading: true}, () => this.handleOpenEdit(params.row))}
              color="primary"
            >
              <LaunchTwoTone fontSize="small" />
            </IconButton>
          </Tooltip></>
        },
      },
    ];

    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.state.Subscriptions}
          columns={columns}
          getRowId={row => row.ID}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  }

  handleOpenEdit = (row) => {
    this.setState({openEdit: true,
      Name: row.Name,
      Price: row.Price,
      Price_annual: row.Price_Annual,
      Num_Users: row.Num_Users,
      Num_Devisions: row.Num_Devisions,
      Price_Extra_User: row.Price_Extra_User,
      Price_Extra_Devision: row.Price_Extra_Devision,
      Price_Report: row.Price_Report,
      Active: row.Active,
      rowID: row.ID,
      loading: false,
     })
  }
  handleCloseEdit = () => {
    this.setState({openEdit: false,
      Name: '',
      Price: '',
      Price_annual: '',
      Num_Users: '',
      Num_Devisions: '',
      Price_Extra_User: '',
      Price_Extra_Devision: '',
      Price_Report: '',
      Active: true,
      rowID: '',
      loading: false,
      })
  }
  handeEditSubscription = () => {
            window.makeRequest("PUT", `/api/AdminSubscription?subscriptionID=${this.state.rowID}`, {
            Name: this.state.Name,
            Order: this.state.Order,
            Price: this.state.Price,
              Price_annual: this.state.Price_annual,
            Num_Users: this.state.Num_Users,
            Num_Devisions: this.state.Num_Devisions,
            Price_Report: this.state.Price_Report,
            Price_Extra_User: this.state.Price_Extra_User,
            Price_Extra_Devision: this.state.Price_Extra_Devision,
            Active: this.state.Active,
            }, true, (callback) => {
              this.setState({openEdit: false}, () => this.getAllSubscriptions())

            }, (err) => {
              if (err) {
                console.log('err', err);
              }
            })

  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  handleChangeNumber = (e) => {
    if(parseInt(e.target.value)){
      this.setState({[e.target.name]: parseInt(e.target.value)})
    }else if(e.target.value === ''){
      this.setState({[e.target.name]: e.target.value})
    }
  }


  render() {
    if(this.state.loading){
      return <SuspenseLoader />
    }
    else {
      return (
        <div >
          <Grid container>
            <Grid item sx={{p: 2, mt: 3}} xs={12}>
              <Card >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  justifyContent={{ xs: 'center', sm: 'space-between' }}
                  pb={3}
                >
                    {this.DataGridSubscriptions()}
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.openEdit}
            onClose={this.handleCloseEdit}
          >
            <DialogTitle sx={{ p: 3 }}>
              <Typography variant="h4" gutterBottom>
                {('Промена на претплата')}
              </Typography>
            </DialogTitle>


            <DialogContent dividers sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>

                    <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      autoFocus
                      fullWidth
                      label={'Име'}
                      name="Name"
                      onChange={this.handleChange}
                      value={this.state.Name}
                      variant="outlined"
                    />
                  </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Цена'}
                        name="Price"
                        onChange={this.handleChangeNumber}
                        value={this.state.Price}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Цена годишно'}
                        name="Price_annual"
                        onChange={this.handleChangeNumber}
                        value={this.state.Price_annual}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Број на корисници'}
                        name="Num_Users"
                        onChange={this.handleChangeNumber}
                        value={this.state.Num_Users}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Број на дивизии'}
                        name="Num_Devisions"
                        onChange={this.handleChangeNumber}
                        value={this.state.Num_Devisions}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Цена за дополнителен корисник'}
                        name="Price_Extra_User"
                        onChange={this.handleChangeNumber}
                        value={this.state.Price_Extra_User}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Цена за дополнителна дивизија'}
                        name="Price_Extra_Devision"
                        onChange={this.handleChangeNumber}
                        value={this.state.Price_Extra_Devision}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        label={'Цена за репорт'}
                        name="Price_Report"
                        onChange={this.handleChangeNumber}
                        value={this.state.Price_Report}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Статус</InputLabel>
                        <Select
                          label={'Статус'}
                          name={'Active'}
                          value={this.state.Active}
                          onChange={this.handleChange}
                        >
                          >
                            <MenuItem value={true}>Активна</MenuItem>
                            <MenuItem value={false}>Неактивна</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button color="secondary" onClick={() => this.setState({loading: true}, () => this.handleCloseEdit())}>
                {('Откажи')}
              </Button>
              <Button
                type="submit"
                onClick={() => this.setState({loading: true}, () => this.handeEditSubscription())}
                variant="contained"
              >
                {('Додади')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }

  }
}

export default Subscription;
