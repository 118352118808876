import { useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import BulkActions from './BulkActions';

const applyFilters = (companies, query) => {
  return companies.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['Company_name', 'Dejnost', 'size', 'VAT', 'PIN'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (user[property] && user[property].toString().toLowerCase().includes(query.toString().toLowerCase())) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (companies, page, limit) => {
  return companies.slice(page * limit, page * limit + limit);
};

const ResultOutdated = ({ companies, lastFinancialYear, close }) => {
  const [selectedItems, setSelectedCompanies] = useState([]);



  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');


  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllCompanies = (event) => {
    setSelectedCompanies(event.target.checked ? companies.map((company) => company) : []);
  };

  const handleSelectOneCompany = (_event, company) => {
    if (!selectedItems.includes(company)) {
      setSelectedCompanies((prevSelected) => [...prevSelected, company]);
    } else {
      setSelectedCompanies((prevSelected) =>
        prevSelected.filter((item) => item !== company)
      );
    }
  };

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCompanies = applyFilters(companies, query);
  const paginatedCompanies = applyPagination(filteredCompanies, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeCompanies =
    selectedItems.length > 0 && selectedItems.length < companies.length;
  const selectedAllCompanies = selectedItems.length === companies.length;


  return (
    <>
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={'Пребарувај...'}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && <BulkActions close={close} lastFinancialYear={lastFinancialYear} selectedItems={selectedItems}  />}
          </Box>

          <Divider />

          {paginatedCompanies.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {"Нема пронајдено резултати"}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllCompanies}
                          indeterminate={selectedSomeCompanies}
                          onChange={handleSelectAllCompanies}
                        />
                      </TableCell>
                      <TableCell>{'Име на компанија'}</TableCell>
                      <TableCell>{'ЕДБ'}</TableCell>
                      <TableCell>{'ЕМБС'}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedCompanies.map((company) => {
                      const isCompanySelectedSelected = selectedItems.includes(company);
                      return (
                        <TableRow hover key={company.ID} selected={isCompanySelectedSelected}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isCompanySelectedSelected}
                              onChange={(event) =>
                                handleSelectOneCompany(event, company)
                              }
                              value={isCompanySelectedSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {company.Company_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                                <Typography noWrap variant="subtitle2">
                                  {company.VAT}
                                </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{company.PIN}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredCompanies.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
    </>
  );
};

ResultOutdated.propTypes = {
  companies: PropTypes.array.isRequired,
  lastFinancialYear: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};


export default ResultOutdated;
