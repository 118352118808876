import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import BI_DataGrid from "../../../../components_bi/BI_DataGrid";
import Text from '../../../../components/Text';

import DeleteIcon from '@mui/icons-material/Delete';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';


const Results = ({ users, ...rest }) => {



  const handleDeleteSuccess = (id) => {
    window.makeRequest("DEL", `/api/Admin_AdminUsers?id=${id}`, false, true, (callback) => {
     rest.renderUsers();
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  };

  const handleActiveDeactive = (id) => {
    window.makeRequest("PUT", `/api/Admin_AdminUsers?id=${id}`, false, true, (callback) => {
      rest.renderUsers();
    }, (err) => {
      if(err) {
        console.log(err);
      }
    });
  }

  const columns = [


    {
      field: 'Username',
      headerName: 'Кориснчко име',
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      disableColumnMenu: true,

    },
    {
      field: 'Firstname',
      headerName: 'Име',
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      disableColumnMenu: true,

    },
    {
      field: 'Lastname',
      headerName: 'Презиме',
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      disableColumnMenu: true,

    },
    {
      field: 'Email',
      headerName: 'Е-пошта',
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      width: 300,
      disableColumnMenu: true,

    },
    {
      field: 'isActiv',
      headerName: 'Активен',
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,

    },
    {
      field: 'UserType',
      headerName: 'Тип на администратор',
      align: 'center',
      headerAlign: 'center',
      width: 250,
      type: 'text',
      renderCell: (params) => {
        return <>
          <Text>{params.row.UserType}</Text>

        </>
      },
      disableColumnMenu: true,
    },
    {
      field: '*',
      headerName: 'Менаџирај',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      type: 'text',
      renderCell: (params) => {
        return <>
          <Typography noWrap align={'center'}>
            <Tooltip title={('Избриши')} arrow>
              <IconButton
                onClick={() => handleDeleteSuccess(params.row.ID)}
                color="primary"
              >
                <DeleteIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
            {
              params.row.isActiv === false ?
                <Tooltip title={('Активирај')} arrow>
                  <IconButton
                    onClick={() => handleActiveDeactive(params.row.ID)}
                    color="primary"
                  >
                    <DoneIcon fontSize="small"/>
                  </IconButton>
                </Tooltip> :
                <Tooltip title={('Деактивирај')} arrow>
                  <IconButton
                    onClick={() => handleActiveDeactive(params.row.ID)}
                    color="primary"
                  >
                    <ClearIcon fontSize="small"/>
                  </IconButton>
                </Tooltip>
            }
          </Typography>

        </>
      },
      disableColumnMenu: true,
    },

  ];



  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
              <BI_DataGrid data={users} columns={columns} search={true} />
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  users: []
};

export default Results;
