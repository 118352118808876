import { Box, Container, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Hero from './Hero';
import HomeHeader from '../../components_bi/HomeHeader/index'
import HomeFooter from '../../components_bi/HomeFooter/index';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(14)};
`
);

const StyledBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.common.white};
    // height: 100%;
`
);

function Overview() {

  return (
    <StyledBox>
      <HomeHeader />
      <Hero />
      <HomeFooter/>
    </StyledBox>
  );
}

export default Overview;
