import PropTypes from 'prop-types';
import { HorizontalBar as Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material';

const TransactionsStatisticsChart = ({ data: dataProp, labels,period,percentage, ...rest }) => {
  const theme = useTheme();



  const data = {
    datasets: [
      {
        label: period,
        backgroundColor: theme.colors.primary.main,
        data: dataProp.income,
        barThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 2
      },

    ],
    labels
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 0,
    animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 0,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [0],
            borderDashOffset: [0],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [0],
            zeroLineBorderDashOffset: [0],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 0,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 20,

          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      caretSize: 6,
      displayColors: false,
      mode: 'label',
      intersect: true,
      yPadding: 8,
      xPadding: 16,
      borderWidth: 4,
      bodySpacing: 10,
      titleFontSize: 16,
      borderColor: theme.palette.common.black,
      backgroundColor: theme.palette.common.black,
      titleFontColor: theme.palette.common.white,
      bodyFontColor: theme.palette.common.white,
      footerFontColor: theme.palette.common.white,
      callbacks: {
        title: () => { },
        label: (tooltipItem) => {
          if(percentage){
            return `${period}: ${Math.round(tooltipItem.xLabel * 100)}%`;
          }
          return `${period}: ${tooltipItem.xLabel.toFixed(2)}`;
        }
      },
    }
  };

  return (
    <div {...rest}>
      <Bar data={data} options={options} />
    </div>
  );
};

TransactionsStatisticsChart.propTypes = {
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired

};

export default TransactionsStatisticsChart;
