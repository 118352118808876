import React from 'react';
import {
  Typography,
  Card,
  Button,
  Box,
} from '@mui/material';
import WishList from './WishList'
import CartList from './CartList'
import DeniedList from "./DeniedList";
import PaymentNotification from './PaymentNotificiation';
import DuplicateNotification from './DuplicateNotification';


class CartBody extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      list: [],
      openCheckOut: false,
      transactionReports: [],
      paymentStatus: false,
      BuyItems: [],
      TransactionID: '',
      openNotification: false,
      openDuplicateNotification: false
    }
  }

  componentDidMount(){

  }

  openCheckOut = (transactionReports) => {
    const ReportIDs = transactionReports.length > 0 && transactionReports.map(row => row.ID)
    window.makeRequest("POST", `/api/ReportOrders/Buy`, {
      ReportIDs: ReportIDs
    }, true, (callback) => {
      if(callback.body === -1) {

        this.setState({openNotification: true, openCheckOut: false})
      }
      else if(callback.body === -2){
        this.setState({openDuplicateNotification: true, openCheckOut: false})
      }
      else{
        window.location.href = `/transaction/${callback.body}/cart`;
      }
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });

  }

  closeNotificiation = () => {
    this.setState({openNotification: false})
  }

  closeDuplicateNotification = () => {
    this.setState({openDuplicateNotification: false})
  };


  removeFromList = (id) => {
    window.makeRequest("DEL", `/api/ReportOrders?ReportOrderID=${id}`, false, true, (callback) => {
      this.setState({openCheckOut: false, paymentStatus: false, transactionReports: []}, () => this.props.reload())
      window.isTokenValid();
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }


  render(){
    return(
      <Card sx={{ p: 3, mb: 3 }}>
        <CartList basket={this.props.basket}  ReportPrice={this.props.ReportPrice} openCheckOut={this.openCheckOut} removeFromList={this.removeFromList}  />
        <Box mt={2}>
          <WishList wishlist={this.props.wishlist} ReportPrice={this.props.ReportPrice} removeFromList={this.removeFromList} />
        </Box>
        <Box mt={2}>
          <DeniedList denied={this.props.denied} ReportPrice={this.props.ReportPrice} removeFromList={this.removeFromList} />
        </Box>
        <Typography variant="subtitle2" gutterBottom>
          {'Дополнителни информации'}
        </Typography>
        <Typography variant="body2">
          Контактирајте не за повеќе информации
        </Typography>

         <PaymentNotification open={this.state.openNotification} closeNotification={this.closeNotificiation} />
         <DuplicateNotification open={this.state.openDuplicateNotification} closeNotification={this.closeDuplicateNotification}/>
      </Card>
    )
  }
}

export default CartBody;

