import {Zoom} from "@mui/material/index";
import { useSnackbar } from 'notistack';


function SnackBar(props) {


  const message = props.message;
  const type = props.type;

  const { enqueueSnackbar } = useSnackbar();



  return enqueueSnackbar(message, {
    variant: type,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    TransitionComponent: Zoom
  });
}

export default SnackBar;
