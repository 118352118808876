import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SuspenseLoader from '../../../../components/SuspenseLoader'
import DateFormating from '../../../../components_bi/DateFormating'

import BI_DataGrid from "../../../../components_bi/BI_DataGrid";


const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Imports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      imports: [],


    }
  }

  componentDidMount() {
    this.getImports();
  }

  getImports = () => {
    window.makeRequest("GET", `/api/User_Imports?ShowAllImports=true`, false, true, (callback) => {
      this.setState({imports: callback.body, loading: false})
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }


  DataGridReports = () => {
    const columns = [

      {
        field: 'FileName',
        headerName: 'Име на документ',
        type: 'number',
        headerAlign: 'center',
        width: 180,
        align: 'center',
        disableColumnMenu: true,

      },
      {
        field: 'createdAt',
        headerName: 'Креиран',
        headerAlign: 'center',
        type: 'date',
        width: 250,
        align: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {
              DateFormating(params.row.createdAt)
            }
          </Typography>
        },

      },
      {
        field: 'updatedAt',
        headerName: 'Изменет',
        headerAlign: 'center',
        type: 'text',
        width: 250,
        align: 'center',
        disableColumnMenu: true,

        renderCell: (params) => {
          return <Typography noWrap align={'center'}>
            {
              DateFormating(params.row.updatedAt)
            }
          </Typography>
        },
      },
      {
        field: 'createdBy',
        headerName: 'Креирал',
        headerAlign: 'center',
        type: 'text',
        width: 150,
        align: 'center',
        disableColumnMenu: true,
      },
      {
        field: 'updatedBy',
        headerName: 'Изменил',
        headerAlign: 'center',
        type: 'text',
        width: 150,
        align: 'center',
        disableColumnMenu: true,
      },
      {
        field: 'Status',
        headerName: 'Статус',
        headerAlign: 'center',
        type: 'text',
        width: 150,
        align: 'center',
        disableColumnMenu: true,
      },
      // {
      //   field: '*',
      //   headerName: 'Менаџирај',
      //   type: 'text',
      //   width: 150,
      //   align: 'center',
      //   disableColumnMenu: true,
      //   renderCell: (params) => {
      //     return <><Typography noWrap align={'center'}>
      //       <Tooltip title={('Преглед')} arrow>
      //         <IconButton
      //           // onClick={() => this.openOrderShow(params.row.TransactionLines)}
      //           color="primary"
      //         >
      //           <LaunchTwoToneIcon fontSize="small"/>
      //         </IconButton>
      //       </Tooltip>
      //     </Typography>
      //     </>
      //   },
      //
      // },

    ];


    return (
      <BI_DataGrid  data={this.state.imports} search={true} columns={columns}  />
    );
  }

  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={1}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {'Преглед на импорти'}
                </Typography>
                <Typography variant="subtitle2">
                  {
                    'Преглед на сите обработени и импортирани извештаи од администраторска страна'
                  }
                </Typography>
              </Box>
            </Box>


          </Box>
          {this.DataGridReports()}
        </RootWrapper>

      );
    }

  }


}


export default Imports;

