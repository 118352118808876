import { useState,  useEffect } from 'react';
import PropTypes from 'prop-types';


import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  aop_titles,
  aop_new_obj,
  aop_fields1,
  app_fields2,
  aop_new_obj_before_year,
  aop_new_obj_this_year,
  validatedFields
} from './Data/aop_titles';
import { useSnackbar } from 'notistack';
import SingleReportResultReportForm from './SingleReportResultReportForm';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);




const applyFilters = (users, query, filters) => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['email', 'name', 'username'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (filters.role && user.role !== filters.role) {
        matches = false;
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (users, page, limit) => {
  return users.slice(page * limit, page * limit + limit);
};

const Results = ({ users, ...rest }) => {

  const [thisYearObj, setThisYearObj] = useState({Godina:rest.Year, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});
  const [lastYearObj, setLastYearObj] = useState({Godina:rest.Year- 1, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if(!isMounted) {
      setIsMounted(true);
      deleteData();
    }
  });


  function deleteData() {
    setThisYearObj({Godina:rest.Year, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});
    setLastYearObj({Godina:rest.Year - 1, Report_type_ID:rest.reportID, Company_ID:parseInt(rest.CompanyID), isDefault:false});
  };


  const [selectedItems, setSelectedUsers] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tabs = [
    {
      value: 'Биланс на состојба',
      label: t('Биланс на состојба')
    },
    {
      value: 'Биланс на успех',
      label: t('Биланс на успех')
    },
  ];



  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');

  const [filters, setFilters] = useState({
    role: 'Биланс на состојба'
  });

  const handleTabsChange = (event, tabsValue) => {

    setFilters((prevFilters) => ({
      ...prevFilters,
      role: tabsValue,

    }));

  };


  const filteredUsers = applyFilters(users, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeUsers =
    selectedItems.length > 0 && selectedItems.length < users.length;
  const selectedAllUsers = selectedItems.length === users.length;

  const [toggleView, setToggleView] = useState('table_view');




  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={filters.role || 'all'}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label}/>
          ))}
        </TabsWrapper>
      </Box>
      <Card>
        <Divider/>
        <>
          {
            filters.role === "Биланс на состојба"?
              <SingleReportResultReportForm data={lastYearObj}  data2={rest.finance} fields={aop_fields1} titles={aop_titles} validatedFields={validatedFields} companyID={rest.companyID} key={Date.now()}></SingleReportResultReportForm>
              :filters.role === "Биланс на успех"
              ?<SingleReportResultReportForm data={lastYearObj}  data2={rest.finance} fields={app_fields2} titles={aop_titles} validatedFields={validatedFields} companyID={rest.companyID} key={Date.now()}></SingleReportResultReportForm>:""
          }
        </>
      </Card>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired
};

Results.defaultProps = {
  users: []
};

export default Results;
