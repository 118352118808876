import React from 'react';
import * as XLSX from 'xlsx';

import {
  Button
} from '@mui/material';
import ImportDialog from "./ImportDialog";
import ImportResultDialog from "./ImportResultDialog";

class Import extends React.Component {
  constructor(props){
    super(props);
    this.state = {
     open: false,
      openResultDialog: false,
     files: [],
     companies: [],
     success: [],
     error: [],
     duplicates: [],
     notAddedCompany: [],

    }
  }
  handleUploadFile = () => {
    const Insert = (e) => {
      this.setState({companies: e}, this.InsertCompanies)
    }
    let files = this.state.files, f = files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, {type: 'binary'});
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      console.log("ws", ws)
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {header:0});
      const dataCompanies = dataParse.map((obj) => Object.values(obj))
      Insert(dataCompanies)

    };
    reader.readAsBinaryString(f)
  };
  handleImportClose = () => {this.setState({open: false, files: ''})};
  onChangeFile = (e, v) => {
    this.setState({files: e.target.files})
  };


  InsertCompanies = () => {
    let companies = this.state.companies.map(c => c[0])
    const divisionID = this.props.id;
    if(companies && divisionID){
      window.makeRequest("POST", `/api/DivisionCompaniesImport?divisionID=${divisionID}`, {
        edbEmbs: companies,
      }, true, (callback) => {
        const success = callback.body.inserted;;
        const error = callback.body.invalid;
        const duplicates = callback.body.duplicates;
        const notAddedCompany = callback.body.doesntExist;
        this.setState({open: false,openResultDialog: true, success: success, error: error,duplicates: duplicates,notAddedCompany: notAddedCompany,})
      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });
    }

  }
  CloseResultImport = () => {
    this.setState({openResultDialog: false,success: [], error: [],duplicates: [],notAddedCompany: [],}, this.props.getDivision)
  }

  render(){
    return(<>
      <Button size={'small'} variant={'contained'} sx={{ml: 1.4}} onClick={() => this.setState({open: true})}>Импортирај</Button>
      <ImportDialog open={this.state.open} handleUploadFile={this.handleUploadFile} handleImportClose={this.handleImportClose} onChangeFile={this.onChangeFile}/>
      <ImportResultDialog open={this.state.openResultDialog} onClose={this.CloseResultImport} success={this.state.success} error={this.state.error} duplicates={this.state.duplicates} notAddedCompany={this.state.notAddedCompany} />

   </>)
  }
}


export default Import;
