import React from 'react';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import TopCard from '../TopCard/index';
import DataGridDashboard from './DataGridDashboard/index';
import Chart from '../ChartDisplay/index'
import BottomCards from '../BottomCards/index'
import {withRouter} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Grid,
  Box,
  Typography,
  Tooltip,
  Dialog,
  IconButton,
} from '@mui/material';


import EditIcon from '@mui/icons-material/Edit';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import WeatherCard from '../WeatherCard'
import SuspenseLoader from 'src/components/SuspenseLoader';
import {Avatar} from "@mui/material/index";
import {styled} from "@mui/material/styles/index";
import request from "superagent";
import BuyDialog from "../DIalogs/BuyDialog";
import BuyStatusDialog from "../DIalogs/BuyStatusDialog";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

class AllWeatherCards extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,


    }
  }

  componentDidMount(){

  }




  returnWeather = () => {
      return(
        <>
                <Grid item lg={2} sm={2} xs={2}>
                  <WeatherCard
                    description={'Показателот ја покажува ефикасноста на кредитната политика на компанијата односно колку пати во текот на годината компанијата успева да ги наплати своите побарувања.'}
                    title={'Денови на наплата на побарувања'}
                    displayWeather={this.props.displayWeather}
                  />
                </Grid>
                <Grid item lg={2} sm={2} xs={2}>
                  <WeatherCard
                    description={'Показателот покажува колку пати компанијата во текот на годината успева да ги исплати своите обврски.'}
                    title={'Денови на исплата на обврски'}
                    displayWeather={this.props.displayWeather}


                  />
                </Grid>
                <Grid item lg={2} sm={2} xs={2}>
                  <WeatherCard
                    description={'Ја покажува способноста на компанијата преку најликвидните тековни средства да ги покрие краткорочните обврски. Солидно ниво на показателот се смета дека е над 1'}
                    title={'Брза ликвидност'}
                    displayWeather={this.props.displayWeather}

                  />
                </Grid>
                <Grid item lg={2} sm={2} xs={2}>
                  <WeatherCard
                    description={'Показателот означува во колкав степен компанијата ги користи туѓите извори на средства за финансирање на своето работење. Како просечна задолженост на компанијата се смета до 60%.'}
                    title={'Задолженост'}
                    displayWeather={this.props.displayWeather}

                  />
                </Grid>
                <Grid item lg={2} sm={2} xs={2}>
                  <WeatherCard
                    description={'Бодови кои и се доделени на компанијата врз основа на остварените резултати и успешноста на нејзиното финансиско работење. Помалиот број на бодови означува дека компанијата е поризична за соработка и обратно.'}
                    title={'Кредитен скор'}
                    displayWeather={this.props.displayWeather}

                  />
                </Grid>
                <Grid item lg={2} sm={2} xs={2}>
                  <WeatherCard
                    description={'Пресметан период за кој компанијата се очекува да ги исплати своите кредити со тековното работење.'}
                    title={'Просечен период на враќање на кредити (во години)'}
                    displayWeather={this.props.displayWeather}
                  />
                </Grid>
          </>
      )

  }


  render(){
    return this.returnWeather();
  }

}

export default AllWeatherCards;
