import React from 'react';

import {
  Dialog,
  Box,
  Typography,
  Button
} from '@mui/material';
import {Avatar} from "@mui/material/index";
import {styled} from "@mui/material/styles/index";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

class DuplicateNotification extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }


  render(){
    return(
      <DialogWrapper
        open={this.props.open}
        maxWidth="sm"
        fullWidth
        keepMounted
        onClose={this.props.closeNotification}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <PriorityHighIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {('Некои од селектираните извештаи веке е генериран или во изработка.' )}
          </Typography>
          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {('Доколку сакате да го купите мора да го избришете.' )}
          </Typography>

          <Box>
            <ButtonError
              onClick={this.props.closeNotification}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {('Откажи')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    )
  }




}

export default DuplicateNotification;

