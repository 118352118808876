import React from 'react';
import request from 'superagent';
import SuspenseLoader from 'src/components/SuspenseLoader';


import Sun from '../weather/sun.png';
import Mist from '../weather/mist.png';
import Storm from '../weather/brainstorming.png';
import Clound from '../weather/cloud.png';
import Cloudy from '../weather/cloudy.png';
import Snowy from '../weather/snowy.png';

import {
  Grid,
  Dialog,
  Alert,
  TextField,DialogTitle,
  DialogActions,
  DialogContent,
  Autocomplete,
  Typography,  Button
} from '@mui/material';

class WeatherLegendDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      files: '',
      year: '',
      loading: false,
      error: '',
    }
  }


  render(){
    if(this.state.loading) {
      return <SuspenseLoader/>
    }else{
      return(
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <DialogContent dividers sx={{ p: 3 }}>
            <Grid
              item
              xs={12}
            >
              <div style={{display: 'flex'}}><img style={{width: '50px'}}  src={Sun}/> <p style={{marginLeft: '20px'}}>- Остварени задоволителни резултати за прикажаниот параметар</p></div>
            </Grid>


            <Grid
              item
              xs={12}
            >
              <div style={{display: 'flex'}}><img style={{width: '50px'}} src={Cloudy}/> <p style={{marginLeft: '20px'}}>- Остварени резултати над просечните вредности за прикажаниот параметар</p></div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div style={{display: 'flex'}}><img style={{width: '50px'}} src={Clound}/> <p style={{marginLeft: '20px'}}>- Остварени просечни резултати за прикажаниот параметар</p></div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div style={{display: 'flex'}}><img style={{width: '50px'}} src={Mist}/> <p style={{marginLeft: '20px'}}>- Остварени резултати под просечните вредности или не може да се прикаже вредност за сооветниот параметар</p></div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div style={{display: 'flex'}}><img style={{width: '50px'}} src={Snowy}/> <p style={{marginLeft: '20px'}}>- Остварени ниски резултати за прикажаниот параметар</p></div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div style={{display: 'flex'}}><img style={{width: '50px'}} src={Storm}/> <p style={{marginLeft: '20px'}}> - Остварени незадоволителни резултати за прикажаниот параметар</p> </div>
            </Grid>
            {this.state.error && <Alert severity="error" sx={{ mt: 1 }}>{this.state.error}</Alert>}
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              {
                window.location.pathname.includes('divisions') ?
                  <div style={{color: 'red'}}>
                    {'Износите се добиени врз основа на избраните примарни извештаи'}
                  </div>
                  :
                  <div></div>
              }
              <div>
                <Button color="secondary" onClick={this.props.onClose}>
                  {'Затвори'}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )
    }

  }
}


export default WeatherLegendDialog;
