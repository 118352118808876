import {
  Box,
  Stack,
  Card,
  Divider,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Autocomplete,
  Button ,
} from '@mui/material';

import { useState } from 'react';

import ResultOutdated from './ResultOutdated';

import { useTranslation } from 'react-i18next';
import Text from 'src/components/Text';

function MiniBoxes(props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const closeResultOutDated = (type,addedReports,foundReports,rejectedReports) => {
    if(type === 'closed'){
      setOpen(false);
    } else {
      setOpen(false);
      props.addToCheckOut(addedReports,foundReports,rejectedReports);
    }
  }

  return (
    <Card style={{height:'100%'}}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box
          p={2}
          sx={{
            textAlign: 'center'
          }}
        >
          <Text color="warning">
            {props.header}
          </Text>
          <Typography variant="h3">{props.number} {props.BuyButton ? <Button size={'small'} onClick={() => setOpen(true)}>Купи</Button>:''}</Typography>
        </Box>
      </Stack>
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle sx={{p: 3}}>
            <Typography variant="h4" gutterBottom>
              {('Додадете компанија за која сакате последен извештај во кошничка')}
            </Typography>
            <Typography variant="subtitle2">
              {(
                'Селектирајте компанија за да ја додадете во кошничка.'
              )}
            </Typography>
          </DialogTitle>
          <DialogContent dividers >


            <ResultOutdated companies={props.outDatedCompanies} close={closeResultOutDated} lastFinancialYear={props.lastFinancialYear}/>



          </DialogContent>
          <DialogActions sx={{p: 3}}>
            <Button color="secondary" onClick={() => setOpen(false)}>
              {('Откажи')}
            </Button>
          </DialogActions>
        </Dialog>
    </Card>
  );
}

export default MiniBoxes;
