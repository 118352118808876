import React from 'react';
import {
  Box,
  CardHeader,
  Card,
  ListItemText,
  Typography,
  Avatar,
  List,
  Divider,
  ListItem,
  Link,
  IconButton,
  alpha,
  styled,
  useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Scrollbar from "../../../../components/Scrollbar";
import Text from "../../../../components/Text";

import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
const CardContentWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};
  `
);
const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const ListWrapper = styled(List)(
  () => `
    .MuiDivider-root:last-of-type {
        display: none;
    }
  `
);

function ListBuyDivisionDialogOutdated(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const ReturnCompanies = () => {
    const items = [];
    if(props.companiesNames) {
      let add = props.companiesNames.addedReports;
      let found = props.companiesNames.foundReports;
      let rejected = props.companiesNames.rejectedReports;
      if(add) {
        add.map((item) => {
          items.push(<><ListItem
            sx={{
              p: 2
            }}
          >
            <ListItemText
              primary={
                <Text color="text.primary" variant="h4">
                  {item}
                </Text>
              }
            />
            <Box alignSelf="flex-start">
              <LabelWrapper
                sx={{
                  background: `${theme.colors.success.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.success.dark
                  )}`
                }}
              >
                {t('Додадено')}
              </LabelWrapper>
            </Box>
          </ListItem>
          <Divider /></>)
          }
        )
      }
      if(found) {
        found.map((item) => {
            items.push(<> <ListItem
              sx={{
                p: 2
              }}
            >
              <ListItemText
                primary={
                  <Text color="text.primary" variant="h4">
                    {item}
                  </Text>
                }
              />
              <Box alignSelf="flex-start">
                <LabelWrapper
                  sx={{
                    background: `${theme.colors.warning.main}`,
                    color: `${theme.palette.getContrastText(
                      theme.colors.warning.dark
                    )}`
                  }}
                >
                  {t('Генериран или додаден')}
                </LabelWrapper>
              </Box>
            </ListItem>
              <Divider /></>)
          }
        )
      }
      if(rejected) {
        rejected.map((item) => {
            items.push(<><ListItem
              sx={{
                p: 2
              }}
            >
              <ListItemText
                primary={
                  <Text color="text.primary" variant="h4">
                    {item}
                  </Text>
                }
              />
              <Box alignSelf="flex-start">
                <LabelWrapper
                  sx={{
                    background: `${theme.colors.error.main}`,
                    color: `${theme.palette.getContrastText(
                      theme.colors.error.dark
                    )}`
                  }}
                >
                  {t('Недостапен')}
                </LabelWrapper>
              </Box>
            </ListItem>
              <Divider /></>)
          }
        )
      }
    }
    return items;

  }


  return (
    <>
      <CardContentWrapper
        sx={{
          mx: 3,
          mb: 3,
          height: 244,
          width: 700,
        }}
      >
        <Scrollbar>
          <ListWrapper disablePadding>
            {ReturnCompanies()}
          </ListWrapper>
        </Scrollbar>
      </CardContentWrapper>
    </>

  );
}

export default ListBuyDivisionDialogOutdated;
