import {useState, forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Zoom
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/components/Label';
import BulkActions from './BulkActions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {useSnackbar} from 'notistack';
import BI_DataGrid from '../../../components_bi/BI_DataGrid'
import Text from '../../../components/Text';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const ButtonError = styled(Button)(
  ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getCompanyStatus = (userRole) => {
  const map = {
    true: {
      text: 'Активна',
      color: 'success'
    },
    false: {
      text: 'Неактивна',
      color: 'error'
    },
  };

  const {text, color} = map[userRole];

  return <Label color={color}>{text}</Label>;
};


const Results = ({companies, ...rest}) => {
  const {enqueueSnackbar} = useSnackbar();


  const [deleteID, setDeleteID] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [nameByUser, setNameByUser] = useState('');
  const [changeNameID, setChangeNameID] = useState(null);
  const [openChangeName, setOpenChangeName] = useState(false);

  const handleConfirmDelete = (id) => {
    setDeleteID(id);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleCloseChangeName = () => {
    setChangeNameID(null);
    setNameByUser('');
    setOpenChangeName(false);
  }

  const handleChangeName = (e) => {
    setNameByUser(e.target.value);
  }

  const handleConfirmChangeName = (id, name) => {
    setChangeNameID(id);
    setNameByUser(name);
    setOpenChangeName(true);
  };

  const handleChangeNameSuccess = () => {
    window.makeRequest("PUT", `/api/Company/NameByUser?id=${changeNameID}`, {
      NameByUser: nameByUser
    }, true, (callback) => {
      handleCloseChangeName();
      rest.renderCompanies();
      enqueueSnackbar('Вашиот краток назив е успешно порменет', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }, (err) => {
      if (err) {
        console.log('err', err);

      }
    });
  }


  const handleDeleteCompleted = () => {
    if (rest.user.ManageCompanies) {
      window.makeRequest("DEL", `/api/Company?id=${deleteID}`, false, true, (callback) => {
        setOpenConfirmDelete(false);
        rest.delete(deleteID);
        setDeleteID(null);
      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });


      enqueueSnackbar('Компанијата е успешно избришана', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } else {
      setOpenConfirmDelete(false);
    }


  };


  const columns = [
    {
      field: 'VAT',
      headerName: 'ЕДБ',
      width: 180,
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'PIN',
      headerName: 'ЕМБС',
      headerAlign: 'center',
      width: 150,
      type: 'number',
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.PIN
      }
    },
    {
      field: 'Company_name',
      headerName: 'Назив на компанија',
      headerAlign: 'center',
      width: 690,
      type: 'number',
      align: 'start',
      disableColumnMenu: true,
    },
    {
      field: 'Company_name_by_user',
      headerName: 'Ваш краток назив на компанијата',
      headerAlign: 'center',
      width: 300,
      type: 'number',
      align: 'center',
      disableColumnMenu: true,


    },
    {
      field: '#',
      headerName: rest.user.ManageCompanies ? 'Уреди' : '',
      headerAlign: 'center',
      width: 120,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        if (rest.user.ManageCompanies) {


          return <>

            {params.row.ID !== 1 ?
              <Tooltip title={'Избриши'} arrow placement='top'>
                <IconButton 
                onClick={(event) => {
                  event.ignore = true;
                  handleConfirmDelete(params.row.ID)
                }}
                color="primary">
                  <DeleteTwoToneIcon fontSize="medium"/>
                </IconButton>

              </Tooltip>
              : ''
            }
            <Tooltip title={<div style={{width: '20vh'}}>Променете Ваше кратко име за компанијата</div>} arrow placement='top'>
              <IconButton onClick={(event) => {
                event.ignore = true
                handleConfirmChangeName(params.row.ID, params.row.Company_name_by_user)
              }}
                          color="primary">
                <EditIcon fontSize="medium"/>
              </IconButton>

            </Tooltip>
          </>
        }

      }
    }


  ];

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{xs: 'column', sm: 'row'}}
        justifyContent={{xs: 'center', sm: 'space-between'}}
        pb={3}
      >

      </Box>
      <BI_DataGrid search={true} columns={columns} data={companies} 
      onRowClick={(params, event) => {
        if(!event.ignore){
          window.location.href = `/company/${params.row.ID}/dashboard`
        }
      }} />

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon/>
          </AvatarError>

          <Typography align="center" sx={{py: 4, px: 6}} variant="h2">
            {'Дали сте сигурни да ја избришите оваа компанија'}
            ?
          </Typography>
          <Box>
            <Button
              variant="text"
              size="large"
              sx={{mx: 1}}
              onClick={closeConfirmDelete}
            >
              {'Откажи'}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{mx: 1, px: 3}}
              variant="contained"
            >
              {'Избриши'}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>


      <Dialog
        fullWidth
        maxWidth="md"
        open={openChangeName}
        onClose={handleCloseChangeName}
      >
        <DialogTitle sx={{p: 3}}>
          <Typography variant="h4" gutterBottom>
            {('Променете го вашиот краток назив')}
          </Typography>
          <Typography variant="subtitle2">
            {(
              'Пополнете го полето за да го промените вашиот краток назив'
            )}
          </Typography>
        </DialogTitle>


        <DialogContent dividers sx={{p: 3}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label={('Ваш краток назив')}
                    name="Limit"
                    value={nameByUser}
                    onChange={handleChangeName}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{p: 3}}>
          <Button color="secondary" onClick={handleCloseChangeName}>
            {('Откажи')}
          </Button>
          <Button
            type="submit"
            onClick={handleChangeNameSuccess}
            variant="contained"
          >
            {('Промени')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  companies: []
};

export default Results;
