import React from 'react';
import TrafficSources from './ChartЕfficiency/TrafficSources';
import TrafficLiquidity from './ChartLiquidity/AudienceOverview';
import TransactionsStatistics from './ChartDebtedness/TransactionsStatistics';
import { Card } from '@mui/material';

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    }
  }


  componentDidMount() {
  }


  returnTypeChar = () => {
    let type = this.props.type;
    let labels = [];
    if(this.props.division){
      labels = this.props.data.map((years) => years.Year);
    }
    else {
      labels = this.props.data.map((years) => years.Year);
    }
    if(type === 1) {
      return <TrafficSources
        Prihodi={this.props.data.map((dat) => dat.Prihodi)}
        Rashodi={this.props.data.map((dat) => dat.Rashodi)}
        Vraboteni={this.props.data.map((dat) => Math.round(dat.Vraboteni))}
        years = {labels}
        prosecni={this.props.prosecni}


      />;
    }
    if(type === 2) {
      return <TrafficLiquidity
        tekovnaLikvidnost={this.props.data.map((dat) => dat.tekovnaLikvidnost)}
        brzaLikvidnost={this.props.data.map((dat) => dat.brzaLikvidnost)}
        years={labels}
        prosecni={this.props.prosecni}
      />
    }
    if(type === 3) {
      const dolg=this.props.data.map((dat) => dat.dolg_kapital)
      const period=this.props.data.map((dat) => dat.periodVrakanjeKrediti)
      const zadolzenost=this.props.data.map((dat) => dat.zadolzenost)
      return <TransactionsStatistics
        dolg_kapital={dolg.reverse()}
        periodVrakanjeKrediti={period.reverse()}
        zadolzenost={zadolzenost.reverse()}
        years={labels.reverse()}
        prosecni={this.props.prosecni}
      />
    }

  }





  render() {
    return <Card >
            {this.returnTypeChar()}
            </Card>

  }
}

export default Chart;
