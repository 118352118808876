import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import {
  Box,
  Card,
  Hidden,
  Link,
  Tooltip,
  Typography,
  Container,
} from '@mui/material';
import JWTLogin from '../LoginJWT';

import { styled } from '@mui/material/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
const Content = styled(Box)(
  () => `
    display: flex;
    height: 100%;
    flex: 1;
`
);

const MainContent = styled(Box)(
  () => `
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    background: white;
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${theme.spacing(6)};
`
);


const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

class LoginCover extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: true
    }
  }

  render() {
    return(
    <>
      <Content>
        {/*<Hidden mdDown>*/}
          {/*<SidebarWrapper>*/}
            {/*<Scrollbars autoHide>*/}
              {/*<SidebarContent>*/}
                {/*<Box mt={6}>*/}
                  {/*<TypographyH1  variant="h1" sx={{mb: 7}}>*/}
                    {/*{'БИ'}*/}
                  {/*</TypographyH1>*/}
                  {/*<Typography variant="subtitle1" sx={{my: 3}}>*/}
                    {/*{*/}
                      {/*'Имате компанија ?'*/}
                    {/*}*/}
                  {/*</Typography>*/}
                  {/*<Typography*/}
                    {/*variant="subtitle1"*/}
                    {/*color="text.primary"*/}
                    {/*fontWeight="bold"*/}
                  {/*>*/}
                    {/*{'Дознајте како работи вашата компанија?'}*/}
                  {/*</Typography>*/}
                  {/*<Typography variant="subtitle1">*/}
                    {/*{*/}
                      {/*'Што е потребно ?'*/}
                    {/*}*/}
                  {/*</Typography>*/}
                  {/*<Link  underline="hover" to="/docs">*/}
                    {/*Регистрација и пријава на нашата платформа!*/}
                  {/*</Link>*/}
                {/*</Box>*/}
              {/*</SidebarContent>*/}
            {/*</Scrollbars>*/}
          {/*</SidebarWrapper>*/}
        {/*</Hidden>*/}
        <MainContent>
          <Container maxWidth="sm">
            <Card sx={{px: 4, py: 5}}>
              <Box textAlign="center">
                <Typography variant="h2" sx={{mb: 1}}>
                  {'Bonitet.mk'}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{mb: 3}}
                >
                  {'Потполнете ги празните полиња за да се најавите.'}
                </Typography>
              </Box>
              {<JWTLogin login={this.props.login}/>}
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {'Немате пристап?'}
                </Typography>{' '}
                <Link component={RouterLink} underline="hover" to="/register">
                  <b>Регистрирај се овде</b>
                </Link>
              </Box>
              <Box my={4}>
                <Link component={RouterLink} underline="hover" to="/ResetPassword">
                  <b>Заборавена лозинка</b>
                </Link>
              </Box>

            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
    )};
}

export default LoginCover;
