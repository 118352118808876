import React from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import {withRouter} from 'react-router-dom';
import Companies from './Companies';
import Users from './Users';
import Header from './Header';
import Import from './Import';
// import Import from '../../Companies/Import'

import {
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Autocomplete,
  Button
} from '@mui/material';
import ExportCompanies from "./Export/ExportCompanies";
import request from "superagent";
import CheckBeforeImportDialog from "../../Companies/Import/CheckBeforeImportDialog";

class EditDivision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      division: {},
      allCompaniesInDivision: [],
      allCompaniesNotInDivision: [],
      allUsersInDivision: [],
      allUsersNotInDivision: [],
      addUsers: [],
      addCompanies: [],
      openAddCompany: false,
      openAddUsers: false,
      Name: '',
      listCompanies: [],
      checkBeforeImportDialog: false,
      arrayList: [],
      deniedList: [],
      invalidList: [],
      duplicateList: [],

    }
  }

  componentDidMount(){
    this.getDivision();

  }

  getDivision = () => {
    this.setState({loading: true})
    this.getUsers();
    this.getCompanies();
    window.makeRequest("GET", `/api/Division?id=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({division: callback.body, Name: callback.body.Name, loading: false});
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };

  getUsers = () => {
    window.makeRequest("GET", `/api/Division/EditDivisionData?divisionID=${this.props.match.params.id}&getUsers=true`, false, true, (callback) => {
      this.setState({allUsersInDivision: callback.body.allUsersInDivision, allUsersNotInDivision: callback.body.allUsersNotInDivision});
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };

  getCompanies = () => {
    window.makeRequest("GET", `/api/Division/EditDivisionData?divisionID=${this.props.match.params.id}&getCompanies=true`, false, true, (callback) => {
      this.setState({allCompaniesInDivision: callback.body.allCompaniesInDivision, allCompaniesNotInDivision: callback.body.allCompaniesNotInDivision});
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };

  handleAddCompanyClose = () => {
    this.setState({openAddCompany: false, addCompanies: []});
  };

  handleAddUsersClose = () => {
    this.setState({openAddUsers: false, addUsers: []});
  };

  handleAddCompanyChange = (ev, val) => {
    if(val){
      console.log("val", val);
      this.setState({addCompanies: val});
    }
  };

  handleAddUsersChange = (ev, val) => {
    if(val){
      this.setState({addUsers: val});
    }
  };

  handleAddCompaniesSuccess = () => {
    console.log("enters here", this.state.arrayList.length)
    if(this.state.addCompanies.length > 0){
    this.state.addCompanies.forEach(company =>
      window.makeRequest("POST", `/api/DivisionCompanies`, {
        CompanyID: company.ID,
        DivisionID: this.props.match.params.id,
      }, true, (callback) => {
        this.getCompanies();
        this.handleAddCompanyClose();
      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      })
    )
      this.setState({checkBeforeImportDialog: false})
  }
  };

  handleAddUsersSuccess = () => {
    if(this.state.addUsers.length > 0){
      this.state.addUsers.forEach(user =>
        window.makeRequest("POST", `/api/userDivisions`, {
          userID: user.ID,
          divisionID: this.props.match.params.id,
        }, true, (callback1) => {
          this.getUsers();
          this.handleAddUsersClose();
        }, (err) => {
          if (err) {
            console.log('err', err);
          }
        })
      )
    }
  };

  importCompanies = (e,v) => {
    this.setState({loading: true, importCompanies: e}, this.separateTextCompanies)
  }

  separateTextCompanies = () => {
    if(this.state.importCompanies) {
      const companies = this.state.importCompanies;
      let separator = ' ';
      if(companies.indexOf('\n') > 0) {
        separator = ('\n');
      }
      if(companies.indexOf(',') > 0) {
        separator = ','
      }
      if(companies.indexOf(',\n') > 0){
        separator = ',\n'
      }

      let removedList = companies.split(separator);

      if(removedList[removedList.length - 1].length !== 7 && removedList[removedList.length - 1].length !== 13 && removedList[removedList.length - 1].length !== 15){
        console.log('removedList[removedList.length - 1].length', removedList[removedList.length - 1], removedList[removedList.length - 1].length )
        removedList[removedList.length - 1] = removedList[removedList.length - 1].substring(0, removedList[removedList.length - 1].length - 1);
      }

      this.setState({ listCompanies: removedList}, this.validateImportCompanies)
    }
  }

  validateImportCompanies = () => {
    let invalidList = [];
    let correctList = [];

    this.state.listCompanies.filter((list) => {
      const PIN = list;
      if(PIN.length === 7 && !parseInt(PIN)){
        invalidList.push(PIN);
      }else if(PIN.length === 13 && !parseInt(PIN)){
        invalidList.push(PIN);
      }else if(PIN.length === 15 && (!PIN.startsWith('MK') || !parseInt(PIN.replace('MK', '')))){
        invalidList.push(PIN);
      }
      else if(PIN.length !== 7 && PIN.length !== 13 && PIN.length !== 15){
        invalidList.push(PIN);
      }
      else {
        correctList.push(PIN)
      }
    })


    window.makeRequest("POST", `/api/Import/CheckEmbs`, {
      Embss: correctList
    }, true, (callback) => {
      let RemovedDuplicates = [];
      let listDuplicates = [];
      if(callback.body && callback.body.duplicates.length > 0) {
        correctList.filter((val, index) => {
            if(!callback.body.duplicates.includes(val)){
              RemovedDuplicates.push(val);
            } else {
              listDuplicates.push(val + ' ' + callback.body.names[index]);
            }
          }
        );
      } else {
        RemovedDuplicates = correctList;
      }
      this.checkEmbsDetails(RemovedDuplicates,listDuplicates, invalidList)

    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }

  checkEmbsDetails = (a,b,c)=> {
    this.setState({loading: true})
    let count = 0;
    const removedDuplicates =  a;
    const duplicates = b;
    const invalid = c;
    let arrayList = [];
    let deniedList = [];

    removedDuplicates && removedDuplicates.length > 0 ? removedDuplicates.forEach((list, index) => {
      const PIN = list;
      request
        .get(`/api/Subjekt/GetSubjektiWithGradAndDejnost?embsEdb=${PIN}`)
        .withCredentials()
        .auth('tgmisajlovska', '123456789')
        .then((callback) => {
          if (callback.body && callback.body !== null) {
            let Pregled = callback.body;
            let VAT = Pregled.Edb ? Pregled.Edb : '';
            let PIN = Pregled.Embs ? Pregled.Embs : '';
            let Company_name = Pregled.CelosenNaziv ? Pregled.CelosenNaziv : '';
            let Size = Pregled.GoleminaNaSubjekt ? Pregled.GoleminaNaSubjekt.trim() : '';
            let Adress = Pregled.Sedishte ? Pregled.Sedishte : '';
            let Occupation = Pregled.OddelSifra ? parseInt(Pregled.OddelSifra.trim()) : '';
            let Establishment_year = Pregled.DatumOsnovanje ? new Date(Pregled.DatumOsnovanje).getFullYear() : this.returnEstablishmentYear(VAT);
            let City = Pregled.Grad ? Pregled.Grad : '';
            count++;
            arrayList.push({VAT, PIN, Company_name, Establishment_year, Size, Occupation, Adress, City});
            if(removedDuplicates.length === count) {
              this.setState({
                loading: false,
                arrayList: arrayList,
                deniedList: deniedList,
                invalidList: invalid,
                duplicateList: duplicates,
                checkBeforeImportDialog: true,
              });
            }
          } else {
            count++;
            deniedList.push({ PIN});
            if(removedDuplicates.length === count) {
              this.setState({
                loading: false,
                arrayList: arrayList,
                deniedList: deniedList,
                invalidList: invalid,
                duplicateList: duplicates,
                checkBeforeImportDialog: true });
            }
          }



        })
        .catch((e) => {
          if(e){
            deniedList.push({ PIN});
            count++;
            if(removedDuplicates.length === count) {
              this.setState({
                loading: false,
                arrayList: arrayList,
                deniedList: deniedList,
                invalidList: invalid,
                duplicateList: duplicates,
                checkBeforeImportDialog: true
              });
            }
          }
        });
    }) : this.setState({
      loading: false,
      arrayList: arrayList,
      deniedList: deniedList,
      invalidList: invalid,
      duplicateList: duplicates,
      checkBeforeImportDialog: true
    })

  }

  returnEstablishmentYear = (VAT) => {
    let arr = VAT.split('');
    const Establishment_year = [];
    if (VAT.indexOf('MK') > -1) {
      if (arr[6] === '0') {
        let Year = '2' + arr[6] + arr[7] + arr[8];
        Establishment_year.push(Year);
      }
      else {
        let Year = '1' + arr[6] + arr[7] + arr[8];
        Establishment_year.push(Year);

      }
    }
    else {
      if (arr[4] === '0') {
        let Year = '2' + arr[4] + arr[5] + arr[6];
        Establishment_year.push(Year);

      }
      else {
        let Year = '1' + arr[4] + arr[5] + arr[6];
        Establishment_year.push(Year);
      }

    }
    return Establishment_year[0];
  }

  createCompanies = () => {
    let companies = this.state.arrayList;
    window.makeRequest("POST", `/api/Import`,{
      companies
    }, true, (callback) => {
      if(callback.body && callback.body.hasOwnProperty('Response') && callback.body.Response.ReasonPhrase === 'Length Required'){
        this.setState({checkBeforeImportDialog: false,limitMessage: true,})
      } else {
        this.setState({checkBeforeImportDialog: false},() => this.getCompanies())
      }
    }, (err) => {
      if(err) {
        this.setState({checkBeforeImportDialog: false},() => this.getCompanies())
        console.log('error', err);
      }
    });
  }
  closeDialog = () => {
    this.setState({checkBeforeImportDialog: false},() => this.getCompanies())

  }

  removeFromImportCompanies = (id) => {

    this.setState({checkBeforeImportDialog: false})
    const removeArr = [...this.state.arrayList];
    let removed = removeArr.filter((row) => row.PIN !== id);
    this.setState({ arrayList: removed,checkBeforeImportDialog: true})

  }

  render() {

    if(this.state.loading){
       return <SuspenseLoader />
     }
     else {
       return (
         <div>

         <Header  ManageSubscription={this.props.ManageSubscription} division={this.state.division} Name={this.state.Name} getDivision={this.getDivision}/>

         <Grid container spacing={1} sx={{p: 2}}>
           <Grid item xs={12} sx={{mb: -2}}>
             <Button size={'small'} variant={'contained'} onClick={() => this.setState({openAddCompany: true})}>+ Компании</Button>
             <Import getDivision={this.getDivision} id={this.props.match.params.id}/>
             {/*<Import importCompanies={this.importCompanies}/>*/}
             <ExportCompanies companies={this.state.allCompaniesInDivision} divisionName={this.state.Name} />
           </Grid>
           <Grid item xs={12}>
             <Companies companies={this.state.allCompaniesInDivision} getCompanies={this.getCompanies} divisionID={this.props.match.params.id}/>
           </Grid>
           <Grid item xs={12} sx={{mb: -2, mt: 1}}><Button size={'small'} variant={'contained'} onClick={() => this.setState({openAddUsers: true})}>+ Корисници</Button></Grid>
           <Grid item xs={12}>
             <Users users={this.state.allUsersInDivision} getUsers={this.getUsers} divisionID={this.props.match.params.id}/>
           </Grid>
         </Grid>

           <Dialog
             fullWidth
             maxWidth="md"
             open={this.state.openAddCompany}
             onClose={this.handleAddCompanyClose}
           >
             <DialogTitle sx={{ p: 3 }}>
               <Typography variant="h4" gutterBottom>
                 {('Изберете нова компанија во портфолиото')}
               </Typography>
             </DialogTitle>


             <DialogContent dividers sx={{ p: 3 }}>
               <Grid container spacing={3}>
                 <Grid item xs={12}>
                   <Grid container spacing={3}>
                     <Grid
                       item
                       xs={12}
                     >
                       <Autocomplete
                         multiple
                         sx={{ m: 0 }}
                         limitTags={2}
                         options={this.state.allCompaniesNotInDivision}
                         renderOption={(props, option) => (
                           <li {...props}>
                             {option.Company_name}
                           </li>
                         )}
                         getOptionLabel={(option) => option.Company_name}
                         renderInput={(params) => (
                           <TextField
                             {...params}
                             variant="outlined"
                             fullWidth
                             InputLabelProps={{
                               shrink: true
                             }}
                             placeholder={('Одберете компании...')}
                           />
                         )}
                         renderTags={(members, getTagProps) =>
                           members.map((ev, index) => (
                             <Chip
                               label={ev.Company_name}
                               {...getTagProps({ index })}
                             />
                           ))
                         }
                         onChange={this.handleAddCompanyChange}
                       />
                     </Grid>
                   </Grid>
                 </Grid>
               </Grid>
             </DialogContent>
             <DialogActions sx={{ p: 3 }}>
               <Button color="secondary" onClick={this.handleAddCompanyClose}>
                 {('Откажи')}
               </Button>
               <Button
                 type="submit"
                 onClick={this.handleAddCompaniesSuccess}
                 variant="contained"
               >
                 {('Додади')}
               </Button>
             </DialogActions>
           </Dialog>

           <Dialog
             fullWidth
             maxWidth="md"
             open={this.state.openAddUsers}
             onClose={this.handleAddUsersClose}
           >
             <DialogTitle sx={{ p: 3 }}>
               <Typography variant="h4" gutterBottom>
                 {('Додадете нов корисник во портфолиото')}
               </Typography>
             </DialogTitle>


             <DialogContent dividers sx={{ p: 3 }}>
               <Grid container spacing={3}>
                 <Grid item xs={12}>
                   <Grid container spacing={3}>
                     <Grid
                       item
                       xs={12}
                     >
                       <Autocomplete
                         multiple
                         sx={{ m: 0 }}
                         limitTags={2}
                         options={this.state.allUsersNotInDivision}
                         renderOption={(props, option) => (
                           <li {...props}>
                             {option.Username}
                           </li>
                         )}
                         getOptionLabel={(option) => option.Username}
                         renderInput={(params) => (
                           <TextField
                             {...params}
                             variant="outlined"
                             fullWidth
                             InputLabelProps={{
                               shrink: true
                             }}
                             placeholder={('Одберете корисници...')}
                           />
                         )}
                         renderTags={(members, getTagProps) =>
                           members.map((ev, index) => (
                             <Chip
                               label={ev.Username}
                               {...getTagProps({ index })}
                             />
                           ))
                         }
                         onChange={this.handleAddUsersChange}
                       />
                     </Grid>
                   </Grid>
                 </Grid>
               </Grid>
             </DialogContent>
             <DialogActions sx={{ p: 3 }}>
               <Button color="secondary" onClick={this.handleAddUsersClose}>
                 {('Откажи')}
               </Button>
               <Button
                 type="submit"
                 onClick={this.handleAddUsersSuccess}
                 variant="contained"
               >
                 {('Додади')}
               </Button>
             </DialogActions>
           </Dialog>
           <CheckBeforeImportDialog
             arrayList={this.state.arrayList}
             deniedList={this.state.deniedList}
             invalidList={this.state.invalidList}
             duplicateList={this.state.duplicateList}
             removeFromImportCompanies={this.removeFromImportCompanies} onClose={this.closeDialog} createCompanies={this.createCompanies}  open={this.state.checkBeforeImportDialog} />
         </div>
       )
     }

  }
}

export default withRouter(EditDivision);
