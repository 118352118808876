import React from 'react';
import { withRouter } from "react-router-dom";

import {
  Box,
  Card,
  TextField,
  Typography,
  Container,
  Alert,
  Button,
} from '@mui/material';
import {styled} from "@mui/material/styles/index";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

class ResetPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      password: '',
      confirm_password: '',
      passwordError: '',
    }
  }

  validate = () => {

    let password = this.state.password;
    let confirm_password = this.state.confirm_password;
    let passwordError = '';

    switch (true) {
      case password.length < 1:
        passwordError = 'Лозинката е задолжителна';
        break;
      case password.length < 7:
        passwordError = 'Лозинката мора да има 7 или повеќе карактери';
        break;
      case password !== confirm_password:
        passwordError = 'Лозинката не се совпаѓа';
        break;
      default:

    }

    if(passwordError){
      this.setState({passwordError});
      return false;
    }

    return true;

  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  };

  resetPasswordSuccess = () => {
    if(this.validate()) {
      window.makeRequest("PUT", `/api/ResetPassword?id=${this.props.match.params.id}`, {
        Password: this.state.password,
      }, true, (callback) => {
        window.location.href = '/';
      }, (err) => {
        if (err) {
          console.log('err', err);

        }
      });
    }
  };

  render() {
    return (
      <MainContent>
        <Container maxWidth="sm">
          <Card sx={{mt: 3, p: 4}}>
            <Box>
              <Typography variant="h2" sx={{mb: 1}}>
                {('Промена на лозинка')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{mb: 3}}
              >
                {(
                  'Внесете нова лозинка'
                )}
              </Typography>

            </Box>


            <TextField
              fullWidth
              label={('Лозинка')}
              margin="normal"
              name="password"
              onChange={this.handleChange}
              type="password"
              value={this.state.passowrd}
              variant="outlined"
            />

            {
              this.state.passwordError? <Alert severity="error" sx={{mt: 1}}>{this.state.passwordError}</Alert> : ""
            }

            <TextField
              fullWidth
              label={('Потврда на лозинка')}
              margin="normal"
              name="confirm_password"
              onChange={this.handleChange}
              type="password"
              value={this.state.confirm_password}
              variant="outlined"
            />


            <Button
              sx={{mt: 3}}
              color="primary"
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              onClick={this.resetPasswordSuccess}
            >
              {('Промени')}
            </Button>
          </Card>
        </Container>
      </MainContent>
    )
  }
}


export default withRouter(ResetPasswordForm);
