import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
//
// const useStyles = styled((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInput(props) {


  return (
    <div >
      <TextField
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        fullWidth
        // autoFocus
        size={props.size}
        margin={props.margin}
        className={props.className}
        type={props.type}
        error={props.error}
        helperText={props.helperText}
        disabled={props.disabled}
        shrink={props.shrink}
        InputLabelProps={props.InputLabelProps}
        defaultValue={props.defaultValue}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
}
