import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Button
} from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Label from '../../components/Label';


class DialogUploadResult extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      report: ''
    }
  }

  componentDidMount(){

  }

  returnSuccessMessages = () => {
    let ReturnValue = [];
    const Success = this.props.SuccessYearImport[0] && this.props.SuccessYearImport[0];
    if(Success && Success.length > 0){
      ReturnValue = Success.map((row) => {
          return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
            <CheckBoxIcon size='small' style={{marginRight: '10px'}}  /><Label>Успешно импортиран извештај за {row} година.</Label>
          </Grid>
        })
    }
    return ReturnValue;

  }

  returnExistsMessages = () => {
    let ReturnValue = [];
    const Exists = this.props.ExistsYearImport[0] && this.props.ExistsYearImport[0];
    if(Exists && Exists.length > 0){
      ReturnValue = Exists.map((row) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label >Извештајот за {row} година веќе постои.</Label>
        </Grid>
      })
    }
    return ReturnValue;

  }

  returnBeforeEstablishmentMessage = () => {
    let ReturnValue = [];
    const BeforeEstablishment = this.props.beforeEstablishment[0] && this.props.beforeEstablishment[0];

    if(BeforeEstablishment && BeforeEstablishment.length > 0){
      ReturnValue = BeforeEstablishment.map((row) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label >Извештајот за {row} година е пред годината на основање</Label>
        </Grid>
      })
    }

    return ReturnValue;
  }


  returnNotValidMessages = () => {
    let ReturnValue = [];
    const InvalidCompany = this.props.InvalidCompanyEMBS && this.props.InvalidCompanyEMBS;
    console.log('InvalidCompany', InvalidCompany)
    if(InvalidCompany && InvalidCompany.length > 0){
      ReturnValue = InvalidCompany.map((row, i) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label>Извештајот за компанијата со ЕМБС {row} не се совпаѓа со оваа компанија.</Label>
        </Grid>
      })
    }
    return ReturnValue;
  }

  returnNotValidEmbs = () => {
    let ReturnValue = [];
    const InvalidEMBS = this.props.InvalidEMBS && this.props.InvalidEMBS;
    if(InvalidEMBS && InvalidEMBS.length > 0){
      ReturnValue = InvalidEMBS.map((row) => {
        return <Grid  xs={12} pb={1} justifyContent={'start'} alignContent={'start'} display={'flex'}>
          <CheckBoxOutlineBlankIcon style={{marginRight: '10px'}}/><Label>Невалиден {row} ЕМБС.</Label>
        </Grid>
      })
    }
    return ReturnValue;

  }

  returnResults = () => {
    return<>
      {this.returnSuccessMessages()}
      <Divider style={{marginBottom: '10px'}}/>
      {this.returnExistsMessages()}
      {this.returnBeforeEstablishmentMessage()}
      {this.returnNotValidMessages()}
      {this.returnNotValidEmbs()}
    </>
  }



  render(){
    return <Dialog
      fullWidth
      maxWidth="md"
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {'Извештај за внесување'}
        </Typography>
        <Typography variant="subtitle2">
          {
            'Во прилог е статусот на внесените извештаи.'
          }
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>

          <Grid item xs={12}  display={'block'}>
            {this.props.open && this.returnResults()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Grid container display={"flex"} justifyContent={"space-between"}>


          <Grid item xs={7}>
            <b style={{color: "green"}}>Импортираните извештаи ке бидат прикажани во "Изработени" табелата</b>
          </Grid>

          <Grid item align={"right"} xs={3}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => this.props.onClose()}
            >
              {'Во ред'}
            </Button>
          </Grid>

        </Grid>
      </DialogActions>
    </Dialog>
  }
}
export default DialogUploadResult;
