import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SuspenseLoader from '../../../../../../components/SuspenseLoader'
import { DataGrid } from '@mui/x-data-grid';
import DateFormating from '../../../../../../components_bi/DateFormating'



const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);


class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false});

  }

  DataGridSubscription = () => {
    const columns = [
      {
        field: 'Name',
        headerName: 'Име',
        width: 200,
        editable: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'Status',
        headerName: 'Статус',
        width: 180,
        editable: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'Date_Start',
        headerName: 'Датум на почеток',
        width: 230,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return DateFormating(params.row.Date_Start)
        }

      },
      {
        field: 'Date_End',
        headerName: 'Датум на завршување',
        width: 230,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        renderCell: (params) => {
          return DateFormating(params.row.Date_End)
        }

      },
      {
        field: 'TotalUsers',
        headerName: 'Дополнителни корисници',
        width: 250,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,

      },
      {
        field: 'TotalDivisions',
        headerName: 'Дополнителни дивизии',
        width: 230,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,

      },


    ];


    return (
      <div style={{ height: 400, width: '100%',  }}>
        <DataGrid
          rows={this.props.SubscriptionList}
          columns={columns}
          pageSize={5}
          getRowId={row => row.ID}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    );
  }

  returnBoxTitle = () => {
    return<Box display="flex" alignItems="center">
      <Box>
        <Typography variant="h3" component="h3" gutterBottom>
          {'Преглед на претплати'}
        </Typography>
        <Typography variant="subtitle2">
          {
            'Преглед на сите претплати за корисник'
          }
        </Typography>
      </Box>
    </Box>
  }


  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>
          <Box
            py={0}
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            {this.returnBoxTitle()}
          </Box>
          {this.DataGridSubscription()}

        </RootWrapper>

      );
    }

  }


}


export default Subscription;

