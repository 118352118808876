import { useState, forwardRef } from 'react';
import {
  Avatar,
  Box,
  Slide,
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Tooltip,
  Dialog,
  DialogContent,
  Stack,
  styled
} from '@mui/material';

import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Label from '../../../../components/Label'
import { Link as RouterLink } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Text from '../../../../components/Text';
import Scrollbar from '../../../../components/Scrollbar';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonSuccess = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.primary.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.primary.dark};
     }
    `
);


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});




function CheckBeforeImportDialog (props) {
  const arrayList = props.arrayList;
  const deniedList = props.deniedList;
  const invalidList = props.invalidList;
  const duplicateList = props.duplicateList;


  const SuccessList = () => {
    let display = [];
    if(arrayList.length > 0) {


      arrayList.map((row, key) => {
        display.push(<><Grid container
                      key={row+key}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={4}
        >
          <Grid  xs={10} display={'flex'} justifyContent={'start'} alignItems={'center'} >
            <CheckBoxIcon/>
            <Text style={{fontSize: '12px',marginLeft: '20px' }}>{'ЕМБС: ' + row.PIN + '-' +row.Company_name}</Text>
          </Grid>
          <Grid  xs={1}>
            <Tooltip title={'Избриши'}>
              <Close color={'error'} onClick={() => props.removeFromImportCompanies(row.PIN)}/>
            </Tooltip>
          </Grid>
        </Grid>
          <Box my={0.5}>
            <Divider />
          </Box>
        </>)
      })

    }
    return display;
  }
  const InvalidList = () => {
    let display = [];
    if(invalidList.length > 0) {
      display = invalidList.map((row) => {
        return<><Grid container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={4}
        >
          <Grid  xs={10} display={'flex'} justifyContent={'start'} alignItems={'center'} >
            <Tooltip title={'Невалиден'}>
              <CheckBoxOutlineBlankIcon color={'error'}/>
            </Tooltip>
            <Text style={{fontSize: '12px',marginLeft: '20px' }}>{ row + ' - ' + 'Невалиден внесен ЕМБС или ЕДБ'}</Text>
          </Grid>
          <Grid  xs={1}>

          </Grid>
        </Grid>
          <Box my={0.5}>
            <Divider />
          </Box>
        </>
      })

    }
    return display;
  }
  const Duplicates = () => {
    let display = [];
    if(duplicateList.length > 0) {
      display = duplicateList.map((row) => {
        return<><Grid container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={4}
        >
          <Grid  xs={10} display={'flex'} justifyContent={'start'} alignItems={'center'} >
            <Tooltip title={'Дупликат'}>
              <CheckBoxOutlineBlankIcon color={'error'}/>
            </Tooltip>
            <Text style={{fontSize: '12px',marginLeft: '20px' }}>{ row + ' - ' + 'Компанијата веќе е внесена'}</Text>
          </Grid>
          <Grid  xs={1}>

          </Grid>
        </Grid>
          <Box my={0.5}>
            <Divider />
          </Box>
        </>
      })

    }
    return display;
  }
  const DeniedList = () => {
    let display = [];
    if(deniedList.length > 0) {
      display = deniedList.map((row) => {
        return<><Grid container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={4}
        >
          <Grid  xs={10} display={'flex'} justifyContent={'start'} alignItems={'center'} >
            <Tooltip title={'Не е пронајдена'}>
              <CheckBoxOutlineBlankIcon color={'error'}/>
            </Tooltip>
            <Text style={{fontSize: '12px',marginLeft: '20px' }}>{ row.PIN + ' - ' + 'Компанијата ne e пронајдена'}</Text>
          </Grid>
          <Grid  xs={1}>

          </Grid>
        </Grid>
          <Box my={0.5}>
            <Divider />
          </Box>
        </>
      })

    }
    return display;
  }


  const ReturnResults = () => {
    return<>
      {SuccessList()}
      {InvalidList()}
      {Duplicates()}
      {DeniedList()}
    </>
  }




  return<>
    <DialogWrapper
      open={props.open}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        py={3}
        xs={12}
      >
        <AvatarSuccess>
          <Check />
        </AvatarSuccess>
        <Typography
          align="center"
          sx={{
            pt: 1,
            px: 6,
            pb: 2,
          }}
          variant="h3"
        >
          {'Статус на импортот!'}

        </Typography>
        </Box>
      <DialogContent>
        {props.open && ReturnResults()}

      </DialogContent>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        py={3}
        x>
        <Box display="flex"
             alignItems="center"
             justifyContent="center"
             flexDirection="row">
          <Button size='small' onClick={props.onClose} >Откажи</Button>
          <ButtonSuccess
            onClick={props.createCompanies}
            size="small"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
          >
            {'Импортирај'}
          </ButtonSuccess>

        </Box>
    </Box>
      <Box style={{width: '100%'}}><LinearProgress  variant={'buffer'}  /></Box>
    </DialogWrapper>

  </>
}

export default CheckBeforeImportDialog;
