const ocupations = [
  {id: 1, text: '1 - РАСТИТЕЛНО И ЖИВОТИНСКО ПРОИЗВОДСТВО, ЛОВ И УСЛУЖНИ ДЕЈНОСТИ ПОВРЗАНИ СО НИВ'},
  {id: 2, text: '2 - ШУМАРСТВО И ИСКОРИСТУВАЊЕ НА ШУМИ'},
  {id: 3, text: '3 - РИБАРСТВО И АКВАКУЛТУРА'},
  {id: 5, text: '5 - ВАДЕЊЕ НА ЈАГЛЕН И ЛИГНИТ'},
  {id: 6, text: '6 - ВАДЕЊЕ НА СУРОВА НАФТА И ПРИРОДЕН ГАС'},
  {id: 7, text: '7 - ВАДЕЊЕ НА РУДИ НА МЕТАЛ'},
  {id: 8, text: '8 - ВАДЕЊЕ НА ДРУГИ РУДИ И КАМЕН'},
  {id: 9, text: '9 - ПОМОШНИ УСЛУЖНИ ДЕЈНОСТИ ВО РУДАРСТВОТО'},
  {id: 10, text: '10 - ВАДЕЊЕ НА ЈАГЛЕН И ЛИГНИТ'},
  {id: 11, text: '11 - ПРОИЗВОДСТВО НА ПРЕХРАНБЕНИ ПРОИЗВОДИ'},
  {id: 12, text: '12 - ПРОИЗВОДСТВО НА ПИЈАЛАЦИ'},
  {id: 13, text: '13 - ПРОИЗВОДСТВО НА ТУТУНСКИ ПРОИЗВОДИ'},
  {id: 14, text: '14 - ПРОИЗВОДСТВО НА ТЕКСТИЛ'},
  {id: 15, text: '15 - ПРОИЗВОДСТВО НА КОЖА И СЛИЧНИ ПРОИЗВОДИ ОД КОЖА'},
  {id: 16, text: '16 - ПРЕРАБОТКА НА ДРВО И ПРОИЗВОДИ ОД ДРВО И ПЛУТА, ОСВЕН МЕБЕЛ, ПРОИЗВОДСТВО НА ПРЕДМЕТИ ОД СЛАМА И ПЛЕТАРСКИ МАТЕРИЈАЛ'},
  {id: 17, text: '17 - ПРОИЗВОДСТВО НА ХАРТИЈА И ПРОИЗВОДИ ОД ХАРТИЈА'},
  {id: 18, text: '18 - ПЕЧАТЕЊЕ И РЕПРОДУКЦИЈА НА СНИМЕНИ МЕДИУМИ (ЗАПИСИ)'},
  {id: 19, text: '19 - ПРОИЗВОДСТВО НА КОКС И РАФИНИРАНИ НАФТЕНИ ПРОИЗВОДИ'},
  {id: 20, text: '20 - ПРОИЗВОДСТВО НА ХЕМИКАЛИИ И ХЕМИСКИ ПРОИЗВОДИ'},
  {id: 21, text: '21 - ПРОИЗВОДСТВО НА ОСНОВНИ ФАРМАЦЕВТСКИ ПРОИЗВОДИ И ФАРМАЦЕВТСКИ ПРЕПАРАТИ'},
  {id: 22, text: '22 - ПРОИЗВОДСТВО НА ПРОИЗВОДИ ОД ГУМА И ПРОИЗВОДИ ОД ПЛАСТИЧНИ МАСИ'},
  {id: 23, text: '23 - ПРОИЗВОДСТВО НА ДРУГИ НЕМЕТАЛНИ МИНЕРАЛНИ ПРОИЗВОДИ'},
  {id: 24, text: '24 - ПРОИЗВОДСТВО НА МЕТАЛИ'},
  {id: 25, text: '25 - ПРОИЗВОДСТВО НА ФАБРИКУВАНИ МЕТАЛНИ ПРОИЗВОДИ, ОСВЕН МАШИНИ И ОПРЕМА'},
  {id: 26, text: '26 - ПРОИЗВОДСТВО НА КОМПЈУТЕРСКИ, ЕЛЕКТРОНСКИ И ОПТИЧКИ ПРОИЗВОДИ'},
  {id: 27, text: '27 - ПРОИЗВОДСТВО НА ЕЛЕКТРИЧНА ОПРЕМА'},
  {id: 28, text: '28 - ПРОИЗВОДСТВО НА МАШИНИ И УРЕДИ, НЕСПОМНАТИ НА ДРУГО МЕСТО'},
  {id: 29, text: '29 - ПРОИЗВОДСТВО НА МОТОРНИ ВОЗИЛА, ПРИКОЛКИ И ПОЛУПРИКОЛКИ'},
  {id: 30, text: '30 - ПРОИЗВОДСТВО НА ОСТАНАТА ОПРЕМА ЗА ТРАНСПОРТ'},
  {id: 31, text: '31 - ПРОИЗВОДСТВО НА МЕБЕЛ'},
  {id: 32, text: '32 - ОСТАНАТА ПРОИЗВОДСТВЕНА ИНДУСТРИЈА'},
  {id: 33, text: '33 - ПОПРАВКА И ИНСТАЛИРАЊЕ НА МАШИНИ И ОПРЕМА'},
  {id: 35, text: '35 - СНАБДУВАЊЕ СО ЕЛЕКТРИЧНА ЕНЕРГИЈА, ГАС, ПАРЕА И КЛИМАТИЗАЦИЈА'},
  {id: 36, text: '36 - СОБИРАЊЕ, ОБРАБОТКА И СНАБДУВАЊЕ СО ВОДА'},
  {id: 37, text: '37 - ОТСТРАНУВАЊЕ НА ОТПАДНИ ВОДИ'},
  {id: 38, text: '38 - ДЕЈНОСТИ ЗА СОБИРАЊЕ, ОБРАБОТКА И ОТСТРАНУВАЊЕ НА ОТПАД; ОБНОВУВАЊЕ НА МАТЕРИЈАЛИ'},
  {id: 39, text: '39 - ДЕЈНОСТИ ЗА САНАЦИЈА И ОСТАНАТИ УСЛУГИ НА УПРАВУВАЊЕ СО ОТПАД'},
  {id: 41, text: '41 - ИЗГРАДБА НА ЗГРАДИ'},
  {id: 42, text: '42 - НИСКОГРАДБА'},
  {id: 43, text: '43 - СПЕЦИЈАЛИЗИРАНИ ГРАДЕЖНИ ДЕЈНОСТИ'},
  {id: 45, text: '45 - ТРГОВИЈА НА ГОЛЕМО И НА МАЛО СО МОТОРНИ ВОЗИЛА И МОТОЦИКЛИ И ПОПРАВКА НА МОТОРНИ ВОЗИЛА И МОТОЦИКЛИ'},
  {id: 53, text: '53 - ПОШТЕНСКИ И КУРИРСКИ ДЕЈНОСТИ'},
  {id: 46, text: '46 - ТРГОВИЈА НА ГОЛЕМО, ОСВЕН ТРГОВИЈА СО МОТОРНИ ВОЗИЛА И МОТОЦИКЛИ'},
  {id: 47, text: '47 - ТРГОВИЈА НА МАЛО, ОСВЕН ТРГОВИЈА СО МОТОРНИ ВОЗИЛА И МОТОЦИКЛИ'},
  {id: 49, text: '49 - КОПНЕН ТРАНСПОРТ И ЦЕВОВОДЕН ТРАНСПОРТ'},
  {id: 50, text: '50 - ВОДЕН ТРАНСПОРТ'},
  {id: 51, text: '51 - ВОЗДУХОПЛОВЕН ТРАНСПОРТ'},
  {id: 52, text: '52 - СКЛАДИРАЊЕ И ПОМОШНИ ДЕЈНОСТИ ВО ПРЕВОЗОТ'},
  {id: 55, text: '55 - ОБЈЕКТИ ЗА СМЕСТУВАЊЕ'},
  {id: 56, text: '56 - ДЕЈНОСТИ ЗА ПОДГОТОВКА НА ОБРОЦИ И СЛУЖЕЊЕ НА ХРАНА'},
  {id: 58, text: '58 - ИЗДАВАЧКА ДЕЈНОСТ'},
  {id: 59, text: '59 - ФИЛМСКА ДЕЈНОСТ, ПРОДУКЦИЈА НА ВИДЕО И ТЕЛЕВИЗИСКА ПРОГРАМА И ДЕЈНОСТИ НА СНИМАЊЕ НА ЗВУЧНИ ЗАПИСИ И МУЗИЧКО ИЗДАВАШТВО'},
  {id: 60, text: '60 - ЕМИТУВАЊЕ НА ПРОГРАМА'},
  {id: 61, text: '61 - ТЕЛЕКОМУНИКАЦИИ'},
  {id: 62, text: '62 - КОМПЈУТЕРСКО ПРОГРАМИРАЊЕ, КОНСУЛТАНТСКИ И СРОДНИ ДЕЈНОСТИ'},
  {id: 63, text: '63 - ИНФОРМАТИВНИ УСЛУЖНИ ДЕЈНОСТИ'},
  {id: 64, text: '64 - ФИНАНСИСКО-УСЛУЖНИ ДЕЈНОСТИ, ОСВЕН ОСИГУРУВАЊЕ И ПЕНЗИСКИ ФОНДОВИ'},
  {id: 65, text: '65 - ОСИГУРУВАЊЕ, РЕОСИГУРУВАЊЕ И ПЕНЗИСКИ ФОНДОВИ, ОСВЕН ЗАДОЛЖИТЕЛНО СОЦИЈАЛНО ОСИГУРУВАЊЕ'},
  {id: 66, text: '66 - ПОМОШНИ ДЕЈНОСТИ КАЈ ФИНАНСИСКИТЕ УСЛУГИ И ДЕЈНОСТИТЕ ВО ОСИГУРУВАЊЕТО'},
  {id: 68, text: '68 - ДЕЈНОСТИ ВО ВРСКА СО НЕДВИЖЕН ИМОТ'},
  {id: 69, text: '69 - ПРАВНИ И СМЕТКОВОДСТВЕНИ ДЕЈНОСТИ'},
  {id: 70, text: '70 - УПРАВУВАЧКИ ДЕЈНОСТИ; СОВЕТУВАЊЕ ВО ВРСКА СО УПРАВУВАЊЕТО'},
  {id: 71, text: '71 - АРХИТЕКТОНСКИ ДЕЈНОСТИ И ИНЖЕНЕРСТВО; ТЕХНИЧКО ИСПИТУВАЊЕ И АНАЛИЗА'},
  {id: 72, text: '72 - НАУЧНО ИСТРАЖУВАЊЕ И РАЗВОЈ'},
  {id: 73, text: '73 - МАРКЕТИНГ (РЕКЛАМА И ПРОПАГАНАДА) И ИСТРАЖУВАЊЕ НА ПАЗАРОТ'},
  {id: 74, text: '74 - ОСТАНАТИ СТРУЧНИ, НАУЧНИ И ТЕХНИЧКИ ДЕЈНОСТИ'},
  {id: 75, text: '75 - ВЕТЕРИНАРНИ ДЕЈНОСТИ'},
  {id: 77, text: '77 - ДЕЈНОСТИ НА ИЗНАЈМУВАЊЕ И ДАВАЊЕ ПОД ЗАКУП (ЛИЗИНГ)'},
  {id: 78, text: '78 - ДЕЈНОСТИ НА ВРАБОТУВАЊЕ'},
  {id: 79, text: '79 - ТУРИСТИЧКИ АГЕНЦИИ, ОРГАНИЗАТОРИ НА ПАТУВАЊА (ТУРОПЕРАТОРИ) И ОСТАНАТИ РЕЗЕРВАЦИСКИ УСЛУГИ КАКО И ДЕЈНОСТИ ПОВРЗАНИ СО НИВ'},
  {id: 80, text: '80 - ЗАШТИТНИ И ИСТРАЖНИ ДЕЈНОСТИ'},
  {id: 81, text: '81 - УСЛУГИ ПОВРЗАНИ СО ОДРЖУВАЊЕ НА ЗГРАДИ И ДЕЈНОСТИ НА УРЕДУВАЊЕ И ОДРЖУВАЊЕ НА ЖИВОТНАТА СРЕДИНА И ПРИРОДАТА'},
  {id: 82, text: '82 - КАНЦЕЛАРИСКО-АДМИНИСТРАТИВНИ И ПОМОШНИ ДЕЈНОСТИ И ДЕЛОВНО-ПОМОШНИ ДЕЈНОСТИ'},
  {id: 84, text: '84 - ЈАВНА УПРАВА И ОДБРАНА; ЗАДОЛЖИТЕЛНО СОЦИЈАЛНО ОСИГУРУВАЊЕ'},
  {id: 85, text: '85 - ОБРАЗОВАНИЕ'},
  {id: 86, text: '86 - ДЕЈНОСТИ ЗА ЗДРАВСТВЕНА ЗАШТИТА'},
  {id: 87, text: '87 - ДЕЈНОСТИ НА СОЦИЈАЛНА ЗАШТИТА СО СМЕСТУВАЊЕ'},
  {id: 88, text: '88 - ДЕЈНОСТИ НА СОЦИЈАЛНА ЗАШТИТА БЕЗ СМЕСТУВАЊЕ'},
  {id: 90, text: '90 - КРЕАТИВНИ, УМЕТНИЧКИ И ЗАБАВНИ ДЕЈНОСТИ'},
  {id: 91, text: '91 - БИБЛИОТЕКИ, АРХИВИ, МУЗЕИ И ОСТАНАТИ КУЛТУРНИ ДЕЈНОСТИ'},
  {id: 92, text: '92 - ДЕЈНОСТИ НА КОЦКАЊЕ И ОБЛОЖУВАЊЕ'},
  {id: 93, text: '93 - СПОРТСКИ, ЗАБАВНИ И РЕКРЕАТИВНИ ДЕЈНОСТИ'},
  {id: 94, text: '94 - ДЕЈНОСТИ НА ОРГАНИЗАЦИИ ВРЗ БАЗА НА ЗАЧЛЕНУВАЊЕ'},
  {id: 95, text: '95 - ПОПРАВКА НА КОМПЈУТЕРИ И ПРЕДМЕТИ ЗА ЛИЧНА УПОТРЕБА И ЗА ДОМАЃИНСТВА'},
  {id: 96, text: '96 - ДРУГИ ЛИЧНИ УСЛУЖНИ ДЕЈНОСТИ'},
  {id: 97, text: '97 - ДЕЈНОСТИ НА ДОМАЃИНСТВАТА КОИ ВРАБОТУВААТ ПОСЛУГА (ДОМАШЕН ПЕРСОНАЛ)'},
  {id: 98, text: '98 - ДЕЈНОСТИ НА ПРИВАТНИТЕ ДОМАЃИНСТВА КОИ ПРОИЗВЕДУВААТ РАЗНОВИДНА СТОКА И ВРШАТ РАЗЛИЧНИ УСЛУГИ ЗА СОПСТВЕНИ ПОТРЕБИ'},
  {id: 99, text: '99 - ДЕЈНОСТИ НА ЕКСТРАТЕРИТОРИЈАЛНИ ОРГАНИЗАЦИИ И ТЕЛА'},




];

export {ocupations};

