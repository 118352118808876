import { Box, Card, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        p={4}
        sx={{backgroundColor: 'white'}}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
        style={{position: 'absolute', width: '100%'}}
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; Сите права се задржани - Компанија на Таргет-Холдинг ДОО Скопје
          </Typography>
        </Box>
        <Typography sx={{ display: 'flex', alignItems: 'center' }} >
          {/*Created by <Link underline="hover"  rel="noopener noreferrer">DataBank</Link>*/}
          Created by <img alt="Databank" src="\static\images\logo\databank-logo.jpg" height="70" width="150"/>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
