import React from 'react';
import Results from './Results/Results'
import SuspenseLoader from '../../components/SuspenseLoader';
import {withRouter} from 'react-router-dom';
import { CheckPayUrl, isSubscription, isReport } from "../../components_bi/helpers/PayType"
class CheckOut extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      transactionLines: [],
      payment: [],
      id: '',
      url: '',

    }
  }


  componentDidMount() {
    let url = '';
    let id = '';
    if(this.props.match.params && this.props.match.params.id){
      id = this.props.match.params.id;
      url = this.props.match.path.substring(this.props.match.path.lastIndexOf('/') + 1);
      this.setState({id: id, url: url})

    } else {
      url = 'subscriptions';
      id = this.props.user.pendingTransactionID;
      this.setState({id: id, url: url})
    }


    if(url && id){
      window.makeRequest("GET", `/api/Transactions?transactionID=${id}&returnUrl=${CheckPayUrl(url)}&isSubscription=${isSubscription(url)}&isReport=${isReport(url)}`, false, true, (data) => {
        console.log('checkout', data.body)
        this.setState({transactionLines: data.body.transactionLines,id:id, payment: data.body.payment, TotalAmount: data.body.TotalAmount, loading: false});
      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      })
    }

  }



  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return<Results subscription={isSubscription(this.state.url)} report={isReport(this.state.url)} reload={this.props.reload} transactionID={this.state.id} payment={this.state.payment} TotalAmount={this.state.TotalAmount} transactionLines={this.state.transactionLines}/>
    }
  }
}
export default withRouter(CheckOut);
