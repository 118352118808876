import React from 'react';
import request from 'superagent';

import {
  Grid,
  Typography,
  Button,
  Box
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

import {
  ocupations,
} from './Data/all_ocupation';

import NotificationDialog from './Dialogs/NotificationDialog';
import Import from './Import';
import LimitCreateDialog from "./Dialogs/LimitCreateDialog";
import AddCompanyDialog from "./Dialogs/AddCompanyDialog";
import AddCompanySecondInputsDialog from "./Dialogs/AddCompanySecondInputsDialog";
import SuspenseLoader from "../../../components/SuspenseLoader";
import AddReportDialog from './Dialogs/AddReportDialog';
import CustomSnackBar from '../../../components_bi/CustomSnackBar'
import {styled} from "@mui/material/styles/index";
import {Dialog} from "@mui/material/index";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      openAddCompany: false,
      openLimitNotification: false,
      VatPin: '',
      VAT: '',
      PIN: '',
      Company_name_by_user: '',
      Status: '',
      Establishment_year: '',
      Size: '',
      Occupation: '',
      ApiOccupation: '',
      Adress: '',
      City: '',
      error: '',
      company_nameError: '',
      vatDisabled: false,
      pinDisabled: false,
      company_nameDisabled: false,
      Company_name: '',
      notification: '',
      companyID: '',
      userPIN: '',
      Divisions: [],
      reportCounts: 0,
      messageLoad: false,
      isActive: true,
      openAddReport: false,
      roles: [
        { label: 'Активна', value: true },
        { label: 'Неактивна', value: false }

      ],
      goleminaRoles: [
        { label: 'Голем', value: 'голем' },
        { label: 'Среден', value: 'среден' },
        { label: 'Мал', value: 'мал' },
        { label: 'Микро', value: 'микро' }
      ],
      dejnostRoles: ocupations,
    };
  }

  componentDidMount(){
    this.getClient();
    this.getDivisions();
  }

  validate = () => {
    let error = '';
    let VatPin = this.state.VatPin;
    switch (true) {
      case VatPin.length < 1:
        error = 'Полето не смее да биде празно';
        break;
      case VatPin.length !== 7 && VatPin.length !== 13 && VatPin.length !== 15:
        error = 'Невалиден број';
    }

    if (error) {
      this.setState({error});
      return false;
    }

    return true;
  };

  handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value, error: '' });

  };
  changeGoleminaRole = (e, val) => {
    if (val) {
      this.setState({ Size: val.props.value });
    }
  };

  changeDejnostRole = (e, val) => {
    if (val) {
      this.setState({ Occupation: val.id });
    }
  };
  handleCreateCompanyOpen = () => {
    this.setState({ openAddCompany: true });
  };

  handleCreateCompanyClose = () => {
    this.setState({ openAddCompany: false, VatPin: '', error: ''});
  };

  closeAddCompany = () => {
    this.setState({openAddCompany: false, notification: true})
  }

  handleAddCompanyClose = () => {
    this.setState({open: false, City: '', Establishment_year: '', Company_name_by_user: ''});
  };

  handleRequireLimitChange = () => {
    window.makeRequest("PUT", `/api/Company/RequireLimitChange`, false, true, (callback) => {
      this.setState({openLimitNotification: false},this.closeNotification);
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };

  closeNotification = () => {
    this.setState({openLimitNotification: false})
    this.props.renderCompanies();
    this.props.setLimitMessageFalse();
  };

  handleAddCompanySuccess = () => {
    if(this.validate()){
      this.setState({loading:true})
      window.makeRequest("GET", `/api/Company/checkIfExist?embsEdb=${this.state.VatPin}`, false, true, (callback) => {
        if(callback.body) {
          request
            .get(`/api/Subjekt/GetSubjektiWithGradAndDejnost?embsEdb=${this.state.VatPin}`)
            .withCredentials()
            .auth('tgmisajlovska', '123456789')
            .then((callback) => {
              if (callback.body && callback.body !== null) {
                const Pregled = callback.body;

                this.setState({
                  VAT: Pregled.Edb ? Pregled.Edb : '',
                  PIN: Pregled.Embs ? Pregled.Embs : '',
                  Company_name: Pregled.CelosenNaziv ? Pregled.CelosenNaziv : '',
                  Size: Pregled.GoleminaNaSubjekt ? Pregled.GoleminaNaSubjekt.trim() : '',
                  Adress: Pregled.Sedishte ? Pregled.Sedishte : '',
                  Status: Pregled.Status ? Pregled.Status : 'Активен',
                  Occupation: Pregled.OddelSifra ? parseInt(Pregled.OddelSifra.trim()) : null,
                  Establishment_year: Pregled.DatumOsnovanje ? new Date(Pregled.DatumOsnovanje).getFullYear() : '',
                  City: Pregled.Grad,
                  pinDisabled: true,
                  company_nameDisabled: true,
                  vatError: '',
                  pinError: '',
                  open: true,
                  loading: false,
                  openAddReport: true
                },this.handleCreateCompanyClose);

              }
              else {
                this.setState({
                  error: 'Компанијата не постопи.',
                  loading: false,
                });
              }
            })
            .catch((e) => {
              console.log(e);
              this.setState({loading: false})
            });
        }
        else{
          this.setState({error: 'Компанијата веќе постои.', loading:false});
        }

      }, (err) => {
        if(err) {
          this.setState({loading:false});
          console.log('err', err);
        }
      });
    }
  };

  getClient = () => {
    window.makeRequest("GET", `/api/Clients`, false, true, (callback) => {
      this.setState({notification: callback.body.notification, companyID: callback.body.ID, userPIN: callback.body.PIN, reportCounts: callback.body.reportCounts})
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  }

  getDivisions = () => {
    window.makeRequest("GET", `/api/Division`, false, true, (callback) => {
      this.setState({Divisions: callback.body})
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });
  }

  onCloseNotification = () => {
    this.setState({notification: false})
  }

  onOpenNotification = () => {
    this.setState({notification: true})
  }

  handleCreateCompanySuccess = () => {
    let Occupation = this.state.Occupation;
    let Establishment_year = this.state.Establishment_year;

    if (!Occupation) {

      Occupation = this.state.Occupation;
    }
    if (!Establishment_year) {
      let arr = this.state.VAT.split('');
      if (this.state.VAT.indexOf('MK') > -1) {
        if (arr[6] === '0') {
          Establishment_year = '2' + arr[6] + arr[7] + arr[8];
        }
        else {
          Establishment_year = '1' + arr[6] + arr[7] + arr[8];
        }
      }
      else {

        if (arr[4] === '0') {

          Establishment_year = '2' + arr[4] + arr[5] + arr[6];

        }
        else {
          Establishment_year = '1' + arr[4] + arr[5] + arr[6];
        }

      }



    }


    window.makeRequest('POST', `/api/company/notification`, {
      VAT: this.state.VAT,
      PIN: this.state.PIN,
      Company_name: this.state.Company_name,
      Company_name_by_user: this.state.Company_name_by_user,
      Status: this.state.Status,
      Establishment_year: Establishment_year,
      Size: this.state.Size,
      Occupation: Occupation,
      Adress: this.state.Adress,
      City: this.state.City,
      Active: this.state.isActive

    }, true, (callback) => {
      if(callback.body !== -1){
        this.props.renderCompanies();
        this.setState({companyID: callback.body})
      }else{
        this.setState({openLimitNotification: true})
      }

    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
    this.handleAddCompanyClose();
  };

  showMessageLoad = () => {
    this.setState({messageLoad: true})
    // setTimeout(() => {
    //   this.setState({messageLoad: false})
    // }, 4000)
  }

  render() {

    if(this.props.limitMessage && this.state.openLimitNotification === false) {
      this.setState({openLimitNotification: true})
    }
    return (
      <>
        {/*<CustomSnackBar message='Успешно зачуван извештај. Истиот се наоѓа во табелата Изработка.'/>*/}
        {/*{this.state.messageLoad && <CustomSnackBar message='Успешно импортиран документ.' top='15px'/>}*/}

        {
          this.state.messageLoad && <DialogWrapper
            open={this.state.messageLoad}
            keepMounted
            onClose={this.props.onClose}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={'400px'}
              width={'600px'}
              borderRadius={1}
              // p={2}
              // style={{
              //   backgroundColor: '#DADADA',
              // }}
            >
              <Box width='90%' height='70%' style={{textAlign: 'center', lineHeight: '50px'}}>
                <Typography align="center" style={{ paddingBottom: '70px', color: 'blue'}} variant="h3">
                  {('Успешно импортиран документ' )}
                </Typography>
                <Typography align="center" style={{ paddingBottom: '80px', color: 'blue'}} variant="h4">
                  {('Документот ќе биде превземен за обработка од нашите администратори. За проверка на статусот на извештајот можете да пристапите до Мој Профил - Импорти' )}
                </Typography>
                <Typography align="right">
                  <Button
                    sx={{ mt: { xs: 2, sm: 0 } }}
                    onClick={()=> this.setState({messageLoad: false})}
                    variant="contained"
                  >
                    {('Во ред')}
                  </Button>
                </Typography>
              </Box>

            </Box>
          </DialogWrapper>
        }

        {/*{this.state.messageLoad && <CustomSnackBar message='Документот ќе биде обработен и вчитан од страна на нашите администратори.' top='80px'/>}*/}
        <Grid container justifyContent="space-between" alignItems="center">
          <SuspenseLoader visible={this.state.loading} full={true} />
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {('Уредување на компании')}
            </Typography>
            <Typography variant="subtitle2">
              {(
                'Сите работи поврзани со компаниите може да се уредуваат од оваа страна'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateCompanyOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small"/>}
            >
              {('Додади компанија')}
            </Button>

            <Import importCompanies={this.props.importCompanies} />

          </Grid>
        </Grid>

        <NotificationDialog open={this.state.notification == null || this.state.notification === true} onOpenNotification={this.onOpenNotification} companyID={this.state.companyID} PIN={this.state.PIN ? this.state.PIN : this.state.userPIN} VAT={this.state.VAT} Company_name={this.state.Company_name} onClose={this.onCloseNotification} addCompany={this.handleCreateCompanyOpen} reportCounts={this.state.reportCounts} Divisions={this.state.Divisions} showMessageLoad={this.showMessageLoad}/>
        <AddReportDialog open={this.state.openAddReport} VAT={this.state.VAT} Company_name={this.state.Company_name} onClose={()=>this.setState({openAddReport:false})} PIN={this.state.PIN} companyID={this.state.companyID}/>
        <LimitCreateDialog handleRequireLimitChange={this.handleRequireLimitChange} open={this.state.openLimitNotification} onClose={this.closeNotification} />
        <AddCompanyDialog error={this.state.error} open={this.state.openAddCompany} onClose={this.handleCreateCompanyClose} handleAddCompanySuccess={this.handleAddCompanySuccess} closeAddCompany={this.closeAddCompany} VatPin={this.state.VatPin} handleChange={this.handleChange}  />
        <AddCompanySecondInputsDialog
          open={this.state.open}
          onClose={this.handleAddCompanyClose}
          VAT={this.state.VAT}
          vatError={this.state.vatError}
          PIN={this.state.PIN}
          pinError={this.state.pinError}
          Company_name={this.state.Company_name}
          Company_name_by_user={this.state.Company_name_by_user}
          handleChange={this.handleChange}
          Establishment_year={this.state.Establishment_year}
          Size={this.state.Size}
          changeGoleminaRole={this.changeGoleminaRole}
          chandeDejnostRole={this.changeDejnostRole}
          goleminaRoles={this.state.goleminaRoles}
          Occupation={this.state.Occupation}
          Occupations={this.state.dejnostRoles}
          Adress={this.state.Adress}
          City={this.state.City}
          handleCreateCompanySuccess={this.handleCreateCompanySuccess}
        />
      </>
    );
  }
}


export default PageHeader;

