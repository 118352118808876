import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';

export default function DataGridDemo(props) {
  let data = props.data;
  return (
    <Box justify={'center'} alignItems={'center'} style={{ height: 222, width: '100%', backgroundColor: 'white'}}>
      <DataGrid
        style={{fontSize: 14}}
        rows={props.data}
        alignContent={'center'}
        columns={props.columns}
        pageSize={3}
        sx={{
          "&:hover:not(.Mui-disabled)": {
            cursor: "pointer"
          }
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {props.noRowsMessage || 'Нема податоци'}
            </Stack>
          )}}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        onRowClick={props.onRowClick}
      />
    </Box>
  );
}
