import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import Sun from '../weather/sun.png';
import Storm from '../weather/brainstorming.png';
import Clound from '../weather/cloud.png';
import Cloudy from '../weather/cloudy.png';
import Snowy from '../weather/snowy.png';
import Mist from '../weather/mist.png';
import topCard from './topCard.css';


const WeatherIcon = styled(Box)(
  () => `
    height: auto;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    img {
      max-width: 80px;
    }
`
);

function GrossSales(props) {
  const { t } = useTranslation();
  const description = props.description;
  const title = props.title;


  const returnIcon = () => {
    let title = props.title;
    let denoviNaNaplata = props.displayWeather.denoviNaNaplata ? props.displayWeather.denoviNaNaplata : 0;
    let denoviNaIsplata = props.displayWeather.denoviNaIsplata ? props.displayWeather.denoviNaIsplata : 0;
    let brzaLikvidnost = props.displayWeather.brzaLikvidnost ? props.displayWeather.brzaLikvidnost : 0;
    let zadolzenost = props.displayWeather.zadolzenost ? props.displayWeather.zadolzenost : 0;
    let Ocena = props.displayWeather.Ocena ? props.displayWeather.Ocena : 0;
    let periodVrakanjeKrediti = props.displayWeather.periodVrakanjeKrediti ? props.displayWeather.periodVrakanjeKrediti : 0;
    let showIcon = '';
    switch(title){
      case title = "Денови на наплата на побарувања":
        switch(true) {
          case denoviNaNaplata <= 60:
            return showIcon = Sun;
          case denoviNaNaplata >= 61 && denoviNaNaplata <= 130:
            return showIcon = Cloudy;
          case denoviNaNaplata >= 131 && denoviNaNaplata <= 180:
            return showIcon = Clound;
          case denoviNaNaplata >= 181 && denoviNaNaplata <= 300:
            return showIcon = Mist;
          case denoviNaNaplata >= 301 && denoviNaNaplata <= 380:
            return showIcon = Snowy;
          case denoviNaNaplata >= 381:
            return showIcon = Storm;
        }
        break;
      case title = "Денови на исплата на обврски":
        switch(true) {
          case denoviNaIsplata <= 75:
            return showIcon = Sun;
          case denoviNaIsplata >= 76 && denoviNaIsplata <= 150:
            return showIcon = Cloudy;
          case denoviNaIsplata >= 151 && denoviNaIsplata <= 250:
            return showIcon = Clound;
          case denoviNaIsplata >= 251 && denoviNaIsplata <= 340:
            return showIcon = Mist;
          case denoviNaIsplata >= 341 && denoviNaIsplata <= 440:
            return showIcon = Snowy;
          case denoviNaIsplata >= 441:
            return showIcon = Storm;
        }
        break;
      case title = "Брза ликвидност":
        switch(true) {
          case brzaLikvidnost >= 1.8:
            return showIcon = Sun;
          case brzaLikvidnost >= 1.2 && brzaLikvidnost <= 1.79:
            return showIcon = Cloudy;
          case brzaLikvidnost >= 0.9 && brzaLikvidnost <= 1.19:
            return showIcon = Clound;
          case brzaLikvidnost >= 0.6 && brzaLikvidnost <= 0.89:
            return showIcon = Mist;
          case brzaLikvidnost >= 0.4 && brzaLikvidnost <= 0.59:
            return showIcon = Snowy;
          case brzaLikvidnost <= 0.39:
            return showIcon = Storm;
        }
        break;
      case title = "Задолженост":
        switch(true) {
          case zadolzenost <= 20:
            return showIcon = Sun;
          case zadolzenost >= 21 && zadolzenost <= 40:
            return showIcon = Cloudy;
          case zadolzenost >= 41 && zadolzenost <= 60:
            return showIcon = Clound;
          case zadolzenost >= 61 && zadolzenost <= 75:
            return showIcon = Mist;
          case zadolzenost >= 76 && zadolzenost <= 85:
            return showIcon = Snowy;
          case zadolzenost >= 85:
            return showIcon = Storm;
        }
        break;
      case title = "Бонитетна оцена":
        switch(true) {
          case Ocena === "A":
            return showIcon = Sun;
          case Ocena === "B":
            return showIcon = Cloudy;
          case Ocena === "C":
            return showIcon = Clound;
          case Ocena === "D" || Ocena === "NR":
            return showIcon = Mist;
          case Ocena === "E":
            return showIcon = Snowy;
          case Ocena === "F":
          return showIcon = Storm;
          case Ocena >= 91:
            return showIcon = Cloudy;
          case Ocena >= 76 && Ocena <= 90:
            return showIcon = Cloudy;
          case Ocena >= 41 && Ocena <= 75:
            return showIcon = Clound;
          case Ocena >= 45 && Ocena <= 60:
            return showIcon = Mist;
          case Ocena >= 37 && Ocena <= 44:
            return showIcon = Snowy;
          case Ocena <= 36:
            return showIcon = Storm;
        }
        break;
      case title = "Просечен период на враќање на кредити":
        switch(true) {
          case periodVrakanjeKrediti <= 0.5:
            return showIcon = Sun;
          case periodVrakanjeKrediti >= 0.51 && periodVrakanjeKrediti <= 2:
            return showIcon = Cloudy;
          case periodVrakanjeKrediti >= 2.01 && periodVrakanjeKrediti <= 3.5:
            return showIcon = Clound;
          case periodVrakanjeKrediti >= 3.51 && periodVrakanjeKrediti <= 4.5:
            return showIcon = Mist;
          case periodVrakanjeKrediti >= 4.51 && periodVrakanjeKrediti <= 7:
            return showIcon = Snowy;
          case periodVrakanjeKrediti >= 7.01:
            return showIcon = Storm;
        }
        break;
    }
    return showIcon;
  }



  return (
    <Card sx={{ px: 1, pt: 1}}>
      <CardHeader
        className={topCard}
        titleTypographyProps={{
          fontWeight: 'bold',
          color: 'textSecondary',
          height: '35px',
          fontSize: 14,

        }}
        action={
          <Tooltip
            placement="top"
            arrow
            title={t(description)}
          >
            <IconButton size="small" color="secondary">
              <HelpOutlineTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
        title={t(title)}
      />
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // height: '200px'
        }}
      >
        <WeatherIcon>
          <img  src={returnIcon()} alt="image" />
        </WeatherIcon>
      </CardContent>
    </Card>
  );
}

export default GrossSales;
