import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

function TotalAmountComponent(props) {

  return (
      <Grid spacing={0} container>
        <Grid item xs={12} >
          <Box display={'flex'} mx={3} justifyContent={'end'}>
            <Typography
              sx={{
                mr: 2,
              }}
              variant="h3"
            >
              Вкупно:
            </Typography>
            <Box >
              <Typography variant="h3" gutterBottom>
                ${props.totalAmount}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignContent={'end'} justifyContent={'end'} mr={3} mt={-0.5}>
            <Typography
              variant="h6"
              fontWeight="normal"
              color="text.secondary"
            >
              без вкучен ддв
            </Typography>
          </Box>
        </Grid>
      </Grid>
  );
}

export default TotalAmountComponent;
