import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Slide,
  Tooltip,
  IconButton,
  Button,
  Typography,
  Dialog,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import BI_DataGrid from "../../../../../components_bi/BI_DataGrid";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const Users = ({ users, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDivisionID, setDeleteDivisionID] = useState(null);
  const [deleteUserID, setDeleteUserID] = useState(null);





  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (ID) => {
    setDeleteDivisionID(rest.divisionID);
    setDeleteUserID(ID);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    window.makeRequest("DEL", `/api/UserDivisions?divisionID=${deleteDivisionID}&userID=${deleteUserID}`, false, true, (callback) => {
      console.log('callback', callback);
      setOpenConfirmDelete(false);
      rest.getUsers();
      setDeleteDivisionID(null);
      setDeleteUserID(null);
    }, (err) => {
      if(err) {
        console.log('err', err);

      }
    });


    enqueueSnackbar(t('Корисникот е успешно избришан од дивизијата'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const columns = [
    {
      field: 'Username',
      headerName: 'Корисничко име',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,

    },
    {
      field: 'Firstname',
      headerName: 'Име',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,

    },
    {
      field: 'Lastnamne',
      headerName: 'Презиме',
      headerAlign: 'center',
      width: 250,
      type:'text',
      align: 'center',
      disableColumnMenu: true,

    },
    {
      field: '#',
      headerName: 'Менаџирај',
      headerAlign: 'center',
      width: 220,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return <><Tooltip title={'Избриши од дивизија'} arrow>
          <IconButton
            onClick={() => handleConfirmDelete(params.row.ID)}
            color="primary"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip></>

      }},

  ]
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >

      </Box>
              <BI_DataGrid search={true} columns={columns} data={users}/>

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {'Дали сакате да го избришете корисникот'}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {'Откажи'}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {'Избриши'}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Users.propTypes = {
  users: PropTypes.array.isRequired
};

Users.defaultProps = {
  users: [],
  companies: [],

};

export default Users;
