import React from 'react';
import Alert from '@mui/material/Alert';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


class AddCompanyDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    }
  }


  render(){

    return( <Dialog
      fullWidth
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <Box>
        <DialogTitle sx={{ p: 3, m: 2 }}>
        <Typography style={{fontSize: 15, fontWeight: 600, color: 'blue'}} gutterBottom align='center'>
          {`Додадете нова компанија`}<br />
        </Typography>
        <Typography style={{fontSize: 15,  color: 'blue', fontWeight: 600}} gutterBottom align='center'>
          {`Пополнете ги полињата за да додадете компанија`}<br /><br />
        </Typography>
        </DialogTitle>


        <DialogContent sx={{ p: 3, m: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} sx={{mt: 2}}>
                  <TextField
                    fullWidth
                    label={('ЕДБ/ЕМБС')}
                    name="VatPin"
                    value={this.props.VatPin}
                    onChange={this.props.handleChange}
                    variant="outlined"
                  />
                  {this.props.error && <Alert severity="error" sx={{ mt: 1 }}>{this.props.error}</Alert>}
                  <div onClick={() => window.open('http://biznismreza.mk/')} style={{color: 'blue', cursor: 'pointer'}}>За проверка на ЕМБС/ЕДБ кликнете тука</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3, justifyContent: 'space-between' }}>
          <Button color="primary" onClick={this.props.closeAddCompany}>
            <ArrowBackIcon />
          </Button>
          <div>
            <Button color="secondary" onClick={this.props.onClose}>
              {('Откажи')}
            </Button>
            <Button
              type="submit"
              onClick={this.props.handleAddCompanySuccess}
              variant="contained"
            >
              {('Додади компанија')}
            </Button>
          </div>
        </DialogActions>
      </Box>
    </Dialog>)
  }
}
export default AddCompanyDialog;
