import React from 'react';
import AllDivisionsPageHeader from './AllDivisionsPageHeader';
import AllDivisionsResult from './AllDivisionsResult'
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {Grid} from '@mui/material';
import Notification from '../Admin/Users/Notification'
import DivisionCreateLimit from './Dialogs/DivisionCreateLimit';

class AllDivisions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      divisions: [],
      companies: [],
      users: [],
      divisionsCount: '',
      limit: '',
      openCreateDivisionCheck: false,
    }
  }


  componentDidMount() {
    this.renderDivisions();
    this.renderUsersWithDivisionPermission();
    this.renderCompanies();
  }



  renderCompanies = () => {
    window.makeRequest("GET", `/api/Company`, false, true, (callback) => {
      this.setState({companies: callback.body})
    }, (err) => {
      if (err) {
        console.log('err', err);

      }
    });
  };
  renderUsersWithDivisionPermission = () => {
    window.makeRequest("GET", `/api/Division?getUsers=${true}`, false, true, (callback) => {
      this.setState({users: callback.body})
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });

  };
  renderDivisions = () => {
    window.makeRequest("GET", `/api/Division`, false, true, (callback) => {
      let divisions = callback.body;
      window.makeRequest("GET", `/api/Limit?divisionLimit=${true}`, false, true, (callback) => {
        this.setState({divisions: divisions,divisionsCount: callback.body.divisionsCount, limit: callback.body.limit,})
      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      });
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  };
  createDivision = (response,b) => {
    if(response === 'OK'){
      this.renderDivisions();
    } else {
      this.setState({openCreateDivisionCheck: true})
    }
  }
  closeCreateDivisionCheck = () => {this.setState({openCreateDivisionCheck: false})};

  createDivisionDialog = () => {
    return<DivisionCreateLimit ManageSubscription={this.props.ManageSubscription} open={this.state.openCreateDivisionCheck} onClose={this.closeCreateDivisionCheck} />
  }


  render() {
    return (
        <>
          <PageTitleWrapper>
            <AllDivisionsPageHeader createDivision={this.createDivision} companies={this.state.companies} users={this.state.users} renderDivisions={this.renderDivisions}/>
          </PageTitleWrapper>

          <Grid
            sx={{px: 4}}
            container
            direction="row"
            alignItems="stretch"
          >
            <Notification text={'Моментален лимит на портфолија:' + this.state.limit + ', Имате искористено:' + this.state.divisionsCount}/>
            <Grid item xs={12}>
              <AllDivisionsResult ManageDivisions={this.props.ManageDivisions} divisions={this.state.divisions} renderDivisions={this.renderDivisions}/>
              {this.createDivisionDialog()}
            </Grid>
          </Grid>
        </>
      )
  }

}


export default AllDivisions;
