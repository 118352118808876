import { Box, Container, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';


const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(14)};`
);


function HomeHeader() {
  const { t } = useTranslation();

  return (

      <HeaderWrapper>
        <Container maxWidth="lg" >
          <Box display="flex" alignItems="center">
            <img alt="Databank" src="\static\images\logo\databank-logo.jpg" height="100" width="250" style={{margin: -30}}/>
            {/*<h1>Бонитет.мк</h1>*/}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Box />
              <Box>
                <Button
                  style={window.location.pathname === '/' ? {backgroundColor: '#dcdeed'} : null}
                  component={RouterLink}
                  to="/"
                  sx={{ ml: 2 }}
                >
                  {t('Почетна страна')}
                </Button>

                <Button
                  style={window.location.href.indexOf('Offer') > -1 ? {backgroundColor: '#dcdeed'} : null}
                  component={RouterLink}
                  to="/Offer"
                  sx={{ ml: 2 }}
                >
                  {t('Понуда')}
                </Button>

                <Button
                  style={window.location.href.indexOf('AboutUs') > -1 ? {backgroundColor: '#dcdeed'} : null}
                  component={RouterLink}
                  to="/AboutUs"
                  sx={{ ml: 2 }}
                >
                  {t('За нас')}
                </Button>
                {/*<LanguageSwitcher />*/}
                <Button
                  style={window.location.href.indexOf('Contact') > -1 ? {backgroundColor: '#dcdeed'} : null}
                  component={RouterLink}
                  to="/Contact"
                  sx={{ ml: 2 }}
                >
                  {t('Контакт')}
                </Button>




                <Button
                  component={RouterLink}
                  to="/Login"
                  variant="contained"
                  sx={{ ml: 2 }}
                >
                  {t('Најави се')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </HeaderWrapper>
  );
}

export default HomeHeader;
