import React, { useState, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button
} from '@mui/material';


function AutoLogout(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const AutoLogoutRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const onIdle = () => {
    setModalOpen(true);
    sessionTimeoutRef.current = setTimeout(Logout, 8000);
  }

  const StayLogged = () => {
    setModalOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  }

  const Logout = () => {
    setModalOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    props.logout();
  }

  return(
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Typography variant="h4" gutterBottom>
                {'Забележана неактивност'}
              </Typography>
              <Typography variant="body1">
                {
                  'Автоматски ќе бидете одјавени!'
                }
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {props.logged && <IdleTimer ref={AutoLogoutRef} timeout={15*60000} onIdle={onIdle} />}
    </div>
  )
}



export default AutoLogout;
