import { Box, Hidden } from '@mui/material';
import CartPreview from './CartPreview';

function Cart(props) {
  return (
    <Box sx={{ mr: 1.5 }}>
      <Hidden smDown>
        {props.number?
          <CartPreview number={props.number}/>
          : <CartPreview />
        }
      </Hidden>
    </Box>
  );
}

export default Cart;
