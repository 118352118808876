import React from 'react';
import {
  Box,
  Card,
  Divider,
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PageHeaderSubscription from './PageHeaderSubscription';
import Profiles from './UserProfile'
import TopCards from './TopCards';
import SuspenseLoader from '../../../components/SuspenseLoader'
import PlanCard from "../Subscription/PlanCard/index";
import AdditionalPlan from "../Subscription/PlanCard/AdditionalPlan/index";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Transactions from "./Transactions";
import Reports from './Reports';
import Imports from "./Imports";
import Notifications from "../../../components_bi/Notifications";
import AdditionalCustomInput from "./../Subscription/PlanCard/AdditionalPlan/AdditionalCustomInput";
import DateFormating from '../../../components_bi/DateFormating'
import {Link as RouterLink, withRouter} from "react-router-dom";
import {Dialog} from "@mui/material/index";
import TabNotificationWrapper from '../../../components/TabNotificationWrapper/TabNotificationWrapper';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `

    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: [
        {
          value: 'Профил',
          label: 'Профил'
        },
        {
          value: 'Претплата',
          label: 'Претплата'
        },
        {
          value: 'Трансакции',
          label: 'Трансакции',
          notification: 'Transactions'
        },
        {
          value: 'Репорти',
          label: 'Купени извештаи',
          notification: 'Reports',
        },
        {
          value: 'Импорти',
          label: 'Импорти',
          notification: 'Imports'
        },
      ],
      selectedTab: 'Профил',
      subscriptionPlan: [],
      totalUsers: 0,
      totalDivisions: 0,
      ReportPrice: 0,
      totalCheckOutPrice: 0,
      selectedPlanID: '',
      SubscriptionPeriod: 'monthly',
      CurrentSubscriptionPeriod: '',
      DateStart: new Date().toLocaleDateString(),
      DateEnd: new Date().toLocaleDateString(),
      transactionLines: [],
      nextSubscriptionID: null,
      ExtraDivisions: 0,
      ExtraUsers: 0,
      addDivision: 0,
      addUser: 0,
      curPlanID: null,
      status: -1,
      openDowngradeUpdate: false,
      showMessage: false,
    }
  }

  componentDidMount() {
    this.getSubscriptionPlan();
    this.selectTab();
    this.getSubscriptionStatus();
    this.getNotifications();

    if(this.props.user.ManageSubscription !== true){
      this.setState({selectedTab: 'Профил'});
      {this.state.tabs = this.state.tabs.filter(v => v.value !== "Претплата" && v.value !== "Трансакции" && v.value !== "Репорти")};
    }
    if(this.props.user.Import !== true){
      this.state.tabs.map(item => {if (item.value === 'Импорти') {this.state.tabs.splice(this.state.tabs.findIndex(v => v.value === "Импорти"), 1)}});
    }
  }

  getNotifications = () => {
    window.makeRequest("GET", `/api/Notification`, false, true, (callback) => {
      if (callback.body) {
        this.setState({notifications: callback.body});
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  selectTab = () => {
    if(this.props.match.params.tab === "Profile"){
      this.setState({selectedTab: "Профил"})
    }
    else if(this.props.match.params.tab === "Subscription"){
      this.setState({selectedTab: "Претплата"})
    }
    else if(this.props.match.params.tab === "Transactions"){
      this.setState({selectedTab: "Трансакции"})
    }
    else if(this.props.match.params.tab === "Reports"){
      this.setState({selectedTab: "Репорти"})
    }
    else if(this.props.match.params.tab === "Imports"){
      this.setState({selectedTab: "Импорти"})
    }
  }

  redirect = (value) => {

    if (value === 'Профил') {
      this.props.history.push("/MyProfile/Profile");
    }
    if (value === 'Претплата') {
      this.props.history.push("/MyProfile/Subscription");
    }
    if (value === 'Трансакции') {
      this.props.history.push("/MyProfile/Transactions");
    }
    if (value === 'Репорти') {
      this.props.history.push("/MyProfile/Reports");
    }
    if (value === 'Импорти') {
      this.props.history.push("/MyProfile/Imports");
    }
  }

  ReturnTab = () => {
    let showTab = this.props.match.params.tab;
    if (showTab === 'Profile') {
      return <Profiles/>;
    }
    if (showTab === 'Subscription') {
      return this.Plan()
    }
    if(showTab === 'Transactions'){
      return <Transactions/>
    }
    if(showTab === 'Reports'){
      return <Reports/>
    }
    if(showTab === 'Imports'){
      return <Imports/>
    }

  };

  getSubscriptionPlan = () => {
    window.makeRequest("GET", `/api/Subscription`, false, true, (callback) => {
      if (callback.body) {
        this.setState({subscriptionPlan: callback.body}, this.getTransaction);
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  getSubscriptionStatus = () => {
    window.makeRequest("GET", `/api/SubscriptionClient/getStatus`, false, true, (callback) => {
      if (callback.body) {
        this.setState({status: callback.body});
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  getTransaction = (cancel) => {
    window.makeRequest("GET", `/api/SubscriptionClient?getCurrent=true`, false, true, (callback) => {
      if (callback) {
        this.setState({
          displayCurrentPlan: callback.body.subscriptionClient,
          TotalUsers:callback.body.subscriptionClient.TotalUsers,
          TotalDivisions: callback.body.subscriptionClient.TotalDivisions,
          DateStart:callback.body.subscriptionClient.DateStart,
          DateEnd: callback.body.subscriptionClient.DateEnd,
          ReportPrice: callback.body.subscriptionClient.ReportPrice,
          selectedPlanID: callback.body.subscriptionClient.SubscriptionID,
          curPlanID: callback.body.subscriptionClient.SubscriptionID,
          SubscriptionPeriod: callback.body.subscriptionClient.Period,
          CurrentSubscriptionPeriod: callback.body.subscriptionClient.Period,
          planPrice: callback.body.subscriptionClient.SubscriptionPrice,
          nextSubscriptionID: callback.body.nextSubscription ? callback.body.nextSubscription.ID : null,
          nextSubscription: callback.body.nextSubscription ? callback.body.nextSubscription : null,
          ExtraDivisions: callback.body.subscriptionClient.ExtraDivisions ? callback.body.subscriptionClient.ExtraDivisions : null,
          addDivision: callback.body.subscriptionClient.ExtraDivisions ? callback.body.subscriptionClient.ExtraDivisions : null,
          ExtraUsers: callback.body.subscriptionClient.ExtraUsers ? callback.body.subscriptionClient.ExtraUsers : null,
          addUser: callback.body.subscriptionClient.ExtraUsers ? callback.body.subscriptionClient.ExtraUsers : null,
          loading: false});
        if(cancel === 'cancel') {
          this.cancelNextSubscription();
        }
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    });
  }

  handleTabsChange = (event, tabsValue) => {
    this.getNotifications();
    this.setState({selectedTab: tabsValue});
    this.redirect(tabsValue);
  };






  selectAdditionalPlan = (Users, Divisions) => {
    if(Users) {
      this.setState({addUser: Users})
    } else {
      this.setState({addUser: 0})

    }
    if(Divisions) {
      this.setState({addDivision: Divisions})
    }else {
      this.setState({addDivision: 0})

    }
  }

  cancelNextSubscription = () => {
    this.setState({loading: true})
    window.makeRequest("PUT", `/api/SubscriptionClient?subscriptionClientID=${this.state.nextSubscriptionID}`, null, true, (callback) => {
      this.getTransaction();
      // this.props.reload();
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }

  changeSubscriptionPeriod = (e) => {
    // console.log('test', e, this.state.curPlanID, this.state.selectedPlanID);
    if(this.state.selectedPlanID === this.state.curPlanID && e == this.state.CurrentSubscriptionPeriod){
      this.setState({SubscriptionPeriod: e, addUser: this.state.ExtraUsers,addDivision: this.state.ExtraDivisions,})
    } else {
      this.setState({SubscriptionPeriod: e, addUser: 0,addDivision: 0,})
    }
  }

  selectPlan = (e, plan) => {
      if(this.state.curPlanID === plan.ID && this.state.SubscriptionPeriod === this.state.CurrentSubscriptionPeriod){
        this.setState({isPlanSelected: true , selectedPlanID: plan.ID, addUser: this.state.ExtraUsers,addDivision: this.state.ExtraDivisions,})
      }else {
        this.setState({isPlanSelected: true , selectedPlanID: plan.ID,addUser: 0,addDivision: 0,})
      }
  }

  handleChange = (e) => {
    let value = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    this.setState({[e.target.name]: value})
  }

  AddUser = () => {
    this.setState({addUser: this.state.addUser+1})
  }
  RemoveUser = () => {
    if(this.state.addUser !== 0){
      this.setState({addUser: this.state.addUser-1})
    }
  }
  AddDivision = () => {
    this.setState({addDivision: this.state.addDivision+1})
  }
  RemoveDivision = () => {
    if(this.state.addDivision !== 0){
      this.setState({addDivision: this.state.addDivision-1})
    }
  }

  checkNextSubscription = () => {
    if(this.state.nextSubscriptionID === null) {
      return<>


        <Box>
          <PlanCard SubscriptionPeriod={this.state.SubscriptionPeriod} changeSubscriptionPeriod={this.changeSubscriptionPeriod}  displayCurrentPlan={this.state.displayCurrentPlan} subscriptionPlan={this.state.subscriptionPlan} onClick={this.selectPlan}/>
        </Box>

        <Box px={4} py={2} >
          <Divider/>
          <Grid  container xs={12} spacing={1} py={1.5} >
            <Grid item xs={3}  >
              <AdditionalCustomInput
                label={'Дополнителни корисници'}
                name='additionalUsers'
                value={this.state.addUser}
                onChange={this.handleChange}
                ButtonIncrement={this.AddUser}
                ButtonDecrement={this.RemoveUser}
              />
            </Grid>
            <Grid item xs={3} >
              <AdditionalCustomInput
                label={'Дополнителни портфолија'}
                name='additionalDivsions'
                value={this.state.addDivision}
                onChange={this.handleChange}
                ButtonIncrement={this.AddDivision}
                ButtonDecrement={this.RemoveDivision}
              />
            </Grid>
            <Grid item xs={3}  display={'block'} justifyContent={'center'} alignContent={'center'}  >
              {/*/!*<TotalAmountComponent totalAmount={this.returnTotalPrice()} />*!/*/}
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'end'} alignContent={'end'} >
              <Button style={{width: '100%'}} onClick={this.returnCheckOut}
              startIcon={<CreditCardIcon/>} size={'small'} variant={'contained'} m={-1}>Промени план</Button>
            </Grid>
          </Grid>
        </Box></>
    } else {
      return <Box px={4} py={2}>
        <PageHeaderSubscription icon={true}  nextSubscription={true} title={'Преглед на следна претплата'} subtitle={'Преглед на следна ажуририрана претплата'}  DateStart={this.state.nextSubscription.DateStart} Name={this.state.nextSubscription.Name}  />
        <TopCards onClick={this.selectPlan} totalUsers={this.state.nextSubscription.TotalUsers} totalDivisions={this.state.nextSubscription.TotalDivisions} ReportPrice={this.state.nextSubscription.ReportPrice} />
        <Box mt={1} display={'flex'} justifyContent={'end'}><Button variant={'contained'} onClick={this.cancelNextSubscription}>Откажете ја претплатата</Button></Box>
      </Box>
    }
  }

  Plan = () => {
    return <>
      <Box px={4} py={0}>
        <PageHeaderSubscription startAndEndDate={true} icon={true} title={'Преглед на моментална претплата'} subtitle={'Моментална претплата и можност за промена на претплатата'} DateStart={this.state.DateStart} DateEnd={this.state.DateEnd}  />
        <TopCards onClick={this.selectPlan} totalUsers={this.state.TotalUsers} totalDivisions={this.state.TotalDivisions} ReportPrice={this.state.ReportPrice} status={this.state.status} handleSubscription={this.handleSubscription}/>
        {
          this.state.showMessage && <DialogWrapper
            open={this.state.showMessage}
            keepMounted
            onClose={this.props.onClose}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={'300px'}
              width={'600px'}
              borderRadius={1}
            >
              <Box width='90%' height='70%' style={{textAlign: 'center', lineHeight: '50px'}}>
                <Typography align="center" style={{ paddingBottom: '70px', color: 'blue'}} variant="h3">
                  {('Успешно направено откажување на претплата' )}
                </Typography>
                <Typography align="center" style={{ paddingBottom: '60px', color: 'blue'}} variant="h4">
                  {('Вашиот пристап ќе биде активен до ' + DateFormating(this.state.DateEnd) )}
                </Typography>
                <Typography align="center">
                  <Button
                    sx={{ mt: { xs: 2, sm: 0 } }}
                    onClick={() => this.setState({showMessage: false})}
                    variant="contained"
                  >
                    {('Во ред')}
                  </Button>
                </Typography>
              </Box>

            </Box>
          </DialogWrapper>
        }
      </Box>
      <Box px={4} >
        <Divider/>
      </Box>
      {this.checkNextSubscription()}
    </>
  }

  handleSubscription = () => {
    window.makeRequest("PUT", `/api/SubscriptionClient/cancelContinue`, false, true, (callback) => {

      this.getSubscriptionStatus();

      if(this.state.status === 2){
        this.setState({showMessage: true})
      }
    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }

  subscriptionClient = (type) => {
    window.makeRequest("POST", `/api/SubscriptionClient`, {
      SubscriptionID: this.state.selectedPlanID,
      ExtraUsers: this.state.addUser,
      SubscriptionPeriod: this.state.SubscriptionPeriod,
      ExtraDivisions: this.state.addDivision,
      UpgradeDowngrade: type,
    }, true, (callback) => {
      if(callback.body === null){
        this.setState({notification: true})
      }else if(callback.body === -3){

      }
      else if(callback.body === -4){
        window.location.href = `/MyProfile/Subscription`;
      }
      else {
        window.location.href = `/transaction/${callback.body}/subscriptions`;
      }

    }, (err) => {
      if (err) {
        console.log('err', err);
      }
    })
  }

  returnCheckOut = () => {

    if(this.state.selectedPlanID === this.state.curPlanID){
      const ExtraUsers = this.state.ExtraUsers;
      const ExtraDivisions = this.state.ExtraDivisions;
      const addUser = this.state.addUser;
      const addDivision = this.state.addDivision;
      if((addUser > ExtraUsers && addDivision < ExtraDivisions) || (addDivision > ExtraDivisions && addUser < ExtraUsers)){
        this.setState({openDowngradeUpdate: true})
      } else {
        this.subscriptionClient(false);
      }




    } else {
      this.subscriptionClient(false);
    }

  }



  closeNotifications = () => {
    this.setState({notification: false, loading: true}, this.getTransaction)
  }

  cancelDialogNextSubscription = () => {
    this.setState({loading: true, notification: false}, () => this.getTransaction('cancel'))
  }

  closeDowngradeUpdateDowngrade = () => {
    this.setState({openDowngradeUpdate: false}, () => this.subscriptionClient(false))
  }
  closeDowngradeUpdateNow = () => {
    this.setState({openDowngradeUpdate: false}, () => this.subscriptionClient(true))
  }

  render() {
    if(this.state.loading) {
      return <SuspenseLoader />
    }else {
      return (
        <RootWrapper sx={{p: 3}}>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{xs: 'column', sm: 'row'}}
            justifyContent={{xs: 'center', sm: 'space-between'}}
            pb={3}
          >
            <TabsWrapper
              onChange={this.handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={this.state.selectedTab}
              variant="scrollable"
            >
              {this.state.tabs.map((tab) => {
                  if (tab.notification && this.state.notifications[tab.notification]) {
                    return <Tab key={tab.value} value={tab.value} label={<><TabNotificationWrapper/>{tab.label}</>}/>
                  }
                  else {
                    return <Tab key={tab.value} value={tab.value} label={tab.label}/>
                  }
                }
              )}
            </TabsWrapper>
          </Box>
          <Card>
            <Divider/>
            <>
              {
                this.ReturnTab()
              }
            </>
          </Card>
          {this.state.notification && <Notifications success={true} text={'Дали сте сигурни да ја намалите претплатата? '} onCancel={this.cancelDialogNextSubscription} open={this.state.notification} onClose={this.closeNotifications}/>}
          {this.state.openDowngradeUpdate && <Notifications success={true} text={'Дали сакате веднаш да ја активирате промената со наредната претплата? '} onCancel={this.closeDowngradeUpdateDowngrade} open={this.state.openDowngradeUpdate} onClose={this.closeDowngradeUpdateNow}/>}

        </RootWrapper>

      );
    }

  }


}


export default withRouter(Profile);

