import React from 'react';
import {withRouter} from 'react-router-dom';
import AddReportDialog from "./Dialogs/AddReportDialog";
// import ReportResult from './ReportResult';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material';
import ReportResult from './ReportResult'
import SuspenseLoader from "../../../../components/SuspenseLoader";
import AddReportPageHeader from './AddReportPageHeader';

class Reports extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: true,
      loading: false,
      CompanyDetails: [],
      showResult: false,
      Godina: '',
      Report_type_ID: '',
      ReportName: '',

    }
  }

  componentDidMount(){
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/Company?id=${this.props.match.params.id}`, false, true, (callback) => {
      this.setState({
        id: callback.body.ID,
        CompanyDetails: {
        VAT:  callback.body.VAT,
        PIN:  callback.body.PIN,
        Occupation: callback.body.Occupation,
        Size: callback.body.Size,
        Establishment_year: callback.body.Establishment_year,
        Company_name:  callback.body.Company_name,
        Active:  callback.body.Active,
        loading: true,
        }
      })
    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  }

  showResults = (reportName,reportID,godina) => {
    this.setState({Godina: godina, Report_type_ID: reportID,ReportName: reportName, showResult: true, open:false})

  }


  render(){
    if(this.state.loading){
      return <SuspenseLoader/>
    }else {
      return <>
        <Grid
          sx={{ px: 4 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
        <AddReportDialog open={this.state.open} showResults={this.showResults}/>
        {this.state.ReportName ?
          <Grid item xs={12} sx={{mt: 2}}>
            <AddReportPageHeader id={this.state.id} pin={this.state.CompanyDetails.PIN} company_name={this.state.CompanyDetails.Company_name} Year={this.state.Godina} reportName={this.state.ReportName}/>
          </Grid> : ''}
        {this.state.showResult && <Grid item xs={12} sx={{mt: 2}}>
          <ReportResult reportID={2} CompanyID={this.props.match.params.id} id={this.state.id} reportType={this.state.Report_type_ID} Godina={this.state.Godina} CompanyDetails={this.state.CompanyDetails} />
        </Grid>}
        </Grid>
      </>
    }

  }
}

export default withRouter(Reports);
