import { useContext, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/LogoSign';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, Drawer, Hidden } from '@mui/material';

import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import SidebarMenuLong from './SidebarMenuLong'
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 120px;
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.md}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);


const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        justify-content: center;
        user-select: none;
        font-size: 26px;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);



const SidebarWrapperLong = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);



const TopSectionLong = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2)};
`
);

const TopSectionStyledLong = styled(TopSectionLong)(
  ({ theme }) => `
         margin-bottom: 10px;
         @media (min-width: ${theme.breakpoints.values.lg}px) {
         margin-bottom: -70px;
        }
`
);

function Sidebar(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  const [ sideBarOpen, setSideBarOpen ] = useState(false);
  const closeSidebarOpen = () => {
    setSideBarOpen(false);
  };
  const openSidebar = () => {
    setSideBarOpen(true);
  }

  return (
    <>
      <Hidden mdDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <p style={{fontSize: 14, fontWeight: "bold"}}>Bonitet.mk</p>
            </TopSection>
              <p style={{textAlign: 'center'}}><MenuTwoToneIcon onClick={openSidebar}/></p>

            <SidebarMenu onClick={() => closeSidebar()}  user={props.user} />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>

      <Hidden mdUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <TopSectionLong>
            <p style={{fontSize: 14, fontWeight: "bold"}}>Bonitet.mk</p>
          </TopSectionLong>
          <SidebarWrapperLong>

            <Scrollbars autoHide>
              <SidebarMenuLong user={props.users}  onClick={closeSidebar} type={props.type} />
            </Scrollbars>
          </SidebarWrapperLong>
        </Drawer>
      </Hidden>
      <Drawer
        anchor="left"
        open={sideBarOpen}
        onClose={closeSidebarOpen}
        variant="temporary"
        elevation={9}
      >
        <TopSectionStyledLong >
          <p style={{fontSize: 14, fontWeight: "bold"}}>Bonitet.mk</p>
        </TopSectionStyledLong>
        <SidebarWrapperLong>

          <Scrollbars autoHide>
            <SidebarMenuLong user={props.users}  onClick={closeSidebarOpen} type={props.type} />
          </Scrollbars>
        </SidebarWrapperLong>
      </Drawer>

    </>
  );
}

export default Sidebar;
