import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';

import ThemeSettings from 'src/components/ThemeSettings';

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        width: 100%;
        
       
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const CollapsedSidebarLayout = (props) => {
  console.log('props', props);
  return (
    <>
      {/*<Sidebar  user={props.user}/>*/}
      <MainWrapper>
        {
          (props.user && props.user.pendingTransactionID === null) && <Header user={props.user} logout={props.logout}/>
        }
        <MainContent>
          <ThemeSettings />
          {props.component}
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default CollapsedSidebarLayout;
