import React from 'react';

import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import { Grid, Box, Card, Typography,Dialog,DialogTitle,DialogContent } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Results from './Results';
import Notification from './Notification'
import UserCreateLimit from "./Dialogs/UserCreateLimit";


class ManagementUsers extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      users: [],
      usersCount: '',
      openCreateUserCheck: false,
      limit: ''
    }
  }

  componentDidMount()
  {
    this.renderUsers();

  }

  renderUsers = () => {
    window.makeRequest("GET", `/api/User`, false, true, (callback) => {
      let users = callback.body;
      window.makeRequest("GET", `/api/Limit?userLimit=${true}`, false, true, (callback) => {
        this.setState({users: users,usersCount: callback.body.usersCount, limit: callback.body.limit })
      }, (err) => {
        if (err) {
          console.log('err', err);
        }
      });

    }, (err) => {
      if(err) {
        console.log('err', err);
      }
    });
  };


  deleteUser = (id) => {
    let items = this.state.users.filter((user) => user.id !== id);
    this.setState({ users: items});
  };

  createUser = (response,b) => {
    if(response === 'OK'){
      this.renderUsers();
    } else {
      this.setState({openCreateUserCheck: true})
    }
  }
  closeCreateUserCheck = () => {this.setState({openCreateUserCheck: false})};

  createUserDialog = () => {
    return<UserCreateLimit ManageSubscription={this.props.ManageSubscription} open={this.state.openCreateUserCheck} onClose={this.closeCreateUserCheck} />
  }



  render() {
      const limit = this.state.limit;
      const usersCount = this.state.usersCount;
      return (
        <>
          <PageTitleWrapper>
            <PageHeader createUser={this.createUser} renderUsers={this.renderUsers}/>
          </PageTitleWrapper>

          <Grid
            sx={{px: 4}}
            container
            direction="row"
            alignItems="stretch"

          >

            <Notification text={'Моментален лимит на корисници:' + limit + ', Имате искористено:' + usersCount}/>

            <Grid item xs={12}>
              {this.createUserDialog()}
              <Results createUser={this.createUser} renderUsers={this.renderUsers} delete={this.deleteUser} users={this.state.users} />
            </Grid>
          </Grid>
          <Footer/>
        </>
      );


  }
}

export default ManagementUsers;
