import React from 'react';
import request from 'superagent';
import * as XLSX from 'xlsx';
import CustomSnackBar from '../CustomSnackBar'

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button
} from '@mui/material';
import DialogUploadReport from "./DialogUploadReport";
import DialogUploadResult from "./DialogUploadResults";
import Dialog_Import_Insert_type from "./Dialog_Import_Insert_type";
import Dialog_Insert from "./Dialog_Insert";
import {Zoom} from "@mui/material/index";
import EnqueueSnackBarMessage from '../EnqueueSnackbarMessage'
import UploadIcon from '@mui/icons-material/Upload';

class Import extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      files: '',
      openResult: false,
      InvalidEMBS: [],
      SuccessYearImport: [],
      ExistsYearImport: [],
      InvalidCompanyEMBS: [],
      fileUploadMessage: '',
      fileUpload: false,
      openUpload: false,
      openInsert: false,
      messageLoad: false,
    }
  }


  handleImportClose = () => {
    if(this.props.clientID){
      this.setState({openUpload: false,openInsert:false, messageLoad: false, files: ''});
    }else {
      this.setState({openUpload: false,openInsert:false, messageLoad: false, files: ''});
    }
    // this.props.successImport()

  };

  onChangeFile = (e, v) => {
    let filename = e.target.value.split('.').pop();

    if(v === 'upload'){
      if(filename === 'xlsx' || filename === 'xls' || filename === 'pdf' || filename === 'csv' || filename === 'doc' || filename === 'docx' || filename === 'txt'){
        this.setState({files: e.target.files, fileUploadMessage: '',fileUpload: true});
      } else {
        this.setState({fileUpload: false, fileUploadMessage: 'Невалиден формат на документот',})
      }
    } else {
      if(filename === 'xlsx'){
        this.setState({files: e.target.files, fileUploadMessage: '',fileUpload: true});
      } else {
        this.setState({fileUpload: false, fileUploadMessage: 'Невалиден формат на документот',})
      }
    }


  };

  handleUploadFile = (evt) => {
    this.setState({openUpload: false});
    const InvalidEMBS = [];
    const SuccessYearImport = [];
    const ExistsYearImport = [];
    const beforeEstablishment = [];
    const InvalidCompanyEMBS = [];
    const f = this.state.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type:'binary'});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, {header:0});
      let splitedData = data.split('\n');
      const rowHeader = splitedData[0];
      let count = 0;
      let Finances = [];
      splitedData.forEach((row, index) => {
        const insertData = (data, index) => {
          let keys = rowHeader.split(',');
          let values = data.split(',');
          let Finance = Object.fromEntries(keys.map((_, i) => [keys[i], values[i]]));
          if(parseInt(this.props.embs) !== parseInt(Finance[keys[0]]) ){
            if(Finance[keys[0]].length === 7){
                InvalidCompanyEMBS.push(Finance[keys[0]]);
                count++;
            } else {
              if(Finance[keys[0]].length > 0){
                InvalidEMBS.push(Finance[keys[0]]);
                count++;
              } else {
                count++;
              }
            }
          }else {
            Finances.push(Finance);
            count++;
          }
          if(splitedData.length === count+1){
            let AdminRoute = `/api/Admin_FinanceImport?clientID=${this.props.clientID}&ReportTypeID=2&Embs=${this.props.embs}`;
            let ClientRoute = `/api/FinanceImport?CompanyID=${this.props.id}&ReportTypeID=2`;

            window.makeRequest("POST", this.props.clientID ? AdminRoute : ClientRoute,{
              Finances
            }, true, (callback) => {
              //const year = callback.body.Year && callback.body.Year.toString();
              if(callback.body) {
                if(callback.body.hasOwnProperty('valid') && callback.body.valid.length > 0){
                  SuccessYearImport.push(callback.body.valid);
                }
                if(callback.body.hasOwnProperty('invalid') && callback.body.invalid.length > 0){
                  ExistsYearImport.push(callback.body.invalid);
                }
                if(callback.body.hasOwnProperty('beforeEstablishment') && callback.body.beforeEstablishment > 0){
                  beforeEstablishment.push(callback.body.beforeEstablishment)
                }
                this.setState({
                  InvalidEMBS: InvalidEMBS,
                  SuccessYearImport: SuccessYearImport,
                  ExistsYearImport: ExistsYearImport,
                  InvalidCompanyEMBS: InvalidCompanyEMBS,
                  redirectID: callback.body.redirectID,
                  beforeEstablishment: beforeEstablishment,
                  openResult: true,
                }, this.changeStatusImported)
              } else {
                this.setState({
                  InvalidEMBS: InvalidEMBS,
                  SuccessYearImport: SuccessYearImport,
                  ExistsYearImport: ExistsYearImport,
                  InvalidCompanyEMBS: InvalidCompanyEMBS,
                  openResult: true,
                }, this.changeStatusImported)

              }
            }, (err) => {
              if(err) {
                console.log(err);
              }
            });
          }
        }
        if(index !== 0) {
          insertData(row, index);
        }

      })
    };
    reader.readAsBinaryString(f);
  };


  changeStatusImported = () => {
    if(this.props.clientID) {
      this.props.handleAccept(this.props.importID);
    }
  }


  handleInsertFile = (evt) => {
    const data = new FormData();
    data.append('file', this.state.files[0]);
    request
      .post(`/api/ClientImports?ImportType=report&Embs=${this.props.embs}`)
      .set({'authorization': 'Bearer ' + window.localStorage.getItem('token')})
      .accept('application/json')
      .send(data).then((callback) => {
      this.setState({messageLoad: true},this.handleImportClose)
      this.props.successImport()
    }, (err) => {
      console.log('err', err);
    });

  }
  closeDialogResult = () => {
    if(!this.props.clientID) {
      if(this.state.SuccessYearImport[0] && this.state.SuccessYearImport[0].length > 1){
        window.location.href = `/company/${this.props.id}/dashboard`;
      } else if(this.state.SuccessYearImport[0] &&this.state.SuccessYearImport[0].length === 1){
        window.location.href = `/company/${this.props.id}/dashboard`;
      } else {
        this.setState({
          openResult: false,
          InvalidEMBS: [],
          SuccessYearImport: [],
          ExistsYearImport: [],
          InvalidCompanyEMBS: [],
        }, this.props.loading)


      }
    } else {
      this.setState({
        openResult: false,
        InvalidEMBS: [],
        SuccessYearImport: [],
        ExistsYearImport: [],
        InvalidCompanyEMBS: [],
      }, this.props.loading)
    }



  }

  closeInsertType = () => {
    this.setState({open: false}, this.props.loading)
  }

  StandardExcelDocument = () => {
    this.setState({open:false,openUpload: true})
  }

  RestDocument = () => {
    this.setState({open:false,openInsert: true})
  }

  closeRestDocument = () => {
    this.setState({openInsert: false})
  }

  showMessage = (message) => {
      // this.setState({messageLoad: false});
      return<CustomSnackBar message={message}/>
    // if(this.state.messageLoad){
    // }
  }

  render(){
    // console.log('beforeEstablishment', this.state.beforeEstablishment)
    return(<>
      {this.props.clientID ? <UploadIcon onClick={() => this.setState({openUpload: true})} fontSize="small"/> : <Button size={'small'} style={this.props.title && {width: this.props.btnWidth, height: '50px'}} variant={'contained'} onClick={() => this.setState({open: true})}>{this.props.title || 'Внеси извештај'}</Button> }

      <DialogUploadResult
        open={this.state.openResult}
        onClose={this.closeDialogResult}
        InvalidEMBS={this.state.InvalidEMBS}
        SuccessYearImport={this.state.SuccessYearImport}
        ExistsYearImport={this.state.ExistsYearImport}
        InvalidCompanyEMBS={this.state.InvalidCompanyEMBS}
        beforeEstablishment={this.state.beforeEstablishment}
      />
      {/*<CustomSnackBar message={'Успешно импортиран документ.'}/>*/}
      {/*{this.showMessage('Успешно импортиран документ')}*/}
      {/*{this.state.messageLoad && <CustomSnackBar message={'Успешно импортиран документ.'}/>}*/}
      {/*{this.state.messageLoad && <EnqueueSnackBarMessage message={'Успешно импортиран документ.'} type={'success'}/>}*/}
      {/*{this.state.messageLoad && <CustomSnackBar message={'Документот ќе биде обработен и вчитан од страна на нашите администратори.'}/>}*/}
      {/*{this.state.messageLoad && <EnqueueSnackBarMessage message={'Документот ќе биде обработен и вчитан од страна на нашите администратори.'} type={'success'}/>}*/}

      <Dialog_Import_Insert_type open={this.state.open} RestDocument={this.RestDocument} StandardExcelDocument={this.StandardExcelDocument} onClose={()=>this.setState({open: false})}/>
      <Dialog_Insert onClose={()=>this.setState({openInsert: false, open: true})} open={this.state.openInsert} fileUpload={this.state.fileUpload} fileUploadMessage={this.state.fileUploadMessage} showMessageLoad={this.props.showMessageLoad} handleUploadFile={this.handleInsertFile} onChange={(e) => this.onChangeFile(e, 'upload')}/>
      <DialogUploadReport open={this.state.openUpload} fileUpload={this.state.fileUpload} fileUploadMessage={this.state.fileUploadMessage} handleUploadFile={this.handleUploadFile} onChange={this.onChangeFile} onClose={()=>this.setState({openUpload: false, open: true})} />
    </>)
  }
}

export default Import;
